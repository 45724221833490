import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_METODO_DE_CANCELACION_DE_DENUNCIA,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_REPORT_CANCELLATION_METHODS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import ReportCancellationMethodFormScreenAdminReducer
    from "../formscreen/ReportCancellationMethodFormScreenAdminReducer";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'ReportCancellationMethodFormAdmin';

export interface ReportCancellationMethodAdminData {
    name: string,
}

interface ReportCancellationMethodCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: ReportCancellationMethodAdminData) => void,
    initialValues?: Partial<ReportCancellationMethodAdminData>,
    parentLoading?: boolean
}

const mapStateToProps = ReportCancellationMethodFormScreenAdminReducer.autoMapToProps();

type Props =
    ReportCancellationMethodCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<ReportCancellationMethodAdminData>

enum ReportMethodCancellationFormAdminFields {
    NAME = "name"
}

class ReportCancellationMethodCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, reportCancellationMethodLoading, pristine, parentLoading
        } = this.props;

        return (
            <Card loading={parentLoading || reportCancellationMethodLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_METODO_DE_CANCELACION_DE_DENUNCIA : TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={ReportMethodCancellationFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: ReportCancellationMethodAdminData) {
    const errors: FormErrors<ReportCancellationMethodAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<ReportCancellationMethodAdminData, ReportCancellationMethodCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ReportCancellationMethodCardFormAdmin);
