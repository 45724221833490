const base = '';

export const ROUTE_LOGIN = base + '/login';
export const ROUTE_SET_NEW_PASSWORD = base + '/recoverpassword/:token';

export const ROUTE_HOME = base + '/';

export const ROUTE_START_ALERT = base + '/home/alert';
export const ROUTE_START_PENDING = base + '/home/pending';

export const ROUTE_ADMIN_SETTINGS_APP = base + '/settings/app';

export const ROUTE_ADMIN_EXPLOITATIONS = base + '/exploitations';
export const ROUTE_ADMIN_EXPLOITATION_ADD = base + '/exploitation/add';
export const ROUTE_ADMIN_EXPLOITATION_DETAIL = base + '/exploitation/edit/:id';

export const ROUTE_ADMIN_REPORT_INVALIDATION_REASONS = base + '/reportInvalidationReasons';
export const ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD = base + '/reportInvalidationReason/add';
export const ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL = base + '/reportInvalidationReason/edit/:id';

export const ROUTE_ADMIN_LOG = base + '';

export const ROUTE_ADMIN_LOG_DETAIL = base + '';

export const ROUTE_ADMIN_CURRENT_USER_DETAIL = base + '';

export const ROUTE_ADMIN_USERS = base + '/users';
export const ROUTE_ADMIN_USER_ADD = base + '/user/add';
export const ROUTE_ADMIN_USER_DETAIL = base + '/user/edit/:id';

export const ROUTE_ADMIN_REPORT_CANCELLATION_METHODS = base + "/report/cancelation/methods";
export const ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD = base + "/report/cancel/method/add";
export const ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL = base + "/report/cancel/method/edit/:id";

export const ROUTE_ADMIN_VEHICLE_COLORS = base + "/vehicle/colors";
export const ROUTE_ADMIN_VEHICLE_COLOR_ADD = base + "/report/vehicle/color/add";
export const ROUTE_ADMIN_VEHICLE_COLOR_DETAIL = base + "/report/vehicle/color/edit/:id";

export const ROUTE_ADMIN_VEHICLE_BRANDS = base + "/vehicle/brands";
export const ROUTE_ADMIN_VEHICLE_BRAND_ADD = base + "/report/vehicle/brand/add";
export const ROUTE_ADMIN_VEHICLE_BRAND_DETAIL = base + "/report/vehicle/brand/edit/:id";

export const ROUTE_ADMIN_VEHICLE_MODELS = base + "/vehicle/models";
export const ROUTE_ADMIN_VEHICLE_MODEL_ADD = base + "/report/vehicle/model/add";
export const ROUTE_ADMIN_VEHICLE_MODEL_DETAIL = base + "/report/vehicle/model/edit/:id";

export const ROUTE_ADMIN_LAW_TYPES = base + '/lawTypes';
export const ROUTE_ADMIN_LAW_TYPE_ADD = base + '/lawType/add';
export const ROUTE_ADMIN_LAW_TYPE_DETAIL = base + '/lawType/edit/:id';

export const ROUTE_ADMIN_PARKING_LOSS_REASONS = base + '/parkingLossReason';
export const ROUTE_ADMIN_PARKING_LOSS_REASON_ADD = base + '/parkingLossReason/add';
export const ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL = base + '/parkingLossReason/edit/:id';

export const ROUTE_COMPANY_USER_PARKING_AREA_TYPES = base + '/parkingAreaTypes';
export const ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD = base + '/parkingAreaType/add';
export const ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL = base + '/parkingAreaType/edit/:id';

export const ROUTE_COMPANY_USER_ROUTES = base + '/routes';
export const ROUTE_COMPANY_USER_ROUTE_ADD = base + '/route/add';
export const ROUTE_COMPANY_USER_ROUTE_DETAIL = base + '/route/edit/:id';

export const ROUTE_COMPANY_USER_STREETS = base + '/streets';
export const ROUTE_COMPANY_USER_STREET_ADD = base + '/street/add';
export const ROUTE_COMPANY_USER_STREET_DETAIL = base + '/street/edit/:id';

export const ROUTE_COMPANY_USER_PARKING_METERS = base + '/parkingMeters';
export const ROUTE_COMPANY_USER_PARKING_METER_ADD = base + '/parkingMeter/add';
export const ROUTE_COMPANY_USER_PARKING_METER_DETAIL = base + '/parkingMeter/edit/:id';

export const ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES = base + '/parkingMeter/incidences';
export const ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL = base + '/parkingMeter/incidence/detail/:id';

export const ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS = base + '/parkingMeter/collections';
export const ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL = base + '/parkingMeter/collection/detail/:id';

export const ROUTE_COMPANY_USER_PARKING_LOSSES = base + '/parking/losses';
export const ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL = base + '/parking/loss/detail/:id';

export const ROUTE_COMPANY_USER_REPORTS = base + '/reports';
export const ROUTE_COMPANY_USER_REPORT_DETAIL = base + '/report/detail/:id';

export const ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE = base + '/report/cancel/file';
export const ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS = base + '/report/cancel/logs';
export const ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS_DATE = base + '/report/cancel/logs/:date';

export const ROUTE_COMPANY_USER_LAWS = base + '/laws';
export const ROUTE_COMPANY_USER_LAW_ADD = base + '/law/add';
export const ROUTE_COMPANY_USER_LAW_DETAIL = base + '/law/edit/:id';

export const ROUTE_COMPANY_USER_BLACK_LIST_RULES = base + '/blacklist/rules';
export const ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD = base + '/blacklist/rule/add';
export const ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL = base + '/blacklist/rule/edit/:id';

export const ROUTE_COMPANY_USER_CONTROLLERS = base + '/controllers';

export const ROUTE_COMPANY_USER_RATIFICATION_REPORTS = base + '/ratification/reports';
export const ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL = base + '/ratification/report/:id';

export const ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION = base + "/controllers/map";

export const ROUTE_COMPANY_USER_VEHICLES = base + '/vehicles';
export const ROUTE_COMPANY_USER_VEHICLE_ADD = base + '/vehicle/add';
export const ROUTE_COMPANY_USER_VEHICLE_DETAIL = base + '/vehicle/edit/:id';

export const ROUTE_COMPANY_USER_CHAT = base + '/chat';
export const ROUTE_COMPANY_USER_BROADCAST = base + '/broadcast';
export const ROUTE_COMPANY_USER_BROADCAST_ADD = base + '/broadcast/add';

export const ROUTE_COMPANY_USER_SENDTOPROCESS = base + '/sendtoprocess';
export const ROUTE_COMPANY_USER_SENDTOPROCESS_FILE = base + '/sendtoprocess/file';

export const ROUTE_COMPLAINT_VIEWER_SENT_REPORTS = base + '/reportsSent';
export const ROUTE_COMPLAINT_VIEWER_SENT_REPORT_DETAIL = base + '/reportSent/detail/:id';

export const ROUTE_COMPLAINT_VIEWER_REPORTS = base + '/reportsViewer';
export const ROUTE_COMPLAINT_VIEWER_REPORT_DETAIL = base + '/reportViewer/detail/:id';

export const ROUTE_POLICE_REPORTS = base + '/reportsPolice';
export const ROUTE_POLICE_REPORT_DETAIL = base + '/reportPolice/detail/:id';

export const ROUTE_USER_CERT = base + '/userCert';

export const ROUTE_ADMIN_COLLECTION_PROCESS = base + "/collection/process";
export const ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL = base + "/collection/process/detail/:id";
export const ROUTE_ADMIN_COLLECTION_PROCESS_ADD = base + "/collection/process/add";

export const ROUTE_COMPANY_USER_DELTA_CARS = base + "/deltacars";
export const ROUTE_COMPANY_USER_DELTA_CAR_ADD = base + "/deltacar/add";
export const ROUTE_COMPANY_USER_DELTA_CAR_DETAIL = base + "/deltacar/edit/:id";
