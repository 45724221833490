import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_LAW_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ANULABLE,
    TR_CODIGO_MUNICIPAL,
    TR_DENUNCIA_ANULABLE,
    TR_IMPORTE,
    TR_IMPORTE_ANULACION, TR_NO_ANULABLE,
    TR_TIPO_DE_LEY
} from "../../../../commons/I18n/constants";
import Law from "../../../../commons/model/Law";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import {goToRoute} from '../../../../utils/Router';
import React from 'react';

interface LawListTableProps {
    data: Law[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class LawListTable extends React.Component<LawListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_LAW_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((law: Law) => {
                    return (
                        <tr key={law.id}
                            onClick={() => this.goToItemDetail(law.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(law.id) : null}
                            <td>
                                {law.law_type && law.law_type.name}
                            </td>
                            <td>
                                {law.municipal_code}
                            </td>
                            <td>
                                {law.amount}
                            </td>
                            <td>
                                {law.cancellation_amount}
                            </td>
                            <td>
                                <ActiveLabel text={law.cancelable_report ? I18nUtils.tr(TR_ANULABLE) : I18nUtils.tr(TR_NO_ANULABLE)}
                                             active={law.cancelable_report}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_TIPO_DE_LEY)}
                    columnName={'law_type.name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CODIGO_MUNICIPAL)}
                    columnName={'description'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_IMPORTE)}
                    columnName={'amount'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_IMPORTE_ANULACION)}
                    columnName={'cancellation_amount'}
                    {...commonProps}
                    sort={undefined}
                />
                <Th text={I18nUtils.tr(TR_DENUNCIA_ANULABLE)}
                    columnName={'cancelable_report'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
