import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_DIAS, TR_IDENTIFICADOR, TR_MULTAS_ACUMULADAS} from "../../../../commons/I18n/constants";
import BlacklistRule from "../../../../commons/model/BlacklistRule";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface BlackListRuleListTableProps {
    data: BlacklistRule[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class BlackListRuleListTable extends React.Component<BlackListRuleListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((blackListRule: BlacklistRule) => {
                    return (
                        <tr key={blackListRule.id}
                            onClick={() => this.goToItemDetail(blackListRule.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(blackListRule.id) : null}
                            <td>
                                {blackListRule.id}
                            </td>
                            <td>
                                {blackListRule.reports}
                            </td>
                            <td>
                                {blackListRule.days}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_IDENTIFICADOR)}
                    columnName={'id'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_MULTAS_ACUMULADAS)}
                    columnName={'reports'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DIAS)}
                    columnName={'days'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
