
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {TR_DENUNCIAS, TR_DENUNCIAS_ENVIADAS, TR_DETALLES_DENUNCIA} from '../../../../commons/I18n/constants';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import TaskReportDetail from '../../../../commons/ws/report/TaskReportDetail';
import { BreadcrumbItem } from '../../../../components/breadcrumb/BreadcrumbManager';
import ScreenCard from '../../../../components/screen/ScreenCard';
import { AppIcon } from '../../../../config/Config';
import { ROUTE_COMPANY_USER_REPORT_DETAIL, ROUTE_COMPANY_USER_REPORTS } from '../../../../routing/Routes';
import { loadItemDetailsOrGoBack } from '../../../../utils/FormUtils';
import ReportDetailsCompanyUserActions from './ReportDetailsCompanyUserActions';
import ReportDetailsCompanyUserReducer from './ReportDetailsCompanyUserReducer';
import ReportDetailsCompanyUserCard from './ReportDetailsCompanyUserCard';
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import React from 'react';

const mapActionsToProps = ReportDetailsCompanyUserActions.autoMapToProps();

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    ReportDetailsCompanyUserReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps()
);

type Props = typeof mapStateToProps & RouteComponentProps<{id: string}> & typeof mapActionsToProps;

class ReportDetailsCompanyUserScreen extends React.Component<Props> {

    public componentDidMount(): void {
        const {id} = this.props.match.params;
        if ( id ) {
            // @ts-ignore
            loadItemDetailsOrGoBack(new TaskReportDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.dispatchResetDetailsReducer();
    }

    public render(): React.ReactNode {
        const {isComplaintViewerSent, report, loading, isComplaintViewer, isPolice} = this.props;
        const reportId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(isComplaintViewerSent ? TR_DENUNCIAS_ENVIADAS : TR_DENUNCIAS),
            url: ROUTE_COMPANY_USER_REPORTS,
            icon: AppIcon.REPORT
        }];

        if ( reportId ) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_DETALLES_DENUNCIA),
                url: ROUTE_COMPANY_USER_REPORT_DETAIL,
                icon: AppIcon.REPORT
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                <ReportDetailsCompanyUserCard
                    report={report}
                    loading={loading}
                    backRoute={ROUTE_COMPANY_USER_REPORTS}
                    isComplaintViewerSent={isComplaintViewerSent}
                    isComplaintViewer={isComplaintViewer}
                    isPolice={isPolice}
                />
            </ScreenCard>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailsCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
