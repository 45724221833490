import React from "react";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../routing/Routes";
import ExploitationListCardAdmin from "./ExploitationListCardAdmin";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_EXPLOTACIONES} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from '../../../../components/screen/ScreenCard';

export default class ExploitationListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_EXPLOTACIONES),
                    url: ROUTE_ADMIN_EXPLOITATIONS,
                    icon: AppIcon.EXPLOITATION,
                },
            ]}>
                <ExploitationListCardAdmin/>
            </ScreenCard>
        )
    }
}
