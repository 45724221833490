import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";
import {sha256} from "js-sha256";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE,
    TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES,
    TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA
} from "../../I18n/constants";

interface TaskSetPasswordRequest {
    token: string,
    password: string,
}

export default class TaskSetPassword extends BaseGenericRequest<TaskSetPasswordRequest, TaskResponse<{}>> {

    private readonly data: TaskSetPasswordRequest;

    public constructor(password: string, token: string) {
        super(METHOD.POST, Urls.URL_USER_SET_PASSWORD);
        this.data = {password, token};

        this.setErrorMessage(401, I18nUtils.tr(TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA));
        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
        this.setErrorMessage(411, I18nUtils.tr(TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));
    }

    protected getRequest(): TaskSetPasswordRequest {
        return {
            ...this.data,
            password: sha256(this.data.password),
        }
    }
}
