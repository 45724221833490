import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ELIMINAR_LEYES, TR_LEYES, TR_NO_EXISTEN_LEYES} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_LAW_TYPE_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import LawTypeListAdminReducer from "./LawTypeListAdminReducer";
import TaskLawTypeDelete from "../../../../commons/ws/lawType/TaskLawTypeDelete";
import LawTypeListTable from "./LawTypeListTable";
import TaskLawTypeList from "../../../../commons/ws/lawType/TaskLawTypeList";
import React from 'react';

const mapStateToProps = LawTypeListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class LawTypeListCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, lawTypes, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_LEYES)}
                      sort={{column: 'name'}}
                      TaskList={TaskLawTypeList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_LEYES)}}
                      data={lawTypes}
                      error={error}
                      ItemsTable={LawTypeListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_LEYES),
                          Task: TaskLawTypeDelete,
                      }}
                      addItem={ROUTE_ADMIN_LAW_TYPE_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(LawTypeListCardAdmin as unknown as React.ComponentType<void>);
