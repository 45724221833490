import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {UserCertData} from "../../model/UserCert";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_EL_CERTIFICADO_NO_ES_VALIDO, TR_LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA} from "../../I18n/constants";

interface UserCertDataResponse {
    url: string
}

export default class TaskUserCertUpload extends BaseGenericRequest<FormData, TaskResponse<UserCertDataResponse>> {

    private readonly data: UserCertData;

    public constructor(data: UserCertData) {
        super(METHOD.POST, Urls.URL_USER_CERT_UPLOAD, true);
        this.data = data;

        this.setErrorMessage(431, I18nUtils.tr(TR_EL_CERTIFICADO_NO_ES_VALIDO));
        this.setErrorMessage(490, I18nUtils.tr(TR_LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA));
    }

    protected getRequest(): FormData {
        const data = new FormData();
        data.append('file', this.data.file, this.data.file.name);
        data.append('override', this.data.override ? "true" : "false");
        data.append('passphrase', this.data.passphrase);
        data.append('password', this.data.password);
        return data;
    }
}
