import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5,
    TR_MARCA_DE_VEHICULO,
    TR_MODELO_DE_VEHICULO,
    TR_MODELO_OBLIGATORIO,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_MODELO_DE_VEHICULO, TR_ORDEN, TR_ORDEN_OBLIGATORIO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_VEHICLE_MODELS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import VehicleModelFormScreenAdminReducer from "../formscreen/VehicleModelFormScreenAdminReducer";
import FormSelect from "../../../../components/form/FormSelect";
import FormInputOption from "../../../../components/form/FormInputOption";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'VehicleModelFormAdmin';

export interface VehicleModelAdminData {
    name: string,
    vehicleBrandId: string,
    order: number,
}

interface VehicleModelCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: VehicleModelAdminData) => void,
    initialValues?: Partial<VehicleModelCardFormAdminProps>,
    parentLoading?: boolean,
}

const mapStateToProps = VehicleModelFormScreenAdminReducer.autoMapToProps();

type Props =
    VehicleModelCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<VehicleModelAdminData>

enum VehicleModelFormAdminFields {
    NAME = "name",
    VEHICLE_BRAND_ID = "vehicleBrandId",
    ORDER = "order",
}

class VehicleModelCardFormAdmin extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskVehicleBrandList({ limit: 0 }).execute();
    }

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, vehicleModelLoading, pristine, vehicleBrands, parentLoading
        } = this.props;

        const vehicleBrandOptions: FormInputOption[] = vehicleBrands.map((vehicleBrand) => {
            return {name: vehicleBrand.name, value: vehicleBrand.id}
        });

        return (
            <Card loading={parentLoading || vehicleModelLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_MODELO_DE_VEHICULO : TR_NUEVO_MODELO_DE_VEHICULO)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={VehicleModelFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 5, lg: 5}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_MARCA_DE_VEHICULO)}
                                name={VehicleModelFormAdminFields.VEHICLE_BRAND_ID}
                                component={FormSelect}
                                blankOptionText={' '}
                                options={vehicleBrandOptions}
                                col={{md: 5, lg: 5}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_ORDEN)}
                                name={VehicleModelFormAdminFields.ORDER}
                                component={FormInput}
                                col={{md: 2, lg: 2}}
                                type={'number'}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_MODELS)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: VehicleModelAdminData) {
    const errors: FormErrors<VehicleModelAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.vehicleBrandId || values.vehicleBrandId === "-1") {
        errors.vehicleBrandId = I18nUtils.tr(TR_MODELO_OBLIGATORIO);
    }
    if (!values.order) {
        errors.order = I18nUtils.tr(TR_ORDEN_OBLIGATORIO);
    } else if (values.order < 0 || values.order > 5) {
        errors.order = I18nUtils.tr(TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5);
    }
    return errors;
}

export default compose(
    reduxForm<VehicleModelAdminData, VehicleModelCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(VehicleModelCardFormAdmin);
