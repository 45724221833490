import {Field, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CANCELAR,
    TR_CONTROLADOR,
    TR_ESTADO_INCIDENCIA,
    TR_FECHA,
    TR_FECHA_SOLUCIONADA,
    TR_INCIDENCIA_PARQUIMETRO,
    TR_NO_SOLUCIONADA,
    TR_NUEVA_INCIDENCIA_PARQUIMETRO,
    TR_OBSERVACIONES,
    TR_PARQUIMETRO,
    TR_SOLUCIONADA,
    TR_SOLUCIONADA_POR_CONTROLADOR
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import {ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES} from "../../../../routing/Routes";
import FormTextArea from "../../../../components/form/FormTextArea";
import FormInput from "../../../../components/form/FormInput";
import Col from "../../../../components/Col";
import Button from "../../../../components/buttons/Button";
import FormSwitch from "../../../../components/form/FormSwitch";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'ParkingMeterCardFormCompanyUser';

export interface ParkingMeterIncidenceCardFormCompanyUserData {
    parking_meter: string,
    state: string,
    observations: string,
    date: string,
    controller: string,
    solved: boolean,
    solved_date: string,
    solved_controller: string,
}

interface ParkingMeterIncidenceCardFormExtProps {
    parkingMeterIncidenceId?: string,
    initialValues?: Partial<ParkingMeterIncidenceCardFormCompanyUserData>,
    parentLoading: boolean,
}

interface ParkingMeterIncidenceCardFormProps {
    initialValues: Partial<ParkingMeterIncidenceCardFormCompanyUserData>,
}

type Props = ParkingMeterIncidenceCardFormExtProps & ParkingMeterIncidenceCardFormProps;

enum ParkingAreaTypeFormAdminFields {
    PARKING_METER = "parking_meter",
    STATE = "state",
    OBSERVATIONS = "observations",
    DATE = "date",
    CONTROLLER = "controller",
    SOLVED = "solved",
    SOLVED_DATE = "solved_date",
    SOLVED_CONTROLLER = "solved_controller",
}

class ParkingMeterIncidenceCardFormCompanyUser extends React.Component<Props> {

    public render(): React.ReactNode {
        const {initialValues, parentLoading} = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_INCIDENCIA_PARQUIMETRO : TR_NUEVA_INCIDENCIA_PARQUIMETRO)}/>
                <CardBody>
                    <form>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_PARQUIMETRO)}
                                name={ParkingAreaTypeFormAdminFields.PARKING_METER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_ESTADO_INCIDENCIA)}
                                name={ParkingAreaTypeFormAdminFields.STATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_FECHA)}
                                name={ParkingAreaTypeFormAdminFields.DATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_CONTROLADOR)}
                                name={ParkingAreaTypeFormAdminFields.CONTROLLER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                labelEnabled={I18nUtils.tr(TR_SOLUCIONADA)}
                                labelDisabled={I18nUtils.tr(TR_NO_SOLUCIONADA)}
                                name={ParkingAreaTypeFormAdminFields.SOLVED}
                                component={FormSwitch}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_FECHA_SOLUCIONADA)}
                                name={ParkingAreaTypeFormAdminFields.SOLVED_DATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_SOLUCIONADA_POR_CONTROLADOR)}
                                name={ParkingAreaTypeFormAdminFields.SOLVED_CONTROLLER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_OBSERVACIONES)}
                                name={ParkingAreaTypeFormAdminFields.OBSERVATIONS}
                                component={FormTextArea}
                                col={{md: 12, lg: 12}}
                                disabled
                            />
                        </Row>
                        <Row className='p-t-20'>
                            <Col sm={4} md={6} lg={8}/>
                            <Col sm={4} md={3} lg={2}/>
                            <Col sm={4} md={3} lg={2}>
                                <Button
                                    text={I18nUtils.tr(TR_CANCELAR)}
                                    onClick={() => goToRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES)}
                                    type={'button'}
                                    block={true}
                                    className={'btn-lg btn-default'}
                                />
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

export default reduxForm<ParkingMeterIncidenceCardFormCompanyUserData, ParkingMeterIncidenceCardFormExtProps>({
    form: FORM_NAME,
    enableReinitialize: true,
})(ParkingMeterIncidenceCardFormCompanyUser as any) as React.ComponentType<ParkingMeterIncidenceCardFormExtProps>;
