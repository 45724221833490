
import { TR_ENVIO_A_TRAMITE, TR_FICHERO_DE_ENVIO_A_TRAMITE } from '../../../../commons/I18n/constants';
import { ROUTE_COMPANY_USER_SENDTOPROCESS, ROUTE_COMPANY_USER_SENDTOPROCESS_FILE } from '../../../../routing/Routes';
import ScreenCard from '../../../../components/screen/ScreenCard';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { AppIcon } from '../../../../config/Config';
import { BreadcrumbItem } from '../../../../components/breadcrumb/BreadcrumbManager';
import { connect } from 'react-redux';
import SendToProccessActions from '../SendToProccessActions';
import SendToProccessReducer from '../SendToProccessReducer';
import SendToProcessFileCard from './SendToProcessFileCard';
import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import CurrentUserReducer from '../../../../redux/CurrentUserReducer';
import TaskUserCurrentDetail from '../../../../commons/ws/user/TaskUserCurrentDetail';
import React from 'react';

const mapActionsToProps = SendToProccessActions.autoMapToProps();
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    SendToProccessReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps()
);

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class ReportDetailsCompanyUserScreen extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskUserCurrentDetail().execute();
    }

    public componentWillUnmount(): void {
        this.props.dispatchResetSendToProccessReducer();
    }

    public render(): React.ReactNode {
        const {date, fileUrl, currentUser, loadingCurrentUser} = this.props;

        const breadcrumbs: BreadcrumbItem[] = [
            {
                name: I18nUtils.tr(TR_ENVIO_A_TRAMITE),
                url: ROUTE_COMPANY_USER_SENDTOPROCESS,
                icon: AppIcon.SEND
            },
            {
                name: I18nUtils.tr(TR_FICHERO_DE_ENVIO_A_TRAMITE),
                url: ROUTE_COMPANY_USER_SENDTOPROCESS_FILE,
                icon: AppIcon.ATTACH_FILE
            }
        ];

        const explotationId = currentUser ? currentUser.exploitation.id : '';

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                <SendToProcessFileCard
                    date={date}
                    fileUrl={fileUrl}
                    loading={loadingCurrentUser}
                    explotationId={explotationId}
                />
            </ScreenCard>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailsCompanyUserScreen) as any;
