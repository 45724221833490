import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CALLES, TR_ELIMINAR_CALLES, TR_NO_EXISTEN_CALLES} from "../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_STREET_ADD} from "../../../../routing/Routes";
import StreetCompanyUserActions from "./StreetCompanyUserActions";
import StreetCompanyUserReducer from "./StreetCompanyUserReducer";
import TaskStreetList from "../../../../commons/ws/street/TaskStreetList";
import TaskStreetDelete from "../../../../commons/ws/route/TaskStreetDelete";
import StreetListTable from "./StreetListTable";
import React from 'react';

const mapActionsToProps = StreetCompanyUserActions.autoMapToProps();
const mapStateToProps = StreetCompanyUserReducer.autoMapToProps();

class StreetCardCompanyUser extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, streets, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_CALLES)}
                      sort={{column: 'name'}}
                      TaskList={TaskStreetList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_CALLES)}}
                      data={streets}
                      error={error}
                      ItemsTable={StreetListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_CALLES),
                          Task: TaskStreetDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_STREET_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(StreetCardCompanyUser as unknown as React.ComponentType<void>);
