
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_NUEVO_VEHICULO_EMBARCADO,
    TR_VEHICULO_EMBARCADO,
    TR_VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE,
    TR_VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE,
    TR_VEHICULOS_EMBARCADOS
} from "../../../../commons/I18n/constants";
import {generateRoute} from "../../../../commons/utils/Router";
import {
    ROUTE_COMPANY_USER_DELTA_CAR_ADD,
    ROUTE_COMPANY_USER_DELTA_CAR_DETAIL,
    ROUTE_COMPANY_USER_DELTA_CARS
} from "../../../../routing/Routes";
import {connect} from "react-redux";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {goToRoute} from '../../../../utils/Router';
import DeltaCarFormScreenCompanyUserReducer from "./DeltaCarFormScreenCompanyUserReducer";
import DeltaCarFormCompanyUserScreenActions from "./DeltaCarFormCompanyUserScreenActions";
import TaskDeltaCarDetail from "../../../../commons/ws/deltaCar/TaskDeltaCarDetail";
import TaskDeltaCarEdit from "../../../../commons/ws/deltaCar/TaskDeltaCarEdit";
import DeltaCar, {DeltaCarData} from "../../../../commons/model/DeltaCar";
import TaskDeltaCarAdd from "../../../../commons/ws/deltaCar/TaskDeltaCarAdd";
import {DeltaCarCompanyUserData} from "../formdetail/DeltaCarCardFormCompanyUser";
import DeltaCarCardFormCompanyUser from "../formdetail/DeltaCarCardFormCompanyUser";
import React from 'react';

const mapStateToProps = DeltaCarFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = DeltaCarFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class DeltaCarFormCompanyUserScreen extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.state = {modal: false};
    }

    public componentDidMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskDeltaCarDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearDeltaCarFormScreenAdminReducer();
    }

    private onSubmit = (values: DeltaCarCompanyUserData): void => {
        const deltaCarId = this.props.match.params.id;

        const submitData: DeltaCarData = {
            name: values.name,
            license_plate: values.license_plate
        };


        if (deltaCarId) {
            executeItemTask(
                new TaskDeltaCarEdit(deltaCarId, submitData),
                () => goToRoute(ROUTE_COMPANY_USER_DELTA_CARS),
                I18nUtils.tr(TR_VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(new TaskDeltaCarAdd(submitData),
                () => goToRoute(ROUTE_COMPANY_USER_DELTA_CARS),
                I18nUtils.tr(TR_VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const deltaCarId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_VEHICULOS_EMBARCADOS),
            url: ROUTE_COMPANY_USER_DELTA_CARS,
            icon: AppIcon.CAR
        }];

        if (deltaCarId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_VEHICULO_EMBARCADO),
                url: generateRoute(ROUTE_COMPANY_USER_DELTA_CAR_DETAIL, {id: deltaCarId}),
                icon: AppIcon.CAR
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_VEHICULO_EMBARCADO),
                url: ROUTE_COMPANY_USER_DELTA_CAR_ADD,
                icon: AppIcon.CAR
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderDeltaCarForm()}
            </ScreenCard>
        );

    }

    private renderDeltaCarForm(): React.ReactNode {
        const {deltaCarFormScreenLoading, deltaCar} = this.props;
        let initialValues: DeltaCarCompanyUserData | undefined;

        if (deltaCar) {
            const deltaCarData: DeltaCar = deltaCar;
            initialValues = {
                license_plate: deltaCarData.license_plate,
                name: deltaCarData.name
            };
        }

        return (
            <DeltaCarCardFormCompanyUser
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                parentLoading={deltaCarFormScreenLoading}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DeltaCarFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
