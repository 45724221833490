import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_MODELO_DE_VEHICULO,
    TR_MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE,
    TR_MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE,
    TR_MODELOS_DE_VEHICULOS,
    TR_NUEVO_MODELO_DE_VEHICULO
} from "../../../../commons/I18n/constants";
import {
    ROUTE_ADMIN_VEHICLE_MODEL_ADD,
    ROUTE_ADMIN_VEHICLE_MODEL_DETAIL,
    ROUTE_ADMIN_VEHICLE_MODELS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import VehicleModelFormScreenAdminReducer from "./VehicleModelFormScreenAdminReducer";
import VehicleModelFormAdminScreenActions from "./VehicleModelFormAdminScreenActions";
import TaskVehicleModelDetail from "../../../../commons/ws/vehicleModel/TaskVehicleModelDetail";
import VehicleModelCardFormAdmin, {VehicleModelAdminData} from "../formdetail/VehicleModelCardFormAdmin";
import {VehicleModelData} from "../../../../commons/model/VehicleModel";
import TaskVehicleModelEdit from "../../../../commons/ws/vehicleModel/TaskVehicleModelEdit";
import TaskVehicleModelAdd from "../../../../commons/ws/vehicleModel/TaskVehicleModelAdd";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = VehicleModelFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = VehicleModelFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class VehicleModelFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskVehicleModelDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: VehicleModelAdminData): void => {
        const existVehicleModel = this.props.match.params.id !== undefined;

        const submitData: VehicleModelData = {
            name: values.name,
            vehicle_brand_id: values.vehicleBrandId,
            order: values.order,
        };

        if (existVehicleModel) {
            executeItemTask(
                new TaskVehicleModelEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_VEHICLE_MODELS),
                I18nUtils.tr(TR_MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskVehicleModelAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_VEHICLE_MODELS),
                I18nUtils.tr(TR_MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const vehicleModelId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_MODELOS_DE_VEHICULOS),
            url: ROUTE_ADMIN_VEHICLE_MODELS,
            icon: AppIcon.CAR
        }];

        if (vehicleModelId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_MODELO_DE_VEHICULO),
                url: generateRoute(ROUTE_ADMIN_VEHICLE_MODEL_DETAIL, {id: vehicleModelId}),
                icon: AppIcon.CAR
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_MODELO_DE_VEHICULO),
                url: ROUTE_ADMIN_VEHICLE_MODEL_ADD,
                icon: AppIcon.CAR
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderVehicleModelForm()}
            </ScreenCard>
        )
    }

    private renderVehicleModelForm(): React.ReactNode {
        const {vehicleModelLoading, vehicleModel} = this.props;

        let initialValues: Partial<VehicleModelAdminData> | undefined;

        if (vehicleModel) {
            initialValues = {
                name: vehicleModel.name ? vehicleModel.name : '',
                vehicleBrandId: vehicleModel.vehicle_brand ? vehicleModel.vehicle_brand.id : "-1",
                order: vehicleModel.order || 0,
            }
        }
        return (
            <VehicleModelCardFormAdmin
                parentLoading={vehicleModelLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(VehicleModelFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
