import LawListCompanyUserReducer from "./LawListCompanyUserReducer";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ARTICULOS, TR_ELIMINAR_ARTICULOS, TR_NO_EXISTEN_ARTICULOS} from "../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_LAW_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import TaskLawList from "../../../../commons/ws/law/TaskLawList";
import TaskLawDelete from "../../../../commons/ws/law/TaskLawDelete";
import {connect} from "react-redux";
import LawListTable from "./LawListTable";
import React from 'react';

const mapStateToProps = LawListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class LawListCardCompanyUser extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, laws, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_ARTICULOS)}
                      sort={{column: 'code'}}
                      TaskList={TaskLawList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_ARTICULOS)}}
                      data={laws}
                      error={error}
                      ItemsTable={LawListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_ARTICULOS),
                          Task: TaskLawDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_LAW_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(LawListCardCompanyUser as unknown as React.ComponentType<void>);
