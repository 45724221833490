import { ROUTE_ADMIN_REPORT_INVALIDATION_REASONS } from '../../../../routing/Routes';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { TR_RAZONES_DE_INVALIDACION } from '../../../../commons/I18n/constants';
import { AppIcon } from '../../../../config/Config';
import ScreenCard from '../../../../components/screen/ScreenCard';
import ReportInvalidationReasonAdminListCard from './ReportInvalidationReasonAdminListCard';
import React from 'react';

export default class ReportInvalidationReasonAdminListScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_RAZONES_DE_INVALIDACION),
                    url: ROUTE_ADMIN_REPORT_INVALIDATION_REASONS,
                    icon: AppIcon.DASHBOARD
                }
            ]}>
                <ReportInvalidationReasonAdminListCard/>
            </ScreenCard>
        );
    }
}
