
import {Modal} from "react-bootstrap";
import {BaseModalProps} from "../../../base/modal/BaseModal";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {
    TR_CERTIFICADO, TR_CERTIFICADO_OBLIGATORIO,
    TR_CONTRASENA,
    TR_CONTRASENA_OBLIGATORIA,
    TR_CREAR_CERTIFICADO, TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO
} from "../../../commons/I18n/constants";
import Row from "../../../components/Row";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import FormInput from "../../../components/form/FormInput";
import FormCardFooter from "../../../components/form/FormCardFooter";
import FormInputFile from "../../../components/form/FormInputFile";
import {FILE_CERT_EXTENSION, FILE_CERT_TYPE} from "../../../commons/model/UserCert";
import React from 'react';

const FORM_NAME = 'UserCertUploadForm';

export interface UserCertCompanyUserUploadModalData {
    files: File | FileList,
    password: string,
}

interface UserCertCompanyUserUploadModalExtProps {
    show: boolean,
    onSubmit: (data: UserCertCompanyUserUploadModalData) => void,
    onClose: () => void,
    title?: string,
}

enum UserCertCompanyUserUploadModalFields {
    FILE = 'files',
    PASSWORD = 'password',
}

type Props = BaseModalProps & UserCertCompanyUserUploadModalExtProps & InjectedFormProps<UserCertCompanyUserUploadModalData>;

class UserCertCompanyUserUploadModal extends React.Component<Props> {

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.show !== prevProps.show) {
            if (this.props.show) {
                this.props.reset();
            }
        }
    }

    public render(): React.ReactNode {
        const {show, onSubmit, onClose, handleSubmit, invalid, pristine, title} = this.props;

        return (
            <Modal onHide={onClose} show={show} bsSize={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>{title || I18nUtils.tr((TR_CREAR_CERTIFICADO))}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_CONTRASENA)}
                                name={UserCertCompanyUserUploadModalFields.PASSWORD}
                                component={FormInput}
                                type="password"
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_CERTIFICADO)}
                                name={UserCertCompanyUserUploadModalFields.FILE}
                                component={FormInputFile}
                                accept={FILE_CERT_TYPE}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>

                        <FormCardFooter
                            isUpdate={false}
                            invalid={invalid}
                            pristine={pristine}
                            cancelHandler={onClose}/>
                    </form>
                </Modal.Body>

            </Modal>
        )
    }
}

function validate(values: UserCertCompanyUserUploadModalData) {
    const errors: FormErrors<UserCertCompanyUserUploadModalData> = {};

    if (!values.password || values.password.length === 0) {
        errors.password = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA);
    }
    // @ts-ignore
    if (!values.files || values.files.length === 0) {
        errors.files = I18nUtils.tr(TR_CERTIFICADO_OBLIGATORIO);
    } else {
        // @ts-ignore: type File
        if (values.files && !!values.files.name && values.files.name !== FILE_CERT_TYPE) { // tipo File
            errors.files = `${I18nUtils.tr(TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${FILE_CERT_EXTENSION}`;
        }
        // @ts-ignore: type FileList
        if (values.files && !!values.files.length && values.files[0].type !== FILE_CERT_TYPE) { // tipo FileList
            errors.files = `${I18nUtils.tr(TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${FILE_CERT_EXTENSION}`;
        }
    }

    return errors;
}

export default (reduxForm<UserCertCompanyUserUploadModalData>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true
})(UserCertCompanyUserUploadModal) as any) as React.ComponentType<UserCertCompanyUserUploadModalExtProps>;
