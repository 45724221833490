import BaseGenericRequest from "../BaseGenericRequest";
import ParkingAreaType, {ParkingAreaTypeData} from "../../model/ParkingAreaType";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_ELEMENTO_SIMILAR} from "../../I18n/constants";

export default class TaskParkingAreaTypeAdd extends BaseGenericRequest<ParkingAreaTypeData, TaskResponse<ParkingAreaType>> {

    private readonly data: ParkingAreaTypeData;

    public constructor(data: ParkingAreaTypeData) {
        super(METHOD.POST, Urls.URL_PARKING_AREA_TYPE_ADD);
        this.data = data;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_ELEMENTO_SIMILAR));
    }

    protected getRequest(): ParkingAreaTypeData {
        return this.data;
    }
}
