import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CODIGO_ALZIRA,
    TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5,
    TR_MARCA_DE_VEHICULO,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVA_MARCA_DE_VEHICULO, TR_ORDEN, TR_ORDEN_OBLIGATORIO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_VEHICLE_BRANDS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import VehicleBrandFormScreenAdminReducer from "../formscreen/VehicleBrandFormScreenAdminReducer";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'VehicleBrandFormAdmin';

export interface VehicleBrandAdminData {
    name: string,
    order: number,
    alziraCode: string,
}

interface VehicleBrandCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: VehicleBrandAdminData) => void,
    initialValues?: Partial<VehicleBrandCardFormAdminProps>,
    parentLoading?: boolean,
}

const mapStateToProps = VehicleBrandFormScreenAdminReducer.autoMapToProps();

type Props =
    VehicleBrandCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<VehicleBrandCardFormAdminProps>

enum VehicleBrandFormAdminFields {
    NAME = "name",
    ORDER = "order",
    ALZIRA_CODE = "alziraCode",
}

class VehicleBrandCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, vehicleBrandLoading, pristine, parentLoading
        } = this.props;

        return (
            <Card loading={parentLoading || vehicleBrandLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_MARCA_DE_VEHICULO : TR_NUEVA_MARCA_DE_VEHICULO)}/>
                <CardBody>
                    {/*
                    // @ts-ignore*/}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={VehicleBrandFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_ORDEN)}
                                name={VehicleBrandFormAdminFields.ORDER}
                                component={FormInput}
                                col={{md: 2, lg: 1}}
                                type={'number'}
                            />
                            <Field
                                label={I18nUtils.tr(TR_CODIGO_ALZIRA)}
                                name={VehicleBrandFormAdminFields.ALZIRA_CODE}
                                component={FormInput}
                                col={{md: 2, lg: 1}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_BRANDS)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: VehicleBrandAdminData) {
    const errors: FormErrors<VehicleBrandAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.order) {
        errors.order = I18nUtils.tr(TR_ORDEN_OBLIGATORIO);
    } else if (values.order < 0 || values.order > 5) {
        errors.order = I18nUtils.tr(TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5);
    }
    return errors;
}

export default compose(
    reduxForm<VehicleBrandAdminData, VehicleBrandCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(VehicleBrandCardFormAdmin);
