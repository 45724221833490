
import UserCert from "../../../commons/model/UserCert";
import DateFormatter, {DateHelpers, DateUtils} from "../../../utils/DateFormatter";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {
    TR_CERTIFICADO_VALIDO_HASTA_EL,
    TR_EL_CERTIFICADO_CADUCA_EL,
    TR_EL_CERTIFICADO_CADUCO_EL
} from "../../../commons/I18n/constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import classNames from "classnames";
import {AppIcon} from "../../../config/Config";
import React from 'react';

interface UserCertTooltipInfoProps {
    userCert: UserCert;
    key: string;
}

export default class UserCertTooltipInfo extends React.Component<UserCertTooltipInfoProps> {

    public render(): React.ReactNode {
        const {userCert, key} = this.props;

        let message = '';
        let iconStyle = '';
        // @ts-ignore
        if (DateUtils.isGreaterThan(DateUtils.getCurrentDate(), userCert.end_date)) { // expired
            message = `${I18nUtils.tr(TR_EL_CERTIFICADO_CADUCO_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
            iconStyle = 'invalid';
        } else {
            const nextExpirationDate = DateHelpers.getXNextDays(14);
            // @ts-ignore
            if (DateUtils.isGreaterThan(nextExpirationDate, userCert.end_date)) { // near expiration
                message = `${I18nUtils.tr(TR_EL_CERTIFICADO_CADUCA_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
                iconStyle = 'near-expiration';
            } else {
                message = `${I18nUtils.tr(TR_CERTIFICADO_VALIDO_HASTA_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
                iconStyle = 'valid';
            }
        }

        const tooltip: React.ReactNode = (<Tooltip id={'userCert'} key={`${key}-tooltip`}>{message}</Tooltip>);
        const iconClassComponent = classNames('material-icons', `digital-sign-icon ${iconStyle}`);
        return (
            <OverlayTrigger key={key} overlay={tooltip} placement={"top"}>
                <i className={iconClassComponent}>{AppIcon.DIGITAL_SIGN_ENABLED}</i>
            </OverlayTrigger>
        )
    }
}
