
import {ROUTE_COMPANY_USER_ROUTES} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_RUTAS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import RouteCardCompanyUser from "./RouteCardCompanyUser";
import React from 'react';

export default class RouteCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_RUTAS),
                    url: ROUTE_COMPANY_USER_ROUTES,
                    icon: AppIcon.ROUTE
                }
            ]}>
                <RouteCardCompanyUser/>
            </ScreenCard>
        )
    }
}
