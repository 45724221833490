import moment from 'moment';
import I18nUtils from "../commons/I18n/I18nUtils";

export default class DateFormatter {

    public static formatDate(date: Date | string, format: string = 'DD/MM/YYYY'): string {
        if (date) {
            return moment(date).format(format);
        }
        return '';
    }

    public static formatDay(date: Date | string): string {
        if (date) {
            moment.locale(I18nUtils.getLocale());
            return moment(date).format('dddd');
        }
        return '';
    }

    public static formatDatetime(date: Date | string): string {
        if (date) {
            return moment(date).format('DD/MM/YYYY - HH:mm');
        }
        return '';
    }

    public static formatTime(date: Date | string): string {
        if (date) {
            return moment(date).format('HH:mm');
        }
        return '';
    }

    public static formatInputDate(date?: Date | string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        return '';
    }

}

export class DateHelpers {

    public static getPreviousMonday(date: Date): Date {
        const mondayIndex = 1;
        return moment(date).isoWeekday(mondayIndex).toDate();
    }

    public static getXDaysAgo(days: number, date: Date = new Date()): Date {
        return moment(date).subtract(days, 'd').toDate();
    }

    public static getXNextDays(days: number, date: Date = new Date()): Date {
        return moment(date).add(days, 'd').toDate();
    }

    public static getFirstSecond(date: Date): Date {
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }

    public static getLastSecond(date: Date): Date {
        date.setUTCHours(23, 59, 59, 999);
        return date;
    }

    public static getDateFromInputs(date: string, time: string): Date {
        return moment(`${date} ${time}`).toDate();
    }
}

export class DateUtils {
    public static isToday(date: Date): boolean {
        return moment(date).isSame(moment(), 'day');
    }

    public static getYesterday(): Date {
        const today = new Date();
        // @ts-ignore
        return today.setDate(today.getDate() - 1);
    }

    public static isYesterday(date: Date): boolean {
        return moment(date).isSame(this.getYesterday(), 'day');
    }

    public static isSameDay(firstDate: Date, secondDate: Date): boolean {
        return moment(firstDate).isSame(secondDate, 'day');
    }

    public static getCurrentDate(): Date {
        const today = new Date();
        today.setDate(today.getDate());
        // @ts-ignore
        return moment(today).utc().format();
    }

    public static stringToDate(date: string): Date {
        return moment(date).toDate();
    }

    public static isGreaterThan(firstDate: Date, secondDate: Date): boolean {
        return firstDate > secondDate
    }

    public static getMaxDate(dates: Date[]): Date {
        const moments = dates.map((date) => moment(date));
        return moment.max(moments).toDate();
    }

    public static sortDates(dates: Date[]): Date[] {
        return dates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
    }
}