import {sprintf} from "sprintf-js";
import * as constants from "./constants";
import I18nUtils from "./I18nUtils";

function mapTranslations(object, key) {
    Object.defineProperty(object, key, {
        get: () => {
            const translation = I18nUtils.tr(key);
            return translation.includes("%s") ? {format: (...args) => sprintf(translation, ...args)} : translation
        },
    });
    return object;
}

export default Object.keys(constants).reduce(mapTranslations, {}) as { [key in keyof typeof constants]: string & { format: (...args: string[]) => string } }
