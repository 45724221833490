import {Component, ReactNode} from 'react';
import Col from "../Col";
import Row from "../Row";
import Button from "../buttons/Button";
import I18nUtils from "../../commons/I18n/I18nUtils";
import {TR_ANADIR, TR_CANCELAR, TR_GUARDAR} from "../../commons/I18n/constants";
import {Field} from "redux-form";
import FormSubmit from "./FormSubmit";

export interface CardHeaderProps {
    invalid: boolean,
    pristine: boolean,
    isUpdate: boolean,
    cancelHandler: () => void,
    classNameContainer?: string,
}

export default class FormCardFooter extends Component<CardHeaderProps> {

    public render(): ReactNode {
        const {pristine, isUpdate, cancelHandler, classNameContainer} = this.props;
        return (
            <Row className={`p-t-20 ${classNameContainer}`}>
                <Col sm={4} md={6} lg={8}/>
                <Col sm={4} md={3} lg={2}>
                    <Button
                        text={I18nUtils.tr(TR_CANCELAR)}
                        onClick={cancelHandler}
                        type={'button'}
                        block={true}
                        className={'btn-lg btn-default'}
                    />
                </Col>
                <Field
                    label={I18nUtils.tr(isUpdate ? TR_GUARDAR : TR_ANADIR)}
                    name="submit"
                    component={FormSubmit}
                    col={{sm: 4, md: 3, lg: 2}}
                    disabled={pristine}
                />
            </Row>
        );
    }
}

