import {Field, FormErrors, InjectedFormProps, reduxForm} from 'redux-form';
import Card from '../../../../components/card/Card';
import CardHeader from '../../../../components/card/CardHeader';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {TR_DESAHABILITADA, TR_ESTADO, TR_HABILITADA, TR_NOMBRE, TR_NOMBRE_OBLIGATORIO, TR_NUEVO_TIPO_ZONA_APARCAMIENTO, TR_TIPO_ZONA_APARCAMIENTO} from '../../../../commons/I18n/constants';
import CardBody from '../../../../components/card/CardBody';
import Row from '../../../../components/Row';
import FormInput from '../../../../components/form/FormInput';
import FormCardFooter from '../../../../components/form/FormCardFooter';
import {ROUTE_COMPANY_USER_PARKING_AREA_TYPES} from '../../../../routing/Routes';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ParkingAreaTypeCompanyUserFormScreenReducer from '../formscreen/ParkingAreaTypeCompanyUserFormScreenReducer';
import {goToRoute} from '../../../../utils/Router';
import FormSwitch from "../../../../components/form/FormSwitch";
import React from 'react';

const FORM_NAME = 'ParkingAreaTypeCardFormAdmin';

export interface ParkingAreaTypeCardFormAdminData {
    name: string,
    enabled: boolean,
}

interface ParkingAreaTypeCardFormAdminProps {
    parkingAreaTypeId?: string,
    readonly?: boolean,
    onSubmit: (data: ParkingAreaTypeCardFormAdminData) => void,
    initialValues?: Partial<ParkingAreaTypeCardFormAdminData>,
    parentLoading?: boolean,
}

const mapStateToProps = ParkingAreaTypeCompanyUserFormScreenReducer.autoMapToProps();

type Props =
    ParkingAreaTypeCardFormAdminProps
    & typeof mapStateToProps
    & InjectedFormProps<ParkingAreaTypeCardFormAdminData>

enum ParkingAreaTypeFormAdminFields {
    NAME = 'name',
    ENABLED = 'enabled',
}

class ParkingAreaTypeCompanyUserFormCard extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, parkingAreaTypeFormScreenLoading, pristine,
        } = this.props;

        return (
            <Card loading={parkingAreaTypeFormScreenLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_TIPO_ZONA_APARCAMIENTO : TR_NUEVO_TIPO_ZONA_APARCAMIENTO)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={ParkingAreaTypeFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_ESTADO)}
                                labelEnabled={I18nUtils.tr(TR_HABILITADA)}
                                labelDisabled={I18nUtils.tr(TR_DESAHABILITADA)}
                                name={ParkingAreaTypeFormAdminFields.ENABLED}
                                component={FormSwitch}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES)}
                        />
                    </form>
                </CardBody>
            </Card>
        );
    }
}

function validate(values: ParkingAreaTypeCardFormAdminData) {
    const errors: FormErrors<ParkingAreaTypeCardFormAdminData> = {};

    if ( !values.name || values.name.length === 0 ) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<ParkingAreaTypeCardFormAdminData, ParkingAreaTypeCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ParkingAreaTypeCompanyUserFormCard);
