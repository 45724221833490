import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_PARQUIMETROS,
    TR_NO_EXISTEN_PARQUIMETROS,
    TR_PARQUIMETROS
} from "../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_PARKING_METER_ADD} from "../../../../routing/Routes";
import ParkingMeterCompanyUserActions from "./ParkingMeterListCompanyUserActions";
import ParkingMeterCompanyUserReducer from "./ParkingMeterListCompanyUserReducer";
import TaskParkingMeterList from "../../../../commons/ws/parkingMeter/TaskParkingMeterList";
import TaskParkingMeterDelete from "../../../../commons/ws/parkingMeter/TaskParkingMeterDelete";
import ParkingMeterListTable from "./ParkingMeterListTable";
import React from 'react';

const mapActionsToProps = ParkingMeterCompanyUserActions.autoMapToProps();
const mapStateToProps = ParkingMeterCompanyUserReducer.autoMapToProps();

class ParkingMeterListCardCompanyUser extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, parkingMeters, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_PARQUIMETROS)}
                      sort={{column: 'code'}}
                      TaskList={TaskParkingMeterList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_PARQUIMETROS)}}
                      data={parkingMeters}
                      error={error}
                      ItemsTable={ParkingMeterListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_PARQUIMETROS),
                          Task: TaskParkingMeterDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_PARKING_METER_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterListCardCompanyUser as unknown as React.ComponentType<void>);
