import { RouteComponentProps } from 'react-router';
import { executeItemTask, loadItemDetailsOrGoBack } from '../../../../utils/FormUtils';
import TaskParkingAreaTypeDetail from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeDetail';
import { BreadcrumbItem } from '../../../../components/breadcrumb/BreadcrumbManager';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_NUEVO_TIPO_ZONA_APARCAMIENTO,
    TR_TIPO_ZONA_APARCAMIENTO,
    TR_TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE,
    TR_TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE,
    TR_TIPOS_ZONAS_APARCAMIENTOS
} from '../../../../commons/I18n/constants';
import {
    ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
    ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL,
    ROUTE_COMPANY_USER_PARKING_AREA_TYPES
} from '../../../../routing/Routes';
import { AppIcon } from '../../../../config/Config';
import ScreenCard from '../../../../components/screen/ScreenCard';
import { connect } from 'react-redux';
import { ParkingAreaTypeData } from '../../../../commons/model/ParkingAreaType';
import TaskParkingAreaTypeEdit from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeEdit';
import { generateRoute } from '../../../../commons/utils/Router';
import TaskParkingAreaTypeAdd from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeAdd';
import ParkingAreaTypeCompanyUserFormScreenReducer from './ParkingAreaTypeCompanyUserFormScreenReducer';
import ParkingAreaTypeCompanyUserFormScreenActions from './ParkingAreaTypeCompanyUserFormScreenActions';
import ParkingAreaTypeCompanyUserFormCard, { ParkingAreaTypeCardFormAdminData } from '../formdetail/ParkingAreaTypeCompanyUserFormCard';
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapActionsToProps = ParkingAreaTypeCompanyUserFormScreenActions.autoMapToProps();
const mapStateToProps = ParkingAreaTypeCompanyUserFormScreenReducer.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{id: string}> & typeof mapActionsToProps;

class ParkingAreaTypeCompanyUserFormScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if ( id ) {
            loadItemDetailsOrGoBack(new TaskParkingAreaTypeDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const parkingAreaTypeId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_TIPOS_ZONAS_APARCAMIENTOS),
            url: ROUTE_COMPANY_USER_PARKING_AREA_TYPES,
            icon: AppIcon.PARKING
        }];

        if ( parkingAreaTypeId ) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_TIPO_ZONA_APARCAMIENTO),
                url: generateRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL, {id: parkingAreaTypeId}),
                icon: AppIcon.PARKING
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_TIPO_ZONA_APARCAMIENTO),
                url: ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
                icon: AppIcon.PARKING
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingAreaTypeForm()}
            </ScreenCard>
        );
    }

    private onSubmit = (values: ParkingAreaTypeCardFormAdminData): void => {
        const existParkingAreaType = this.props.match.params.id !== undefined;

        const submitData: ParkingAreaTypeData = {
            name: values.name,
            enabled: values.enabled,
        };

        if ( existParkingAreaType ) {
            executeItemTask(
                new TaskParkingAreaTypeEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES),
                I18nUtils.tr(TR_TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE)
            );
        } else {
            executeItemTask(
                new TaskParkingAreaTypeAdd(submitData),
                () => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES),
                I18nUtils.tr(TR_TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE)
            );
        }
    };

    private renderParkingAreaTypeForm(): React.ReactNode {
        const {parkingAreaTypeFormScreenLoading, parkingAreaType} = this.props;

        let initialValues: ParkingAreaTypeCardFormAdminData | undefined;

        if ( parkingAreaType ) {
            initialValues = {
                name: parkingAreaType.name,
                enabled: parkingAreaType.enabled,
            };
        }

        return (
            <ParkingAreaTypeCompanyUserFormCard
                parentLoading={parkingAreaTypeFormScreenLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        );

    }

}

export default connect(mapStateToProps, mapActionsToProps)(ParkingAreaTypeCompanyUserFormScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
