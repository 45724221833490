import {ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_INCIDENCIAS_PARQUIMETROS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import ParkingMeterIncidenceListCardCompanyUser from "./ParkingMeterIncidenceListCardCompanyUser";
import React from 'react';

export default class ParkingMeterIncidenceListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_INCIDENCIAS_PARQUIMETROS),
                    url: ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES,
                    icon: AppIcon.PARKING_METER_INCIDENCE
                }
            ]}>
                <ParkingMeterIncidenceListCardCompanyUser/>
            </ScreenCard>
        )
    }
}
