import { ROUTE_COMPANY_USER_PARKING_AREA_TYPES } from '../../../../routing/Routes';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { TR_TIPOS_ZONAS_APARCAMIENTOS } from '../../../../commons/I18n/constants';
import { AppIcon } from '../../../../config/Config';
import ScreenCard from '../../../../components/screen/ScreenCard';
import ParkingAreaTypeCompanyUserListCard from './ParkingAreaTypeCompanyUserListCard';
import React from 'react';

export default class ParkingAreaTypeCompanyUserListScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_TIPOS_ZONAS_APARCAMIENTOS),
                    url: ROUTE_COMPANY_USER_PARKING_AREA_TYPES,
                    icon: AppIcon.PARKING
                }
            ]}>
                <ParkingAreaTypeCompanyUserListCard/>
            </ScreenCard>
        );
    }
}
