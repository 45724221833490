import {RouteComponentProps} from "react-router";
import {loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METERS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import ParkingMeterIncidenceFormScreenCompanyUserReducer from "./ParkingMeterIncidenceFormScreenCompanyUserReducer";
import ParkingMeterIncidenceFormCompanyUserScreenActions from "./ParkingMeterIncidenceFormCompanyUserScreenActions";
import TaskParkingMeterIncidenceDetail
    from "../../../../commons/ws/parkingMeterIncidence/TaskParkingMeterIncidenceDetail";
import ParkingMeterIncidenceCardFormCompanyUser, {ParkingMeterIncidenceCardFormCompanyUserData} from "../formdetail/ParkingMeterIncidenceCardFormCompanyUser";
import DateFormatter from "../../../../utils/DateFormatter";
import {generateRoute} from "../../../../commons/utils/Router";
import {TR_INCIDENCIA_PARQUIMETRO, TR_INCIDENCIAS_PARQUIMETROS} from "../../../../commons/I18n/constants";
import React from 'react';

const mapStateToProps = ParkingMeterIncidenceFormScreenCompanyUserReducer.autoMapToProps();

const mapActionsToProps = ParkingMeterIncidenceFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class ParkingMeterIncidenceFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskParkingMeterIncidenceDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const parkingMeterId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_INCIDENCIAS_PARQUIMETROS),
            url: ROUTE_COMPANY_USER_PARKING_METERS,
            icon: AppIcon.PARKING_METER_INCIDENCE
        }];

        if (parkingMeterId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_INCIDENCIA_PARQUIMETRO),
                url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL, {id: parkingMeterId}),
                icon: AppIcon.PARKING_METER_INCIDENCE
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingMeterForm()}
            </ScreenCard>
        )
    }

    private renderParkingMeterForm(): React.ReactNode {
        const {parkingMeterIncidenceLoading, parkingMeterIncidence} = this.props;

        let initialValues: ParkingMeterIncidenceCardFormCompanyUserData | undefined;

        if (parkingMeterIncidence) {
            initialValues = {
                parking_meter: parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.code : '',
                state: parkingMeterIncidence.type ? parkingMeterIncidence.type.name : '',
                date: parkingMeterIncidence.date ? DateFormatter.formatDatetime(parkingMeterIncidence.date) : '',
                controller: parkingMeterIncidence.controller ?
                    `${parkingMeterIncidence.controller.name} ${parkingMeterIncidence.controller.last_name}` : '',
                solved: parkingMeterIncidence.solved ? parkingMeterIncidence.solved : false,
                solved_controller: parkingMeterIncidence.solved_controller ?
                    `${parkingMeterIncidence.solved_controller.name} ${parkingMeterIncidence.solved_controller.last_name}` : '',
                solved_date: parkingMeterIncidence.solved_date ? DateFormatter.formatDatetime(parkingMeterIncidence.solved_date) : '',
                observations: parkingMeterIncidence.observations ? parkingMeterIncidence.observations : '',
            }
        }

        return (
            <ParkingMeterIncidenceCardFormCompanyUser
                initialValues={initialValues}
                parentLoading={parkingMeterIncidenceLoading}
            />
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterIncidenceFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
