import ReportExportHistory from "../../../commons/model/ReportExportHistory";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import DateFormatter from "../../../utils/DateFormatter";
import Th from "../../../components/table/Th";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {TR_DESCARGAR_FICHERO, TR_FECHA, TR_FECHA_FIN, TR_FECHA_INICIO, TR_FICHERO_DE_ENVIO_A_TRAMITE, TR_LOTE, TR_TOTAL_DENUNCIAS_TRAMITADAS} from "../../../commons/I18n/constants";
import Button from "../../../components/buttons/Button";
import React from 'react';

interface ReportExportHistoryTableItemProps {
    data: ReportExportHistory[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ReportExportHistoryTableItem extends React.Component<ReportExportHistoryTableItemProps> {

    private openUrl = (url: string): void => {
        window.open(url, "_blank");
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((reportExportHistory: ReportExportHistory) => {
                    return (
                        <tr key={reportExportHistory.lot}>
                            {renderSelectTd ? renderSelectTd(reportExportHistory.lot) : null}
                            <td>
                                {reportExportHistory.lot}
                            </td>
                            <td>
                                {DateFormatter.formatDate(reportExportHistory.start_date)}
                            </td>
                            <td>
                                {DateFormatter.formatDate(reportExportHistory.end_date)}
                            </td>
                            <td>
                                {reportExportHistory.export_proccess_report_count}
                            </td>
                            <td>
                                {DateFormatter.formatDate(reportExportHistory.date)}
                            </td>
                            <td>
                                <Button
                                    text={I18nUtils.tr(TR_DESCARGAR_FICHERO)}
                                    onClick={() => this.openUrl(reportExportHistory.file_export_url)}
                                    type={'button'}
                                    block={false}
                                    className={'btn-sm btn-primary'}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_LOTE)}
                    columnName={'lot'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_INICIO)}
                    columnName={'start_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_FIN)}
                    columnName={'end_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TOTAL_DENUNCIAS_TRAMITADAS)}
                    columnName={'export_proccess_report_count'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FICHERO_DE_ENVIO_A_TRAMITE)}
                    columnName={'file_export_url'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
