import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ELIMINAR_RUTAS, TR_NO_EXISTEN_RUTAS, TR_RUTAS} from "../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_ROUTE_ADD} from "../../../../routing/Routes";
import RouteCompanyUserActions from "./RouteCompanyUserActions";
import RouteCompanyUserReducer from "./RouteCompanyUserReducer";
import TaskRouteList from "../../../../commons/ws/route/TaskRouteList";
import TaskRouteDelete from "../../../../commons/ws/route/TaskRouteDelete";
import RouteListTable from "./RouteListTable";
import React from 'react';

const mapActionsToProps = RouteCompanyUserActions.autoMapToProps();
const mapStateToProps = RouteCompanyUserReducer.autoMapToProps();

class RouteCardCompanyUser extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, routes, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_RUTAS)}
                      sort={{column: 'name'}}
                      TaskList={TaskRouteList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_RUTAS)}}
                      data={routes}
                      error={error}
                      ItemsTable={RouteListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_RUTAS),
                          Task: TaskRouteDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_ROUTE_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(RouteCardCompanyUser as unknown as React.ComponentType<void>);
