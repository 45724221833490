import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_NUEVO_PARQUIMETRO,
    TR_PARQUIMETRO,
    TR_PARQUIMETRO_CREADO_CORRECTAMENTE,
    TR_PARQUIMETRO_MODIFICADO_CORRECTAMENTE,
    TR_PARQUIMETROS
} from "../../../../commons/I18n/constants";
import {
    ROUTE_COMPANY_USER_PARKING_METER_ADD,
    ROUTE_COMPANY_USER_PARKING_METER_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METERS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import ParkingMeterFormScreenCompanyUserReducer from "./ParkingMeterFormScreenCompanyUserReducer";
import ParkingMeterFormCompanyUserScreenActions from "./ParkingMeterFormCompanyUserScreenActions";
import TaskParkingMeterDetail from "../../../../commons/ws/parkingMeter/TaskParkingMeterDetail";
import ParkingMeterCardFormCompanyUser, {ParkingMeterCardFormCompanyUserData} from "../formdetail/ParkingMeterCardFormCompanyUser";
import {ParkingMeterData} from "../../../../commons/model/ParkingMeter";
import TaskParkingMeterAdd from "../../../../commons/ws/parkingMeter/TaskParkingMeterAdd";
import TaskParkingMeterEdit from "../../../../commons/ws/parkingMeter/TaskParkingMeterEdit";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = ParkingMeterFormScreenCompanyUserReducer.autoMapToProps();

const mapActionsToProps = ParkingMeterFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class ParkingMeterFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskParkingMeterDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: ParkingMeterCardFormCompanyUserData): void => {
        const existParkingMeter = this.props.match.params.id !== undefined;

        const submitData: ParkingMeterData = {
            code: values.code,
            description: values.description,
            type: values.type,
            latitude: values.coord.lat,
            longitude: values.coord.lng,
            places: values.places,
        };

        if (existParkingMeter) {
            executeItemTask(
                new TaskParkingMeterEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_COMPANY_USER_PARKING_METERS),
                I18nUtils.tr(TR_PARQUIMETRO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskParkingMeterAdd(submitData),
                () => goToRoute(ROUTE_COMPANY_USER_PARKING_METERS),
                I18nUtils.tr(TR_PARQUIMETRO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const parkingMeterId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_PARQUIMETROS),
            url: ROUTE_COMPANY_USER_PARKING_METERS,
            icon: AppIcon.PARKING_METER
        }];

        if (parkingMeterId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_PARQUIMETRO),
                url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_DETAIL, {id: parkingMeterId}),
                icon: AppIcon.PARKING_METER
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_PARQUIMETRO),
                url: ROUTE_COMPANY_USER_PARKING_METER_ADD,
                icon: AppIcon.PARKING_METER
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingMeterForm()}
            </ScreenCard>
        )
    }

    private renderParkingMeterForm(): React.ReactNode {
        const {parkingMeterLoading, parkingMeter} = this.props;

        let initialValues: Partial<ParkingMeterCardFormCompanyUserData> | undefined;

        if (parkingMeter) {
            initialValues = {
                code: parkingMeter.code,
                description: parkingMeter.description,
                type: parkingMeter.type,
                coord: {
                    lat: parkingMeter.latitude,
                    lng: parkingMeter.longitude
                },
                places: parkingMeter.places || 0,
            }
        }

        return (
            <ParkingMeterCardFormCompanyUser
                parentLoading={parkingMeterLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                editMode={!!this.props.match.params.id}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(ParkingMeterFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
