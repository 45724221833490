import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_FECHA_FIN,
    TR_FECHA_INICIO,
    TR_NO_EXISTEN_PLAZAS_PERDIDAS,
    TR_PLAZAS_PERDIDAS
} from "../../../../commons/I18n/constants";
import Row from "../../../../components/Row";
import FormCol from "../../../../components/form/FormCol";
import ParkingLossListCompanyUserActions from "./ParkingLossListCompanyUserActions";
import ParkingLossListCompanyUserReducer from "./ParkingLossListCompanyUserReducer";
import TaskParkingLossList, {ParkingLossCriteriaProps} from "../../../../commons/ws/parkingLoss/TaskParkingLossList";
import ParkingLossListTable from "./ParkingLossListTable";
import React from 'react';

const mapActionsToProps = ParkingLossListCompanyUserActions.autoMapToProps();
const mapStateToProps = ParkingLossListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

interface State {
    currentCriteria: ParkingLossCriteriaProps;
}

class ParkingLossListCardCompanyUser extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {currentCriteria: {}}
    }

    public componentWillUnMount(): void {
        this.props.clearReducer();
    }

    private onStartDateSelect = (date?: Date): void => {
        const newCriteria: ParkingLossCriteriaProps = {
            ...this.state.currentCriteria,
            start_date: date ? date.toISOString() : undefined,
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onEndDateSelect = (date?: Date): void => {
        const newCriteria: ParkingLossCriteriaProps = {
            ...this.state.currentCriteria,
        };
        if (date) {
            date.setDate(date.getDate() + 1);
            newCriteria.end_date = date.toISOString();
        } else {
            delete newCriteria.end_date;
        }
        this.setState({currentCriteria: newCriteria});
    };

    public render(): React.ReactNode {
        const {loading, parkingLosses, error, pager} = this.props;
        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_PLAZAS_PERDIDAS)}
                      sort={{column: 'start_date'}}
                      TaskList={TaskParkingLossList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_PLAZAS_PERDIDAS)}}
                      data={parkingLosses}
                      error={error}
                      ItemsTable={ParkingLossListTable}
                      customCriteria={this.state.currentCriteria}
            >
                {TaskParkingLossList && this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent = (): React.ReactNode => {
        return (
            <Row>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                            <input type={'date'} className="form-control"
                                    // @ts-ignore
                                   onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
                        </div>
                    </div>
                </FormCol>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_FECHA_FIN)}</label>
                            <input type={'date'} className="form-control"
                                   // @ts-ignore
                                   onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
                        </div>
                    </div>
                </FormCol>
            </Row>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingLossListCardCompanyUser as unknown as React.ComponentType<void>);
