import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_VEHICLE_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_COLOR,
    TR_ESPECIAL,
    TR_MASCARA,
    TR_MATRICULA,
    TR_MODELO,
    TR_NO,
    TR_SI,
    TR_TIPO
} from "../../../../commons/I18n/constants";
import Vehicle from "../../../../commons/model/Vehicle";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface VehicleListTableProps {
    data: Vehicle[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class VehicleListTable extends React.Component<VehicleListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_VEHICLE_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((vehicle: Vehicle) => {
                    return (
                        <tr key={vehicle.id}
                            onClick={() => this.goToItemDetail(vehicle.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(vehicle.id) : null}
                            <td>
                                {vehicle.license_plate}
                            </td>
                            <td>
                                {vehicle.license_plate_mask ? vehicle.license_plate_mask.name : ''}
                            </td>
                            <td>
                                <ActiveLabel text={vehicle.special ? I18nUtils.tr(TR_SI) : I18nUtils.tr(TR_NO)}
                                             active={vehicle.special}
                                />
                            </td>
                            <td>
                                {vehicle.vehicle_model ? vehicle.vehicle_model.name : ''}
                            </td>
                            <td>
                                {vehicle.vehicle_type ? vehicle.vehicle_type.name : ''}
                            </td>
                            <td>
                                {vehicle.vehicle_color ? vehicle.vehicle_color.name : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_MATRICULA)}
                    columnName={'license_plate'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_MASCARA)}
                    columnName={'license_plate_mask'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ESPECIAL)}
                    columnName={'special'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_MODELO)}
                    columnName={'vehicle_model'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO)}
                    columnName={'vehicle_type'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_COLOR)}
                    columnName={'vehicle_color'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
