import {ChatBroadcastData} from "../../../../../commons/model/ChatBroadcast";
import {ChatBroadcastCardFormCompanyUserData} from "../formAdd/ChatBroadcastCardFormCompanyUser";
import TaskChatBroadcastAdd from "../../../../../commons/ws/chat/TaskChatBroadcastAdd";
import {ROUTE_COMPANY_USER_BROADCAST, ROUTE_COMPANY_USER_BROADCAST_ADD} from "../../../../../routing/Routes";
import {
    TR_MENSAJE_CREADO_CORRECTAMENTE,
    TR_MENSAJES_A_TODOS,
    TR_NUEVO_MENSAJE
} from "../../../../../commons/I18n/constants";
import {executeItemTask} from "../../../../../utils/FormUtils";
import {goToRoute} from "../../../../../utils/Router";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {BreadcrumbItem} from "../../../../../components/breadcrumb/BreadcrumbManager";
import {AppIcon} from "../../../../../config/Config";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import ChatBroadcastCardFormCompanyUser from "../formAdd/ChatBroadcastCardFormCompanyUser";
import React from 'react';

export default class ChatBroadcastFormCompanyUserScreen extends React.Component<{}> {

    private onSubmit = (values: ChatBroadcastCardFormCompanyUserData): void => {

        const submitData: ChatBroadcastData = {
            message: values.message,
            expiration_date: values.expirationDate
        };

        executeItemTask(
            new TaskChatBroadcastAdd(submitData),
            () => goToRoute(ROUTE_COMPANY_USER_BROADCAST),
            I18nUtils.tr(TR_MENSAJE_CREADO_CORRECTAMENTE)
        )

    };

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_MENSAJES_A_TODOS),
            url: ROUTE_COMPANY_USER_BROADCAST,
            icon: AppIcon.POST_ADD
        }];

        breadcrumbs.push({
            name: I18nUtils.tr(TR_NUEVO_MENSAJE),
            url: ROUTE_COMPANY_USER_BROADCAST_ADD,
            icon: AppIcon.POST_ADD
        });


        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderChatBroadcastForm()}
            </ScreenCard>
        )
    }

    private renderChatBroadcastForm(): React.ReactNode {
        return <ChatBroadcastCardFormCompanyUser onSubmit={this.onSubmit}/>
    }

}
