import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO} from "../../I18n/constants";
import TaskResponse, {BatchResponseCode} from "../TaskResponse";


export default class TaskReportStateCancelFile extends BaseGenericRequest<FormData, TaskResponse<BatchResponseCode>> {

    private readonly file: File;

    public constructor(file: File) {
        super(METHOD.POST, Urls.URL_REPORT_STATE_CANCEL_FILE);
        this.file = file;

        this.setErrorMessage(491, I18nUtils.tr(TR_EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO));

        delete this.headers["Content-type"];
        this.addHeader('Content-Type', 'multipart/form-data');
    }

    protected getRequest(): FormData {
        const data = new FormData();
        data.append('file', this.file);
        return data;
    }
}
