import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import {METHOD} from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import I18nUtils from "../../I18n/I18nUtils";
import {TR_INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS, TR_NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR} from "../../I18n/constants";
import {ReportCustomCriteria} from "./TaskReportList";

type TaskReportExportResponse = TaskResponse<{url: string}>

export default class TaskReportExport extends BaseGenericRequest<ReportCustomCriteria, TaskReportExportResponse> {

    private readonly data: ReportCustomCriteria;

    public constructor(data: ReportCustomCriteria) {
        super(METHOD.POST, Urls.URL_REPORT_EXPORT_EXCEL);
        this.data = data;

        this.setErrorMessage(404, I18nUtils.tr(TR_NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR));
        this.setErrorMessage(405, I18nUtils.tr(TR_INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS));
    }

    protected getRequest(): ReportCustomCriteria {
        return this.data;
    }
}
