
import {ROUTE_COMPANY_USER_LAWS} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ARTICULOS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import LawListCardCompanyUser from "./LawListCardCompanyUser";
import React from 'react';

export default class LawListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                        {
                            name: I18nUtils.tr(TR_ARTICULOS),
                            url: ROUTE_COMPANY_USER_LAWS,
                            icon: AppIcon.LAW
                        }
                        ]}>
                <LawListCardCompanyUser/>
                </ScreenCard>
        )
    }
}
