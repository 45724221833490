import {OnSortHandler, SortState} from "../../../../../utils/Sort";
import Th from "../../../../../components/table/Th";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {
    TR_DENUNCIAS,
    TR_FIN_DE_JORNADA,
    TR_HORAS,
    TR_INICIO_DE_JORNADA,
    TR_INVALIDADAS,
    TR_NOMBRE
} from "../../../../../commons/I18n/constants";
import WorkdayReport from "../../../../../commons/model/WordayReport";
import WordayReport from "../../../../../commons/model/WordayReport";
import DateFormatter from "../../../../../utils/DateFormatter";
import React from 'react';

interface ControllerReportListTableProps {
    data: WorkdayReport[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ControllerReportListTable extends React.Component<ControllerReportListTableProps> {

    public render(): React.ReactNode {
        const {data} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((controllerReport: WordayReport) => {
                    return (
                        <tr key={controllerReport.id}>
                            <td>
                                {controllerReport.user ? controllerReport.user.name : ''}
                            </td>
                            <td>
                                {controllerReport.start_date ? DateFormatter.formatDatetime(controllerReport.start_date) : ''}
                            </td>
                            <td>
                                {controllerReport.end_date ? DateFormatter.formatDatetime(controllerReport.end_date) : ''}
                            </td>
                            <td>
                                {controllerReport.effective_time ? (controllerReport.effective_time / 60).toFixed(2) : ''}
                            </td>
                            <td>
                                {controllerReport.report_count ? controllerReport.report_count : ''}
                            </td>
                            <td>
                                {controllerReport.report_invalidated_count ? controllerReport.report_invalidated_count : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_INICIO_DE_JORNADA)}
                    columnName={'start_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FIN_DE_JORNADA)}
                    columnName={'end_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_HORAS)}
                    columnName={'effective_time'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DENUNCIAS)}
                    columnName={'report_count'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_INVALIDADAS)}
                    columnName={'report_invalidated_count'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
