import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_STREET_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_N_PLAZAS, TR_NOMBRE, TR_RUTA, TR_TIPO_DE_CALLE, TR_TIPO_DE_ZONA} from "../../../../commons/I18n/constants";
import Street from "../../../../commons/model/Street";
import {goToRoute} from '../../../../utils/Router';
import React from 'react';

interface StreetListTableProps {
    data: Street[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class StreetListTable extends React.Component<StreetListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_STREET_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((street: Street) => {
                    return (
                        <tr key={street.id}
                            onClick={() => this.goToItemDetail(street.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(street.id) : null}
                            <td>
                                {street.name ? street.name : ''}
                            </td>
                            <td>
                                {street.route ? street.route.name : ''}
                            </td>
                            <td>
                                {street.street_type ? street.street_type.name : ''}
                            </td>
                            <td>
                                {street.parking_area_type ? street.parking_area_type.name : ''}
                            </td>
                            <td>
                                {street.parking_spaces}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_RUTA)}
                    columnName={'route._name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO_DE_CALLE)}
                    columnName={'street_type.name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO_DE_ZONA)}
                    columnName={'parking_area.type_name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_N_PLAZAS)}
                    columnName={'parking_spaces'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
