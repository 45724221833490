import ReportCancelLogListCard from "./ReportCancelLogListCard";
import {TR_LOGS_CANCELACION_DENUNCIAS} from "../../../../../commons/I18n/constants";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {AppIcon} from "../../../../../config/Config";
import {ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS} from "../../../../../routing/Routes";
import {RouteComponentProps} from "react-router";
import React from 'react';

export default class ReportCancelLogListScreen extends React.Component<RouteComponentProps<{ date: string }>> {

    public render(): React.ReactNode {

        const {date} = this.props.match.params;

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LOGS_CANCELACION_DENUNCIAS),
                    url: ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS,
                    icon: AppIcon.REPORT
                }
            ]}>
                <ReportCancelLogListCard date={date}/>
            </ScreenCard>
        )
    }
}
