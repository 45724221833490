import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import NavBarActions from "./NavBarActions";

interface State {
    loading: boolean,
    alerts: any[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: State = {
    loading: false,
    alerts: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    // .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
    //     return {...oldState, loading: true}
    // })
    // .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
    //     return {...oldState, loading: false}
    // })
    // .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
    //     return {...oldState, error: payload}
    // })
    // .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
    //     return {
    //         ...oldState,
    //         alerts: payload.data,
    //         pager: payload.pager || oldState.pager,
    //     }
    // })

    .onEvent(NavBarActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()
