import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE,
    TR_MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE,
    TR_MOTIVOS_DE_PLAZAS_PERDIDAS,
    TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA
} from "../../../../commons/I18n/constants";
import {
    ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
    ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL,
    ROUTE_ADMIN_PARKING_LOSS_REASONS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import {goToRoute} from '../../../../utils/Router';
import ParkingLossReasonFormScreenAdminReducer from "./ParkingLossReasonFormScreenAdminReducer";
import ParkingLossReasonFormAdminScreenActions from "./ParkingLossReasonFormAdminScreenActions";
import ParkingLossReasonCardFormAdmin, {ParkingLossReasonCardFormAdminData} from "../formdetail/ParkingLossReasonCardFormAdmin";
import {ParkingLossReasonData} from "../../../../commons/model/ParkingLossReason";
import TaskParkingLossReasonDetail from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonDetail";
import TaskParkingLossReasonEdit from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonEdit";
import TaskParkingLossReasonAdd from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonAdd";
import React from 'react';

const mapStateToProps = ParkingLossReasonFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = ParkingLossReasonFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class ParkingLossReasonFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskParkingLossReasonDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: ParkingLossReasonCardFormAdminData): void => {
        const existParkingLossReason = this.props.match.params.id !== undefined;

        const submitData: ParkingLossReasonData = {
            name: values.name,
        };

        if (existParkingLossReason) {
            executeItemTask(
                new TaskParkingLossReasonEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS),
                I18nUtils.tr(TR_MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskParkingLossReasonAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS),
                I18nUtils.tr(TR_MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const parkingLossReasonId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_MOTIVOS_DE_PLAZAS_PERDIDAS),
            url: ROUTE_ADMIN_PARKING_LOSS_REASONS,
            icon: AppIcon.PARKING
        }];

        if (parkingLossReasonId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_MOTIVOS_DE_PLAZAS_PERDIDAS),
                url: generateRoute(ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL, {id: parkingLossReasonId}),
                icon: AppIcon.PARKING
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA),
                url: ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
                icon: AppIcon.PARKING
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingLossReasonForm()}
            </ScreenCard>
        )
    }

    private renderParkingLossReasonForm(): React.ReactNode {
        const {parkingLossReasonLoading, parkingLossReason} = this.props;

        let initialValues: Partial<ParkingLossReasonCardFormAdminData> | undefined;

        if (parkingLossReason) {
            initialValues = {
                name: parkingLossReason.name ? parkingLossReason.name : '',
            }
        }
        return (
            <ParkingLossReasonCardFormAdmin
                parentLoading={parkingLossReasonLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)
(ParkingLossReasonFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
