import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CONTROLADOR,
    TR_FECHA,
    TR_FECHA_SOLUCIONADA,
    TR_NO_SOLUCIONADA,
    TR_OBSERVACIONES,
    TR_PARQUIMETRO,
    TR_SOLUCIONADA,
    TR_TIPO, TR_TIPO_DE_INCIDENCIA
} from "../../../../commons/I18n/constants";
import ParkingMeterIncidence from "../../../../commons/model/ParkingMeterIncidence";
import DateFormatter from "../../../../utils/DateFormatter";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface ParkingMeterIncidenceListTableProps {
    data: ParkingMeterIncidence[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ParkingMeterIncidenceListTable extends React.Component<ParkingMeterIncidenceListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover table-font-small">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((parkingMeterIncidence: ParkingMeterIncidence) => {
                    return (
                        <tr key={parkingMeterIncidence.id}
                            onClick={() => this.goToItemDetail(parkingMeterIncidence.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(parkingMeterIncidence.id) : null}
                            <td>
                                {parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.code : ''}
                            </td>
                            <td>
                                {parkingMeterIncidence.type ? parkingMeterIncidence.type.name : ''}
                            </td>
                            <td>
                                {parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.type : ''}
                            </td>
                            <td>
                                {parkingMeterIncidence.observations ? parkingMeterIncidence.observations : ''}
                            </td>
                            <td>
                                {parkingMeterIncidence.controller ?
                                    `${parkingMeterIncidence.controller.name} ${parkingMeterIncidence.controller.last_name}` : ''}
                            </td>
                            <td>
                                {parkingMeterIncidence.date ? DateFormatter.formatDatetime(parkingMeterIncidence.date) : ''}
                            </td>
                            <td>
                                <ActiveLabel text={parkingMeterIncidence.solved ? I18nUtils.tr(TR_SOLUCIONADA) : I18nUtils.tr(TR_NO_SOLUCIONADA)}
                                             active={parkingMeterIncidence.solved}
                                             />
                            </td>
                            <td>
                                {parkingMeterIncidence.solved_date ? DateFormatter.formatDatetime(parkingMeterIncidence.solved_date) : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_PARQUIMETRO)}
                    columnName={'parking_meter'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO_DE_INCIDENCIA)}
                    columnName={'type'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO)}
                    columnName={'parking_meter.type'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_OBSERVACIONES)}
                    columnName={'observations'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CONTROLADOR)}
                    columnName={'controller'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_SOLUCIONADA)}
                    columnName={'solved'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_SOLUCIONADA)}
                    columnName={'solved_date'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
