
import { TR_ENVIO_A_TRAMITE } from '../../../commons/I18n/constants';
import { ROUTE_COMPANY_USER_SENDTOPROCESS } from '../../../routing/Routes';
import ScreenCard from '../../../components/screen/ScreenCard';
import I18nUtils from '../../../commons/I18n/I18nUtils';
import { AppIcon } from '../../../config/Config';
import SendToProcessDataContainer from "./SendToProcessDataContainer";
import React from 'react';

export default class SendToProcessScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_ENVIO_A_TRAMITE),
                    url: ROUTE_COMPANY_USER_SENDTOPROCESS,
                    icon: AppIcon.SEND
                }
            ]}>
                <SendToProcessDataContainer/>
            </ScreenCard>
        );
    }
}
