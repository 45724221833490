
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CONFIGURACION_DE_LISTAS_NEGRAS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import BlackListRuleListCardCompanyUser from "./BlackListRuleListCardCompanyUser";
import {ROUTE_COMPANY_USER_BLACK_LIST_RULES} from "../../../../routing/Routes";
import React from 'react';

export default class BlackListRuleListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                        {
                            name: I18nUtils.tr(TR_CONFIGURACION_DE_LISTAS_NEGRAS),
                            url: ROUTE_COMPANY_USER_BLACK_LIST_RULES,
                            icon: AppIcon.BLACK_LIST_RULE
                        }
                        ]}>
                <BlackListRuleListCardCompanyUser/>
                </ScreenCard>
        )
    }
}
