import {ROUTE_COMPANY_USER_PARKING_METERS} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_PARQUIMETROS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import ParkingMeterCardCompanyUser from "./ParkingMeterListCardCompanyUser";
import React from 'react';

export default class ParkingMeterListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_PARQUIMETROS),
                    url: ROUTE_COMPANY_USER_PARKING_METERS,
                    icon: AppIcon.PARKING_METER
                }
            ]}>
                <ParkingMeterCardCompanyUser/>
            </ScreenCard>
        )
    }
}
