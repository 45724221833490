import {Component, ReactNode} from "react";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE,
    TR_ERROR_SUMA_COLECTAS,
    TR_GENERIC_WS_ERROR,
    TR_INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO,
    TR_NO_EXISTEN_COLECTAS_PARQUIMETROS, TR_PROCESO_RECAUDACION,
    TR_SELECCIONAR_COLECTAS_PARQUIMETROS,
    TR_SIGUIENTE
} from "../../../../commons/I18n/constants";
import CardList, {SelectAction} from "../../../../components/card/CardList";
import {PagerBackendProps} from "../../../../components/Pager";
import CollectionProcessAddTable from "./CollectionProcessAddTable";
import TaskParkingMeterCollectionList from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";
import CollectionProcessBaseModal from "../modal/CollectionProcessBaseModal";
import {ParkingMeterCollectionProcessAddData} from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessAdd from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessAdd";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_COLLECTION_PROCESS} from "../../../../routing/Routes";
import Alert from "../../../../base/alerts/Alert";

interface CollectionProcessAddViewProps {
    parkingMeterCollections: ParkingMeterCollection[],
    loading: boolean,
    errors: string,
    pager: PagerBackendProps
}

interface CollectionProcessAddViewState {
    showAddCollectionsModal: boolean,
    showErrorModal: boolean,
    selectedItemIds: string []
}

export default class CollectionProcessAddView extends Component<CollectionProcessAddViewProps, CollectionProcessAddViewState> {

    constructor(props: Readonly<CollectionProcessAddViewProps>) {
        super(props);
        this.state = {
            showAddCollectionsModal: false,
            showErrorModal: false,
            selectedItemIds: []
        }
    }

    private handleOnCheckedItem = (newItemId: string): void => {
        const newSelectedItemIds: string[] = [...this.state.selectedItemIds];
        newSelectedItemIds.push(newItemId);
        this.setState({selectedItemIds: newSelectedItemIds});
    };

    private handleOnUncheckedItem = (toRemoveItemId: string): void => {
        this.setState({
            selectedItemIds: this.state.selectedItemIds.filter(
                (selectedItemId) => selectedItemId !== toRemoveItemId)
        });
    };

    private handleOnAddCollectionProcess(code: number): void {
        if (code === 200) {
            goToRoute(ROUTE_ADMIN_COLLECTION_PROCESS);
            setTimeout(() => {
                Alert.success(I18nUtils.tr(TR_COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE))
            }, 1)

        } else if (code === 205) {
            this.setState({showErrorModal: true});
        }
    }


    public render(): ReactNode {
        const {parkingMeterCollections, loading, errors, pager} = this.props;

        return (
            <>
                <CardList loading={loading}
                          pager={pager}
                          title={I18nUtils.tr(TR_SELECCIONAR_COLECTAS_PARQUIMETROS)}
                          sort={{column: 'id'}}
                          TaskList={TaskParkingMeterCollectionList}
                          customCriteria={{not_in_collection_process: true}}
                          emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_COLECTAS_PARQUIMETROS)}}
                          data={parkingMeterCollections}
                          error={errors}
                          noSearchBar={true}
                          footerActionSelected={SelectAction.SEND}
                          ItemsTable={CollectionProcessAddTable}
                          onCheckedItem={this.handleOnCheckedItem}
                          onUncheckedItem={this.handleOnUncheckedItem}
                          onSendButtonClick={() => this.setState({showAddCollectionsModal: true})}
                          sendButtonText={I18nUtils.tr(TR_SIGUIENTE)}
                />

                <CollectionProcessBaseModal
                    title={I18nUtils.tr(TR_PROCESO_RECAUDACION)}
                    message={I18nUtils.tr(TR_INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO)}
                    inputType={"number"}
                    onConfirmAction={(totalCash) => {
                        this.setState({showAddCollectionsModal: false});

                        const submitData: ParkingMeterCollectionProcessAddData = {
                            parking_meter_collection_ids: this.state.selectedItemIds,
                            total_cash: Number(totalCash)
                        }

                        new TaskParkingMeterCollectionProcessAdd(submitData)
                            .onSuccess((response) => this.handleOnAddCollectionProcess(response.code))
                            .onFail(() => setTimeout(() => {
                                Alert.error(I18nUtils.tr(TR_GENERIC_WS_ERROR))
                            }, 1))
                            .execute()

                    }}
                    show={this.state.showAddCollectionsModal}
                    onClose={() => this.setState({showAddCollectionsModal: false})}
                />

                <CollectionProcessBaseModal
                    error={true}
                    title={I18nUtils.tr(TR_PROCESO_RECAUDACION)}
                    message={I18nUtils.tr(TR_ERROR_SUMA_COLECTAS)}
                    onConfirmAction={() => {
                        this.setState({showErrorModal: false});
                        goToRoute(ROUTE_ADMIN_COLLECTION_PROCESS);
                    }}
                    show={this.state.showErrorModal}
                    onClose={() => this.setState({showErrorModal: false})}
                />
            </>
        );
    }
}
