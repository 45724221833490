import {Field, WrappedFieldArrayProps} from "redux-form";
import UserFieldArrayReducer from "./RoleFieldArrayReducer";
import {connect} from "react-redux";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import Col from "../../../../components/Col";
import {UserRoleValue} from "../../../../commons/model/UserRole";
import TaskUserRoleList from "../../../../commons/ws/userRole/TaskUserRoleList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ROLES} from "../../../../commons/I18n/constants";
import FormSelect from "../../../../components/form/FormSelect";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import { UserRoleAutocompleteBuilder } from '../../../../components/filter/FilterTypeaheadOptions';
import React from 'react';

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    CurrentUserReducer.autoMapToProps(),
    UserFieldArrayReducer.autoMapToProps()
);

export interface RoleFieldArrayProps {
    showFirstOption?: boolean,
    isCurrentUser: boolean,
}

type Props = WrappedFieldArrayProps<UserRoleAutocompleteBuilder> & typeof mapStateToProps & RoleFieldArrayProps;

class RoleFieldArray extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskUserRoleList().execute();

        if (this.props.showFirstOption) {
            this.props.fields.push("");
        }
    }

    public render(): React.ReactNode {
        const {fields, roleList, meta, isCurrentUser, isAdmin} = this.props;

        let userRoleOptions = roleList.map((role) => ({value: role.id, name: role.name}));
        if (!isAdmin) {
            userRoleOptions = userRoleOptions.filter((role) => role.value !== UserRoleValue.ADMIN);
        }

        return (
            <CardFieldArray fields={fields}
                            meta={meta}
                            title={''}
                            fieldsHeader={this.renderHeader()}
                            addHandler={() => fields.push("")}
                            hideRemoveWithOneOption={true}
            >
                {fields.map((fieldName: string, index: number) => {
                    return (
                        <Field
                            key={index}
                            name={fieldName}
                            component={FormSelect}
                            options={userRoleOptions}
                            blankOptionText={' '}
                            col={{className: 'm-b--10 m-t--15 p-r-10'}}
                            disabled={isCurrentUser}
                            errorMessageWithoutTouch={true}
                        />
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <Col className={'m-b-0 m-l-15'}>
                <label>{I18nUtils.tr(TR_ROLES)}</label>
            </Col>
        )
    }
}

export default connect(mapStateToProps)(RoleFieldArray) as unknown as React.ComponentType<RoleFieldArrayProps>;
