import {Component, ReactNode} from "react";
import {connect} from "react-redux";
import CollectionProcessAdminActions from "./CollectionProcessAdminActions";
import CollectionProcessAdminReducer from "./CollectionProcessAdminReducer";
import CollectionProcessAdminView from "./CollectionProcessAdminView"
import TaskParkingMeterCollectionProcessList
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessList";
import TaskParkingMeterCollectionProcessSend
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessSend";
import {ParkingMeterCollectionProcessSendData} from "../../../../commons/model/ParkingMeterCollectionProcess";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
	TR_PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE,
	TR_SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION
} from "../../../../commons/I18n/constants";
import Alert from "../../../../base/alerts/Alert";

const mapStateToProps = CollectionProcessAdminReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class CollectionProcessAdminDataContainer extends Component<Props> {
	 public componentWillUnmount(): void {
		 this.props.dispatchClearReducer();
	 }

	public componentDidMount(): void {
		new TaskParkingMeterCollectionProcessList().execute();
	}

	private handleOnParkingMeterCollectionProcessSend = () => {
		const submitData: ParkingMeterCollectionProcessSendData = {
			parking_meter_collection_process_ids: this.props.parkingMeterCollectionProcessToSend
		}

		new TaskParkingMeterCollectionProcessSend(submitData)
			.onFail(() => setTimeout(() => {
				Alert.error(I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION))
			},1))

			.onSuccess(() => setTimeout(() => {
				Alert.success(I18nUtils.tr(TR_PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE))
				// Recargar
				new TaskParkingMeterCollectionProcessList().execute();
			},1))

			.execute();
	}

	public render(): ReactNode {
		const {parkingMeterCollectionProcessList, parkingMeterCollectionProcessToSend ,loading, errors, pager} = this.props;

		return (
			<CollectionProcessAdminView
				parkingMeterCollectionProcessList={parkingMeterCollectionProcessList}
				onParkingMeterCollectionProcessSend={this.handleOnParkingMeterCollectionProcessSend}
				disabledSend={parkingMeterCollectionProcessToSend.length === 0}
				loading={loading}
				errors={errors}
				pager={pager}
			/>
		);
	 }
}

export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessAdminDataContainer as unknown as React.ComponentType<{}>);
