import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_COLORES_DE_VEHICULOS,
    TR_ELIMINAR_COLORES,
    TR_NO_EXISTEN_COLORES_DE_VEHICULOS
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_VEHICLE_COLOR_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import TaskVehicleColorList from "../../../../commons/ws/vehicleColor/TaskVehicleColorList";
import VehicleColorListAdminReducer from "./VehicleColorListAdminReducer";
import TaskVehicleColorDelete from "../../../../commons/ws/vehicleColor/TaskVehicleColorDelete";
import VehicleColorListTable from "./VehicleColorListTable";
import React from 'react';

const mapStateToProps = VehicleColorListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleColorListCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, vehicleColors, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_COLORES_DE_VEHICULOS)}
                      sort={{column: 'id'}}
                      TaskList={TaskVehicleColorList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_COLORES_DE_VEHICULOS)}}
                      data={vehicleColors}
                      error={error}
                      ItemsTable={VehicleColorListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_COLORES),
                          Task: TaskVehicleColorDelete,
                      }}
                      addItem={ROUTE_ADMIN_VEHICLE_COLOR_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(VehicleColorListCardAdmin as unknown as React.ComponentType<void>);
