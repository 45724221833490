import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_ADMIN_LAW_TYPE_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CODIGO_SUMA, TR_DESCUENTO, TR_NOMBRE} from "../../../../commons/I18n/constants";
import {goToRoute} from '../../../../utils/Router';
import LawType from "../../../../commons/model/LawType";
import React from 'react';

interface LawTypeListTableProps {
    data: LawType[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class LawTypeListTable extends React.Component<LawTypeListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_LAW_TYPE_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((lawType: LawType) => {
                    return (
                        <tr key={lawType.id}
                            onClick={() => this.goToItemDetail(lawType.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(lawType.id) : null}
                            <td>
                                {lawType.name}
                            </td>
                            <td>
                                {lawType.suma_code}
                            </td>
                            <td>
                                {lawType.discount_percentage}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CODIGO_SUMA)}
                    columnName={'suma_code'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DESCUENTO)}
                    columnName={'discount_percentage'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
