import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import Route, {RouteData} from "../../model/Route";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_ELEMENTO_SIMILAR} from "../../I18n/constants";

export default class TaskRouteAdd extends BaseGenericRequest<RouteData, TaskResponse<Route>> {

    private readonly data: RouteData;

    public constructor(data: RouteData) {
        super(METHOD.POST, Urls.URL_ROUTE_ADD);
        this.data = data;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_ELEMENTO_SIMILAR));

    }

    protected getRequest(): RouteData {
        return this.data;
    }
}
