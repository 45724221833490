import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_METODOS_DE_CANCELACION_DE_DENUNCIA} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {ROUTE_ADMIN_REPORT_CANCELLATION_METHODS} from "../../../../routing/Routes";
import ReportCancellationMethodListCardAdmin from "./ReportCancellationMethodListCardAdmin";
import React from 'react';

export default class ReportCancellationMethodListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_METODOS_DE_CANCELACION_DE_DENUNCIA),
                    url: ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
                    icon: AppIcon.REPORT_OFF
                }
            ]}>
                <ReportCancellationMethodListCardAdmin/>
            </ScreenCard>
        )
    }
}
