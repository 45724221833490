import {ROUTE_COMPANY_USER_STREETS} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CALLES} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import StreetCardCompanyUser from "./StreetCardCompanyUser";
import React from 'react';

export default class StreetCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_CALLES),
                    url: ROUTE_COMPANY_USER_STREETS,
                    icon: AppIcon.STREET
                }
            ]}>
                <StreetCardCompanyUser/>
            </ScreenCard>
        )
    }
}
