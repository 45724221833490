
import ScreenCard from "../../../components/screen/ScreenCard";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {TR_FIRMA_DIGITAL} from "../../../commons/I18n/constants";
import {ROUTE_USER_CERT} from "../../../routing/Routes";
import {AppIcon} from "../../../config/Config";
import UserCertCardCompanyUserReducer from "./UserCertCompanyUserReducerScreen";
import {connect} from "react-redux";
import TaskUserCertDetail from "../../../commons/ws/userCert/TaskUserCertDetail";
import UserCertCardCompanyUser, {UserCertCardCompanyUserData} from "./UserCertCardCompanyUser";
import UserCertCompanyUserScreenActions from "./UserCertCompanyUserScreenActions";
import React from 'react';

const mapStateToProps = UserCertCardCompanyUserReducer.autoMapToProps();
const mapActionsToProps = UserCertCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class UserCertCompanyUserScreen extends React.Component<Props> {

    public componentDidMount(): void {
      this.getUserCertDetail();
    }

    private getUserCertDetail = (): void => {
        new TaskUserCertDetail().execute();
    };

    private handleRefresh = (): void => {
        this.props.clearReducer();
        this.getUserCertDetail();
    };

    public render(): React.ReactNode {
        return (
            <ScreenCard breadcrumbs={[{
                name: I18nUtils.tr(TR_FIRMA_DIGITAL),
                url: ROUTE_USER_CERT,
                icon: AppIcon.DIGITAL_SIGN
            }]}>
                {this.renderUserCertCard()}
            </ScreenCard>
        )
    }

    private renderUserCertCard(): React.ReactNode {
        const {loading, userCert} = this.props;

        let initialValues: UserCertCardCompanyUserData | undefined;
        if (userCert) {
            initialValues = {
                startDate: userCert.start_date,
                endDate: userCert.end_date
            }
        }

        return (
            <UserCertCardCompanyUser parentLoading={loading}
                                     initialValues={initialValues}
                                     refreshHandler={this.handleRefresh}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UserCertCompanyUserScreen as React.ComponentType<{}>);
