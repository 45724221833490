import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_NUEVO_VEHICULO,
    TR_VEHICULO,
    TR_VEHICULO_CREADO_CORRECTAMENTE,
    TR_VEHICULO_MODIFICADO_CORRECTAMENTE,
    TR_VEHICULOS
} from "../../../../commons/I18n/constants";
import {
    ROUTE_COMPANY_USER_VEHICLE_ADD,
    ROUTE_COMPANY_USER_VEHICLE_DETAIL,
    ROUTE_COMPANY_USER_VEHICLES
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import VehicleFormScreenCompanyUserReducer from "./VehicleFormScreenCompanyUserReducer";
import VehicleFormCompanyUserScreenActions from "./VehicleFormCompanyUserScreenActions";
import TaskVehicleDetail from "../../../../commons/ws/vehicle/TaskVehicleDetail";
import VehicleCardFormCompanyUser, {VehicleCompanyUserData} from "../formdetail/VehicleCardFormCompanyUser";
import {VehicleData} from "../../../../commons/model/Vehicle";
import TaskVehicleEdit from "../../../../commons/ws/vehicle/TaskVehicleEdit";
import TaskVehicleAdd from "../../../../commons/ws/vehicle/TaskVehicleAdd";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = VehicleFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = VehicleFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class VehicleFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskVehicleDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: VehicleCompanyUserData): void => {
        const existVehicle = this.props.match.params.id !== undefined;

        const submitData: VehicleData = {
            license_plate: values.licensePlate,
            license_plate_mask_id: values.licensePlateMaskId,
            special: values.special ? values.special : false,
            description: values.description,
            vehicle_color_id: values.vehicleColorId,
            vehicle_model_id: values.vehicleModelId,
            vehicle_type_id: values.vehicleTypeId
        };

        if (existVehicle) {
            executeItemTask(
                new TaskVehicleEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_COMPANY_USER_VEHICLES),
                I18nUtils.tr(TR_VEHICULO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskVehicleAdd(submitData),
                () => goToRoute(ROUTE_COMPANY_USER_VEHICLES),
                I18nUtils.tr(TR_VEHICULO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const vehicleId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_VEHICULOS),
            url: ROUTE_COMPANY_USER_VEHICLES,
            icon: AppIcon.CAR
        }];

        if (vehicleId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_VEHICULO),
                url: generateRoute(ROUTE_COMPANY_USER_VEHICLE_DETAIL, {id: vehicleId}),
                icon: AppIcon.CAR
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_VEHICULO),
                url: ROUTE_COMPANY_USER_VEHICLE_ADD,
                icon: AppIcon.CAR
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderVehicleForm()}
            </ScreenCard>
        )
    }

    private renderVehicleForm(): React.ReactNode {
        const {vehicleLoading, vehicle} = this.props;

        let initialValues: Partial<VehicleCompanyUserData> | undefined;

        if (vehicle) {
            initialValues = {
                licensePlate: vehicle.license_plate ? vehicle.license_plate : '',
                licensePlateMaskId: vehicle.license_plate_mask ? vehicle.license_plate_mask.id : '-1',
                vehicleTypeId: vehicle.vehicle_type ? vehicle.vehicle_type.id : '-1',
                vehicleModelId: vehicle.vehicle_model ? vehicle.vehicle_model.id : '-1',
                vehicleColorId: vehicle.vehicle_color ? vehicle.vehicle_color.id : '-1',
                description: vehicle.description ? vehicle.description : '',
                special: vehicle.special ? vehicle.special : false
            }
        }
        return (
            <VehicleCardFormCompanyUser
                parentLoading={vehicleLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(VehicleFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
