import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CONTROLADOR,
    TR_EXPORTAR_EXCEL,
    TR_FECHA_FIN,
    TR_FECHA_INICIO,
    TR_NO_EXISTEN_RECAUDACIONES,
    TR_PARQUIMETRO,
    TR_RECAUDACIONES_PARQUIMETROS,
    TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES
} from "../../../../commons/I18n/constants";
import Row from "../../../../components/Row";
import FormCol from "../../../../components/form/FormCol";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import ParkingMeterCollectionListCompanyUserActions from "./ParkingMeterCollectionListCompanyUserActions";
import ParkingMeterCollectionListCompanyUserReducer from "./ParkingMeterCollectionListCompanyUserReducer";
import TaskParkingMeterList from "../../../../commons/ws/parkingMeter/TaskParkingMeterList";
import TaskParkingMeterCollectionList, {ParkingMeterCollectionCriteriaProps} from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";
import ParkingMeterCollectionListTable from "./ParkingMeterCollectionListTable";
import Col from "../../../../components/Col";
import Button from "../../../../components/buttons/Button";
import TaskParkingMeterCollectionExport from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionExport";
import Alert from "../../../../base/alerts/Alert";
import FormMultiSelect from "../../../../components/form/FormMultiSelect";
import React from 'react';

const mapActionsToProps = ParkingMeterCollectionListCompanyUserActions.autoMapToProps();
const mapStateToProps = ParkingMeterCollectionListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

interface State {
    currentCriteria: ParkingMeterCollectionCriteriaProps,
}

class ParkingMeterCollectionListCardCompanyUser extends React.Component<Props, State> {

    private cardList;

    public constructor(props: Props) {
        super(props);
        this.state = {currentCriteria: {}}
    }

    public componentWillMount(): void {
        new TaskParkingMeterList({limit: 0}).execute();
        new TaskUserList({limit: 0}).execute();
    }

    public componentWillUnMount(): void {
        this.props.clearReducer();
    }

    private onStartDateSelect = (date?: Date): void => {
        const newCriteria: ParkingMeterCollectionCriteriaProps = {
            ...this.state.currentCriteria,
            start_date: date ? date.toISOString() : undefined,
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onEndDateSelect = (date?: Date): void => {
        const newCriteria: ParkingMeterCollectionCriteriaProps = {
            ...this.state.currentCriteria,
        };
        if (date) {
            date.setDate(date.getDate() + 1);
            newCriteria.end_date = date.toISOString();
        } else {
            delete newCriteria.end_date;
        }
        this.setState({currentCriteria: newCriteria});
    };

    private onControllerSelect = (selectControllerId: string): void => {

        let currentControllerIds: string[] = this.state.currentCriteria.controller_ids ? this.state.currentCriteria.controller_ids : [];
        const existInCurrentControllersIds: boolean = currentControllerIds.includes(selectControllerId);

        if (existInCurrentControllersIds) {
            currentControllerIds = currentControllerIds.filter((id) => id !== selectControllerId);

        } else {
            currentControllerIds = [...currentControllerIds, selectControllerId]
        }
        this.setState({
            currentCriteria: {
                ...this.state.currentCriteria,
                controller_ids: currentControllerIds.length === 0 || (currentControllerIds.length === 1 && currentControllerIds[0] === "-1") ?
                    undefined : currentControllerIds
            }
        })
    };

    private onExportExcel = (): void => {
        new TaskParkingMeterCollectionExport(this.cardList.getCriteria()).executeAsPromise()
            .then((response) => {
                if (response.success) {
                    // @ts-ignore
                    window.open(response.data.url, "_blank");
                }
            })
            .catch(() => Alert.error(I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES)));
    };

    private onParkingMeterSelect = (selectParkingMeterId: string): void => {
        let currentParkingMeterIds: string[] = this.state.currentCriteria.parking_meter_ids ? this.state.currentCriteria.parking_meter_ids : [];
        const existInCurrentParkingMetersIds: boolean = currentParkingMeterIds.includes(selectParkingMeterId);

        if (existInCurrentParkingMetersIds) {
            currentParkingMeterIds = currentParkingMeterIds.filter((id) => id !== selectParkingMeterId);

        } else {
            currentParkingMeterIds = [...currentParkingMeterIds, selectParkingMeterId]
        }
        this.setState({
            currentCriteria: {
                ...this.state.currentCriteria,
                parking_meter_ids: currentParkingMeterIds.length === 0 || (currentParkingMeterIds.length === 1 && currentParkingMeterIds[0] === "-1") ?
                    undefined : currentParkingMeterIds
            }
        })
    };

    public render(): React.ReactNode {
        const {loading, parkingMeterCollections, error, pager} = this.props;
        return (
            <CardList ref={(node) => this.cardList = node}
                      loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_RECAUDACIONES_PARQUIMETROS)}
                      sort={{column: ''}}
                      TaskList={TaskParkingMeterCollectionList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_RECAUDACIONES)}}
                      data={parkingMeterCollections}
                      error={error}
                      ItemsTable={ParkingMeterCollectionListTable}
                      customCriteria={this.state.currentCriteria}
            >
                {TaskParkingMeterCollectionList && this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent = (): React.ReactNode => {
        const {userList, parkingMeterList, parkingMeterCollections} = this.props;

        const userOptions = userList.map((user) => ({value: user.id, name: `${user.name} ${user.last_name}`}));
        const parkingMeterOptions = parkingMeterList.map((parkingMeter) => ({
            value: parkingMeter.id,
            name: parkingMeter.code
        }));
        const existParkingMeterCollections = parkingMeterCollections.length !== 0;

        return (
            <>
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_CONTROLADOR)}</label>
                                <FormMultiSelect
                                    options={userOptions}
                                    selectHandler={this.onControllerSelect}
                                    selectedOptions={this.state.currentCriteria.controller_ids || []}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                                <input type={'date'} className="form-control"
                                       // @ts-ignore
                                       onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_FIN)}</label>
                                <input type={'date'} className="form-control"
                                       // @ts-ignore
                                       onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_PARQUIMETRO)}</label>
                                <FormMultiSelect
                                    options={parkingMeterOptions}
                                    selectHandler={this.onParkingMeterSelect}
                                    selectedOptions={this.state.currentCriteria.parking_meter_ids || []}
                                />
                            </div>
                        </div>
                    </FormCol>
                </Row>
                <Row>
                    <Col sm={4} md={6} lg={8}/>
                    <Col sm={4} md={3} lg={2}/>
                    <Col sm={4} md={3} lg={2}>
                        <Button
                            text={I18nUtils.tr(TR_EXPORTAR_EXCEL)}
                            onClick={this.onExportExcel}
                            type={'button'}
                            block={true}
                            className={'btn-lg btn-default btn-export'}
                            disabled={!existParkingMeterCollections}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterCollectionListCardCompanyUser as unknown as React.ComponentType<void>);
