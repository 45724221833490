import {TR_DENUNCIA, TR_FECHA, TR_OBSERVACIONES} from "../../../../../commons/I18n/constants";
import {OnSortHandler, SortState} from "../../../../../utils/Sort";
import ReportCancellationLog from "../../../../../commons/model/ReportCancellationLog";
import DateFormatter from "../../../../../utils/DateFormatter";
import Th from "../../../../../components/table/Th";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import React from 'react';

interface ParkingMeterListTableProps {
    data: ReportCancellationLog[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
}

export default class ReportCancelLogListTable extends React.Component<ParkingMeterListTableProps> {

    public render(): React.ReactNode {
        const {data} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((reportCancellationLog: ReportCancellationLog) => {
                    return (
                        <tr key={reportCancellationLog.id}>
                            <td>
                                {DateFormatter.formatDatetime(reportCancellationLog.date)}
                            </td>
                            <td>
                                {reportCancellationLog.report_number}
                            </td>
                            <td>
                                {reportCancellationLog.result_description}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DENUNCIA)}
                    columnName={'report_number'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_OBSERVACIONES)}
                    columnName={'result_code'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
