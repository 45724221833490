import I18nUtils from '../../commons/I18n/I18nUtils';
import { TR_BUSCANDO, TR_BUSCAR, TR_NO_SE_HAN_ENCONTRADO_RESULTADOS } from '../../commons/I18n/constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import FormCol from '../form/FormCol';
import { AutocompleteOption } from '../form/FormAutocomplete';

interface Props {
    gridSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    label: string
    options: Array<AutocompleteOption<any>>
    initialValue?: string
    isLoading: boolean
    disabled?: boolean
    onRef?: any,
    onSearch?: (query: string) => void
    onChange: (id: string) => void
}

export const FilterTypeahead: React.FunctionComponent<Props> = (
    {
        gridSize,
        label,
        options,
        initialValue,
        isLoading,
        disabled = false,
        onRef,
        onSearch = () => null,
        onChange,
    }
) => {
    const defaultOption = options.find((option: AutocompleteOption<any>) => option.value === initialValue);
    const defaultInputValue: string | undefined = defaultOption ? defaultOption.name : undefined;

    return (
        <FormCol md={gridSize} lg={gridSize}>
            <div className="form-group">
                <div className="form-line">
                    <label>{label}</label>
                    <AsyncTypeahead
                        align={'justify'}
                        allowNew={false}
                        autoFocus={false}
                        clearButton={false}
                        delay={200}
                        disabled={disabled}
                        emptyLabel={I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                        ignoreDiacritics={true}
                        isLoading={isLoading}
                        labelKey={'name'}
                        maxResults={10}
                        minLength={2}
                        multiple={false}
                        onChange={(selected) => selected.length > 0 && onChange(selected[0].data.id)}
                        onInputChange={(input) => input === '' && onChange('-1')}
                        onSearch={onSearch}
                        options={options}
                        placeholder={I18nUtils.tr(TR_BUSCAR)}
                        promptText={I18nUtils.tr(TR_BUSCAR)}
                        searchText={I18nUtils.tr(TR_BUSCANDO)}
                        selectHintOnEnter={true}
                        useCache={true}
                        defaultInputValue={defaultInputValue}
                        ref={onRef}
                    />
                </div>
            </div>
        </FormCol>
    );
};
