
import {connect} from "react-redux";
import CardList from "../../../../../components/card/CardList";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {
    TR_CONTROLADOR,
    TR_CONTROLADORES,
    TR_EXPORTAR_EXCEL,
    TR_FECHA_FIN,
    TR_FECHA_INICIO,
    TR_NO_EXISTEN_INFORMES_DE_CONTROLADORES,
    TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES
} from "../../../../../commons/I18n/constants";
import ControllerReportCompanyUserReducer from "./ControllerReportCompanyUserReducer";
import ControllerReportCompanyUserActions from "./ControllerReportCompanyUserActions";
import TaskWorkdayReport, {WorkdayReportCriteria} from "../../../../../commons/ws/workdayReport/TaskWorkdayReport";
import ControllerReportListTable from "./ControllerReportListTable";
import Row from "../../../../../components/Row";
import FormCol from "../../../../../components/form/FormCol";
import FormMultiSelect from "../../../../../components/form/FormMultiSelect";
import Col from "../../../../../components/Col";
import Button from "../../../../../components/buttons/Button";
import TaskUserList from "../../../../../commons/ws/user/TaskUserList";
import Alert from "../../../../../base/alerts/Alert";
import TaskWorkdayReportExport from "../../../../../commons/ws/workdayReport/TaskWorkdayReportExport";
import React from 'react';

const mapStateToProps = ControllerReportCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ControllerReportCompanyUserActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

interface State {
    currentCriteria: WorkdayReportCriteria,
    exportLoading: boolean,
}

class ControllerReportCardCompanyUser extends React.Component<Props, State> {

    private cardList;

    public constructor(props: Props) {
        super(props);
        this.state = {
            currentCriteria: {},
            exportLoading: false,
        }
    }

    public componentWillMount(): void {
        new TaskUserList({limit: 0}).execute();
    }

    private onControllerSelect = (selectControllerId: string): void => {

        let currentControllerIds: string[] = this.state.currentCriteria.user_ids ? this.state.currentCriteria.user_ids : [];
        const existInCurrentControllersIds: boolean = currentControllerIds.includes(selectControllerId);

        if (existInCurrentControllersIds) {
            currentControllerIds = currentControllerIds.filter((id) => id !== selectControllerId);

        } else {
            currentControllerIds = [...currentControllerIds, selectControllerId]
        }
        this.setState({
            currentCriteria: {
                ...this.state.currentCriteria,
                user_ids: currentControllerIds.length === 0 || (currentControllerIds.length === 1 && currentControllerIds[0] === "-1") ?
                    undefined : currentControllerIds
            }
        })
    };

    private onStartDateSelect = (date?: Date): void => {
        const newCriteria: WorkdayReportCriteria = {
            ...this.state.currentCriteria,
            start_date: date ? date.toISOString() : undefined,
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onEndDateSelect = (date?: Date): void => {
        const newCriteria: WorkdayReportCriteria = {
            ...this.state.currentCriteria,
        };
        if (date) {
            date.setDate(date.getDate() + 1);
            newCriteria.end_date = date.toISOString();
        } else {
            delete newCriteria.end_date;
        }
        this.setState({currentCriteria: newCriteria});
    };

    private onExportExcel = (): void => {
        const criteria = this.cardList.getCriteria();
        criteria.limit = 0; // Es necesario reconfigurar el límite para que exporte todos los elementos
        this.setState({exportLoading: true});
        new TaskWorkdayReportExport(criteria)
            .executeAsPromise()
            .then((response) => {
                if (response.success) {
                    this.setState({exportLoading: false});
                    // @ts-ignore
                    window.open(response.data.url, "_blank");
                }
            })
            .catch(() => {
                this.setState({exportLoading: false});
                Alert.error(I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES));
            });
    };

    public render(): React.ReactNode {
        const {loading, workdayReports, error, pager} = this.props;
        const {exportLoading} = this.state;

        return (
            <CardList ref={(node) => this.cardList = node}
                      loading={loading || exportLoading}
                      pager={pager}
                      title={I18nUtils.tr(TR_CONTROLADORES)}
                      sort={{column: 'user.name'}}
                      TaskList={TaskWorkdayReport}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_INFORMES_DE_CONTROLADORES)}}
                      data={workdayReports}
                      error={error}
                      ItemsTable={ControllerReportListTable}
                      customCriteria={this.state.currentCriteria}
            >
                {TaskWorkdayReport && this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent = (): React.ReactNode => {
        const {userList, workdayReports} = this.props;

        const userOptions = userList.map((user) => ({value: user.id, name: `${user.name} ${user.last_name}`}));
        const existWordayReports = workdayReports.length !== 0;

        return (
            <>
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_CONTROLADOR)}</label>
                                <FormMultiSelect
                                    options={userOptions}
                                    selectHandler={this.onControllerSelect}
                                    selectedOptions={this.state.currentCriteria.user_ids || []}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                                <input type={'date'} className="form-control"
                                       // @ts-ignore
                                       onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_FIN)}</label>
                                <input type={'date'} className="form-control"
                                       // @ts-ignore
                                       onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <Col sm={4} md={3} lg={2} className={"m-t-35"}>
                        <Button
                            text={I18nUtils.tr(TR_EXPORTAR_EXCEL)}
                            onClick={this.onExportExcel}
                            type={'button'}
                            block={true}
                            className={'btn-lg btn-default btn-export'}
                            disabled={!existWordayReports}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ControllerReportCardCompanyUser as unknown as React.ComponentType<void>);
