import { FunctionComponent } from 'react';
import { CardBodyFooterProps } from './typings';
import I18nUtils from '../commons/I18n/I18nUtils';
import { TR_CANCELAR } from '../commons/I18n/constants';
import Row from '../components/Row';
import Col from '../components/Col';
import Button from '../components/buttons/Button';

export const CardBodyFooter: FunctionComponent<CardBodyFooterProps> = (
    {
        acceptTitle,
        acceptOnClick,
        acceptDisabled,
        cancelTitle = I18nUtils.tr(TR_CANCELAR),
        cancelOnClick,
    }
) => {
    return (
        <Row className='p-t-20'>
            <Col sm={4} md={6} lg={8}/>
            <Col sm={4} md={3} lg={2}>
                <Button
                    text={cancelTitle}
                    onClick={cancelOnClick}
                    type={'button'}
                    block={true}
                    className={'btn-lg btn-default'}
                />
            </Col>
            <Col sm={4} md={3} lg={2}>
                <Button
                    text={acceptTitle}
                    onClick={acceptOnClick}
                    type={'button'}
                    block={true}
                    className={'btn-primary btn-lg'}
                    disabled={acceptDisabled}
                />
            </Col>
        </Row>
    );
};
