
import {TR_MAPA_DE_SITUACION} from "../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION} from "../../../../routing/Routes";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {AppIcon} from "../../../../config/Config";
import ControllerSituationMapCardCompanyUser from "./ControllerSituationMapCardCompanyUser";
import React from 'react';

export default class ControllerSituationMapCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_MAPA_DE_SITUACION),
                    url: ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION,
                    icon: AppIcon.MAP
                }
            ]}>
                <ControllerSituationMapCardCompanyUser/>
            </ScreenCard>
        )
    }
}
