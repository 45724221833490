import React, {ReactNode} from "react";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_COLECTAS_PARQUIMETRO, TR_PROCESOS_RECAUDACION} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_COLLECTION_PROCESS, ROUTE_ADMIN_COLLECTION_PROCESS_ADD} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import CollectionProcessAddDataContainer from "./CollectionProcessAddDataContainer";

export default class CollectionProcessAddScreen extends React.Component {
	public render(): ReactNode {
		return (
			<ScreenCard breadcrumbs={[
				{
					name: I18nUtils.tr(TR_PROCESOS_RECAUDACION),
					url: ROUTE_ADMIN_COLLECTION_PROCESS,
					icon: AppIcon.EURO,
				},
				{
					name: I18nUtils.tr(TR_COLECTAS_PARQUIMETRO),
					url: ROUTE_ADMIN_COLLECTION_PROCESS_ADD,
					icon: AppIcon.EURO,
				}
			]}>
				<CollectionProcessAddDataContainer/>
			</ScreenCard>
		);
	}
}
