import { FunctionComponent } from 'react';
import Report from '../../commons/model/Report';
import {
    TR_ADVERACION_ANULADA,
    TR_ADVERADA,
    TR_ADVERADOR,
    TR_ADVERAR_DENUNCIA,
    TR_ANULAR_ADVERACION,
    TR_APELLIDO,
    TR_ARTICULO,
    TR_AVISADA,
    TR_CIUDAD,
    TR_COLOR,
    TR_CONTROLADOR,
    TR_DATOS_CONDUCTOR,
    TR_DATOS_DENUNCIA,
    TR_DENUNCIA_ADVERADA,
    TR_DETALLES_DENUNCIA,
    TR_DIRECCION,
    TR_DOCUMENTACION,
    TR_ESTADO_ADVERACION,
    TR_ESTADO_DE_LA_DENUNCIA,
    TR_FECHA,
    TR_FECHA_DE_EXPIRACION,
    TR_FECHA_Y_HORA_DE_RATIFICACION,
    TR_FIRMA,
    TR_FOTOGRAFIA_DE_INVALIDACION,
    TR_FOTOGRAFIAS,
    TR_IMPORTE_A_PAGAR,
    TR_IMPORTE_ANULACION,
    TR_IMPORTE_ANULACION_DEVUELTO,
    TR_IMPORTE_REDUCIDO,
    TR_LEY,
    TR_MARCA,
    TR_MATRICULA,
    TR_MODELO,
    TR_NIF,
    TR_NO,
    TR_NO_AVISADA,
    TR_NO_PRESENTE,
    TR_NOMBRE,
    TR_NOTIFICACION_DE_LA_GRUA,
    TR_NUMERO_DE_ANULACION,
    TR_NUMERO_DE_DENUNCIA,
    TR_OBSERVACIONES,
    TR_OBSERVACIONES_DE_INVALIDACION,
    TR_PDF_DENUNCIA,
    TR_PDF_DENUNCIA_RATIFICADA,
    TR_PENDIENTE,
    TR_RAZON_DE_ANULACION,
    TR_RAZON_DE_INVALIDACION,
    TR_RECLAMADA,
    TR_RUTA,
    TR_SI,
    TR_TIPO_CONDUCTOR,
    TR_TIPO_DE_VEHICULO,
    TR_TIPO_DE_ZONA,
    TR_TRAMITE_DE_LA_DENUNCIA,
    TR_VEHICULO,
    TR_VOLVER,
} from '../../commons/I18n/constants';
import I18nUtils from '../../commons/I18n/I18nUtils';
import DateFormatter from '../../utils/DateFormatter';
import NumberFormatter from '../../utils/NumberFormatter';
import TaskReportRatifierCancel from '../../commons/ws/report/TaskReportRatifierCancel';
import TaskReportRatifier from '../../commons/ws/report/TaskReportRatifier';
import Alert from '../../base/alerts/Alert';
import { goToRoute } from '../../utils/Router';
import { Card } from '../../components-new/Card';
import CardBody from '../card/CardBody';
import { NewButton } from '../../components-new/Button';
import { CardHeader } from '../../components-new/CardHeader';
import { CardSectionCollapsed2 } from '../../components-new/CardSectionCollapsed2';
import DetailsText from '../detail/DetailsText';
import DetailsImage from '../detail/DetailsImage';
import DetailsLink from '../detail/DetailsLink';
import Col from '../Col';
import Row from '../Row';

interface Props {
    report: Report | null
    loading: boolean
    backRoute: string
    withRatifier?: boolean
    withStateOperations?: boolean
    withSentToProcessStateOperations?: boolean
    withExportPDF?: boolean
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const handleGoBack = (route) => goToRoute(route);

const handleRatifier = ({ id, ratifier_images }) =>
    new TaskReportRatifier(id, ratifier_images, undefined)
        .onFail((error) => Alert.error(error))
        .onSuccess(() => Alert.success(I18nUtils.tr(TR_DENUNCIA_ADVERADA)))
        .execute();

const handleRatifierCancel = ({ id }) =>
    new TaskReportRatifierCancel(id)
        .onFail((error) => Alert.error(error))
        .onSuccess(() => Alert.success(I18nUtils.tr(TR_ADVERACION_ANULADA)))
        .execute();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ReportDetailsCard: FunctionComponent<Props> = (
    {
        report,
        loading,
        backRoute,
        withRatifier = false,
        withExportPDF = false,
        withStateOperations = false,
        withSentToProcessStateOperations = false,
    },
) => {
    const hasHeaderButtons = withExportPDF || withRatifier;

    return (
        <Card loading={loading}>
            <CardHeader
                title={`${I18nUtils.tr(TR_DETALLES_DENUNCIA)} ${report && report.number}`}
                headerButtons={
                    hasHeaderButtons && report ?
                        <HeaderButtons
                            report={report}
                            withExportPDF={withExportPDF}
                            withRatifier={withRatifier}
                            withStateOperations={withStateOperations}
                            withSentToProcessStateOperations={withSentToProcessStateOperations}
                        /> : null
                }
            >
            </CardHeader>
            <CardBody>
                {
                    report ?
                        <ReportDetailsCardBody
                            report={report}
                            backRoute={backRoute}
                        /> : null
                }
            </CardBody>
        </Card>
    );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ReportDetailsCardBody: FunctionComponent<any> = (
    {
        report,
        backRoute,
    },
) => {
    const {
        date,
        expiration_date,
        number: reportNumber,
        cancellation_number,
        report_state,
        report_invalidation_reason,
        report_invalidation_image,
        report_invalidation_observations,
        report_cancellation_method,
        vehicle,
        law,
        workday,
        street,
        street_number,
        // street_situation,
        // street_situation_description,
        ratified,
        ratifier,
        ratification_date,
        report_sent_to_process_state,
        claimed,
        cancellation_returned,
        notify_crane,
        observations,
        signature_image_controller,
        signature_image_ratifier,
        report_pdf,
        report_ratified_pdf,
        images,
        ratifier_images,
        // latitude,
        // longitude,
        exploitation,
        driver_name,
        driver_last_name,
        driver_nif,
        driver_address,
        driver_type,
        driver_signature_image,
    } = report as Report;

    const sectionCollapsed = false;
    const sectionCollapsedStyle = { marginBottom: '30px' };
    const inputDirection = street.name + ' ' + street_number;

    return (
        <>
            <CardSectionCollapsed2
                title={I18nUtils.tr(TR_VEHICULO)}
                initialCollapsed={sectionCollapsed}
                style={sectionCollapsedStyle}
            >
                <Row>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_MATRICULA)}
                            value={vehicle.license_plate}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_TIPO_DE_VEHICULO)}
                            value={vehicle.vehicle_type ? vehicle.vehicle_type.name : '-'}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_MARCA)}
                            value={vehicle.vehicle_model && vehicle.vehicle_model.vehicle_brand ? vehicle.vehicle_model.vehicle_brand.name : '-'}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_MODELO)}
                            value={vehicle.vehicle_model ? vehicle.vehicle_model.name : '-'}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_COLOR)}
                            value={vehicle.vehicle_color ? vehicle.vehicle_color.name : '-'}
                        />
                    </Col>
                </Row>
            </CardSectionCollapsed2>
            <CardSectionCollapsed2
                title={I18nUtils.tr(TR_DATOS_DENUNCIA)}
                initialCollapsed={sectionCollapsed}
                style={sectionCollapsedStyle}
            >
                <Row>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_NUMERO_DE_DENUNCIA)}
                            value={reportNumber}
                        />
                    </Col>
                    {
                        cancellation_number ? (
                            <Col md={3} lg={3}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_NUMERO_DE_ANULACION)}
                                    value={cancellation_number}
                                />
                            </Col>
                        ) : null
                    }
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_FECHA)}
                            value={DateFormatter.formatDatetime(date)}
                        />
                    </Col>
                    {
                        expiration_date ? (
                            <Col md={3} lg={3}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_FECHA_DE_EXPIRACION)}
                                    value={DateFormatter.formatDatetime(expiration_date)}
                                />
                            </Col>
                        ) : null
                    }
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_IMPORTE_A_PAGAR)}
                            value={NumberFormatter.formatMoneyNumber(law.amount)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_IMPORTE_REDUCIDO)}
                            value={NumberFormatter.formatMoneyNumber(law.reduced_amount)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_IMPORTE_ANULACION)}
                            value={NumberFormatter.formatMoneyNumber(law.cancellation_amount)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={`${I18nUtils.tr(TR_LEY)} / ${I18nUtils.tr(TR_ARTICULO)}`}
                            value={law.screen_text}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_ESTADO_DE_LA_DENUNCIA)}
                            value={report_state.name}
                        />
                    </Col>
                    <Col md={2} lg={2}>
                        <DetailsText
                            label={I18nUtils.tr(TR_TRAMITE_DE_LA_DENUNCIA)}
                            value={report_sent_to_process_state.name}
                        />
                    </Col>
                    <Col md={2} lg={2}>
                        <DetailsText
                            label={I18nUtils.tr(TR_RECLAMADA)}
                            value={I18nUtils.tr(claimed ? TR_SI : TR_NO)}
                        />
                    </Col>
                    <Col md={2} lg={2}>
                        <DetailsText
                            label={I18nUtils.tr(TR_IMPORTE_ANULACION_DEVUELTO)}
                            value={I18nUtils.tr(cancellation_returned ? TR_SI : TR_NO)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_CIUDAD)}
                            value={exploitation.name}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_DIRECCION)}
                            value={inputDirection}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_RUTA)}
                            value={workday.route ? workday.route.name : '-'}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_TIPO_DE_ZONA)}
                            value={street.parking_area_type.name}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_ESTADO_ADVERACION)}
                            value={ratified ? I18nUtils.tr(TR_ADVERADA) : I18nUtils.tr(TR_PENDIENTE)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <DetailsText
                            label={I18nUtils.tr(TR_NOTIFICACION_DE_LA_GRUA)}
                            value={notify_crane ? I18nUtils.tr(TR_AVISADA) : I18nUtils.tr(TR_NO_AVISADA)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col md={9} lg={9}>
                        <DetailsText
                            label={I18nUtils.tr(TR_OBSERVACIONES)}
                            value={observations}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    {
                        report_invalidation_reason ? (
                            <Col md={3} lg={3}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_RAZON_DE_INVALIDACION)}
                                    value={report_invalidation_reason && report_invalidation_reason.name}
                                />
                            </Col>
                        ) : null
                    }
                    {
                        report_invalidation_observations ? (
                            <Col md={3} lg={3}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_OBSERVACIONES_DE_INVALIDACION)}
                                    value={report_invalidation_observations}
                                />
                            </Col>
                        ) : null
                    }
                    {
                        report_cancellation_method ? (
                            <Col md={3} lg={3}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_RAZON_DE_ANULACION)}
                                    value={report_cancellation_method && report_cancellation_method.name}
                                />
                            </Col>
                        ) : null
                    }
                </Row>
                {
                    report_invalidation_image ? (
                        <Row style={{ marginTop: 15 }}>
                            <Col md={3} lg={3}>
                                <DetailsImage
                                    label={I18nUtils.tr(TR_FOTOGRAFIA_DE_INVALIDACION)}
                                    images={report_invalidation_image ? [report_invalidation_image] : []}
                                    imageWidth={'254px'}
                                    imageHeight={'160px'}
                                />
                            </Col>
                        </Row>
                    ) : null
                }
            </CardSectionCollapsed2>
            <CardSectionCollapsed2
                title={I18nUtils.tr(TR_CONTROLADOR)}
                initialCollapsed={sectionCollapsed}
                style={sectionCollapsedStyle}
            >
                <Row>
                    <Col md={4} lg={4}>
                        <DetailsText
                            label={I18nUtils.tr(TR_NOMBRE)}
                            value={workday.user.name}
                        />
                        <DetailsImage
                            label={I18nUtils.tr(TR_FIRMA)}
                            images={signature_image_controller ? [signature_image_controller] : []}
                            style={{ marginTop: 15 }}
                        />
                    </Col>
                    <Col md={8} lg={8}>
                        <DetailsImage
                            label={I18nUtils.tr(TR_FOTOGRAFIAS)}
                            images={images ? images : []}
                            imageWidth={'254px'}
                            imageHeight={'160px'}
                        />
                    </Col>
                </Row>
            </CardSectionCollapsed2>
            {
                ratifier ?
                    <CardSectionCollapsed2
                        title={I18nUtils.tr(TR_ADVERADOR)}
                        style={sectionCollapsedStyle}
                        initialCollapsed={sectionCollapsed}
                    >
                        <Row>
                            <Col md={4} lg={4}>
                                <DetailsText
                                    label={I18nUtils.tr(TR_NOMBRE)}
                                    value={ratifier.name}
                                />
                                <DetailsText
                                    label={I18nUtils.tr(TR_FECHA_Y_HORA_DE_RATIFICACION)}
                                    value={DateFormatter.formatDatetime(ratification_date)}
                                    style={{ marginTop: 15 }}
                                />
                                <DetailsImage
                                    label={I18nUtils.tr(TR_FIRMA)}
                                    images={signature_image_ratifier ? [signature_image_ratifier] : []}
                                    style={{ marginTop: 15 }}
                                />
                            </Col>
                            <Col md={8} lg={8}>
                                <DetailsImage
                                    label={I18nUtils.tr(TR_FOTOGRAFIAS)}
                                    images={ratifier_images ? ratifier_images : []}
                                    imageWidth={'254px'}
                                    imageHeight={'160px'}
                                />
                            </Col>
                        </Row>
                    </CardSectionCollapsed2> : null
            }
            <CardSectionCollapsed2
                title={I18nUtils.tr(TR_DOCUMENTACION)}
                initialCollapsed={sectionCollapsed}
                style={sectionCollapsedStyle}
            >
                <Row>
                    <Col md={12} lg={12}>
                        <DetailsLink
                            label={I18nUtils.tr(TR_PDF_DENUNCIA)}
                            href={report_pdf}
                        />
                    </Col>
                    <Col md={12} lg={12}>
                        <DetailsLink
                            label={I18nUtils.tr(TR_PDF_DENUNCIA_RATIFICADA)}
                            href={report_ratified_pdf}
                        />
                    </Col>
                </Row>
            </CardSectionCollapsed2>
            {
                exploitation.data_driver_screen ? (
                    <CardSectionCollapsed2
                        title={I18nUtils.tr(TR_DATOS_CONDUCTOR)}
                        initialCollapsed={sectionCollapsed}
                        style={sectionCollapsedStyle}
                    >

                        <Row>
                            <Col md={8} lg={8}>
                                <Row>
                                    <Col md={6} lg={6}>
                                        <DetailsText
                                            label={I18nUtils.tr(TR_NIF)}
                                            value={driver_nif}
                                        />
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <DetailsText
                                            label={I18nUtils.tr(TR_TIPO_CONDUCTOR)}
                                            value={driver_type && driver_type.name}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Col md={6} lg={6}>
                                        <DetailsText
                                            label={I18nUtils.tr(TR_NOMBRE)}
                                            value={driver_name}
                                        />
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <DetailsText
                                            label={I18nUtils.tr(TR_APELLIDO)}
                                            value={driver_last_name}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Col md={12} lg={12}>
                                        <DetailsText
                                            label={I18nUtils.tr(TR_DIRECCION)}
                                            value={driver_address}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} lg={4}>
                                {
                                    driver_signature_image ? (
                                        <DetailsImage
                                            label={I18nUtils.tr(TR_FIRMA)}
                                            images={[driver_signature_image]}
                                            style={{ marginTop: 15 }}
                                        />
                                    ) : (
                                        <DetailsText
                                            label={I18nUtils.tr(TR_FIRMA)}
                                            value={I18nUtils.tr(TR_NO_PRESENTE)}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    </CardSectionCollapsed2>
                ) : null
            }
            <BodyButtons backRoute={backRoute} />
        </>
    );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const HeaderButtons: FunctionComponent<any> = (
    {
        report,
        withRatifier,
    },
) => {
    const { ratified } = report!;

    return (
        <>
            {
                withRatifier ?
                    <NewButton
                        label={ratified ? I18nUtils.tr(TR_ANULAR_ADVERACION) : I18nUtils.tr(TR_ADVERAR_DENUNCIA)}
                        type={'btn-bordered'}
                        style={{ marginRight: 25 }}
                        onClick={ratified ? () => handleRatifierCancel(report) : () => handleRatifier(report)}
                    /> : null
            }
        </>
    );
};

const BodyButtons: FunctionComponent<any> = (
    {
        backRoute,
    },
) => {
    return (
        <div className={'card-footer'}>
            <NewButton
                label={I18nUtils.tr(TR_VOLVER)}
                disabled={false}
                type={'btn-filled'}
                style={{ width: '133px' }}
                onClick={() => handleGoBack(backRoute)}
            />
        </div>
    );
};
