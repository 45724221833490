
import ScreenCard from '../../../../components/screen/ScreenCard';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { TR_DENUNCIAS } from '../../../../commons/I18n/constants';
import { ROUTE_COMPANY_USER_REPORTS } from '../../../../routing/Routes';
import { AppIcon } from '../../../../config/Config';
import ReportListCompanyUserCard from './ReportListCompanyUserCard';
import React from 'react';

export default class ReportListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_DENUNCIAS),
                    url: ROUTE_COMPANY_USER_REPORTS,
                    icon: AppIcon.REPORT
                }
            ]}>
                <ReportListCompanyUserCard/>
            </ScreenCard>
        );
    }
}
