import {TR_MENSAJES_A_TODOS} from "../../../../../commons/I18n/constants";
import {ROUTE_COMPANY_USER_BROADCAST} from "../../../../../routing/Routes";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {AppIcon} from "../../../../../config/Config";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import ChatBroadcastCardCompanyUser from "./ChatBroadcastCardCompanyUser";
import React from 'react';

export default class ChatBroadcastCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_MENSAJES_A_TODOS),
                    url: ROUTE_COMPANY_USER_BROADCAST,
                    icon: AppIcon.POST_ADD,
                }
            ]}>
                <ChatBroadcastCardCompanyUser/>
            </ScreenCard>
        )
    }
}
