
import { Component } from 'react';
import { CardHeader } from '../../../../components-new/CardHeader';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_ANULAR,
    TR_DENUNCIA_ANULADA,
    TR_DENUNCIA_EXPORTADA,
    TR_DENUNCIA_INVALIDADA,
    TR_DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE,
    TR_DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE,
    TR_DENUNCIA_RECLAMADA,
    TR_DENUNCIA_RECTIFICADA,
    TR_DESHACER_ENVIO_A_TRAMITE,
    TR_DETALLES_DENUNCIA,
    TR_DEVOLVER_ANULACION,
    TR_ENVIAR_A_TRAMITE,
    TR_EXPORTAR_A_PDF,
    TR_IMPORTE_ANULACION_DEVUELTO,
    TR_INVALIDAR,
    TR_RECLAMAR,
    TR_RECTIFICAR_ANULACION_INVALIDACION,
    TR_SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION
} from '../../../../commons/I18n/constants';
import CardBody from '../../../../components/card/CardBody';
import { Card } from '../../../../components-new/Card';
import Report from '../../../../commons/model/Report';
import { ReportStateIds } from '../../../../commons/model/ReportState';
import { NewButtonGroup } from '../../../../components-new/ButtonGroup';
import { ReportSentToProcessStateIds } from '../../../../commons/model/ReportSentToProcessState';
import TaskReportGetPDF from '../../../../commons/ws/report/TaskReportGetPDF';
import Alert from '../../../../base/alerts/Alert';
import { ReportDetailsCardBody } from '../../../../components/sections/ReportDetailsCard';
import TaskReportStateCancel from '../../../../commons/ws/reportState/TaskReportStateCancel';
import TaskReportStateInvalidate from '../../../../commons/ws/reportState/TaskReportStateInvalidate';
import TaskReportStateRectify from '../../../../commons/ws/reportState/TaskReportStateRectify';
import TaskReportSentToProcessStateMarkToSend
    from '../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateMarkToSend';
import TaskReportSentToProcessStateCancelToSend
    from '../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateCancelToSend';
import TaskReportDetail from '../../../../commons/ws/report/TaskReportDetail';
import { HeaderOptions } from '../list/ReportListCompanyUserReducer';
import ReportDetailsCompanyUserModal from './modal/ReportDetailsCompanyUserModal';
import TaskReportSentToProcessStateClaim
    from '../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateClaim';
import TaskReportSentToProcessStateReturnCancellation
    from '../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateReturnCancellation';
import { ROUTE_COMPANY_USER_REPORTS } from '../../../../routing/Routes';
import ReportListCompanyUserStorageManager from '../list/ReportListCompanyUserStorageManager';
import { RouteComponentProps, withRouter } from 'react-router';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InnerProps {
    report: Report | null
    loading: boolean
    backRoute: string,
    isComplaintViewerSent: boolean,
    isComplaintViewer: boolean,
    isPolice: boolean,
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface State {
    showModal: boolean
    headerOptionIdSelected: HeaderOptions | null
    headerOptionOnConfirm: (reportOperationReasonId: string) => void
}

type Props = InnerProps & RouteComponentProps

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportDetailsCompanyUserCard extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showModal: false,
            headerOptionIdSelected: null,
            headerOptionOnConfirm: () => {
            }
        };
    }

    public componentWillUnmount(): void {
        this.handleLocalStorageDeletion();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    public render() {
        const {report, loading, backRoute, isComplaintViewerSent, isComplaintViewer, isPolice} = this.props;
        const {showModal, headerOptionIdSelected, headerOptionOnConfirm} = this.state;

        return (
            <Card loading={loading}>
                <CardHeader
                    title={`${I18nUtils.tr(TR_DETALLES_DENUNCIA)} ${report && report.number}`}
                    headerButtons={
                        report &&
                        !isComplaintViewerSent &&
                        !isComplaintViewer &&
                        !isPolice
                            ? this.renderHeaderButtons(report) : null
                    }
                >
                </CardHeader>
                <CardBody>
                    {
                        report ?
                            <ReportDetailsCardBody
                                report={report}
                                backRoute={backRoute}
                            /> : null
                    }
                    <ReportDetailsCompanyUserModal
                        show={showModal}
                        headerOptionIdSelected={headerOptionIdSelected}
                        onClose={() => this.setState({showModal: false})}
                        onConfirm={headerOptionOnConfirm}
                    />
                </CardBody>
            </Card>
        );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private renderHeaderButtons = (report: Report) => {
        const {id, report_state, report_sent_to_process_state, claimed, cancellation_returned} = report;

        const notOkToSentOrUndo: boolean = report_state.id !== ReportStateIds.PUESTA;
        const stateCannotBeModified: boolean =
            report_sent_to_process_state.id === ReportSentToProcessStateIds.ENVIADA ||
            report_sent_to_process_state.id === ReportSentToProcessStateIds.DUPLICADA;

        return (
            <>
                <NewButtonGroup
                    buttons={[
                        {
                            label: I18nUtils.tr(TR_ANULAR),
                            disabled: stateCannotBeModified || report_state.id === ReportStateIds.ANULADA,
                            onClick: () => this.handleNullify([id]),
                        },
                        {
                            label: I18nUtils.tr(TR_INVALIDAR),
                            disabled: stateCannotBeModified || report_state.id === ReportStateIds.INVALIDADA,
                            onClick: () => this.handleInvalidate([id]),
                        },
                        {
                            label: I18nUtils.tr(TR_RECTIFICAR_ANULACION_INVALIDACION),
                            disabled: stateCannotBeModified || report_state.id === ReportStateIds.PUESTA,
                            onClick: () => this.handleRectify([id]),
                        }
                    ]}
                />
                <NewButtonGroup
                    style={{marginLeft: 25}}
                    buttons={[
                        {
                            label: I18nUtils.tr(TR_ENVIAR_A_TRAMITE),
                            disabled: stateCannotBeModified || notOkToSentOrUndo || report_sent_to_process_state.id === ReportSentToProcessStateIds.PENDIENTE_DE_ENVIAR,
                            onClick: () => this.handleSend([id]),
                        },
                        {
                            label: I18nUtils.tr(TR_DESHACER_ENVIO_A_TRAMITE),
                            disabled: stateCannotBeModified || notOkToSentOrUndo || report_sent_to_process_state.id === ReportSentToProcessStateIds.NO_ENVIADA,
                            onClick: () => this.handleUndoSend([id]),
                        },
                        {
                            label: I18nUtils.tr(TR_RECLAMAR),
                            disabled: !stateCannotBeModified || claimed,
                            onClick: () => this.handleClaim([id]),
                        },
                        {
                            label: I18nUtils.tr(TR_DEVOLVER_ANULACION),
                            disabled: !stateCannotBeModified || cancellation_returned,
                            onClick: () => this.handleReturnCancellation([id]),
                        }
                    ]}
                />
                <NewButtonGroup
                    style={{marginLeft: 25}}
                    buttons={[
                        {
                            label: I18nUtils.tr(TR_EXPORTAR_A_PDF),
                            disabled: false,
                            onClick: () => this.handleExportPDF(report),
                        }
                    ]}
                />
            </>
        );
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleNullify = (reportsIdsSelected: string[]) => {
        this.setState({
            showModal: true,
            headerOptionIdSelected: HeaderOptions.NULLIFY,
            headerOptionOnConfirm: (reportOperationReasonId: string) =>
                new TaskReportStateCancel(reportsIdsSelected, reportOperationReasonId)
                    .onSuccess(() => this.handleSuccess(TR_DENUNCIA_ANULADA))
                    .onFail((error) => Alert.error(error))
                    .execute()
        });
    };

    private handleInvalidate = (reportsIdsSelected: string[]) => {
        this.setState({
            showModal: true,
            headerOptionIdSelected: HeaderOptions.INVALIDATE,
            headerOptionOnConfirm: (reportOperationReasonId: string) =>
                new TaskReportStateInvalidate(reportsIdsSelected, reportOperationReasonId)
                    .onSuccess(() => this.handleSuccess(TR_DENUNCIA_INVALIDADA))
                    .onFail((error) => Alert.error(error))
                    .execute()
        });
    };

    private handleRectify = (reportsIdsSelected: string[]) => {
        new TaskReportStateRectify(reportsIdsSelected)
            .onSuccess(() => this.handleSuccess(TR_DENUNCIA_RECTIFICADA))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleSend = (reportsIdsSelected: string[]) => {
        new TaskReportSentToProcessStateMarkToSend(reportsIdsSelected)
            .onSuccess(() => this.handleSuccess(TR_DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleUndoSend = (reportsIdsSelected: string[]) => {
        new TaskReportSentToProcessStateCancelToSend(reportsIdsSelected)
            .onSuccess(() => this.handleSuccess(TR_DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleClaim = (reportsIdsSelected: string[]) => {
        new TaskReportSentToProcessStateClaim(reportsIdsSelected)
            .onSuccess(() => this.handleSuccess(TR_DENUNCIA_RECLAMADA))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleReturnCancellation = (reportsIdsSelected: string[]) => {
        new TaskReportSentToProcessStateReturnCancellation(reportsIdsSelected)
            .onSuccess(() => this.handleSuccess(TR_IMPORTE_ANULACION_DEVUELTO))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleExportPDF = ({id}) => {
        new TaskReportGetPDF(id)
            .executeAsPromise()
            .then((response) => {
                if ( response.success ) {
                    Alert.success(I18nUtils.tr(TR_DENUNCIA_EXPORTADA));
                    window.open(
                        response.data.url,
                        '_blank',
                        'screenX=0,screenY=0,left=0,top=0,fullscreen=yes,width=' + ( screen.availWidth - 5 ) + ',height=' + ( screen.availHeight - ( 55 ) )
                    );
                }
            })
            .catch(() => Alert.error(I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION)));
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleSuccess = (tr: string) => {
        new TaskReportDetail(this.props.report!.id).execute();
        Alert.success(I18nUtils.tr(tr));
    };

    private handleLocalStorageDeletion = () => {
        const {pathname} = this.props.history.location;
        if ( pathname !== ROUTE_COMPANY_USER_REPORTS ){
            ReportListCompanyUserStorageManager.removeAll();
        }
    };

}

export default withRouter(ReportDetailsCompanyUserCard);
