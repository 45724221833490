import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_IDENTIFICADOR, TR_NOMBRE} from "../../../../commons/I18n/constants";
import ReportCancellationMethod from "../../../../commons/model/ReportCancellationMethod";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface ReportCancellationMethodListTableProps {
    data: ReportCancellationMethod[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ReportCancellationMethodListTable extends React.Component<ReportCancellationMethodListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((reportCancellationMethod: ReportCancellationMethod) => {
                    return (
                        <tr key={reportCancellationMethod.id}
                            onClick={() => this.goToItemDetail(reportCancellationMethod.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(reportCancellationMethod.id) : null}
                            <td>
                                {reportCancellationMethod.id}
                            </td>
                            <td>
                                {reportCancellationMethod.name}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_IDENTIFICADOR)}
                    columnName={'id'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
