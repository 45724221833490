import {Component, ReactNode} from "react";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ENVIAR, TR_ENVIAR_PROCESOS_RECAUDACION,
    TR_ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION,
    TR_NO_EXISTEN_PROCESOS_DE_RECAUDACION,
    TR_PROCESOS_RECAUDACION
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_COLLECTION_PROCESS_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {PagerBackendProps} from "../../../../components/Pager";
import CollectionProcessTable from "./CollectionProcessTable";
import {ParkingMeterCollectionProcess} from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessList from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessList";
import ConfirmModal from "../../../../components/modal/ConfirmModal";

interface CollectionProcessAdminViewProps {
    parkingMeterCollectionProcessList: ParkingMeterCollectionProcess[],
    onParkingMeterCollectionProcessSend: () => void,
    disabledSend: boolean
    loading: boolean,
    errors: string,
    pager: PagerBackendProps
}

interface CollectionProcessAdminViewState {
    showSendModal: boolean
}

export default class CollectionProcessAdminView extends Component<CollectionProcessAdminViewProps, CollectionProcessAdminViewState> {

    constructor(props: Readonly<CollectionProcessAdminViewProps>) {
        super(props);
        this.state = {
            showSendModal: false
        }
    }

    public render(): ReactNode {
        const {
            parkingMeterCollectionProcessList, onParkingMeterCollectionProcessSend
            , disabledSend, loading, errors, pager
        } = this.props;

        return (
            <>
                <CardList loading={loading}
                          pager={pager}
                          title={I18nUtils.tr(TR_PROCESOS_RECAUDACION)}
                          sort={{column: 'id'}}
                          TaskList={TaskParkingMeterCollectionProcessList}
                          emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_PROCESOS_DE_RECAUDACION)}}
                          data={parkingMeterCollectionProcessList}
                          error={errors}
                          ItemsTable={CollectionProcessTable}
                          addItem={ROUTE_ADMIN_COLLECTION_PROCESS_ADD}
                          sendItems={() => this.setState({showSendModal: true})}
                          enableSend={disabledSend}
                          noSearchBar={true}
                />

                <ConfirmModal
                    title={I18nUtils.tr(TR_ENVIAR_PROCESOS_RECAUDACION)}
                    message={I18nUtils.tr(TR_ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION)}
                    textConfirmButton={I18nUtils.tr(TR_ENVIAR)}
                    onConfirmAction={() => {
                        this.setState({showSendModal: false});
                        onParkingMeterCollectionProcessSend();
                    }}
                    show={this.state.showSendModal}
                    onClose={() => this.setState({showSendModal: false})}
                />
            </>
        );
    }
}
