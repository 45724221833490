import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_VEHICULO_EMBARCADO,
    TR_NO_EXISTE_NINGUN_VEHICULO_EMBARCADO,
    TR_VEHICULOS_EMBARCADOS
} from "../../../../commons/I18n/constants";
import DeltaCarListCardAdminActions from "./DeltaCarListCompanyUserActions";
import DeltaCarListCardAdminReducer from "./DeltaCarListCompanyUserReducer";
import TaskDeltaCarDelete from "../../../../commons/ws/deltaCar/TaskDeltaCarDelete";
import DeltaCarListTable from "./DeltaCarListTable";
import {ROUTE_COMPANY_USER_DELTA_CAR_ADD} from "../../../../routing/Routes";
import TaskDeltaCarList from "../../../../commons/ws/deltaCar/TaskDeltaCarList";
import React from 'react';

const mapActionsToProps = DeltaCarListCardAdminActions.autoMapToProps();
const mapStateToProps = DeltaCarListCardAdminReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class DeltaCarListCardCompanyUser extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearDeltaCarListCardAdminReducer();
    }

    public render(): React.ReactNode {
        const {loading, deltaCars, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_VEHICULOS_EMBARCADOS)}
                      sort={{column: 'name'}}
                      TaskList={TaskDeltaCarList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTE_NINGUN_VEHICULO_EMBARCADO)}}
                      data={deltaCars}
                      error={error}
                      ItemsTable={DeltaCarListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_VEHICULO_EMBARCADO),
                          Task: TaskDeltaCarDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_DELTA_CAR_ADD}
                />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DeltaCarListCardCompanyUser as unknown as React.ComponentType<void>);
