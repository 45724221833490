import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import ReportCancellationByFileFormScreenReducer from './ReportCancellationByFileFormScreenReducer';
import ReportCancellationByFileFormScreenActions from './ReportCancellationByFileFormScreenActions';
import { BreadcrumbItem } from '../../../../../components/breadcrumb/BreadcrumbManager';
import I18nUtils from '../../../../../commons/I18n/I18nUtils';
import {
    TR_ANULACION_DENUNCIAS_DE_FICHERO,
    TR_ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE,
    TR_ERROR_EN_EL_SERVIDOR,
    TR_FICHERO_CSV_OBLIGATORIO,
} from '../../../../../commons/I18n/constants';
import {
    ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE,
    ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS_DATE,
} from '../../../../../routing/Routes';
import { AppIcon } from '../../../../../config/Config';
import ScreenCard from '../../../../../components/screen/ScreenCard';
import ReportCancellationByFileCardForm, { ReportCancellationByFileCardFormData } from './form/ReportCancellationByFileCardForm';
import Alert from '../../../../../base/alerts/Alert';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../../../../../commons/ws/Urls';
import AuthManager from '../../../../../utils/AuthManager';
import { goToRoute } from '../../../../../utils/Router';
import DateFormatter from '../../../../../utils/DateFormatter';
import React from 'react';

const mapStateToProps = ReportCancellationByFileFormScreenReducer.autoMapToProps();

const mapActionsToProps = ReportCancellationByFileFormScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{id: string}> & typeof mapActionsToProps;

class ReportCancellationByFileFormScreen extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_ANULACION_DENUNCIAS_DE_FICHERO),
            url: ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE,
            icon: AppIcon.REPORT,
        }];

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderReportCancellationFileForm()}
            </ScreenCard>
        );
    }

    private onSubmit = (values: ReportCancellationByFileCardFormData): void => {

        if (values && values.csv_file && values.csv_file[0]) {

            const data = new FormData();
            data.append('file', values.csv_file[0], values.csv_file[0].name);

            const request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState !== 4) {
                    return;
                }

                if (request.status !== 0) {
                    const response = JSON.parse(request.response);
                    if (response.success) {
                        Alert.success(I18nUtils.tr(TR_ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE));
                        goToRoute(ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS_DATE, { date: DateFormatter.formatDate(new Date(), 'YYYY-MM-DD') });
                    } else {
                        if (response.message && response.message.code === 500) {
                            Alert.error(I18nUtils.tr(TR_ERROR_EN_EL_SERVIDOR));
                        } else {
                            Alert.error(response.message);
                        }
                    }
                }
            };

            request.open(METHOD.POST, Urls.URL_REPORT_STATE_CANCEL_FILE);
            if (AuthManager.isLogged()) {
                request.setRequestHeader('Authorization', `Bearer ${AuthManager.getAuthToken()}`);
            }
            request.setRequestHeader('Accept', 'application/json');
            request.send(data);

        } else {
            Alert.error(I18nUtils.tr(TR_FICHERO_CSV_OBLIGATORIO));
        }

    };

    private renderReportCancellationFileForm(): React.ReactNode {
        const { reportCancellationFileLoading } = this.props;

        return (
            <ReportCancellationByFileCardForm
                initialValues={undefined}
                parentLoading={reportCancellationFileLoading}
                onSubmit={this.onSubmit}
            />
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(ReportCancellationByFileFormScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
