import {Component, ReactNode} from "react";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_PROCESOS_RECAUDACION} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_COLLECTION_PROCESS} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import CollectionProcessAdminDataContainer from "./CollectionProcessAdminDataContainer";
import ScreenCard from "../../../../components/screen/ScreenCard";

export default class CollectionProcessAdminScreen extends Component{
	public render(): ReactNode {
		return (
			<ScreenCard breadcrumbs={[
				{
					name: I18nUtils.tr(TR_PROCESOS_RECAUDACION),
					url: ROUTE_ADMIN_COLLECTION_PROCESS,
					icon: AppIcon.EURO,
				},
			]}>
				<CollectionProcessAdminDataContainer/>
			</ScreenCard>
		);
	 }
}
