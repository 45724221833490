
import {Field, WrappedFieldArrayProps} from "redux-form";
import {connect} from "react-redux";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import Col from "../../../../components/Col";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_TIPOS_DE_USUARIO} from "../../../../commons/I18n/constants";
import UserTypeFieldArrayReducer from "./UserTypeFieldArrayReducer";
import TaskUserTypeList from "../../../../commons/ws/userType/TaskUserTypeList";
import FormSelect from "../../../../components/form/FormSelect";
import { UserTypeAutocompleteBuilder } from '../../../../components/filter/FilterTypeaheadOptions';
import React from 'react';

const mapStateToProps = UserTypeFieldArrayReducer.autoMapToProps();

interface UserTypeFieldArrayProps {
    showFirstOption?: boolean,
}

type Props = WrappedFieldArrayProps<UserTypeAutocompleteBuilder> & typeof mapStateToProps & UserTypeFieldArrayProps;

class UserTypeFieldArray extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskUserTypeList().execute();

        if (this.props.showFirstOption) {
            this.props.fields.push("");
        }
    }

    public render(): React.ReactNode {

        const {fields, userTypeList, meta} = this.props;

        const userTypeOptions = userTypeList.map(
            (userType) => ({value: userType.id, name: userType.name})
        );

        return (
            <CardFieldArray fields={fields}
                            meta={meta}
                            title={''}
                            fieldsHeader={this.renderHeader()}
                            addHandler={() => fields.push("")}
                            hideRemoveWithOneOption={true}
            >
                {fields.map((fieldName: string, index: number) => {
                    return (
                            <Field
                                key={index}
                                name={fieldName}
                                component={FormSelect}
                                options={userTypeOptions}
                                blankOptionText={' '}
                                col={{className: 'm-b--10 m-t--15 p-r-10'}}
                                disabled={false}
                                errorMessageWithoutTouch={true}
                            />
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <Col className={'m-b-0 m-l-15'}>
                <label>{I18nUtils.tr(TR_TIPOS_DE_USUARIO)}</label>
            </Col>
        )
    }
}

export default connect(mapStateToProps)(UserTypeFieldArray) as unknown as React.ComponentType<any>;
