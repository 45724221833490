import LoggedScreen from '../../../../base/screens/LoggedScreen';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_CONFIGURACION_APP,
    TR_CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE
} from '../../../../commons/I18n/constants';
import { ROUTE_ADMIN_SETTINGS_APP } from '../../../../routing/Routes';
import { AppIcon } from '../../../../config/Config';
import ScreenContent from '../../../../components/screen/ScreenContent';
import Row from '../../../../components/Row';
import Col from '../../../../components/Col';
import AppSettingsFormAdminCard from './AppSettingsFormAdminCard';
import AppInfo, { AppInfoData, AppInfoDataReduxForm } from '../../../../commons/model/AppInfo';
import { executeItemTask } from '../../../../utils/FormUtils';
import TaskAppInfoEdit from '../../../../commons/ws/appInfo/TaskAppInfoEdit';
import TaskAppInfoDetail from '../../../../commons/ws/appInfo/TaskAppInfoDetail';
import Alert from '../../../../base/alerts/Alert';

interface Props {}

interface State {
    appInfo: AppInfo | null
    appInfoLoading: boolean
    appInfoError: string
}

class AppSettingsFormAdminScreen extends LoggedScreen<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(
            I18nUtils.tr(TR_CONFIGURACION_APP),
            ROUTE_ADMIN_SETTINGS_APP,
            AppIcon.SETTINGS
        );

        this.state = {
            appInfo: null,
            appInfoLoading: false,
            appInfoError: '',
        };
    }

    componentDidMount(): void {
        new TaskAppInfoDetail()
            .onPreExecution(() => this.setState({appInfoLoading: true}))
            .onPostExecution(() => this.setState({appInfoLoading: false}))
            .onSuccess((res) => this.setState({
                appInfo: res.success ? res.data : null,
                appInfoError: res.success ? '' : ( res.message || '' ),
            }))
            .onFail((res) => this.setState({appInfoError: res}))
            .execute();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const differentError: boolean = prevState.appInfoError !== this.state.appInfoError;
        if ( differentError ) {
            Alert.error(this.state.appInfoError);
        }
    }

    public renderScreen(): React.ReactNode {
        const {appInfoLoading} = this.state;

        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <AppSettingsFormAdminCard
                            onSubmit={this.handleSubmit}
                            initialValues={this.getInitialValues()}
                            parentLoading={appInfoLoading}
                        />
                    </Col>
                </Row>
            </ScreenContent>
        );
    }

    private handleSubmit = (values: AppInfoDataReduxForm): void => {
        const submitData: AppInfoData = {
            minimum_version_app: values.minimumVersionApp,
            app_url_android: values.appUrlAndroid,
            app_url_ios: values.appUrlIos,
        };

        executeItemTask(
            new TaskAppInfoEdit(submitData),
            undefined,
            I18nUtils.tr(TR_CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE)
        );
    };

    private getInitialValues = (): Partial<AppInfoDataReduxForm> => {
        const {appInfo} = this.state;

        return {
            minimumVersionApp: appInfo && appInfo.minimum_version_app ? appInfo.minimum_version_app : '',
            appUrlAndroid: appInfo && appInfo.app_url_android ? appInfo.app_url_android : '',
            appUrlIos: appInfo && appInfo.app_url_ios ? appInfo.app_url_ios : '',
        };
    };

}

export default AppSettingsFormAdminScreen;
