import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User, {UserData} from "../../model/User";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {sha256} from "js-sha256";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF} from "../../I18n/constants";

export default class TaskUserAdd extends BaseGenericRequest<UserData, TaskResponse<User>> {

    private readonly data: UserData;

    public constructor(data: UserData) {
        super(METHOD.POST, Urls.URL_USER_ADD);
        this.data = data;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF))
    }

    protected getRequest(): UserData {
        return {
            ...this.data,
            password: this.data.password ? sha256(this.data.password) : '',
        }
    }
}
