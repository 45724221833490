import {Field, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_AYUDANTE,
    TR_CANCELAR,
    TR_CONTROLADOR,
    TR_EFECTIVO,
    TR_FECHA,
    TR_ID_COLECTA,
    TR_IMAGEN,
    TR_NUMERO_DE_BRIDA,
    TR_OBSERVACIONES,
    TR_PARQUIMETRO,
    TR_RECAUDACION_PARQUIMETRO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import {ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS} from "../../../../routing/Routes";
import FormTextArea from "../../../../components/form/FormTextArea";
import FormInput from "../../../../components/form/FormInput";
import Col from "../../../../components/Col";
import Button from "../../../../components/buttons/Button";
import {goToRoute} from '../../../../utils/Router';
import FormInputImage from "../../../../components/form/FormInputImage";
import React from 'react';

const FORM_NAME = 'ParkingMeterCollectionCardFormCompanyUser';

export interface ParkingMeterCollectionCardFormCompanyUserData {
    cash: string,
    flange_number: string,
    date: string,
    collect_id: number,
    assistant: string,
    collection_strip_image: string,
    observations: string,
    parking_meter: string,
    controller: string,
}

interface ParkingMeterCollectionCardFormExtProps {
    parkingMeterCollectionId?: string,
    initialValues?: Partial<ParkingMeterCollectionCardFormCompanyUserData>,
    parentLoading: boolean,
}

interface ParkingMeterCollectionCardFormProps {
    initialValues: Partial<ParkingMeterCollectionCardFormCompanyUserData>,
}

type Props = ParkingMeterCollectionCardFormExtProps & ParkingMeterCollectionCardFormProps;

enum ParkingAreaTypeFormAdminFields {
    CASH = "cash",
    FLANGE_NUMBER = "flange_number",
    DATE = "date",
    COLLECT_ID = "collect_id",
    ASSISTANT = "assistant",
    COLLECTION_STRIP_IMAGE = "collection_strip_image",
    OBSERVATIONS = "observations",
    PARKING_METER = "parking_meter",
    CONTROLLER = "controller",
}

class ParkingMeterCollectionCardFormCompanyUser extends React.Component<Props> {

    public render(): React.ReactNode {
        const {parentLoading} = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(TR_RECAUDACION_PARQUIMETRO)}/>
                <CardBody>
                    <form>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_PARQUIMETRO)}
                                name={ParkingAreaTypeFormAdminFields.PARKING_METER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_FECHA)}
                                name={ParkingAreaTypeFormAdminFields.DATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_CONTROLADOR)}
                                name={ParkingAreaTypeFormAdminFields.CONTROLLER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_ID_COLECTA)}
                                name={ParkingAreaTypeFormAdminFields.COLLECT_ID}
                                component={FormInput}
                                type={'number'}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_EFECTIVO)}
                                name={ParkingAreaTypeFormAdminFields.CASH}
                                component={FormInput}
                                type={'number'}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_AYUDANTE)}
                                name={ParkingAreaTypeFormAdminFields.ASSISTANT}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NUMERO_DE_BRIDA)}
                                name={ParkingAreaTypeFormAdminFields.FLANGE_NUMBER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_IMAGEN)}
                                name={ParkingAreaTypeFormAdminFields.COLLECTION_STRIP_IMAGE}
                                component={FormInputImage}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_OBSERVACIONES)}
                                name={ParkingAreaTypeFormAdminFields.OBSERVATIONS}
                                component={FormTextArea}
                                col={{md: 12, lg: 12}}
                                disabled
                            />
                        </Row>
                        <Row className='p-t-20'>
                            <Col sm={4} md={6} lg={8}/>
                            <Col sm={4} md={3} lg={2}/>
                            <Col sm={4} md={3} lg={2}>
                                <Button
                                    text={I18nUtils.tr(TR_CANCELAR)}
                                    onClick={() => goToRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS)}
                                    type={'button'}
                                    block={true}
                                    className={'btn-lg btn-default'}
                                />
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

export default reduxForm<ParkingMeterCollectionCardFormCompanyUserData, ParkingMeterCollectionCardFormExtProps>({
    form: FORM_NAME,
    enableReinitialize: true,
})(ParkingMeterCollectionCardFormCompanyUser as any) as React.ComponentType<ParkingMeterCollectionCardFormExtProps>;
