import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_VEHICULOS,
    TR_EXPORTAR_LISTA_NEGRA,
    TR_HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA,
    TR_NO_EXISTEN_VEHICULOS,
    TR_VEHICULOS
} from "../../../../commons/I18n/constants";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import VehicleListCompanyUserReducer from "./VehicleListCompanyUserReducer";
import TaskVehicleList from "../../../../commons/ws/vehicle/TaskVehicleList";
import TaskVehicleDelete from "../../../../commons/ws/vehicle/TaskVehicleDelete";
import {ROUTE_COMPANY_USER_VEHICLE_ADD} from "../../../../routing/Routes";
import VehicleListTable from "./VehicleListTable";
import Col from "../../../../components/Col";
import Button from "../../../../components/buttons/Button";
import Row from "../../../../components/Row";
import Alert from "../../../../base/alerts/Alert";
import TaskVehicleBlackListExport from "../../../../commons/ws/vehicle/TaskVehicleBlackListExport";
import React from 'react';

const mapStateToProps = VehicleListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleListCardCompanyUser extends React.Component<Props> {

    private onExportExcel = (): void => {
        new TaskVehicleBlackListExport().executeAsPromise()
            .then((response) => {
                if (response.success) {
                    // @ts-ignore
                    window.open(response.data.url, "_blank");
                }
            })
            .catch(() => Alert.error(I18nUtils.tr(TR_HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA)));
    };

    public render(): React.ReactNode {
        const {loading, vehicles, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_VEHICULOS)}
                      sort={{column: 'license_plate'}}
                      TaskList={TaskVehicleList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_VEHICULOS)}}
                      data={vehicles}
                      error={error}
                      ItemsTable={VehicleListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_VEHICULOS),
                          Task: TaskVehicleDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_VEHICLE_ADD}
            >
                {this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent(): React.ReactNode {
        return (
            <Row className={"m-t-15"}>
                <Col sm={8} md={9} lg={9}/>
                <Col sm={4} md={3} lg={3}>
                    <Button
                        text={I18nUtils.tr(TR_EXPORTAR_LISTA_NEGRA)}
                        onClick={this.onExportExcel}
                        type={'button'}
                        block={true}
                        className={'btn-lg btn-default btn-export'}
                    />
                </Col>
            </Row>
        )
    }
}

export default connect(mapStateToProps)(VehicleListCardCompanyUser as unknown as React.ComponentType<void>);
