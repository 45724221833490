
import {Component, ReactNode} from 'react';
import imgLogo from "../../../res/img/logo.png";
import {ROUTE_HOME, ROUTE_START_ALERT} from "../../../routing/Routes";
import {Dropdown, MenuItem} from "react-bootstrap";
import NavBarDropDownToggle from "../../../components/dropdown/DropDownCustomToggle";
import Icon from "../../../components/Icon";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {TR_ALERTAS, TR_NO_HAY_ALERTAS, TR_VER_TODAS_LAS_ALERTAS} from "../../../commons/I18n/constants";
import {connect} from "react-redux";
import LoadingView from "../../loading/LoadingView";
import NavBarReducer from "./SideBar/NavBarReducer";
import NavBarActions from "./SideBar/NavBarActions";

export interface NavBarProps {
    onClickBars: () => void;
    className: string
}

const mapStateToProps = NavBarReducer.autoMapToProps();

class NavBar extends Component<NavBarProps & typeof mapStateToProps> {

    private containerCollapse: HTMLDivElement;

    public componentWillUnmount(): void {
        NavBarActions.clearReducer();
    }

    private toggleContainer = (): void => {
        if (this.containerCollapse) {
            if (this.containerCollapse.classList.contains('collapse')) {
                this.containerCollapse.classList.remove('collapse');
            } else {
                this.containerCollapse.classList.add('collapse');
            }
        }
    };

    public render(): ReactNode {
        const {alerts} = this.props;

        return (
            <nav
                className="navbar sidebar-closed">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a onClick={this.toggleContainer} className="navbar-toggle collapsed"/>
                        <a className="bars" onClick={this.props.onClickBars}/>
                        <a className="navbar-brand" href={ROUTE_HOME}><img src={imgLogo} alt="Inicio"/></a>
                    </div>
                    <div className="collapse navbar-collapse"
                         ref={(ref: HTMLDivElement) => this.containerCollapse = ref}>
                        <ul className="nav navbar-nav navbar-right">
                            {/*<Dropdown id="dropdown-custom-1" componentClass={'li'}>*/}
                            {/*<NavBarDropDownToggle bsRole="toggle" bsClass={''}>*/}
                            {/*<Icon icon={'message'}/>*/}
                            {/*<span className="label-count">2</span>*/}
                            {/*</NavBarDropDownToggle>*/}

                            {/*<Dropdown.Menu className="super-colors">*/}
                            {/*<MenuItem bsClass="header">MENSAJES</MenuItem>*/}
                            {/*<MenuItem bsClass="body">*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem bsClass="footer">*/}
                            {/*Ver todos los mensajes*/}
                            {/*</MenuItem>*/}
                            {/*</Dropdown.Menu>*/}
                            {/*</Dropdown>*/}

                            <Dropdown id="dropdown-custom-2" componentClass={'li'}>
                                <NavBarDropDownToggle bsRole="toggle" bsClass={''}>
                                    <Icon icon={'notifications'}/>
                                    {alerts.length > 0 ?
                                        <span className="label-count">{alerts.length}</span> :
                                        null}
                                </NavBarDropDownToggle>

                                <Dropdown.Menu className="super-colors">
                                    {alerts.length > 0 ?
                                        this.renderAlertsMenu() :
                                        <MenuItem>
                                            <b>{I18nUtils.tr(TR_NO_HAY_ALERTAS)}</b>
                                        </MenuItem>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    private renderAlertsMenu = (): React.ReactNode => {
        const {loading} = this.props;

        return (
            <>
                <MenuItem header={true}
                          bsClass="header"
                          className="align-center">
                    <b>{I18nUtils.tr(TR_ALERTAS).toUpperCase()}</b>
                </MenuItem>

                <MenuItem divider={true}/>

                <MenuItem header={true} bsClass="body" className={'body menuitem-body'}>
                    <LoadingView loading={loading}/>
                    {/*{alerts.map((alert: AlertModel) => {*/}
                    {/*    return (*/}
                    {/*        <React.Fragment key={alert.id}>*/}
                    {/*            <Link to={''} className="menuitem-item p-r-5">*/}
                    {/*                                    <span className="title-alert align-right">*/}
                    {/*                                        <b>{alert.name}</b>*/}
                    {/*                                    </span>*/}
                    {/*                <p className="m-t-5">{truncate(alert.description, 120)}</p>*/}
                    {/*                <div className="time-alert">*/}
                    {/*                    <Icon icon="query_builder"/>*/}
                    {/*                    {DateFormatter.formatDatetime(alert.datetime)}*/}
                    {/*                </div>*/}
                    {/*            </Link>*/}
                    {/*            <hr/>*/}
                    {/*        </React.Fragment>*/}
                    {/*    )*/}
                    {/*})}*/}
                </MenuItem>

                <MenuItem divider={true}/>

                <MenuItem
                    href={ROUTE_START_ALERT}
                    bsClass="footer"
                    className="align-center"
                >
                    <b>{I18nUtils.tr(TR_VER_TODAS_LAS_ALERTAS).toUpperCase()}</b>
                </MenuItem>
            </>
        )
    };
}

export default connect(mapStateToProps)(NavBar) as unknown as React.ComponentType<NavBarProps>;
