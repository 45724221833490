import {METHOD, ReduxAwareGenericRequest} from "@cuatroochenta/co-generic-request";
import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";
import I18nUtils from "../I18n/I18nUtils";
import {TR_GENERIC_WS_ERROR} from "../I18n/constants";
import {TaskCriteriaRequest} from "./TaskCriteriaRequest";
import TaskResponse from "./TaskResponse";
import I18nTranslations from "../I18n/I18nTranslations";
// import I18nTranslations from "../I18n/I18nTranslations";

export type TaskGenericList = new() => BaseGenericRequest<TaskCriteriaRequest<any>, TaskResponse<any[]>>

export default abstract class BaseGenericRequest<Request = {}, Response = {}>
    extends ReduxAwareGenericRequest<Request, Response> {

    public static AuthManager: { isLogged(), getAuthToken(); };
    protected static NetworkManager: { isConnected(); } = {
        isConnected() {
            return new Promise((resolve) => resolve(true));
        }
    };

    protected constructor(method: METHOD, url: string, notSpecifyContentType: boolean = false) {
        super(method, url, I18nUtils.tr(TR_GENERIC_WS_ERROR), ReduxStorageUtils.dispatch);
        if (BaseGenericRequest.AuthManager && BaseGenericRequest.AuthManager.isLogged()) {
            this.addHeader("Authorization", `Bearer ${BaseGenericRequest.AuthManager.getAuthToken()}`);
        }
        this.addHeader("Accept", "application/json");

        if (!notSpecifyContentType) {
            this.addHeader("Content-type", "application/json");
        }

        this.enableLogs(false);
    }

    private checkIfConnectionAvailable() {
        return BaseGenericRequest.NetworkManager.isConnected();
    }

    protected sendRequest(request: any) {
        return this.checkIfConnectionAvailable()
            .then((isAvailable) => isAvailable ? super.sendRequest(request) : new Error(I18nTranslations.TR_NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR));
    }

    protected isResponseSuccess(response: { status: number; _bodyText?: string }): boolean {
        let success = super.isResponseSuccess(response);
        try {
            const responseBody = JSON.parse(response._bodyText || '{}');
            success = success || responseBody.success;
        } catch (e) {
            console.warn(e.message);
        }
        return success;
    }

    protected parseResponse(responseObj: any): Response {
        if (this.errorMessages[responseObj.code]) {
            throw new Error(this.errorMessages[responseObj.code]);
        }
        if (!responseObj.success) {
            throw new Error(responseObj.errorMessage || responseObj.message);
        }
        return super.parseResponse(responseObj);
    }

    protected parseError(errorObj: string): string {
        try {
            return JSON.parse(errorObj).message || super.parseError(errorObj);
        } catch (ignored) {
            // @ts-ignore
            return super.parseError(errorObj);
        }
    }

}
