import {OnSortHandler, SortState} from "../../../../utils/Sort";
import User from "../../../../commons/model/User";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_ADMIN_USER_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_APELLIDO, TR_CERTIFICADO, TR_EMAIL, TR_NOMBRE} from "../../../../commons/I18n/constants";
import {goToRoute} from '../../../../utils/Router';
import UserCertTooltipInfo from "../UserCertTooltipInfo";
import React from 'react';

interface UserListTableProps {
    data: User[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class UserListTable extends React.Component<UserListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_USER_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((user: User) => {
                    return (
                        <tr key={user.id}
                            onClick={() => this.goToItemDetail(user.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(user.id) : null}
                            <td>
                                {user.name}
                            </td>
                            <td>{user.last_name ? user.last_name : ''}</td>
                            <td>{user.email}</td>
                            <td>{user.user_cert ?
                                <UserCertTooltipInfo key={user.id} userCert={user.user_cert}/> : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_APELLIDO)}
                    columnName={'last_name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_EMAIL)}
                    columnName={'email'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CERTIFICADO)}
                    columnName={'user_cert'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
