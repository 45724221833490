import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_PARKING_METER_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CODIGO, TR_DESCRIPCION, TR_N_PLAZAS, TR_TIPO} from "../../../../commons/I18n/constants";
import ParkingMeter from "../../../../commons/model/ParkingMeter";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface ParkingMeterListTableProps {
    data: ParkingMeter[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ParkingMeterListTable extends React.Component<ParkingMeterListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_METER_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((parkingMeter: ParkingMeter) => {
                    return (
                        <tr key={parkingMeter.id}
                            onClick={() => this.goToItemDetail(parkingMeter.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(parkingMeter.id) : null}
                            <td>
                                {parkingMeter.code}
                            </td>
                            <td>
                                {parkingMeter.places}
                            </td>
                            <td>
                                {parkingMeter.description}
                            </td>
                            <td>
                                {parkingMeter.type}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_CODIGO)}
                    columnName={'code'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_N_PLAZAS)}
                    columnName={'code'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DESCRIPCION)}
                    columnName={'description'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO)}
                    columnName={'type'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
