import React, {ReactNode} from 'react';
import {debounce, isEqual} from 'lodash';
import {connect} from 'react-redux';
import {
    T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS,
    TR_ANULACION,
    TR_ANULAR,
    TR_DENUNCIAS,
    TR_DENUNCIAS_ENVIADAS,
    TR_DESHACER,
    TR_DESHACER_ENVIO_A_TRAMITE,
    TR_ENVIAR,
    TR_ENVIAR_A_TRAMITE,
    TR_ENVIO_A_TRAMITE,
    TR_EXPORTAR_EXCEL,
    TR_INVALIDACION,
    TR_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS,
    TR_INVALIDAR,
    TR_NO_EXISTEN_DENUNCIAS,
    TR_PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS,
    TR_PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS,
    TR_RECTIFICACION,
    TR_RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS,
    TR_RECTIFICAR,
    TR_RECTIFICAR_ANULACION_INVALIDACION
} from '../../../../commons/I18n/constants';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import TaskReportList from '../../../../commons/ws/report/TaskReportList';
import {CardBodyFooter} from '../../../../components-new/CardBodyFooter';
import {CardListV3} from '../../../../components-new/CardList';
import {DropDownOption} from '../../../../components/dropdown/DropDown';
import ReportListCompanyUserFilter from './filter/ReportListCompanyUserFilter';
import ReportListCompanyUserModal from './modal/ReportListCompanyUserModal';
import ReportListCompanyUserActions from './ReportListCompanyUserActions';
import ReportListCompanyUserReducer, {HeaderOptions} from './ReportListCompanyUserReducer';
import {generateRoute} from '../../../../commons/utils/Router';
import {ROUTE_COMPANY_USER_REPORT_DETAIL} from '../../../../routing/Routes';
import {ReportListTable} from '../../../../components/sections/ReportListTable';
import {goToRoute} from '../../../../utils/Router';
import {ActionBuilder, ReducerBuilder} from '@cuatroochenta/co-redux-builders';
import CurrentUserReducer from '../../../../redux/CurrentUserReducer';
import Row from '../../../../components/Row';
import Col from '../../../../components/Col';
import FormCol from '../../../../components/form/FormCol';
import Button from '../../../../components/buttons/Button';
import TaskReportExport from '../../../../commons/ws/report/TaskReportExport';
import TaskResponse from '../../../../commons/ws/TaskResponse';
import Upload from '../../../../commons/model/Upload';
import Alert from '../../../../base/alerts/Alert';
import DomainActions from '../../../../redux/DomainActions';
import ReportListCompanyUserStorageManager from './ReportListCompanyUserStorageManager';
import {RouteComponentProps, withRouter} from 'react-router';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const headerOptionTexts = {
    [HeaderOptions.NULLIFY]: {
        headerOptionText: I18nUtils.tr(TR_ANULAR),
        buttonText: I18nUtils.tr(TR_ANULAR),
        modalTitle: I18nUtils.tr(TR_ANULACION),
        modalMessage: I18nUtils.tr(T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS),

    },
    [HeaderOptions.INVALIDATE]: {
        headerOptionText: I18nUtils.tr(TR_INVALIDAR),
        buttonText: I18nUtils.tr(TR_INVALIDAR),
        modalTitle: I18nUtils.tr(TR_INVALIDACION),
        modalMessage: I18nUtils.tr(TR_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS),
    },
    [HeaderOptions.RECTIFY]: {
        id: HeaderOptions.RECTIFY,
        headerOptionText: I18nUtils.tr(TR_RECTIFICAR_ANULACION_INVALIDACION),
        buttonText: I18nUtils.tr(TR_RECTIFICAR),
        modalTitle: I18nUtils.tr(TR_RECTIFICACION),
        modalMessage: I18nUtils.tr(TR_RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS),

    },
    [HeaderOptions.SEND]: {
        id: HeaderOptions.SEND,
        headerOptionText: I18nUtils.tr(TR_ENVIAR_A_TRAMITE),
        buttonText: I18nUtils.tr(TR_ENVIAR),
        modalTitle: I18nUtils.tr(TR_ENVIO_A_TRAMITE),
        modalMessage: I18nUtils.tr(TR_PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS),
    },
    [HeaderOptions.UNDO_SEND]: {
        id: HeaderOptions.SEND,
        headerOptionText: I18nUtils.tr(TR_DESHACER_ENVIO_A_TRAMITE),
        buttonText: I18nUtils.tr(TR_DESHACER),
        modalTitle: I18nUtils.tr(TR_DESHACER_ENVIO_A_TRAMITE),
        modalMessage: I18nUtils.tr(TR_PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS),
    },
};

const mapActionsToProps = ActionBuilder.combineActionsAutoMaps(
    ReportListCompanyUserActions.autoMapToProps(),
    DomainActions.autoMapToProps(),
);

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    ReportListCompanyUserReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps(),
);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPERTIES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface State {
    showModal: boolean
}

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportListCompanyUserCard extends React.Component<Props, State> {

    public state: State = {
        showModal: false,
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    public componentDidMount() {
        const dataSort = ReportListCompanyUserStorageManager.getDataSort();
        const dataPager = ReportListCompanyUserStorageManager.getDataPager();
        const dataCriteria = ReportListCompanyUserStorageManager.getDataCriteria();

        if (!dataCriteria) {
            this.initData();
        }
        this.props.dispatchSetStorageData(dataSort, dataPager, dataCriteria);
    }

    private initData(){
        this.handleFetchDataDebounced();
        this.handleFetchDataDebounced.flush()
    }

    /**
     * En caso de cargar criterios iniciales (filtros) almacenados en localStorage se realizará una llamada para traer
     * los datos inmediatamente después de la de componentDidMount. Se da el caso de que dicha llamada tarda menos en
     * efectuarse que la de componentDidMount a pesar de hacerse después (menor carga de datos). Como solución, se
     * emplea el método flush que deja la última llamada.
     */

    public componentDidUpdate(prevProps: Readonly<Props>) {
        const differentPager: boolean = !isEqual(prevProps.dataPager, this.props.dataPager);
        const differentCriteria: boolean = !isEqual(prevProps.dataCriteria, this.props.dataCriteria);
        const differentSort: boolean = !isEqual(prevProps.dataSort, this.props.dataSort);

        if ( differentCriteria ) {
            this.initData();
            ReportListCompanyUserStorageManager.setDataCriteria(this.props.dataCriteria);
        }

        if ( differentSort ) {
            ReportListCompanyUserStorageManager.setDataSort(this.props.dataSort);
        }

        if ( differentPager ) {
            ReportListCompanyUserStorageManager.setDataPager(this.props.dataPager);
        }
    }

    public componentWillUnmount() {
        this.props.dispatchResetListReducer();
        this.props.dispatchResetDomain();
        this.handleFetchDataDebounced.cancel();
        this.handleLocalStorageDeletion();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    public render(): ReactNode {
        const {showModal} = this.state;
        const {
            dispatchResetListReducer,
            dispatchAddCriteria,
            itemIdsSelectedDisabled,
            headerOptionIdSelected,
            itemIdsSelected,
            itemIdsDisabled,
            dataLoading,
            dataPager,
            dataError,
            dataSort,
            data,
            isComplaintViewerSent,
            isComplaintViewer,
            isPolice
        } = this.props;

        const isThereAnyHeaderOptionSelected = headerOptionIdSelected !== '';

        // Utilidad para debug
        // CardUtils.reduxConsoleLog(this.props);

        const headerOptionsProp = isComplaintViewerSent || isComplaintViewer || isPolice
            ? {}
            : {headerOptions: this.handleHeaderOptions()};

        return (
            <CardListV3
                showCheckboxes={isThereAnyHeaderOptionSelected}
                itemIdsSelected={itemIdsSelected}
                itemIdsDisabled={itemIdsDisabled}
                itemIdsSelectedDisabled={itemIdsSelectedDisabled}
                dataLoading={dataLoading}
                dataPager={dataPager}
                elementsLabelPager={I18nUtils.tr(TR_DENUNCIAS)}
                dataError={dataError}
                dataSort={dataSort}
                data={data}
                headerOptionIdSelected={headerOptionIdSelected}
                emptyOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_DENUNCIAS)}}
                ItemsTable={ReportListTable}
                subtitle={undefined}
                footer={this.handleSelectFooter(headerOptionIdSelected)}
                search={undefined}
                title={I18nUtils.tr(isComplaintViewerSent ? TR_DENUNCIAS_ENVIADAS : TR_DENUNCIAS)}
                onGoToItemDetails={this.handleGoToItemDetails}
                onSelectAllItems={this.handleSelectAllItems}
                onChangeSearch={undefined}
                onSelectItem={this.handleSelectItem}
                onFetchData={this.handleFetchData}
                onRefresh={undefined}
                onSetPage={this.handleSetPage}
                onAssign={undefined}
                onSort={this.handleSort}
                onAdd={undefined}
                {...headerOptionsProp}
            >
                <ReportListCompanyUserFilter
                    onClearCriteria={dispatchResetListReducer}
                    onAddCriteria={dispatchAddCriteria}
                    isComplaintViewerSent={isComplaintViewerSent}
                />
                <Row>
                    <Col sm={9} md={9} lg={9}/>
                    <FormCol md={3} lg={3}>
                        <Button
                            text={I18nUtils.tr(TR_EXPORTAR_EXCEL)}
                            onClick={this.handleExportExcel}
                            type={'button'}
                            block={true}
                            className={'btn-lg btn-default btn-export'}
                            disabled={false}
                        />
                    </FormCol>
                </Row>
                {showModal && <ReportListCompanyUserModal
                    show={showModal}
                    onClose={this.handleCloseModal}
                />}
            </CardListV3>
        );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleHeaderOptionClick = (id: string) => {
        const {headerOptionIdSelected, dispatchChangeHeaderOption} = this.props;
        const differentHeaderOption = headerOptionIdSelected !== id;
        if ( differentHeaderOption ) {
            dispatchChangeHeaderOption(id);
        }
    };

    private handleHeaderOptions = (): DropDownOption[] => {
        return [
            {
                id: HeaderOptions.NULLIFY,
                text: headerOptionTexts.NULLIFY.headerOptionText,
                onClick: () => this.handleHeaderOptionClick(HeaderOptions.NULLIFY),
            },
            {
                id: HeaderOptions.INVALIDATE,
                text: headerOptionTexts.INVALIDATE.headerOptionText,
                onClick: () => this.handleHeaderOptionClick(HeaderOptions.INVALIDATE),
            },
            {
                id: HeaderOptions.RECTIFY,
                text: headerOptionTexts.RECTIFY.headerOptionText,
                onClick: () => this.handleHeaderOptionClick(HeaderOptions.RECTIFY),
            },
            {
                id: HeaderOptions.SEND,
                text: headerOptionTexts.SEND.headerOptionText,
                onClick: () => this.handleHeaderOptionClick(HeaderOptions.SEND),
            },
            {
                id: HeaderOptions.UNDO_SEND,
                text: headerOptionTexts.UNDO_SEND.headerOptionText,
                onClick: () => this.handleHeaderOptionClick(HeaderOptions.UNDO_SEND),
            }
        ];
    };

    private handleSelectFooter = (id: string): ReactNode | null => {
        const {
            itemIdsSelected,
        } = this.props;

        const acceptDisabled = itemIdsSelected.length === 0;

        switch ( id ) {
            case HeaderOptions.NULLIFY:
                return (
                    <CardBodyFooter
                        acceptTitle={headerOptionTexts.NULLIFY.buttonText}
                        acceptOnClick={this.handleHeaderOptionAccept}
                        cancelOnClick={this.handleHeaderOptionCancel}
                        acceptDisabled={acceptDisabled}
                    />
                );
            case HeaderOptions.INVALIDATE:
                return (
                    <CardBodyFooter
                        acceptTitle={headerOptionTexts.INVALIDATE.buttonText}
                        acceptOnClick={this.handleHeaderOptionAccept}
                        cancelOnClick={this.handleHeaderOptionCancel}
                        acceptDisabled={acceptDisabled}
                    />
                );
            case HeaderOptions.RECTIFY:
                return (
                    <CardBodyFooter
                        acceptTitle={headerOptionTexts.RECTIFY.buttonText}
                        acceptOnClick={this.handleHeaderOptionAccept}
                        cancelOnClick={this.handleHeaderOptionCancel}
                        acceptDisabled={acceptDisabled}
                    />
                );
            case HeaderOptions.SEND:
                return (
                    <CardBodyFooter
                        acceptTitle={headerOptionTexts.SEND.buttonText}
                        acceptOnClick={this.handleHeaderOptionAccept}
                        cancelOnClick={this.handleHeaderOptionCancel}
                        acceptDisabled={acceptDisabled}
                    />
                );
            case HeaderOptions.UNDO_SEND:
                return (
                    <CardBodyFooter
                        acceptTitle={headerOptionTexts.UNDO_SEND.buttonText}
                        acceptOnClick={this.handleHeaderOptionAccept}
                        cancelOnClick={this.handleHeaderOptionCancel}
                        acceptDisabled={acceptDisabled}
                    />
                );
            default:
                return null;
        }
    };

    private handleHeaderOptionCancel = () => {
        this.props.dispatchClearHeaderOption();
    };

    private handleHeaderOptionAccept = () => {
        this.setState({showModal: true});
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleCloseModal = () => {
        this.setState({showModal: false});
    };

    private handleSetPage = (page: number) => {
        this.props.dispatchSetPage(page);
    };

    private handleSort = (asc: boolean, column: string) => {
        this.props.dispatchSetSort({asc, column});
    };

    private handleSelectItem = (itemId: string) => {
        this.props.dispatchSelectItem(itemId);
    };

    private handleGoToItemDetails = (id: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_REPORT_DETAIL, {id}));
    };

    private handleSelectAllItems = () => {
        this.props.dispatchSelectAllItems();
    };

    private handleLocalStorageDeletion = () => {
        const {pathname} = this.props.history.location;
        const toMatch = ROUTE_COMPANY_USER_REPORT_DETAIL.replace(':id', '');
        if ( !pathname.includes(toMatch) ){
            ReportListCompanyUserStorageManager.removeAll();
        }
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleFetchData = async () => {
        const {dataCriteria} = this.props;
        return new TaskReportList(dataCriteria).executeAsPromise();
    };

    private handleFetchDataDebounced = debounce(this.handleFetchData, 500);

    private handleExportExcel = () => {
        const {dataCriteria} = this.props;
        new TaskReportExport(dataCriteria)
            .onSuccess((response: TaskResponse<Upload>) => {
                if ( response.success ) {
                    // @ts-ignore
                    window.open(response.data.url, '_blank');
                }
            })
            .onFail(Alert.error)
            .execute();
    };

}

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapActionsToProps)(ReportListCompanyUserCard)) as any;
