import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CONFIGURACION_DE_LISTAS_NEGRAS,
    TR_ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS,
    TR_NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS
} from "../../../../commons/I18n/constants";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import BlackListRuleListCompanyUserReducer from "./BlackListRuleListCompanyUserReducer";
import TaskBlacklistRuleList from "../../../../commons/ws/blackListRule/TaskBlacklistRuleList";
import TaskBlacklistRuleDelete from "../../../../commons/ws/blackListRule/TaskBlacklistRuleDelete";
import BlackListRuleListTable from "./BlackListRuleListTable";
import {ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD} from "../../../../routing/Routes";
import React from 'react';

const mapStateToProps = BlackListRuleListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class BlackListRuleListCardCompanyUser extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, blackListRules, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_CONFIGURACION_DE_LISTAS_NEGRAS)}
                      sort={{column: 'id'}}
                      TaskList={TaskBlacklistRuleList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS)}}
                      data={blackListRules}
                      error={error}
                      ItemsTable={BlackListRuleListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS),
                          Task: TaskBlacklistRuleDelete,
                      }}
                      addItem={ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(BlackListRuleListCardCompanyUser as unknown as React.ComponentType<void>);
