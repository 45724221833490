import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_MOTIVO_DE_PLAZA_PERDIDA,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_PARKING_LOSS_REASONS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import {goToRoute} from '../../../../utils/Router';
import ParkingLossReasonFormScreenAdminReducer from "../formscreen/ParkingLossReasonFormScreenAdminReducer";
import React from 'react';

const FORM_NAME = 'ParkingLossReasonCardFormAdmin';

export interface ParkingLossReasonCardFormAdminData {
    name: string,
}

interface ParkingLossReasonCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: ParkingLossReasonCardFormAdminData) => void,
    initialValues?: Partial<ParkingLossReasonCardFormAdminData>,
    parentLoading?: boolean,
}

const mapStateToProps = ParkingLossReasonFormScreenAdminReducer.autoMapToProps();

type Props =
    ParkingLossReasonCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<ParkingLossReasonCardFormAdminData>

enum ParkingLossReasonFormAdminFields {
    NAME = "name",
}

class ParkingLossReasonCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, pristine, parentLoading
        } = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_MOTIVO_DE_PLAZA_PERDIDA : TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={ParkingLossReasonFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>

                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: ParkingLossReasonCardFormAdminData) {
    const errors: FormErrors<ParkingLossReasonCardFormAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }

    return errors;
}

export default compose(
    reduxForm<ParkingLossReasonCardFormAdminData, ParkingLossReasonCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ParkingLossReasonCardFormAdmin);
