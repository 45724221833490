import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {sha256} from "js-sha256";
import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CONTRASENA_INCORRECTA, TR_EL_USUARIO_NO_EXISTE, TR_NO_ESTAS_AUTORIZADO,} from "../../I18n/constants";

interface TaskLoginRequest {
    platform: string,
    email: string,
    password: string,
}

export default class TaskLogin extends BaseGenericRequest<TaskLoginRequest, TaskResponse<string>> {

    private readonly email: string;
    private readonly password: string;
    private readonly platform: string;
    private readonly wrapErrorCodeWithMessage: boolean;

    public constructor(email: string, password: string, wrapErrorCodeWithMessage = false) {
        super(METHOD.POST, Urls.URL_LOGIN);

        // @ts-ignore
        this.platform = navigator && navigator.product == "ReactNative" ? "app" : "backend";
        this.email = email;
        this.password = password;
        this.wrapErrorCodeWithMessage = wrapErrorCodeWithMessage;

        this.setErrorMessage(401, I18nUtils.tr(TR_NO_ESTAS_AUTORIZADO));
        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_NO_EXISTE));
        this.setErrorMessage(490, I18nUtils.tr(TR_CONTRASENA_INCORRECTA));
    }


    protected parseResponse(responseObj: any) {
        if (!this.wrapErrorCodeWithMessage) return super.parseResponse(responseObj);
        if (responseObj.success === false) {
            throw new Error(JSON.stringify({errorMessage: responseObj.message || this.errorMessages[responseObj.code], errorCode: responseObj.code}));
        }
        return super.parseResponse(responseObj);
    }


    protected getRequest(): TaskLoginRequest {
        return {
            platform: this.platform,
            email: this.email,
            password: sha256(this.password)
        }
    }
}
