
import {ROUTE_COMPANY_USER_CONTROLLERS} from "../../../../../routing/Routes";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {TR_CONTROLADORES} from "../../../../../commons/I18n/constants";
import {AppIcon} from "../../../../../config/Config";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import ControllerReportCardCompanyUser from "./ControllerReportCardCompanyUser";
import React from 'react';

export default class ControllerReportCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_CONTROLADORES),
                    url: ROUTE_COMPANY_USER_CONTROLLERS,
                    icon: AppIcon.CONTROLLER_REPORT
                }
            ]}>
                <ControllerReportCardCompanyUser/>
            </ScreenCard>
        )
    }
}
