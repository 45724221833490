import {OnSortHandler, SortState} from '../../../../utils/Sort';
import {generateRoute} from '../../../../commons/utils/Router';
import {ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL} from '../../../../routing/Routes';
import Th from '../../../../components/table/Th';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {TR_DESAHABILITADA, TR_ESTADO, TR_HABILITADA, TR_NOMBRE} from '../../../../commons/I18n/constants';
import ParkingAreaType from '../../../../commons/model/ParkingAreaType';
import {goToRoute} from '../../../../utils/Router';
import ActiveLabel from "../../../../components/label/ActiveLabel";
import React from 'react';

interface ParkingAreaTypeListTableProps {
    data: ParkingAreaType[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ParkingAreaTypeCompanyUserListTable extends React.Component<ParkingAreaTypeListTableProps> {

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                    {data && data.map((parkingAreaType: ParkingAreaType) => {
                        return (
                            <tr key={parkingAreaType.id}
                                onClick={() => this.goToItemDetail(parkingAreaType.id)}
                                className={'tr-cursor'}
                            >
                                {renderSelectTd ? renderSelectTd(parkingAreaType.id) : null}
                                <td>
                                    {parkingAreaType.name}
                                </td>
                                <td>
                                    <ActiveLabel
                                        text={I18nUtils.tr(parkingAreaType.enabled ? TR_HABILITADA : TR_DESAHABILITADA)}
                                        active={parkingAreaType.enabled}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL, {id: itemId}));
    };

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ESTADO)}
                    columnName={'enabled'}
                    {...commonProps}
                />
            </tr>
        );
    }
}
