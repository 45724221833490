
import {ROUTE_COMPANY_USER_PARKING_LOSSES} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_PLAZAS_PERDIDAS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import ParkingLossListCardCompanyUser from "./ParkingLossListCardCompanyUser";
import React from 'react';

export default class ParkingLossListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_PLAZAS_PERDIDAS),
                    url: ROUTE_COMPANY_USER_PARKING_LOSSES,
                    icon: AppIcon.PARKING
                }
            ]}>
                <ParkingLossListCardCompanyUser/>
            </ScreenCard>
        )
    }
}
