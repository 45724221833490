import { StyleProps } from './typings';
import classNames from 'classnames';

interface Props {
    label: string
    disabled?: boolean
    onRef?: any
    onChange: (date: Date) => void
}

export const NewFilterDate: React.FunctionComponent<Props & StyleProps> = (
    {
        label,
        disabled = false,
        onRef,
        style,
        className,
        onChange
    }
) => {
    const classComponent = classNames('input-filter', className);

    return (
        <div className={classComponent} style={style}>
            <label>{label}</label>
            <input
                type={'date'}
                disabled={disabled}
                ref={onRef}
                // @ts-ignore
                onChange={(e) => onChange(e.target.valueAsDate)}
            />
        </div>
    );
};

