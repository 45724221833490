import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import Vehicle from "../../../../commons/model/Vehicle";
import TaskVehicleDetail from "../../../../commons/ws/vehicle/TaskVehicleDetail";
import VehicleFormCompanyUserScreenActions from "./VehicleFormCompanyUserScreenActions";
import VehicleModel from "../../../../commons/model/VehicleModel";
import VehicleType from "../../../../commons/model/VehicleType";
import VehicleColor from "../../../../commons/model/VehicleColor";
import TaskVehicleModelList from "../../../../commons/ws/vehicleModel/TaskVehicleModelList";
import TaskVehicleTypeList from "../../../../commons/ws/vehicleType/TaskVehicleTypeList";
import TaskVehicleColorList from "../../../../commons/ws/vehicleColor/TaskVehicleColorList";
import LicensePlateMask from "../../../../commons/model/LicensePlateMask";
import TaskVehicleLicenseplatemaskList from "../../../../commons/ws/vehicle/TaskVehicleLicenseplatemaskList";

interface InitialState {
    vehicleLoading: boolean,
    vehicle?: Vehicle,
    vehicleModels: VehicleModel[],
    vehicleTypes: VehicleType[],
    vehicleColors: VehicleColor[],
    vehicleLicensePlateMasks: LicensePlateMask[],
}

const INITIAL_STATE: InitialState = {
    vehicleLoading: false,
    vehicle: undefined,
    vehicleModels: [],
    vehicleTypes: [],
    vehicleColors: [],
    vehicleLicensePlateMasks: []
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskVehicleDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleLoading: true
    }))
    .onEvent(TaskVehicleDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleLoading: false
    }))
    .onEvent(TaskVehicleDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, vehicle: payload.data};
    })

    .onEvent(TaskVehicleModelList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, vehicleModels: payload.data};
    })

    .onEvent(TaskVehicleTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, vehicleTypes: payload.data};
    })

    .onEvent(TaskVehicleColorList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, vehicleColors: payload.data};
    })

    .onEvent(TaskVehicleLicenseplatemaskList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, vehicleLicensePlateMasks: payload.data};
    })

    .onEvent(VehicleFormCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
