import {RouteComponentProps} from "react-router";
import {loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_RECAUDACION_PARQUIMETRO, TR_RECAUDACIONES_PARQUIMETROS} from "../../../../commons/I18n/constants";
import {
    ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import DateFormatter from "../../../../utils/DateFormatter";
import {generateRoute} from "../../../../commons/utils/Router";
import ParkingMeterCollectionFormScreenCompanyUserReducer from "./ParkingMeterCollectionFormScreenCompanyUserReducer";
import ParkingMeterCollectionFormCompanyUserScreenActions from "./ParkingMeterCollectionFormCompanyUserScreenActions";
import TaskParkingMeterCollectionDetail
    from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionDetail";
import ParkingMeterCollectionCardFormCompanyUser, {ParkingMeterCollectionCardFormCompanyUserData} from "../formdetail/ParkingMeterCollectionCardFormCompanyUser";
import React from 'react';

const mapStateToProps = ParkingMeterCollectionFormScreenCompanyUserReducer.autoMapToProps();

const mapActionsToProps = ParkingMeterCollectionFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class ParkingMeterCollectionFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        console.warn('PARAMS ', this.props.match.params);
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskParkingMeterCollectionDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const parkingMeterCollectionId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_RECAUDACIONES_PARQUIMETROS),
            url: ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS,
            icon: AppIcon.PARKING_METER_COLLECTION
        }];

        if (parkingMeterCollectionId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_RECAUDACION_PARQUIMETRO),
                url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, {id: parkingMeterCollectionId}),
                icon: AppIcon.PARKING_METER_COLLECTION
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingMeterForm()}
            </ScreenCard>
        )
    }

    private renderParkingMeterForm(): React.ReactNode {
        const {parkingMeterCollectionLoading, parkingMeterCollection} = this.props;

        let initialValues: ParkingMeterCollectionCardFormCompanyUserData | undefined;

        if (parkingMeterCollection) {
            initialValues = {
                parking_meter: parkingMeterCollection.parking_meter ? parkingMeterCollection.parking_meter.code : '',
                cash: parkingMeterCollection.cash ? parkingMeterCollection.cash.toString() : '',
                date: parkingMeterCollection.date ? DateFormatter.formatDatetime(parkingMeterCollection.date) : '',
                collect_id: parkingMeterCollection.collect_id,
                assistant: parkingMeterCollection.assistant ? parkingMeterCollection.assistant : '',
                collection_strip_image: parkingMeterCollection.collection_strip_image ? parkingMeterCollection.collection_strip_image : '',
                controller: parkingMeterCollection.controller ?
                    `${parkingMeterCollection.controller.name} ${parkingMeterCollection.controller.last_name}` : '',
                flange_number: parkingMeterCollection.flange_number ? parkingMeterCollection.flange_number : '',
                observations: parkingMeterCollection.observations ? parkingMeterCollection.observations : '',
            }
        }
        return (
            <ParkingMeterCollectionCardFormCompanyUser
                initialValues={initialValues}
                parentLoading={parkingMeterCollectionLoading}
            />
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterCollectionFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
