import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_CALLE, TR_CONTROLADOR, TR_FECHA, TR_HORAS, TR_MOTIVO, TR_OBSERVACIONES, TR_PLAZAS_PERDIDAS} from "../../../../commons/I18n/constants";
import ParkingLoss from "../../../../commons/model/ParkingLoss";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface ParkingLostListTableProps {
    data: ParkingLoss[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ParkingLossListTable extends React.Component<ParkingLostListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((parkingLoss: ParkingLoss) => {
                    return (
                        <tr key={parkingLoss.id}
                            onClick={() => this.goToItemDetail(parkingLoss.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(parkingLoss.id) : null}
                            <td>
                                {parkingLoss.date ? DateFormatter.formatDatetime(parkingLoss.date) : ''}
                            </td>
                            <td>
                                {parkingLoss.street ? parkingLoss.street.name : ''}
                            </td>
                            <td>
                                {parkingLoss.parking_loss_reason ? parkingLoss.parking_loss_reason.name : ''}
                            </td>
                            <td>
                                {parkingLoss.controller ?
                                    `${parkingLoss.controller.name} ${parkingLoss.controller.last_name}` : ''}
                            </td>
                            <td>
                                {parkingLoss.places ? parkingLoss.places : ''}
                            </td>
                            <td>
                                {parkingLoss.occupied_hours ? parkingLoss.occupied_hours : ''}
                            </td>
                            <td>
                                {parkingLoss.observations ? parkingLoss.observations : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CALLE)}
                    columnName={'street'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_MOTIVO)}
                    columnName={'parking_loss_reason'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CONTROLADOR)}
                    columnName={'controller'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_PLAZAS_PERDIDAS)}
                    columnName={'places'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_HORAS)}
                    columnName={'occupied_hours'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_OBSERVACIONES)}
                    columnName={'observations'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
