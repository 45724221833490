import { Component, CSSProperties, ReactNode } from 'react';
import Icon from './Icon';
import { ClipLoader } from 'react-spinners';

export interface InfoBoxData {
    label: string
    value: string | number
}

interface InfoBoxProps {
    icon: string
    title: string
    data: InfoBoxData[][]
    style: CSSProperties
    loading: boolean
    columnWidth?: number
}

export default class InfoBox extends Component<InfoBoxProps> {

    public render(): ReactNode {
        const {
            icon,
            title,
            data,
            loading,
            style,
            columnWidth,
        } = this.props;

        return (
            <div
                className="info-box"
                style={style}
            >
                <div className="icon">
                    {
                        loading ?
                            <ClipLoader
                                sizeUnit={'px'}
                                size={35}
                                color={'#FFFFFF'}
                                loading={loading}
                            />
                            :
                            <Icon icon={icon}/>
                    }
                </div>
                <div className="content">
                    <div className="title">
                        {title}
                    </div>
                    <div className="data">
                        {
                            loading ? null :
                                data.map((infoBoxDataArray: InfoBoxData[], index: number) => {
                                    const style = columnWidth !== undefined
                                        ? {maxWidth: columnWidth}
                                        : {};

                                    return (
                                        <div
                                            key={index}
                                            className="data-column"
                                            style={style}
                                        >
                                            {
                                                this.renderDataColumn(infoBoxDataArray)
                                            }
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
                {
                    loading ? <div className="loading"/> : null
                }
            </div>
        );
    }

    private renderDataColumn = (data: InfoBoxData[]) => {
        return data.map((infoBoxData: InfoBoxData, index: number) => {
            const {label, value} = infoBoxData;

            return (
                <div key={index} className="data-row">
                    <div className="text">
                        {label}
                    </div>
                    <div className="text" style={{textAlign: 'right'}}>
                        {value}
                    </div>
                </div>
            );
        });
    };

}
