import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS,
    TR_MOTIVOS_DE_PLAZAS_PERDIDAS,
    TR_NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS
} from "../../../../commons/I18n/constants";
import ParkingLossReasonListAdminReducer from "./ParkingLossReasonListAdminReducer";
import TaskParkingLossReasonList from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonList";
import ParkingLossReasonListTable from "./ParkingLossReasonListTable";
import {ROUTE_ADMIN_PARKING_LOSS_REASON_ADD} from "../../../../routing/Routes";
import TaskParkingLossReasonDelete from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonDelete";
import React from 'react';

const mapStateToProps = ParkingLossReasonListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps

class ParkingLossReasonListCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, parkingLossesReasons, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_MOTIVOS_DE_PLAZAS_PERDIDAS)}
                      sort={{column: 'name'}}
                      TaskList={TaskParkingLossReasonList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS)}}
                      data={parkingLossesReasons}
                      error={error}
                      ItemsTable={ParkingLossReasonListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS),
                          Task: TaskParkingLossReasonDelete,
                      }}
                      addItem={ROUTE_ADMIN_PARKING_LOSS_REASON_ADD}
            >
            </CardList>
        )
    }
}

export default connect(mapStateToProps)(ParkingLossReasonListCardAdmin as unknown as React.ComponentType<void>);
