import * as i18next from "i18next";
import translationsEs from './translations/translations.es.json';

const i18nNextModuleResolution = (i18next as any).default || i18next;

void i18nNextModuleResolution
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'es',
        // Using simple hardcoded resources for simple example
        resources: {
            es: {
                translation: translationsEs,
            },
        },
    });

export default i18nNextModuleResolution;
