import {connect} from "react-redux";
import {TR_EXPORTAR_EXCEL, TR_FECHA_INICIO, TR_LOGS_CANCELACION_DENUNCIAS, TR_NO_EXISTEN_LOGS, TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES} from "../../../../../commons/I18n/constants";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import CardList from "../../../../../components/card/CardList";
import TaskReportCancellationLogList from "../../../../../commons/ws/reportCancellationLog/TaskReportCancellationLogList";
import ReportCancelLogListTable from "./ReportCancelLogListTable";
import ReportCancelLogListActions from "./ReportCancelLogListActions";
import ReportCancelLogListReducer from "./ReportCancelLogListReducer";
import FormCol from "../../../../../components/form/FormCol";
import Button from "../../../../../components/buttons/Button";
import Col from "../../../../../components/Col";
import Row from "../../../../../components/Row";
import Alert from "../../../../../base/alerts/Alert";
import TaskReportCancellationLogExport from "../../../../../commons/ws/reportCancellationLog/TaskReportCancellationLogExport";
import DateFormatter from "../../../../../utils/DateFormatter";
import React from 'react';

export interface ReportCancelLogListCardProps {
    date?: string,
}

const mapActionsToProps = ReportCancelLogListActions.autoMapToProps();
const mapStateToProps = ReportCancelLogListReducer.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & ReportCancelLogListCardProps;

export interface ReportCancelLogListCardState {
    date?: Date,
}

class ReportCancelLogListCard extends React.Component<Props, ReportCancelLogListCardState> {

    private cardList;

    public constructor(props) {
        super(props);
        this.state = {
            date: this.props.date ? new Date(this.props.date) : undefined,
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onDateSelect = (date?: Date): void => {
        this.setState({date});
    };

    private onExportExcel(): void {
        new TaskReportCancellationLogExport(this.cardList.getCriteria()).executeAsPromise()
            .then((response) => {
                if (response.success) {
                    // @ts-ignore
                    window.open(response.data.url, "_blank");
                }
            })
            .catch(() => Alert.error(I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES)));
    }

    public render(): React.ReactNode {
        const {loading, reportCancelLogs, error, pager} = this.props;

        return (
            <CardList
                ref={(node) => this.cardList = node}
                loading={loading}
                pager={pager}
                title={I18nUtils.tr(TR_LOGS_CANCELACION_DENUNCIAS)}
                sort={{column: 'date', desc: true}}
                // @ts-ignore
                TaskList={TaskReportCancellationLogList}
                emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_LOGS)}}
                data={reportCancelLogs}
                error={error}
                ItemsTable={ReportCancelLogListTable}
                customCriteria={this.state}
                noSearchBar={true}
            >
                {this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent = (): React.ReactNode => {
        const {} = this.props;

        return (
            <>
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                                <input
                                    type={'date'}
                                    className="form-control"
                                    value={this.state.date ? DateFormatter.formatDate(this.state.date, 'YYYY-MM-DD') : undefined}
                                    // @ts-ignore
                                    onChange={(e) => this.onDateSelect(e.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <Col md={6} lg={7}/>
                    <Col md={3} lg={2}>
                        <Button
                            text={I18nUtils.tr(TR_EXPORTAR_EXCEL)}
                            onClick={this.onExportExcel.bind(this)}
                            type={'button'}
                            block={true}
                            className={'btn-lg btn-default btn-export m-t-30'}
                            disabled={!this.state.date}
                        />
                    </Col>
                </Row>
            </>
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(ReportCancelLogListCard as unknown as React.ComponentType<ReportCancelLogListCardProps>);
