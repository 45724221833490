import React, {Component} from "react";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_FECHA, TR_IMPORTE, TR_PARQUIMETRO} from "../../../../commons/I18n/constants";
import DateFormatter from "../../../../utils/DateFormatter";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";

interface CollectionProcessAddTableProps {
    data: Array<ParkingMeterCollection>,
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}


export default class CollectionProcessAddTable extends Component<CollectionProcessAddTableProps> {
    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((collection) => {
                    return (
                        <tr key={collection.id}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(collection.id) : null}
                            <td>
                                {collection.id}
                            </td>
                            <td>
                                {DateFormatter.formatDate(collection.date)}
                            </td>
                            <td>
                                {collection.cash}
                            </td>
                            <td>
                                {collection.parking_meter.description}
                            </td>

                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={"Id"}
                    columnName={'id'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_IMPORTE)}
                    columnName={'state'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_PARQUIMETRO)}
                    columnName={'realCash'}
                    {...commonProps}
                />

            </tr>
        )
    }
}
 