import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_NOMBRE, TR_NOMBRE_OBLIGATORIO, TR_NUEVA_RUTA, TR_RUTA, TR_TIPO_DE_ZONA, TR_TIPO_DE_ZONA_OBLIGATORIO} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_COMPANY_USER_ROUTES} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import RouteFormScreenAdminReducer from "../formscreen/RouteFormScreenCompanyUserReducer";
import { goToRoute } from '../../../../utils/Router';
import FormSelect from "../../../../components/form/FormSelect";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";
import React from 'react';

const FORM_NAME = 'RouteCardFormAdmin';

export interface RouteCardFormCompanyUserData {
    name: string,
    parkingAreaTypeId: string,
}

interface RouteCardFormCompanyUserProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: RouteCardFormCompanyUserData) => void,
    initialValues?: Partial<RouteCardFormCompanyUserProps>,
    parentLoading?: boolean,
}

const mapStateToProps = RouteFormScreenAdminReducer.autoMapToProps();

type Props =
    RouteCardFormCompanyUserProps
    & typeof mapStateToProps
    & InjectedFormProps<RouteCardFormCompanyUserData>

enum RouteFormAdminFields {
    NAME = "name",
    PARKING_AREA_TYPE_ID = "parkingAreaTypeId",
}

class RouteCardFormCompanyUser extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskParkingAreaTypeList().execute();
    }

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, routeLoading, pristine, parentLoading, parkingAreaTypeList
        } = this.props;

        const parkingAreaTypeOptions = parkingAreaTypeList.map((parkingAreaType) => {
            return {
                value: parkingAreaType.id,
                name: parkingAreaType.name
            }
        });

        return (
            <Card loading={parentLoading || routeLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_RUTA : TR_NUEVA_RUTA)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={RouteFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_TIPO_DE_ZONA)}
                                name={RouteFormAdminFields.PARKING_AREA_TYPE_ID}
                                component={FormSelect}
                                options={parkingAreaTypeOptions}
                                blankOptionText={' '}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_ROUTES)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: RouteCardFormCompanyUserData) {
    const errors: FormErrors<RouteCardFormCompanyUserData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.parkingAreaTypeId || values.parkingAreaTypeId === "-1") {
        errors.parkingAreaTypeId = I18nUtils.tr(TR_TIPO_DE_ZONA_OBLIGATORIO)
    }
    return errors;
}

export default compose(
    reduxForm<RouteCardFormCompanyUserData, RouteCardFormCompanyUserProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(RouteCardFormCompanyUser);
