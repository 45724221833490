
import LoggedScreen from '../../base/screens/LoggedScreen';
import ScreenContent from '../../components/screen/ScreenContent';
import InfoBox, {InfoBoxData} from '../../components/InfoBox';
import {DashboardInformation} from '../../commons/model/Dashboard';
import TaskDashboardInformation from '../../commons/ws/dashboard/TaskDashboardInformation';
import DateFormatter from '../../utils/DateFormatter';
import I18nUtils from '../../commons/I18n/I18nUtils';
import {
    FECHA_ULTIMA_RECAUDACION,
    TR_ANO_ACTUAL,
    TR_ANO_ANTERIOR,
    TR_DIA_ACTUAL,
    TR_DIA_ANTERIOR,
    TR_FECHA_ENVIO,
    TR_INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS,
    TR_LOTE,
    TR_MES_ACTUAL,
    TR_MES_ANTERIOR,
    TR_PERIODO_DENUNCIAS,
    TR_RECAUDACIONES,
    TR_SEMANA_ACTUAL,
    TR_SEMANA_ANTERIOR,
    TR_TOTAL_DENUNCIAS_TRAMITADAS,
    TR_TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL,
    TR_TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL,
    TR_TOTALIZACION_DENUNCIAS,
    TR_ULTIMO_ENVIO
} from '../../commons/I18n/constants';
import HomeReducer from "./HomeReducer";
import {connect} from "react-redux";

const mapStateToProps = HomeReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class HomeScreen extends LoggedScreen<Props> {

    public componentDidMount(): void {
        new TaskDashboardInformation().execute();
    }

    public renderScreen(): React.ReactNode {
        const {information, informationLoading} = this.props;

        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>

                <div className="dashboard">
                    <InfoBox
                        icon={'send'}
                        title={I18nUtils.tr(TR_ULTIMO_ENVIO).toUpperCase()}
                        data={this.handleDashboardLastSendingToInfoBoxData(information)}
                        style={{backgroundColor: '#8BC34A', gridArea: 'sending'}}
                        loading={informationLoading}
                    />
                    <InfoBox
                        icon={'assessment'}
                        title={I18nUtils.tr(TR_TOTALIZACION_DENUNCIAS).toUpperCase()}
                        data={this.handleDashboardTotalReportsToInfoBoxData(information)}
                        style={{backgroundColor: '#0063A2', gridArea: 'reports'}}
                        loading={informationLoading}
                        columnWidth={200}
                    />
                    <InfoBox
                        icon={'account_balance'}
                        title={I18nUtils.tr(TR_RECAUDACIONES).toUpperCase()}
                        data={this.handleDashboardCollectionsToInfoBoxData(information)}
                        style={{backgroundColor: '#FBB907', gridArea: 'collections'}}
                        loading={informationLoading}
                    />
                    <InfoBox
                        icon={'warning'}
                        title={I18nUtils.tr(TR_INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS).toUpperCase()}
                        data={this.handleDashboardParkingToInfoBoxData(information)}
                        style={{backgroundColor: '#FF6347', gridArea: 'parking'}}
                        loading={informationLoading}
                    />
                </div>

            </ScreenContent>
        );
    }

    private handleDashboardLastSendingToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
        if ( information && information.last_report ) {
            const {
                lot,
                date,
                start_date,
                end_date,
                export_proccess_report_count,
            } = information.last_report;

            const sendingDate: string = DateFormatter.formatDate(date);
            const periodStartDate: string = DateFormatter.formatDate(start_date);
            const periodEndDate: string = DateFormatter.formatDate(end_date);

            return [[
                {
                    label: I18nUtils.tr(TR_LOTE),
                    value: lot,
                },
                {
                    label: I18nUtils.tr(TR_FECHA_ENVIO),
                    value: sendingDate,
                },
                {
                    label: I18nUtils.tr(TR_PERIODO_DENUNCIAS),
                    value: `${periodStartDate} - ${periodEndDate}`,
                },
                {
                    label: I18nUtils.tr(TR_TOTAL_DENUNCIAS_TRAMITADAS),
                    value: export_proccess_report_count,
                },
            ]];
        }
        return [];
    };

    private handleDashboardTotalReportsToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
        if ( information ) {
            const {
                previous_day,
                previous_week,
                previous_month,
                previous_year,
                actual_day,
                actual_week,
                actual_month,
                actual_year,
            } = information.total_reports;

            return [
                [
                    {
                        label: I18nUtils.tr(TR_DIA_ACTUAL),
                        value: actual_day,
                    },
                    {
                        label: I18nUtils.tr(TR_SEMANA_ACTUAL),
                        value: actual_week,
                    },
                    {
                        label: I18nUtils.tr(TR_MES_ACTUAL),
                        value: actual_month,
                    },
                    {
                        label: I18nUtils.tr(TR_ANO_ACTUAL),
                        value: actual_year,
                    },
                ],
                [
                    {
                        label: I18nUtils.tr(TR_DIA_ANTERIOR),
                        value: previous_day,
                    },
                    {
                        label: I18nUtils.tr(TR_SEMANA_ANTERIOR),
                        value: previous_week,
                    },
                    {
                        label: I18nUtils.tr(TR_MES_ANTERIOR),
                        value: previous_month,
                    },
                    {
                        label: I18nUtils.tr(TR_ANO_ANTERIOR),
                        value: previous_year,
                    },
                ]
            ];
        }
        return [];
    };

    private handleDashboardCollectionsToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
        if ( information ) {
            const {
                last_collection_made_date,
            } = information.collections;

            const lastCollectionMadeDate: string = DateFormatter.formatDate(last_collection_made_date);

            return [[
                {
                    label: I18nUtils.tr(FECHA_ULTIMA_RECAUDACION),
                    value: lastCollectionMadeDate,
                },
            ]];
        }
        return [];
    };

    private handleDashboardParkingToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
        if ( information ) {
            const {
                num_parking_incidences_actual_week,
                rate_places_hours_parking_loss_month,
            } = information.parking;

            return [[
                {
                    label: I18nUtils.tr(TR_TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL),
                    value: num_parking_incidences_actual_week,
                },
                {
                    label: I18nUtils.tr(TR_TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL),
                    value: Number(rate_places_hours_parking_loss_month).toFixed(0),
                },
            ]];
        }
        return [];
    };

}

export default connect(mapStateToProps)(HomeScreen as unknown as React.ComponentType<{}>);
