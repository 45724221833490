import {Field, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CODIGO,
    TR_CODIGO_OBLIGATORIO, TR_DEBE_SER_UN_VALOR_POSITIVO,
    TR_DESCRIPCION,
    TR_DESCRIPCION_OBLIGATORIA,
    TR_LA_POSICION_ES_OBLIGATORIA, TR_N_PLAZAS, TR_N_PLAZAS_OBLIGATIORIO,
    TR_NUEVO_PARQUIMETRO,
    TR_PARQUIMETRO,
    TR_POSICION,
    TR_TIPO,
    TR_TIPO_OBLIGATORIO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_COMPANY_USER_PARKING_METERS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import ParkingMeterFormScreenCompanyUserReducer from "../formscreen/ParkingMeterFormScreenCompanyUserReducer";
import FormTextArea from "../../../../components/form/FormTextArea";
import MapLocationPicker, {MarkerPosition} from "../../../../components/MapLocationPicker";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import Config from "../../../../config/Config";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'ParkingMeterCardFormCompanyUser';

interface SynchronousError {
    coord: string,
}

export interface ParkingMeterCardFormCompanyUserData {
    code: string,
    description: string,
    type: string,
    coord: MarkerPosition
    places: number,
}

interface ParkingMeterCardFormCompanyExtUserProps {
    parkingMeterId?: string,
    readonly?: boolean,
    onSubmit: (data: ParkingMeterCardFormCompanyUserData) => void,
    initialValues?: Partial<ParkingMeterCardFormCompanyUserData>,
    editMode: boolean,
    parentLoading?: boolean,
}

interface ParkingMeterCardFormCompanyUserProps extends InjectedFormProps<ParkingMeterCardFormCompanyUserData> {
    onSubmit: (data: ParkingMeterCardFormCompanyUserData) => void,
    initialValues: Partial<ParkingMeterCardFormCompanyUserData>,
    formState: { values: ParkingMeterCardFormCompanyUserData },
    synchronousError: SynchronousError,
}

const mapStateToPropsGeneric = (state: any) => {
    return {
        formState: state.form[FORM_NAME],
        synchronousError: getFormSyncErrors(FORM_NAME)(state)
    }
};

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    ParkingMeterFormScreenCompanyUserReducer.autoMapToProps(),
    mapStateToPropsGeneric,
);

type Props =
    ParkingMeterCardFormCompanyUserProps
    & typeof mapStateToProps
    & InjectedFormProps<ParkingMeterCardFormCompanyUserData>
    & ParkingMeterCardFormCompanyExtUserProps

enum ParkingAreaTypeFormAdminFields {
    CODE = "code",
    DESCRIPTION = "description",
    TYPE = "type",
    COORD = "coord",
    PLACES = "places",
}

class ParkingMeterCardFormCompanyUser extends React.Component<Props> {

    private mapContainer;

    private onChangeCoord = (newCoord: MarkerPosition): void => {
        this.props.change(ParkingAreaTypeFormAdminFields.COORD, newCoord)
    };

    private onCoordError(): boolean {
        if (this.props.synchronousError.coord && this.props.formState.values &&
            this.props.formState.values.coord) {
            return this.props.synchronousError.coord.length !== 0
        }
        return false;
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, parkingMeterLoading, pristine, editMode
        } = this.props;

        return (
            <Card loading={parkingMeterLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_PARQUIMETRO : TR_NUEVO_PARQUIMETRO)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_CODIGO)}
                                name={ParkingAreaTypeFormAdminFields.CODE}
                                component={FormInput}
                                type={"text"}
                                col={{md: 3, lg: 3}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_N_PLAZAS)}
                                name={ParkingAreaTypeFormAdminFields.PLACES}
                                component={FormInput}
                                type={"number"}
                                col={{md: 3, lg: 3}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_TIPO)}
                                name={ParkingAreaTypeFormAdminFields.TYPE}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_DESCRIPCION)}
                                name={ParkingAreaTypeFormAdminFields.DESCRIPTION}
                                component={FormTextArea}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>

                        <Row ref={(node) => this.mapContainer = node}
                             className={"gmap"}
                        >
                            <Field label={I18nUtils.tr(TR_POSICION)}
                                   name={ParkingAreaTypeFormAdminFields.COORD}
                                   component={MapLocationPicker}
                                   google={this.mapContainer}
                                   changeCoordHandler={this.onChangeCoord}
                                   invalid={this.onCoordError()}
                                   defaultPosition={Config.DEFAULT_MAP_POSITION}
                                   detailMode={editMode}
                                   // TODO: icon marker
                            />
                        </Row>

                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_PARKING_METERS)}
                            classNameContainer={"m-t-50"}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: ParkingMeterCardFormCompanyUserData) {
    const errors: FormErrors<ParkingMeterCardFormCompanyUserData> = {};

    if (!values.code || values.code.length === 0) {
        errors.code = I18nUtils.tr(TR_CODIGO_OBLIGATORIO);
    }
    if (!values.places) {
        errors.places = I18nUtils.tr(TR_N_PLAZAS_OBLIGATIORIO);
    } else if (values.places < 0) {
        errors.places = I18nUtils.tr(TR_DEBE_SER_UN_VALOR_POSITIVO);
    }
    if (!values.description || values.description.length === 0) {
        errors.description = I18nUtils.tr(TR_DESCRIPCION_OBLIGATORIA);
    }
    if (!values.type || values.type.length === 0) {
        errors.type = I18nUtils.tr(TR_TIPO_OBLIGATORIO);
    }
    if (!values.coord) {
        errors.coord = I18nUtils.tr(TR_LA_POSICION_ES_OBLIGATORIA)
    } else if (!values.coord.lng || !values.coord.lat ||
        values.coord.lng.toString().length === 0 ||
        values.coord.lat.toString().length === 0) {
        errors.coord = I18nUtils.tr(TR_LA_POSICION_ES_OBLIGATORIA)
    }
    return errors;
}

export default compose(
    reduxForm<ParkingMeterCardFormCompanyUserData, ParkingMeterCardFormCompanyExtUserProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ParkingMeterCardFormCompanyUser) as React.ComponentType<ParkingMeterCardFormCompanyExtUserProps>;
