import {Field, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CALLE,
    TR_CANCELAR,
    TR_CONTROLADOR,
    TR_FECHA, TR_HORAS,
    TR_MOTIVO,
    TR_OBSERVACIONES,
    TR_PLAZA_PERDIDA, TR_PLAZAS_PERDIDAS
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import {ROUTE_COMPANY_USER_PARKING_LOSSES} from "../../../../routing/Routes";
import FormTextArea from "../../../../components/form/FormTextArea";
import FormInput from "../../../../components/form/FormInput";
import Col from "../../../../components/Col";
import Button from "../../../../components/buttons/Button";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'ParkingLossCardFormCompanyUser';

export interface ParkingLossCardFormCompanyUserData {
    date: string,
    places: number,
    occupied_hours: number,
    street: string,
    parking_loss_reason: string,
    controller: string,
    observations: string,
}

interface ParkingLossCardFormExtProps {
    parkingMeterCollectionId?: string,
    initialValues?: Partial<ParkingLossCardFormCompanyUserData>,
    parentLoading: boolean,
}

interface ParkingLossCardFormProps {
    initialValues: Partial<ParkingLossCardFormCompanyUserData>,
}

type Props = ParkingLossCardFormExtProps & ParkingLossCardFormProps;

enum ParkingAreaTypeFormAdminFields {
    DATE = "date",
    STREET  = "street",
    PLACES  = "places",
    OCCUPIED_HOURS  = "occupied_hours",
    PARKING_LOSS_REASON = "parking_loss_reason",
    CONTROLLER = "controller",
    OBSERVATIONS = "observations",
}

class ParkingLossCardFormCompanyUser extends React.Component<Props> {

    public render(): React.ReactNode {
        const {parentLoading} = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(TR_PLAZA_PERDIDA)}/>
                <CardBody>
                    <form>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_FECHA)}
                                name={ParkingAreaTypeFormAdminFields.DATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_CALLE)}
                                name={ParkingAreaTypeFormAdminFields.STREET}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_MOTIVO)}
                                name={ParkingAreaTypeFormAdminFields.PARKING_LOSS_REASON}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_PLAZAS_PERDIDAS)}
                                name={ParkingAreaTypeFormAdminFields.PLACES}
                                component={FormInput}
                                type={"number"}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_HORAS)}
                                name={ParkingAreaTypeFormAdminFields.OCCUPIED_HOURS}
                                component={FormInput}
                                type={"number"}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                            <Field
                                label={I18nUtils.tr(TR_CONTROLADOR)}
                                name={ParkingAreaTypeFormAdminFields.CONTROLLER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_OBSERVACIONES)}
                                name={ParkingAreaTypeFormAdminFields.OBSERVATIONS}
                                component={FormTextArea}
                                col={{md: 12, lg: 12}}
                                disabled
                            />
                        </Row>
                        <Row className='p-t-20'>
                            <Col sm={4} md={6} lg={8}/>
                            <Col sm={4} md={3} lg={2}/>
                            <Col sm={4} md={3} lg={2}>
                                <Button
                                    text={I18nUtils.tr(TR_CANCELAR)}
                                    onClick={() => goToRoute(ROUTE_COMPANY_USER_PARKING_LOSSES)}
                                    type={'button'}
                                    block={true}
                                    className={'btn-lg btn-default'}
                                />
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

export default reduxForm<ParkingLossCardFormCompanyUserData, ParkingLossCardFormExtProps>({
    form: FORM_NAME,
    enableReinitialize: true,
})(ParkingLossCardFormCompanyUser as any) as React.ComponentType<ParkingLossCardFormExtProps>;
