import Report from '../../../../commons/model/Report';
import TaskReportList, { ReportCriteriaProps } from '../../../../commons/ws/report/TaskReportList';
import { PagerBackendProps } from '../../../../components/Pager';
import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import { SortState } from '../../../../utils/Sort';
import ReportListCompanyUserActions from './ReportListCompanyUserActions';
import { ReportStateIds } from '../../../../commons/model/ReportState';
import { ReportSentToProcessStateIds } from '../../../../commons/model/ReportSentToProcessState';
import TaskReportExport from '../../../../commons/ws/report/TaskReportExport';

export enum HeaderOptions {
    NULLIFY = 'NULLIFY',
    INVALIDATE = 'INVALIDATE',
    RECTIFY = 'RECTIFY',
    SEND = 'SEND',
    UNDO_SEND = 'UNDO_SEND',
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER STATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InitialState {
    data: Report[]
    dataSort: SortState
    dataPager: PagerBackendProps
    dataError: string
    dataLoading: boolean
    dataCriteria: ReportCriteriaProps
    itemIdsSelected: string[]
    itemIdsDisabled: string[]
    itemIdsSelectedDisabled: string[]
    headerOptionIdSelected: string
}

const INITIAL_STATE: InitialState = {
    data: [],
    dataSort: {asc: false, column: 'date'},
    dataPager: {current: 1, total_pages: 1, total_elements: 0},
    dataError: '',
    dataLoading: false,
    dataCriteria: {limit: 30, sort: '-date', simply: true}, // PAVAZUL-391: visualizar en esta pantalla 30 elementos mínimo
    itemIdsSelected: [],
    itemIdsDisabled: [],
    itemIdsSelectedDisabled: [],
    headerOptionIdSelected: '',
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        ReportListCompanyUserActions.dispatchResetListReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchAddCriteria,
        (oldState, payload) => ( dispatchAddCriteriaLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchChangeSearchText,
        (oldState, payload) => ( dispatchChangeSearchTextLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchChangeHeaderOption,
        (oldState, payload) => ( dispatchChangeHeaderOptionLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchClearHeaderOption,
        (oldState) => ( dispatchClearHeaderOptionLogic(oldState) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchSetSort,
        (oldState, payload) => ( dispatchSetSortLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchSetPage,
        (oldState, payload) => ( dispatchSetPageLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchSelectItem,
        (oldState, payload) => ( dispatchSelectItemLogic(oldState, payload) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchSelectAllItems,
        (oldState) => ( dispatchSelectAllItemsLogic(oldState) )
    )
    .onEvent(
        ReportListCompanyUserActions.dispatchSetStorageData,
        (oldState, payload) => ( dispatchSetStorageDataLogic(oldState, payload) )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: true} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: false} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, dataError: payload} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( dispatchFetchDataOnSuccessLogic(oldState, payload) )
    )
    .onEvent(
        TaskReportExport.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: true} )
    )
    .onEvent(
        TaskReportExport.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: false} )
    )
    .build();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER LOGIC
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const dispatchFetchDataOnSuccessLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const {data, pager} = payload;
    const [itemIdsDisabled, itemIdsSelectedDisabled] = getDisabledItemIds(data, oldState.headerOptionIdSelected);
    newState.data = data;
    newState.dataPager = pager || oldState.dataPager;
    newState.itemIdsSelected = [];
    newState.itemIdsDisabled = itemIdsDisabled;
    newState.itemIdsSelectedDisabled = itemIdsSelectedDisabled;
    return newState;
};

const dispatchSetSortLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const {asc, column} = payload as SortState;
    const sort = asc ? `+${column}` : `-${column}`;
    newState.dataSort = payload;
    newState.dataCriteria = {...oldState.dataCriteria, sort};
    return newState;
};

const dispatchSetPageLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const page: number = payload;
    newState.dataCriteria = {...oldState.dataCriteria, page};
    return newState;
};

const dispatchAddCriteriaLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    newState.dataCriteria = {...oldState.dataCriteria, ...payload, page: 1};
    return newState;
};

const dispatchChangeSearchTextLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const newSearchText: string = payload;
    newState.dataCriteria = {...oldState.dataCriteria, page: 1, search: newSearchText};
    return newState;
};

const dispatchChangeHeaderOptionLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const [itemIdsDisabled, itemIdsSelectedDisabled] = getDisabledItemIds(oldState.data, payload);
    newState.headerOptionIdSelected = payload;
    newState.itemIdsSelected = [];
    newState.itemIdsDisabled = itemIdsDisabled;
    newState.itemIdsSelectedDisabled = itemIdsSelectedDisabled;
    return newState;
};

const dispatchClearHeaderOptionLogic = (oldState: InitialState): InitialState => {
    const newState: InitialState = {...oldState};
    newState.headerOptionIdSelected = '';
    newState.itemIdsSelected = [];
    newState.itemIdsSelectedDisabled = [];
    newState.itemIdsDisabled = [];
    return newState;
};

const dispatchSelectItemLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const itemIdSelected: string = payload;
    const isItemIncluded = oldState.itemIdsSelected.includes(itemIdSelected);
    if ( isItemIncluded ) {
        newState.itemIdsSelected = oldState.itemIdsSelected.filter((id) => id !== itemIdSelected);
    } else {
        newState.itemIdsSelected = [...oldState.itemIdsSelected, itemIdSelected];
    }
    return newState;
};

const dispatchSelectAllItemsLogic = (oldState: InitialState): InitialState => {
    const newState: InitialState = {...oldState};
    const {data, itemIdsSelected, itemIdsDisabled, itemIdsSelectedDisabled} = oldState;
    const totalSelectedAndDisabledItems = itemIdsSelected.length + itemIdsDisabled.length + itemIdsSelectedDisabled.length;
    const isAllSelected = itemIdsSelected.length > 0 && totalSelectedAndDisabledItems === data.length;

    newState.itemIdsSelected = [];
    if ( !isAllSelected ) {
        data.forEach(({id}) => {
            const isItemDisabled = itemIdsDisabled.includes(id) || itemIdsSelectedDisabled.includes(id);
            if ( !isItemDisabled ) {
                newState.itemIdsSelected.push(id);
            }
        });
    }

    return newState;
};

// Función exclusiva para setear los datos si han sido guardados en local storage
const dispatchSetStorageDataLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const {dataSort, dataPager, dataCriteria} = payload;
    if ( dataSort ) newState.dataSort = {...dataSort};
    if ( dataPager ) newState.dataPager = {...dataPager};
    if ( dataCriteria ) newState.dataCriteria = {...dataCriteria};
    return newState;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const getDisabledItemIds = (data: Report[], headerOptionIdSelected: string): [string[], string[]] => {
    const itemIdsDisabled: string[] = [];
    const itemIdsSelectedDisabled: string[] = [];

    if ( headerOptionIdSelected !== '' ) {
        data.forEach((report: Report) => {
            console.warn('report: ', report);
            const {
                id,
                report_state,
                report_sent_to_process_state,
            } = report;

            const isOk = report_state.id === ReportStateIds.PUESTA;
            const isNullified = report_state.id === ReportStateIds.ANULADA;
            const isInvalidated = report_state.id === ReportStateIds.INVALIDADA;
            const isNoSent = report_sent_to_process_state.id === ReportSentToProcessStateIds.NO_ENVIADA;
            const isPendingToSent = report_sent_to_process_state.id === ReportSentToProcessStateIds.PENDIENTE_DE_ENVIAR;
            const cannotBeModified =
                isNullified ||
                isInvalidated ||
                report_sent_to_process_state.id === ReportSentToProcessStateIds.ENVIADA ||
                report_sent_to_process_state.id === ReportSentToProcessStateIds.DUPLICADA;

            console.warn('header option id selected: ', headerOptionIdSelected);
            switch ( headerOptionIdSelected ) {
                case HeaderOptions.NULLIFY:
                    if ( cannotBeModified ) itemIdsDisabled.push(id);
                    else if ( isNullified ) itemIdsSelectedDisabled.push(id);
                    break;
                case HeaderOptions.INVALIDATE:
                    if ( cannotBeModified ) itemIdsDisabled.push(id);
                    else if ( isInvalidated ) itemIdsSelectedDisabled.push(id);
                    break;
                case HeaderOptions.RECTIFY:
                    if ( cannotBeModified ) itemIdsDisabled.push(id);
                    else if ( isOk ) itemIdsSelectedDisabled.push(id);
                    break;
                case HeaderOptions.SEND:
                    if ( cannotBeModified ) itemIdsDisabled.push(id);
                    else if ( isPendingToSent ) itemIdsSelectedDisabled.push(id);
                    break;
                case HeaderOptions.UNDO_SEND:
                    if ( cannotBeModified ) itemIdsDisabled.push(id);
                    else if ( isNoSent ) itemIdsSelectedDisabled.push(id);
                    break;
            }
        });
    }

    return [itemIdsDisabled, itemIdsSelectedDisabled];
};
