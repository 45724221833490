import { ActionBuilder, ReducerBuilder } from '@cuatroochenta/co-redux-builders';

import { connect } from 'react-redux';
import {
    TR_BORRAR_FILTROS,
    TR_CALLE,
    TR_CONTROLADOR,
    TR_FECHA_FIN,
    TR_FECHA_INICIO,
    TR_FILTROS,
    TR_MATRICULA,
    TR_NUMERO_DE_DENUNCIA,
    TR_RATIFICADOR,
    TR_RUTA
} from '../../../../../commons/I18n/constants';
import I18nUtils from '../../../../../commons/I18n/I18nUtils';
import TaskLawList from '../../../../../commons/ws/law/TaskLawList';
import TaskReportStateList from '../../../../../commons/ws/reportState/TaskReportStateList';
import TaskRouteList from '../../../../../commons/ws/route/TaskRouteList';
import TaskStreetList from '../../../../../commons/ws/street/TaskStreetList';
import TaskVehicleList from '../../../../../commons/ws/vehicle/TaskVehicleList';
import { FilterDate, FilterInputSearch, typeaheadOptionsBuilder, } from '../../../../../components/filter';
import RatificationReportListCompanyUserActions from '../RatificationReportListCompanyUserActions';
import RatificationReportListCompanyUserFilterActions from './RatificationReportListCompanyUserFilterActions';
import RatificationReportListCompanyUserFilterReducer from './RatificationReportListCompanyUserFilterReducer';
import RatificationReportListCompanyUserReducer from '../RatificationReportListCompanyUserReducer';
import { CardSectionCollapsed } from '../../../../../components-new/CardSectionCollapsed';
import { FilterTypeahead } from '../../../../../components/filter/FilterTypeahead';
import FormCol from '../../../../../components/form/FormCol';
import Button from '../../../../../components/buttons/Button';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import TaskUserControllerList from '../../../../../commons/ws/user/TaskUserControllerList';
import TaskUserRatifierList from '../../../../../commons/ws/user/TaskUserRatifierList';
import React from 'react';

const mapActionsToProps = ActionBuilder.combineActionsAutoMaps(
    RatificationReportListCompanyUserFilterActions.autoMapToProps(),
    RatificationReportListCompanyUserActions.autoMapToProps()
);

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    RatificationReportListCompanyUserFilterReducer.autoMapToProps(),
    RatificationReportListCompanyUserReducer.autoMapToProps()
);

type Props = typeof mapStateToProps & typeof mapActionsToProps

class ReportListCompanyUserFilter extends React.Component<Props> {

    private refReportNumber;
    private refStartDate;
    private refEndDate;
    private refFilterVehicle;
    private refFilterRoute;
    private refFilterUserController;
    private refFilterUserRatifier;
    private refFilterStreet;

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount(): void {
        new TaskVehicleList({limit: 0}).execute();
        new TaskRouteList({limit: 0}).execute();
        new TaskLawList({limit: 0}).execute();
        new TaskUserControllerList({limit: 0}).execute();
        new TaskUserRatifierList({limit: 0}).execute();
        new TaskStreetList({limit: 0}).execute();
        new TaskReportStateList({limit: 0}).execute();
    }

    public componentWillUnmount(): void {
        this.props.dispatchResetFilterReducer();
    }

    public render() {
        const {
            vehicles,
            vehiclesLoading,
            routes,
            routesLoading,
            controllers,
            controllersLoading,
            ratifiers,
            ratifiersLoading,
            streets,
            streetsLoading,
        } = this.props;

        const vehiclesOptions = typeaheadOptionsBuilder.vehicles(vehicles);
        const routesOptions = typeaheadOptionsBuilder.routes(routes);
        const controllersOptions = typeaheadOptionsBuilder.users(controllers);
        const ratifiersOptions = typeaheadOptionsBuilder.users(ratifiers);
        const streetsOptions = typeaheadOptionsBuilder.streets(streets);

        return (
            <CardSectionCollapsed
                title={I18nUtils.tr(TR_FILTROS)}
                className={'card-filters'}
            >
                <Row>
                    <FilterInputSearch
                        gridSize={3}
                        label={I18nUtils.tr(TR_NUMERO_DE_DENUNCIA)}
                        onRef={(ref) => this.refReportNumber = ref}
                        onChange={this.handleSearchByReportNumber}
                    />
                    <FilterDate
                        gridSize={3}
                        label={I18nUtils.tr(TR_FECHA_INICIO)}
                        onRef={(ref) => this.refStartDate = ref}
                        onChange={this.handleSearchByStartDate}
                    />
                    <FilterDate
                        gridSize={3}
                        label={I18nUtils.tr(TR_FECHA_FIN)}
                        onRef={(ref) => this.refEndDate = ref}
                        onChange={this.handleSearchByEndDate}
                    />
                    <FilterTypeahead
                        gridSize={3}
                        label={I18nUtils.tr(TR_MATRICULA)}
                        options={vehiclesOptions}
                        isLoading={vehiclesLoading}
                        onRef={(ref) => this.refFilterVehicle = ref}
                        onChange={this.handleSearchByLicensePlate}
                    />
                </Row>
                <Row>
                    <FilterTypeahead
                        gridSize={3}
                        label={I18nUtils.tr(TR_RUTA)}
                        options={routesOptions}
                        isLoading={routesLoading}
                        onRef={(ref) => this.refFilterRoute = ref}
                        onChange={this.handleSearchByRoute}
                    />
                    <FilterTypeahead
                        gridSize={3}
                        label={I18nUtils.tr(TR_CONTROLADOR)}
                        options={controllersOptions}
                        isLoading={controllersLoading}
                        onRef={(ref) => this.refFilterUserController = ref}
                        onChange={this.handleSearchByUserController}
                    />
                    <FilterTypeahead
                        gridSize={3}
                        label={I18nUtils.tr(TR_RATIFICADOR)}
                        options={ratifiersOptions}
                        isLoading={ratifiersLoading}
                        onRef={(ref) => this.refFilterUserRatifier = ref}
                        onChange={this.handleSearchByUserRatifier}
                    />
                    <FilterTypeahead
                        gridSize={3}
                        label={I18nUtils.tr(TR_CALLE)}
                        options={streetsOptions}
                        isLoading={streetsLoading}
                        onRef={(ref) => this.refFilterStreet = ref}
                        onChange={this.handleSearchByStreet}
                    />
                </Row>
                <Row>
                    <Col sm={9} md={9} lg={9}/>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <Button
                                text={I18nUtils.tr(TR_BORRAR_FILTROS)}
                                onClick={this.handleClearFilter}
                                type={'button'}
                                block={true}
                                className={'btn-lg btn-default btn-export'}
                            />
                        </div>
                    </FormCol>
                </Row>
            </CardSectionCollapsed>
        );

    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleSearchByReportNumber = (reportNumber: string) => {
        this.props.dispatchAddCriteria({
            search: reportNumber,
        });
    };

    private handleSearchByStartDate = (date: Date): void => {
        this.props.dispatchAddCriteria({
            start_date: date ? date.toISOString() : undefined,
        });
    };

    private handleSearchByEndDate = (date: Date): void => {
        this.props.dispatchAddCriteria({
            end_date: date ? date.toISOString() : undefined,
        });
    };

    private handleSearchByLicensePlate = (vehicleId: string): void => {
        this.props.dispatchAddCriteria({
            vehicle_ids: ( vehicleId !== '-1' ) ? [vehicleId] : undefined,
        });
    };

    private handleSearchByRoute = (routeId: string): void => {
        this.props.dispatchAddCriteria({
            route_ids: ( routeId !== '-1' ) ? [routeId] : undefined,
        });
    };

    private handleSearchByUserController = (controllerId: string): void => {
        this.props.dispatchAddCriteria({
            controller_ids: ( controllerId !== '-1' ) ? [controllerId] : undefined,
        });
    };

    private handleSearchByUserRatifier = (ratifierId: string): void => {
        this.props.dispatchAddCriteria({
            ratifier_ids: ( ratifierId !== '-1' ) ? [ratifierId] : undefined,
        });
    };

    private handleSearchByStreet = (streetId: string): void => {
        this.props.dispatchAddCriteria({
            street_ids: ( streetId !== '-1' ) ? [streetId] : undefined,
        });
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleClearFilter = () => {
        this.refReportNumber.value = '';
        this.refStartDate.value = '';
        this.refEndDate.value = '';
        this.refFilterVehicle.getInstance().clear();
        this.refFilterRoute.getInstance().clear();
        this.refFilterUserController.getInstance().clear();
        this.refFilterUserRatifier.getInstance().clear();
        this.refFilterStreet.getInstance().clear();

        this.props.dispatchClearCriteria();
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ReportListCompanyUserFilter) as any;
