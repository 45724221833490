
import React, {ReactNode} from 'react';
import {ActionBuilder, ReducerBuilder} from '@cuatroochenta/co-redux-builders';
import {connect} from 'react-redux';
import Report from '../../../../../commons/model/Report';
import TaskReportCancellationMethodList from '../../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList';
import {FilterSelect, selectOptionsBuilder} from '../../../../../components/filter';
import ConfirmModal from '../../../../../components/modal/ConfirmModal';
import Row from '../../../../../components/Row';
import ReportListCompanyUserActions from '../ReportListCompanyUserActions';
import {headerOptionTexts} from '../ReportListCompanyUserCard';
import ReportListCompanyUserReducer, {HeaderOptions} from '../ReportListCompanyUserReducer';
import ReportListCompanyUserModalActions from './ReportListCompanyUserModalActions';
import ReportListCompanyUserModalReducer from './ReportListCompanyUserModalReducer';
import I18nUtils from '../../../../../commons/I18n/I18nUtils';
import {
    TR_DENUNCIAS_ANULADAS,
    TR_DENUNCIAS_INVALIDADAS,
    TR_DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE,
    TR_DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE,
    TR_DENUNCIAS_RECTIFICADAS,
    TR_ESCOJA_UN_MOTIVO
} from '../../../../../commons/I18n/constants';
import TaskReportInvalidationReasonList from '../../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList';
import TaskReportStateCancel from '../../../../../commons/ws/reportState/TaskReportStateCancel';
import Alert from '../../../../../base/alerts/Alert';
import TaskReportStateInvalidate from '../../../../../commons/ws/reportState/TaskReportStateInvalidate';
import TaskReportStateRectify from '../../../../../commons/ws/reportState/TaskReportStateRectify';
import TaskReportList from '../../../../../commons/ws/report/TaskReportList';
import TaskReportSentToProcessStateMarkToSend from '../../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateMarkToSend';
import TaskReportSentToProcessStateCancelToSend from '../../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateCancelToSend';

interface IProps {
    show: boolean
    onClose: () => void
}

const mapActionsToProps = ActionBuilder.combineActionsAutoMaps(
    ReportListCompanyUserModalActions.autoMapToProps(),
    ReportListCompanyUserActions.autoMapToProps()
);

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    ReportListCompanyUserModalReducer.autoMapToProps(),
    ReportListCompanyUserReducer.autoMapToProps()
);

type Props = typeof mapStateToProps & typeof mapActionsToProps & IProps

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const onOperationSucces = (res, tr, dataCriteria) => {
    if (res.success) {
        Alert.success(`${res.data['200']} ${I18nUtils.tr(tr).toLowerCase()}.`);
        new TaskReportList(dataCriteria).execute();
    }
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportListCompanyUserModal extends React.Component<Props> {

    public componentDidMount() {
        const {cancellationMethodReasons, invalidationReasons} = this.props;
        const cancellationDataFetched = cancellationMethodReasons.length > 0;
        const invalidationDataFetched = invalidationReasons.length > 0;

        if (!cancellationDataFetched) {
            new TaskReportCancellationMethodList().execute();
        }
        if (!invalidationDataFetched) {
            new TaskReportInvalidationReasonList().execute();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    public render() {
        const {
            handleConfirmAction,
            handleClose,
            props: {
                show,
                reportOperationReasonId,
                headerOptionIdSelected,
            }
        } = this;

        let modalTextProps = {title: '', message: '', textConfirmButton: ''};
        if (headerOptionIdSelected !== '') {
            modalTextProps = {
                title: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].modalTitle,
                message: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].modalMessage,
                textConfirmButton: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].buttonText,
            };
        }

        const confirmDisabled =
            (headerOptionIdSelected === HeaderOptions.NULLIFY || headerOptionIdSelected === HeaderOptions.INVALIDATE)
            && reportOperationReasonId === '-1';

        return (
            <ConfirmModal
                {...modalTextProps}
                show={show}
                confirmDisabled={confirmDisabled}
                onConfirmAction={handleConfirmAction}
                onClose={handleClose}
            >
                {
                    headerOptionIdSelected === HeaderOptions.NULLIFY ? this.renderNullifyBody() : null
                }
                {
                    headerOptionIdSelected === HeaderOptions.INVALIDATE ? this.renderInvalidateBody() : null
                }
            </ConfirmModal>
        );
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private renderNullifyBody = (): ReactNode => {
        const {
            cancellationMethodReasons,
            cancellationMethodReasonsLoading,
            dispatchChangeReportOperationReason
        } = this.props;

        const cancellationMethodReasonsOptions = selectOptionsBuilder.reportCancellationMethods(cancellationMethodReasons);

        return (
            <Row>
                <FilterSelect
                    gridSize={12}
                    label={I18nUtils.tr(TR_ESCOJA_UN_MOTIVO)}
                    isLoading={cancellationMethodReasonsLoading}
                    options={cancellationMethodReasonsOptions}
                    onChange={dispatchChangeReportOperationReason}
                />
            </Row>
        );
    };

    private renderInvalidateBody = (): ReactNode => {
        const {
            invalidationReasons,
            invalidationReasonsLoading,
            dispatchChangeReportOperationReason
        } = this.props;

        const invalidationReasonsOptions = selectOptionsBuilder.reportInvalidationReasons(invalidationReasons);

        return (
            <Row>
                <FilterSelect
                    gridSize={12}
                    label={I18nUtils.tr(TR_ESCOJA_UN_MOTIVO)}
                    isLoading={invalidationReasonsLoading}
                    options={invalidationReasonsOptions}
                    onChange={dispatchChangeReportOperationReason}
                />
            </Row>
        );
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleClose = () => {
        this.props.onClose();
        this.props.dispatchChangeReportOperationReason('-1');
    };

    private handleConfirmAction = () => {
        const {
            reportOperationReasonId,
            headerOptionIdSelected,
            itemIdsSelected,
            data,
        } = this.props;

        const reportsIdsSelected: string[] = [];
        data.forEach((report: Report) => {
            if (itemIdsSelected.includes(report.id)) {
                reportsIdsSelected.push(report.id);
            }
        });

        switch (headerOptionIdSelected) {
            case HeaderOptions.NULLIFY:
                this.handleNullify(reportsIdsSelected, reportOperationReasonId);
                break;
            case HeaderOptions.INVALIDATE:
                this.handleInvalidate(reportsIdsSelected, reportOperationReasonId);
                break;
            case HeaderOptions.RECTIFY:
                this.handleRectify(reportsIdsSelected);
                break;
            case HeaderOptions.SEND:
                this.handleSend(reportsIdsSelected);
                break;
            case HeaderOptions.UNDO_SEND:
                this.handleUndoSend(reportsIdsSelected);
                break;
        }
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleNullify = (reportsIdsSelected: string[], reportOperationReasonId: string) => {
        const {dataCriteria} = this.props;
        new TaskReportStateCancel(reportsIdsSelected, reportOperationReasonId)
            .onSuccess((res) => onOperationSucces(res, TR_DENUNCIAS_ANULADAS, dataCriteria))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleInvalidate = (reportsIdsSelected: string[], reportOperationReasonId: string) => {
        const {dataCriteria} = this.props;
        new TaskReportStateInvalidate(reportsIdsSelected, reportOperationReasonId)
            .onSuccess((res) => onOperationSucces(res, TR_DENUNCIAS_INVALIDADAS, dataCriteria))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleRectify = (reportsIdsSelected: string[]) => {
        const {dataCriteria} = this.props;
        new TaskReportStateRectify(reportsIdsSelected)
            .onSuccess((res) => onOperationSucces(res, TR_DENUNCIAS_RECTIFICADAS, dataCriteria))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleSend = (reportsIdsSelected: string[]) => {
        const {dataCriteria} = this.props;
        new TaskReportSentToProcessStateMarkToSend(reportsIdsSelected)
            .onSuccess((res) => onOperationSucces(res, TR_DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE, dataCriteria))
            .onFail((error) => Alert.error(error))
            .execute();
    };

    private handleUndoSend = (reportsIdsSelected: string[]) => {
        const {dataCriteria} = this.props;
        new TaskReportSentToProcessStateCancelToSend(reportsIdsSelected)
            .onSuccess((res) => onOperationSucces(res, TR_DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE, dataCriteria))
            .onFail((error) => Alert.error(error))
            .execute();
    };

}

export default connect(mapStateToProps, mapActionsToProps)(ReportListCompanyUserModal) as any;
