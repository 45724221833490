import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {sha256} from "js-sha256";
import Urls from "../Urls";
import Password from "../../model/Password";
import TaskResponse from "../TaskResponse";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE, TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES,
    TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA
} from "../../I18n/constants";
import {generateRoute} from "../../utils/Router";

export default class TaskUserPasswordEdit extends BaseGenericRequest <Password, TaskResponse<any>> {

    private readonly data: Password;
    private readonly wrapErrorCodeWithMessage: boolean;

    public constructor(password: Password, userId: string, wrapErrorCodeWithMessage = false) {
        super(METHOD.POST, generateRoute(Urls.URL_USER_CHANGE_PASSWORD, {uuid: userId}));
        this.wrapErrorCodeWithMessage = wrapErrorCodeWithMessage;

        this.setErrorMessage(401, I18nUtils.tr(TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA));
        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
        this.setErrorMessage(411, I18nUtils.tr(TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));

        this.data = password;
    }

    protected parseResponse(responseObj: any) {
        if (!this.wrapErrorCodeWithMessage) return super.parseResponse(responseObj);
        if (responseObj.success === false) {
            throw new Error(JSON.stringify({errorMessage: responseObj.message || this.errorMessages[responseObj.code], errorCode: responseObj.code}));
        }
        return super.parseResponse(responseObj);
    }


    protected getRequest(): Password {
        return {
            ...this.data,
            password: sha256(this.data.password)
        };
    }
}
