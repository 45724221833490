import { Component, ReactNode } from 'react';
import FormCol, { FormColProps } from './FormCol';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import Icon from '../Icon';
import FileInfo from '../FileInfo';
import AuthManager from '../../utils/AuthManager';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Alert from '../../base/alerts/Alert';
import {
    TR_ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS,
    TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO,
    TR_ERROR_EN_EL_SERVIDOR,
    TR_FICHERO_CARGADO_CORRECTAMENTE,
    TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO,
    TR_TIPO_DE_FICHERO_NO_VALIDO,
} from '../../commons/I18n/constants';
import I18nUtils from '../../commons/I18n/I18nUtils';
import Config, { AppIcon } from '../../config/Config';
import Urls from '../../commons/ws/Urls';
import FileProgress from '../FileProgress';

export interface FormDragFileProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    showError?: boolean,
    maxMBSize?: number,
    multiple?: boolean,
    fileTypes?: string[],
    fileIcon?: string,
    invalidFileMessage?: string,
    showErrorInLabel?: boolean,
}

interface DropZoneFile extends File {
    path: string
}

interface State {
    uploadingFiles: DropZoneFile[],
    touched: boolean,

    [fileIndex: number]: number,
}

export default class FormDragFile extends Component<FormDragFileProps, State> {

    public state: State = {
        uploadingFiles: [],
        touched: false,
    };

    private requests: XMLHttpRequest[] = [];

    public componentWillUnmount(): void {
        this.requests.forEach((request) => request.abort());
    }

    public render(): ReactNode {
        const { meta, label, col, showError, input: { value }, multiple = false } = this.props;

        const hasFiles = this.isUploadingFile() || ( Array.isArray(value) && value.length !== 0 );

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    {label ? <label className={'main-label'}>{label || ''}</label> : null}
                    {hasFiles && !multiple ?
                        this.renderFiles() :
                        this.renderDropZone(hasFiles)
                    }
                    <label className="error">{( meta.touched || showError ) ? meta.error : ''}</label>
                </div>
            </FormCol>
        );
    }

    private sendFile = (file: DropZoneFile, index: number): void => {
        const { input: { onChange }, multiple } = this.props;

        const data = new FormData();
        data.append('file', file, file.name);

        const request = this.requests[index];

        request.upload.onprogress = (event) => {
            const percent = +( ( event.loaded / event.total ) * 100 ).toFixed(2);

            this.setState({ [index]: percent });
        };
        request.onreadystatechange = () => {
            if (request.readyState !== 4) {
                return;
            }

            if (request.status !== 0) {
                const response = JSON.parse(request.response);
                if (response.success) {
                    // importante que lea el value desde esta línea!!!
                    onChange(multiple ? [response.data.url, ...this.props.input.value] : [response.data.url]);
                    Alert.success(I18nUtils.tr(TR_FICHERO_CARGADO_CORRECTAMENTE));
                } else {
                    if (response.message && response.message.code === 500) {
                        Alert.error(TR_ERROR_EN_EL_SERVIDOR);
                    } else {
                        Alert.error(response.message);
                    }
                }
            }
        };

        request.open(METHOD.POST, Urls.URL_FILE_UPLOAD);
        if (AuthManager.isLogged()) {
            request.setRequestHeader('Authorization', `Bearer ${AuthManager.getAuthToken()}`);
        }
        request.setRequestHeader('Accept', 'application/json');
        request.send(data);
    };

    private onDrop = (acceptedFiles: DropZoneFile[]): void => {
        const newState: State = {
            uploadingFiles: [],
            touched: false,
        };

        // resetea el array de peticiones ajax
        this.requests.length = 0;

        // iniciamos el estado individual antes de la carga
        acceptedFiles.forEach((file, index) => {
            this.requests.push(new XMLHttpRequest());
            newState.uploadingFiles.push(file);
            newState[index] = 0;
        });

        this.setState(newState);

        acceptedFiles.forEach(this.sendFile);
    };

    private onRemoveFile = (toRemoveUrl: string): void => {
        const { input: { onChange, value } } = this.props;
        if (Array.isArray(value)) {
            onChange(value.filter((url) => url !== toRemoveUrl));
        }
        this.setState({ touched: true });
    };

    private isUploadingFile = (): boolean => {
        return !!this.requests.find((request) => request.readyState !== 4);
    };

    private renderDropZone = (hasFiles: boolean): React.ReactNode => {
        const {
            disabled, maxMBSize = Config.MAX_FILE_SIZE_MB, fileTypes = [], multiple = false, invalidFileMessage,
            showErrorInLabel = false,
        } = this.props;

        const maxSize = maxMBSize * 1024 * 1024;
        const fileTypesText = fileTypes.join(', ');

        return (
            // @ts-ignore
            <Dropzone onDrop={this.onDrop}
                      disabled={disabled || this.isUploadingFile()}
                      maxSize={maxSize}
                      multiple={multiple}
                      accept={fileTypes.join(',')}
                      onFileDialogCancel={() => this.setState({ touched: true })}
                      onDropRejected={(files) => {
                          let existInvalidExtension: boolean = false;
                          files.forEach((file) => {
                              const fileExtension = file.type.split('/')[1];
                              existInvalidExtension = !fileTypes.includes(fileExtension);
                          });
                          if (existInvalidExtension && !showErrorInLabel) {
                              Alert.error(I18nUtils.tr(invalidFileMessage ? invalidFileMessage : TR_TIPO_DE_FICHERO_NO_VALIDO));
                          }
                      }}
            >
                {({ getRootProps, getInputProps, rejectedFiles }) => {
                    const rejected = rejectedFiles.length > 0;
                    return (
                        <>
                            <div className={
                                classNames(`dropzone ${rejected ? 'dz-error' : ''}`, {
                                    disabled,
                                })}>
                                <div {...getRootProps()} className={'dz-root'}>
                                    <input {...getInputProps()} />
                                    {hasFiles ?
                                        this.renderFiles() :
                                        this.renderDZMessage()}
                                </div>
                            </div>
                            {rejected && showErrorInLabel &&
                            <label className={'error m-t-5 m-b-0'}>
                                {`${I18nUtils.tr(TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${fileTypesText}.`}
                            </label>}
                        </>
                    );
                }}
            </Dropzone>
        );
    };

    private renderDZMessage = (): React.ReactNode => {
        const { fileIcon = AppIcon.DOCUMENTATION, multiple = false } = this.props;
        return (
            <div className={'dz-message'}>
                <Icon icon={fileIcon} />
                <p>{I18nUtils.tr(multiple ?
                    TR_ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS :
                    TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO)}</p>
            </div>
        );
    };

    private renderFiles = (): React.ReactNode => {
        const { input: { value }, multiple = false, fileIcon = AppIcon.DOCUMENTATION } = this.props;
        const { uploadingFiles } = this.state;
        return (
            <div className={'dz-files'}>
                {Array.isArray(value) && value.map((url: string) => (
                    <FileInfo key={url}
                              url={url}
                              icon={fileIcon}
                              fileName={url.replace(Urls.URL_FILE_UPLOAD + 's/', '')}
                              removeHandler={() => this.onRemoveFile(url)}
                              className={multiple ? '' : 'p-l-0 m-t-15'}
                    />
                ))}
                {this.requests.map((request, index) => {
                    return request.readyState !== 4 && <FileProgress
                        key={index}
                        icon={fileIcon}
                        cancelHandler={(event) => this.requests[index].abort()}
                        percentLoaded={this.state[index] || 0}
                        size={uploadingFiles[index].size} />;
                })}
            </div>
        );
    };

}

