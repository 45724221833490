import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User, {UserData} from "../../model/User";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF} from "../../I18n/constants";

export default class TaskUserEdit extends BaseGenericRequest<UserData, TaskResponse<User>> {

    private readonly data: UserData;

    public constructor(userId: string, userData: UserData) {
        super(METHOD.PUT, generateRoute(Urls.URL_USER_EDIT, {uuid: userId}));
        this.data = userData;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF))

    }

    protected getRequest(): UserData {
        return this.data;
    }
}
