import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import Exploitation, {ExploitationData} from "../../model/Exploitation";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_ELEMENTO_SIMILAR} from "../../I18n/constants";

export default class TaskExploitationAdd extends BaseGenericRequest<ExploitationData, TaskResponse<Exploitation>> {

    private readonly data: ExploitationData;

    public constructor(data: ExploitationData) {
        super(METHOD.POST, Urls.URL_EXPLOITATION_ADD);
        this.data = data;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_ELEMENTO_SIMILAR));

    }

    protected getRequest(): ExploitationData {
        return this.data;
    }
}
