import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_COLOR,
    TR_COLOR_OBLIGATORIO,
    TR_DESCRIPCION,
    TR_DESCRIPCION_OBLIGATORIA,
    TR_ESPECIAL,
    TR_MASCARA,
    TR_MASCARA_OBLIGATORIA,
    TR_MATRICULA,
    TR_MATRICULA_OBLIGATORIA,
    TR_MODELO,
    TR_MODELO_OBLIGATORIO,
    TR_NO,
    TR_NUEVO_VEHICULO,
    TR_SI,
    TR_TIPO,
    TR_TIPO_OBLIGATORIO,
    TR_VEHICULO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_COMPANY_USER_VEHICLES} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import VehicleFormScreenCompanyUserReducer from "../formscreen/VehicleFormScreenCompanyUserReducer";
import FormSelect from "../../../../components/form/FormSelect";
import FormSwitch from "../../../../components/form/FormSwitch";
import FormTextArea from "../../../../components/form/FormTextArea";
import FormInputOption from "../../../../components/form/FormInputOption";
import VehicleModel from "../../../../commons/model/VehicleModel";
import VehicleType from "../../../../commons/model/VehicleType";
import VehicleColor from "../../../../commons/model/VehicleColor";
import TaskVehicleModelList from "../../../../commons/ws/vehicleModel/TaskVehicleModelList";
import TaskVehicleColorList from "../../../../commons/ws/vehicleColor/TaskVehicleColorList";
import TaskVehicleTypeList from "../../../../commons/ws/vehicleType/TaskVehicleTypeList";
import { goToRoute } from '../../../../utils/Router';
import TaskVehicleLicenseplatemaskList from "../../../../commons/ws/vehicle/TaskVehicleLicenseplatemaskList";
import LicensePlateMask from "../../../../commons/model/LicensePlateMask";
import React from 'react';

const FORM_NAME = 'VehicleFormCompanyUser';

export interface VehicleCompanyUserData {
    licensePlate: string,
    licensePlateMaskId: string,
    special: boolean,
    description: string,
    vehicleModelId: string,
    vehicleTypeId: string,
    vehicleColorId: string,
}

interface VehicleCardFormCompanyUserProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: VehicleCompanyUserData) => void,
    initialValues?: Partial<VehicleCardFormCompanyUserProps>,
    parentLoading?: boolean,
}

const mapStateToProps = VehicleFormScreenCompanyUserReducer.autoMapToProps();

type Props =
    VehicleCardFormCompanyUserProps &
    typeof mapStateToProps
    & InjectedFormProps<VehicleCardFormCompanyUserProps>

enum VehicleFormCompanyUserFields {
    LICENSE_PLATE = "licensePlate",
    LICENSE_PLATE_MASK_ID = "licensePlateMaskId",
    SPECIAL = "special",
    DESCRIPTION = "description",
    VEHICLE_MODEL_ID = "vehicleModelId",
    VEHICLE_TYPE_ID = "vehicleTypeId",
    VEHICLE_COLOR_ID = "vehicleColorId",
}

class VehicleCardFormCompanyUser extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskVehicleModelList().execute();
        new TaskVehicleColorList().execute();
        new TaskVehicleTypeList().execute();
        new TaskVehicleLicenseplatemaskList().execute();
    }

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, vehicleLoading, pristine, vehicleModels, vehicleColors, vehicleTypes,
            vehicleLicensePlateMasks, parentLoading
        } = this.props;

        const vehicleModelOptions: FormInputOption[] = vehicleModels.map((vehicleModel: VehicleModel) => {
            return {value: vehicleModel.id, name: vehicleModel.name}
        });

        const vehicleTypeOptions: FormInputOption[] = vehicleTypes.map((vehicleType: VehicleType) => {
            return {value: vehicleType.id, name: vehicleType.name}
        });

        const vehicleColorOptions: FormInputOption[] = vehicleColors.map((vehicleColor: VehicleColor) => {
            return {value: vehicleColor.id, name: vehicleColor.name}
        });

        const licensePlateMaskOptions: FormInputOption[] = vehicleLicensePlateMasks.map((licensePlateMask: LicensePlateMask) => {
            return {value: licensePlateMask.id, name: licensePlateMask.name}
        });

        return (
            <Card loading={parentLoading || vehicleLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_VEHICULO : TR_NUEVO_VEHICULO)}/>
                <CardBody>
                    {/*
                    // @ts-ignores*/}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_MATRICULA)}
                                name={VehicleFormCompanyUserFields.LICENSE_PLATE}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_MASCARA)}
                                name={VehicleFormCompanyUserFields.LICENSE_PLATE_MASK_ID}
                                component={FormSelect}
                                blankOptionText={' '}
                                options={licensePlateMaskOptions}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_ESPECIAL)}
                                name={VehicleFormCompanyUserFields.SPECIAL}
                                component={FormSwitch}
                                labelEnabled={I18nUtils.tr(TR_SI)}
                                labelDisabled={I18nUtils.tr(TR_NO)}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_DESCRIPCION)}
                                name={VehicleFormCompanyUserFields.DESCRIPTION}
                                component={FormTextArea}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_MODELO)}
                                name={VehicleFormCompanyUserFields.VEHICLE_MODEL_ID}
                                component={FormSelect}
                                blankOptionText={' '}
                                options={vehicleModelOptions}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_TIPO)}
                                name={VehicleFormCompanyUserFields.VEHICLE_TYPE_ID}
                                component={FormSelect}
                                blankOptionText={' '}
                                options={vehicleTypeOptions}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_COLOR)}
                                name={VehicleFormCompanyUserFields.VEHICLE_COLOR_ID}
                                component={FormSelect}
                                blankOptionText={' '}
                                options={vehicleColorOptions}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_VEHICLES)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: VehicleCompanyUserData) {
    const errors: FormErrors<VehicleCompanyUserData> = {};

    if (!values.licensePlate || values.licensePlate.length === 0) {
        errors.licensePlate = I18nUtils.tr(TR_MATRICULA_OBLIGATORIA);
    }
    if (!values.licensePlateMaskId || values.licensePlateMaskId === "-1") {
        errors.licensePlateMaskId = I18nUtils.tr(TR_MASCARA_OBLIGATORIA);
    }
    if (!values.description || values.description.length === 0) {
        errors.description = I18nUtils.tr(TR_DESCRIPCION_OBLIGATORIA);
    }
    if (!values.vehicleModelId || values.vehicleModelId === "-1") {
        errors.vehicleModelId = I18nUtils.tr(TR_MODELO_OBLIGATORIO);
    }
    if (!values.vehicleTypeId || values.vehicleTypeId === "-1") {
        errors.vehicleTypeId = I18nUtils.tr(TR_TIPO_OBLIGATORIO);
    }
    if (!values.vehicleColorId || values.vehicleColorId === "-1") {
        errors.vehicleColorId = I18nUtils.tr(TR_COLOR_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<VehicleCompanyUserData, VehicleCardFormCompanyUserProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(VehicleCardFormCompanyUser);
