import {Component, ReactNode} from 'react';
import I18nUtils from "../commons/I18n/I18nUtils";
import {TR_IR_A_LA_PAGINA} from "../commons/I18n/constants";
import _ from 'lodash';
import Icon from "./Icon";

export interface PagerBackendProps {
    current: number,
    total_pages: number,
    total_elements: number,
}

export interface PagerProps {
    data: PagerBackendProps,
    onPage: (page: number) => void,
    elementsLabel?: string,
}

export default class Pager extends Component<PagerProps> {

    public render(): ReactNode {
        return (
            <nav className="row footer-pagination">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 m-b--10">
                    <ul className="pagination">
                        {this.renderPreviousPage()}
                        {this.renderPageNumbers()}
                        {this.renderNextPage()}
                    </ul>
                </div>
                <div className="col-lg-4col-md-4 col-sm-4 col-xs-12 text-center">
                    {this.props.elementsLabel && <h6 className={'m-t-30 bold'}>{this.props.data.total_elements} {this.props.elementsLabel}</h6>}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 m-b--10">
                    <div className="form-group row"><label htmlFor="pagina" className="col-md-8 col-sm-7 col-xs-8 m-t-5">{I18nUtils.tr(TR_IR_A_LA_PAGINA)}:</label>
                        <div className="col-md-4 col-sm-5 col-xs-4">
                            <div className="border-form">
                                <select
                                    id="pagina"
                                    className="form-control show-tick"
                                    value={this.props.data.current}
                                    onChange={(e) => this.props.onPage(parseInt(e.target.value, 10))}
                                >
                                    {[...Array(this.props.data.total_pages).keys()].map((element, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    private renderPageNumbers(): ReactNode {
        let minValue = this.props.data.current - 2;
        if (minValue <= 0 ){
            minValue = 1;
        }

        let maxValue = this.props.data.current + 2;
        if (maxValue > this.props.data.total_pages ){
            maxValue = this.props.data.total_pages;
        }


        const pages = _.range(minValue, maxValue + 1);
        return pages.map((page: number, i: number) => {
            return (
                <li key={i} className={this.props.data.current === page ? 'active' : '' }>
                    <a className="waves-effect" onClick={() => this.props.onPage(page)}>{page}</a>
                </li>
            );
        });
    }

    private renderPreviousPage(): ReactNode {
        const isDisabled = this.props.data.current - 1 <= 0;
        const tagAprops = {className: "waves-effect"};
        if (!isDisabled) {
            tagAprops['onClick'] = () => this.props.onPage(this.props.data.current - 1);
        }

        return (
            <li  className={isDisabled ? 'disabled' : ''}>
                <a {...tagAprops}>
                    <Icon icon={'chevron_left'}/>
                </a>
            </li>
        );
    }

    private renderNextPage(): ReactNode {
        const isDisabled = this.props.data.current + 1 > this.props.data.total_pages;
        const tagAprops = {className: "waves-effect"};
        if (!isDisabled) {
            tagAprops['onClick'] = () => this.props.onPage(this.props.data.current + 1);
        }

        return (
            <li className={isDisabled ? 'disabled' : ''}>
                <a {...tagAprops}>
                    <Icon icon={'chevron_right'}/>
                </a>
            </li>
        );
    }

}

