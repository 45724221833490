import {connect} from "react-redux";
import UnloggedScreen from "../../../base/screens/UnloggedScreen";
import AuthReducer from "./LoginReducer";
import AuthActions from "./LoginActions";
import imgLogo from "../../../res/img/logo.png";
import Icon from "../../../components/Icon";
import I18nUtils from "../../../commons/I18n/I18nUtils";
import {
    TR_ABRIR_SESION,
    TR_CONTRASENA,
    TR_EMAIL,
    TR_INTRODUCE_TU_CONTRASENA,
    TR_LOGIN,
    TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE,
    TR_OLVIDASTE_TU_CONTRASENA,
    TR_RECUERDAME,
    TR_EL_EMAIL_NO_ES_VALIDO,
    TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA,
    TR_USUARIO_NO_ENCONTRADO
} from "../../../commons/I18n/constants";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import Row from "../../../components/Row";
import Col from "../../../components/Col";
import Button from "../../../components/buttons/Button";
import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";
import {push} from "react-router-redux";
import {ROUTE_HOME} from "../../../routing/Routes";
import EditPasswordModal, {EditPasswordModalData} from '../../admin/users/formdetail/EditPasswordModal';
import TaskLogin from "../../../commons/ws/login/TaskLogin";
import {executeItemTask} from "../../../utils/FormUtils";
import TaskRecoverPassword from "../../../commons/ws/login/TaskRecoverPassword";
import RecoverPasswordModal from "./RecoverPasswordModal";
import AuthManager, { changeUserPassword } from '../../../utils/AuthManager';
import { goToRoute } from '../../../utils/Router';

const mapStateToProps = AuthReducer.autoMapToProps();
const mapActionsToProps = AuthActions.autoMapToProps();

interface State {
    showRecoverPwd: boolean,
    rememberMe: boolean,
    showErrorMsg: boolean,
    showEditPasswordModal: boolean,
    errorResponseMsg: string,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps

class LoginScreen extends UnloggedScreen<Props, State> {

    public state: State = {
        showRecoverPwd: false,
        rememberMe: false,
        showErrorMsg: false,
        showEditPasswordModal: false,
        errorResponseMsg: '',
    };

    public componentDidMount() {
        document.body.classList.add('login-page');
    }

    public componentWillUnmount() {
        document.body.classList.remove('login-page');
    }

    private onShowRecoverPwd = (): void => {
        this.setState({showRecoverPwd: true})
    };

    private onHideRecoverPwdModal = (): void => {
        this.setState({showRecoverPwd: false})
    };

    private onRememberMe = (): void => {
        this.setState({rememberMe: !this.state.rememberMe})
    };

    private setErrorResponseMsg = (errorResponseMsg: string = ""): void => {
        this.setState({errorResponseMsg})
    };

    private onShowErrorMsg = (): void => {
        this.setState({showErrorMsg: true})
    };

    private onHideErrorMsg = (): void => {
        this.setState({showErrorMsg: false})
    };

    private doLogin = () => {
        const {email, password, allowLogin, setToken} = this.props;

        if (allowLogin) {
            new TaskLogin(email, password)
                .onSuccess((response) => {
                    if (response.success) {
                        setToken(response.data);
                        AuthManager.setAuthToken(response.data, this.state.rememberMe);
                        ReduxStorageUtils.dispatch(push(ROUTE_HOME))
                    }
                })
                .onFail((response) => {
                    this.onShowErrorMsg();
                    this.setErrorResponseMsg(response);
                })
                .execute();
        } else {
            this.onShowErrorMsg();
            // this.setState({
            //     showErrorMsg: true,
            // })
        }
    };

    private handleKeyboard = (event) => {
        if (event.key === 'Enter') {
            this.doLogin();
        }
    };

    private onRecoverPassword = (email: string): void => {
        executeItemTask(
            new TaskRecoverPassword(email),
            () => goToRoute(ROUTE_HOME),
            I18nUtils.tr(TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA),
            I18nUtils.tr(TR_USUARIO_NO_ENCONTRADO)
        )
    };

    private onHideEditPasswordModal = (): void => {
        this.setState({
            showEditPasswordModal: false,
        })
    };

    private onChangePassword = (passwordData: EditPasswordModalData): void => {
        changeUserPassword(
            passwordData.password,
            '1',
            () => {
                AuthManager.setAuthToken(this.props.token, this.state.rememberMe);
                ReduxStorageUtils.dispatch(push(ROUTE_HOME));
            },
            this.props.token);
    };

    public renderScreen(): React.ReactNode {
        const {isValidPassword, isValidUser, allowLogin} = this.props;
        const {showErrorMsg, showEditPasswordModal, errorResponseMsg} = this.state;

        return (
            <div className="login-box">
                <div className="logo">
                    <a>
                        <img src={imgLogo} alt="Pavapark" className="img-responsive center-block m-b-15"/>
                    </a>
                    <small>{I18nUtils.tr(TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE)}</small>
                </div>
                <Card loading={this.props.loading}>
                    <CardBody>
                        <div className="msg">{I18nUtils.tr(TR_ABRIR_SESION)}</div>
                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'person'}/></span>
                            <div className="form-line">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_EMAIL)}
                                    required={true}
                                    autoFocus={true}
                                    value={this.props.email}
                                    onChange={(e) => {
                                        this.onHideErrorMsg();
                                        this.props.setUsername(e.target.value)
                                    }}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg &&
                        <p className={'error-message'}>{isValidUser ? '' :
                            I18nUtils.tr(TR_EL_EMAIL_NO_ES_VALIDO)}
                        </p>
                        }

                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'lock'}/></span>
                            <div className="form-line">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_CONTRASENA)}
                                    required={true}
                                    value={this.props.password}
                                    onChange={(e) => {
                                        this.onHideErrorMsg();
                                        this.props.setPassword(e.target.value)
                                    }}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg && isValidUser &&
                        <p className={'error-message'}>
                            {isValidPassword ? '' : I18nUtils.tr(TR_INTRODUCE_TU_CONTRASENA)}
                            {errorResponseMsg.length !== 0 ? errorResponseMsg : ''}
                        </p>
                        }

                        <Row>
                            <Col xs={8} className={'p-t-5 p-l-20'}>
                                <input type="checkbox"
                                       name="rememberme"
                                       id="rememberme"
                                       className="filled-in chk-col-blue-grey"
                                       checked={this.state.rememberMe}
                                       onChange={(e) => this.onRememberMe()}
                                />
                                <label htmlFor="rememberme">{I18nUtils.tr(TR_RECUERDAME)}</label>
                            </Col>
                            <Col xs={4}>
                                <Button
                                    className={'bg-blue-grey'}
                                    text={I18nUtils.tr(TR_LOGIN)}
                                    onClick={this.doLogin}
                                    block={true}
                                    disabled={!allowLogin && showErrorMsg}
                                />
                            </Col>
                        </Row>
                        <Row className={'m-b-5 m-t-15'}>
                            <Col xs={12} className={'align-right'}>
                                <a onClick={(e) => this.onShowRecoverPwd()}>{I18nUtils.tr(TR_OLVIDASTE_TU_CONTRASENA)}</a>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <RecoverPasswordModal show={this.state.showRecoverPwd}
                                      onClose={this.onHideRecoverPwdModal}
                                      onSubmit={this.onRecoverPassword}
                />
                <EditPasswordModal show={showEditPasswordModal}
                                   onClose={this.onHideEditPasswordModal}
                                   onSubmit={this.onChangePassword}
                                   forceChange={true}
                />
            </div>
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(LoginScreen);
