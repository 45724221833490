import {ROUTE_ADMIN_VEHICLE_MODELS} from "../../../../routing/Routes";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_MODELOS_DE_VEHICULOS} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import VehicleModelListCardAdmin from './VehicleModelListCardAdmin';
import React from 'react';

export default class VehicleColorListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_MODELOS_DE_VEHICULOS),
                    url: ROUTE_ADMIN_VEHICLE_MODELS,
                    icon: AppIcon.CAR
                }
            ]}>
                <VehicleModelListCardAdmin/>
            </ScreenCard>
        )
    }
}
