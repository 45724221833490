import { Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import Card from '../../../../components/card/Card';
import CardHeader from '../../../../components/card/CardHeader';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVA_RAZON_DE_INVALIDACION,
    TR_RAZON_DE_INVALIDACION
} from '../../../../commons/I18n/constants';
import CardBody from '../../../../components/card/CardBody';
import Row from '../../../../components/Row';
import FormInput from '../../../../components/form/FormInput';
import { connect } from 'react-redux';
import FormCardFooter from '../../../../components/form/FormCardFooter';
import { compose } from 'redux';
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASONS } from '../../../../routing/Routes';
import ReportInvalidationReasonAdminFormReducer from '../formscreen/ReportInvalidationReasonAdminFormReducer';
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'ReportInvalidationReasonAdminForm';

export interface ReportInvalidationReasonAdminFormData {
    name: string,
}

interface InnerProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: ReportInvalidationReasonAdminFormData) => void,
    initialValues?: Partial<ReportInvalidationReasonAdminFormData>,
    parentLoading?: boolean,
}

const mapStateToProps = ReportInvalidationReasonAdminFormReducer.autoMapToProps();

type Props = InnerProps & typeof mapStateToProps & InjectedFormProps<ReportInvalidationReasonAdminFormData>

enum Fields {
    NAME = 'name'
}

class ReportInvalidationReasonAdminFormCard extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit,
            onSubmit,
            initialValues,
            invalid,
            loading,
            pristine,
            parentLoading
        } = this.props;

        const headerCardTitle = I18nUtils.tr(initialValues ? TR_RAZON_DE_INVALIDACION : TR_NUEVA_RAZON_DE_INVALIDACION);

        return (
            <Card loading={parentLoading || loading}>
                <CardHeader title={headerCardTitle}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={Fields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASONS)}
                        />
                    </form>
                </CardBody>
            </Card>
        );
    }
}

function validate(values: ReportInvalidationReasonAdminFormData) {
    const errors: FormErrors<ReportInvalidationReasonAdminFormData> = {};
    if ( !values.name || values.name.length === 0 ) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<ReportInvalidationReasonAdminFormData, InnerProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ReportInvalidationReasonAdminFormCard);
