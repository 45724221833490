import React from 'react';
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CODIGO_SUMA,
    TR_CODIGO_SUMA_OBLIGATORIO,
    TR_DESCUENTO,
    TR_DESCUENTO_OBLIGATORIO,
    TR_LEY, TR_LONGITUD_DEBE_SER,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVA_LEY
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_LAW_TYPES} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import {goToRoute} from '../../../../utils/Router';
import LawTypeFormScreenAdminReducer from "../formscreen/LawTypeFormScreenAdminReducer";

const FORM_NAME = 'LawTypeCardFormAdmin';

export interface LawTypeCardFormAdminData {
    name: string,
    sumaCode: string,
    discountPercentage: string,
}

interface LawTypeCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: LawTypeCardFormAdminData) => void,
    initialValues?: Partial<LawTypeCardFormAdminData>,
    parentLoading?: boolean,
}

const mapStateToProps = LawTypeFormScreenAdminReducer.autoMapToProps();

type Props =
    LawTypeCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<LawTypeCardFormAdminData>

enum LawTypeFormAdminFields {
    NAME = "name",
    SUMA_CODE = "sumaCode",
    DISCOUNT_PERCENTAGE = "discountPercentage"
}

class LawTypeCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, pristine, parentLoading
        } = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_LEY : TR_NUEVA_LEY)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={LawTypeFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_CODIGO_SUMA)}
                                name={LawTypeFormAdminFields.SUMA_CODE}
                                component={FormInput}
                                col={{md: 3, lg: 3}}
                            />

                            <Field
                                label={I18nUtils.tr(TR_DESCUENTO)}
                                name={LawTypeFormAdminFields.DISCOUNT_PERCENTAGE}
                                type={"number"}
                                component={FormInput}
                                col={{md: 3, lg: 3}}
                            />
                        </Row>

                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_LAW_TYPES)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: LawTypeCardFormAdminData) {
    const errors: FormErrors<LawTypeCardFormAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.sumaCode || values.sumaCode.toString().length === 0) {
        errors.sumaCode = I18nUtils.tr(TR_CODIGO_SUMA_OBLIGATORIO);
    } else if (values.sumaCode.toString().length > 2) {
        errors.sumaCode = `${I18nUtils.tr(TR_LONGITUD_DEBE_SER)} 2`;
    }
    if (!values.discountPercentage || values.discountPercentage.toString().length === 0) {
        errors.discountPercentage = I18nUtils.tr(TR_DESCUENTO_OBLIGATORIO)
    }

    return errors;
}

export default compose(
    reduxForm<LawTypeCardFormAdminData, LawTypeCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(LawTypeCardFormAdmin);
