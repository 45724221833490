import {Component, ReactNode} from "react";
import CollectionProcessDetailDataContainer from "./CollectionProcessDetailDataContainer";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_PROCESO_RECAUDACION, TR_PROCESOS_RECAUDACION} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_COLLECTION_PROCESS, ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import {generateRoute} from "../../../../commons/utils/Router";
import {RouteComponentProps} from "react-router";

export default class CollectionProcessDetailScreen extends Component<RouteComponentProps<{id: string}>> {
	public render(): ReactNode {
		const {id} = this.props.match.params;

		return (
			<ScreenCard breadcrumbs={[
				{
					name: I18nUtils.tr(TR_PROCESOS_RECAUDACION),
					url: ROUTE_ADMIN_COLLECTION_PROCESS,
					icon: AppIcon.EURO,
				},
				{
					name: I18nUtils.tr(TR_PROCESO_RECAUDACION),
					url: generateRoute(ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL, {id}),
					icon: AppIcon.EURO,
				}
			]}>
				<CollectionProcessDetailDataContainer id={id}/>
			</ScreenCard>
		)
	}
}
