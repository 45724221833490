import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import {METHOD} from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import I18nUtils from "../../I18n/I18nUtils";
import {TR_NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA} from "../../I18n/constants";

interface TaskReportExportProcessingRequest {
    date: string
}

type TaskReportExportProcessingResponse = TaskResponse<{url: string}>

export default class TaskReportExportProcessing extends BaseGenericRequest<TaskReportExportProcessingRequest, TaskReportExportProcessingResponse> {

    private readonly data: TaskReportExportProcessingRequest;

    public constructor(date: string) {
        super(METHOD.POST, Urls.URL_REPORT_EXPORT_PROCESSING);
        this.data = {date};

        this.setErrorMessage(404, I18nUtils.tr(TR_NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA))
    }

    protected getRequest(): TaskReportExportProcessingRequest {
        return this.data;
    }
}
