import Report from '../../commons/model/Report';
import DateFormatter from '../../utils/DateFormatter';
import Th from '../table/Th';
import I18nUtils from '../../commons/I18n/I18nUtils';
import {
    TR_ADVERADA,
    TR_ARTICULO_DE_LA_DENUNCIA,
    TR_COLOR,
    TR_CONTROLADOR,
    TR_ESTADO,
    TR_ESTADO_DE_ADVERACION,
    TR_ESTADO_DE_TRAMITE_DE_DENUNCIA,
    TR_FECHA_Y_HORA_DE_DENUNCIA,
    TR_MARCA,
    TR_MATRICULA,
    TR_MODELO,
    TR_NUMERO_DE_DENUNCIA,
    TR_PENDIENTE,
    TR_RATIFICADOR,
    TR_TIPO_DE_VEHICULO
} from '../../commons/I18n/constants';
import ActiveLabel from '../label/ActiveLabel';
import {CardBodyCheckbox} from '../../components-new/CardBodyCheckbox';
import {TableProps} from '../../components-new/typings';

const ths = [
    {text: I18nUtils.tr(TR_NUMERO_DE_DENUNCIA), columnName: 'number'},
    {text: I18nUtils.tr(TR_ESTADO), columnName: 'report_state'},
    {text: I18nUtils.tr(TR_ESTADO_DE_TRAMITE_DE_DENUNCIA), columnName: 'report_sent_to_process_state'},
    {text: I18nUtils.tr(TR_ESTADO_DE_ADVERACION), columnName: 'ratified'},
    {text: I18nUtils.tr(TR_FECHA_Y_HORA_DE_DENUNCIA), columnName: 'date'},
    {text: I18nUtils.tr(TR_MATRICULA), columnName: 'vehicle'},
    {text: I18nUtils.tr(TR_TIPO_DE_VEHICULO), columnName: 'vehicle_type'},
    {text: I18nUtils.tr(TR_MARCA), columnName: 'vehicle_brand'},
    {text: I18nUtils.tr(TR_MODELO), columnName: 'vehicle_model'},
    {text: I18nUtils.tr(TR_COLOR), columnName: 'vehicle_color'},
    {text: I18nUtils.tr(TR_ARTICULO_DE_LA_DENUNCIA), columnName: 'law'},
    {text: I18nUtils.tr(TR_CONTROLADOR), columnName: 'controller'},
    {text: I18nUtils.tr(TR_RATIFICADOR), columnName: 'ratifier'},
];

export const ReportListTable: React.FunctionComponent<TableProps> = (
    {
        data,
        sort,
        showCheckboxes,
        itemIdsSelected = [],
        itemIdsDisabled = [],
        itemIdsSelectedDisabled = [],
        onSort,
        onSelectItem,
        onSelectAllItems,
        onGoToItemDetails,
    }
) => {
    const showChecks =
        !!showCheckboxes && showCheckboxes &&
        onSelectItem &&
        onSelectAllItems &&
        onGoToItemDetails;

    const totalDisabledItems = itemIdsDisabled.length + itemIdsSelectedDisabled.length;
    const totalSelectedAndDisabledItems = totalDisabledItems + itemIdsSelected.length;
    const isAllDisabled = totalDisabledItems === data.length;
    const isAllSelected = itemIdsSelected.length > 0 && totalSelectedAndDisabledItems === data.length;

    const reportListTableTH = ths.map((th, index) =>
        <Th
            key={index}
            text={th.text}
            columnName={th.columnName}
            sort={sort}
            onSort={onSort}
        />
    );

    return (
        <table className="table table-hover table-font-small">
            <thead>
            <tr>
                {
                    showChecks ?
                        <CardBodyCheckbox
                            checked={isAllSelected}
                            disabled={isAllDisabled}
                            onChange={onSelectAllItems!}
                        /> : null
                }
                {reportListTableTH}
            </tr>
            </thead>
            <tfoot>
            <tr>
                {
                    showChecks ?
                        <CardBodyCheckbox
                            checked={isAllSelected}
                            disabled={isAllDisabled}
                            onChange={onSelectAllItems!}
                        /> : null
                }
                {reportListTableTH}
            </tr>
            </tfoot>
            <tbody>
            {
                data ? data.map((report: Report) => {
                    const {
                        id,
                        // tslint:disable-next-line:variable-name
                        number,
                        date,
                        report_state,
                        vehicle,
                        law,
                        workday,
                        ratified,
                        ratifier,
                        report_sent_to_process_state,
                    } = report;

                    const handleClick = onGoToItemDetails ? () => onGoToItemDetails(id) : undefined;
                    const isSelected = itemIdsSelected.includes(id);
                    const isDisabled = itemIdsDisabled.includes(id);
                    const isDisabledSelected = itemIdsSelectedDisabled.includes(id);

                    return (
                        <tr
                            key={id}
                            onClick={handleClick}
                            className={'tr-cursor'}
                        >
                            {
                                showChecks ?
                                    <CardBodyCheckbox
                                        checked={isSelected || isDisabledSelected}
                                        disabled={isDisabled || isDisabledSelected}
                                        onChange={() => onSelectItem!(id)}
                                    /> : null
                            }
                            <td>{number}</td>
                            <td>{report_state ? report_state.name : '-'}</td>
                            <td>{report_sent_to_process_state ? report_sent_to_process_state.name : '-'}</td>
                            <td>
                                <ActiveLabel
                                    text={ratified ? I18nUtils.tr(TR_ADVERADA) : I18nUtils.tr(TR_PENDIENTE)}
                                    active={ratified}
                                />
                            </td>
                            <td>{DateFormatter.formatDatetime(date)}</td>
                            <td>{vehicle ? vehicle.license_plate : '-'}</td>
                            <td>{vehicle && vehicle.vehicle_type ? vehicle.vehicle_type.name : '-'}</td>
                            <td>{vehicle && vehicle.vehicle_model && vehicle.vehicle_model.vehicle_brand ? vehicle.vehicle_model.vehicle_brand.name : '-'}</td>
                            <td>{vehicle && vehicle.vehicle_model ? vehicle.vehicle_model.name : '-'}</td>
                            <td>{vehicle && vehicle.vehicle_color ? vehicle.vehicle_color.name : '-'}</td>
                            <td>{law ? law.screen_text: '-'}</td>
                            <td>{workday && workday.user ? workday.user.name : '-'}</td>
                            <td>{ratifier ? ratifier.name : ''}</td>
                        </tr>
                    );
                }) : null
            }
            </tbody>
        </table>
    );
};
