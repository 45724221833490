import ScreenCard from '../../../../components/screen/ScreenCard';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {TR_DENUNCIAS_ENVIADAS} from '../../../../commons/I18n/constants';
import {ROUTE_COMPLAINT_VIEWER_SENT_REPORTS} from '../../../../routing/Routes';
import {AppIcon} from '../../../../config/Config';
import ReportListCompanyUserCard from "../../../companyUser/report/list/ReportListCompanyUserCard";
import React from 'react';

export default class ReportListComplaintViewerSentScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_DENUNCIAS_ENVIADAS),
                    url: ROUTE_COMPLAINT_VIEWER_SENT_REPORTS,
                    icon: AppIcon.REPORT
                }
            ]}>
                <ReportListCompanyUserCard/>
            </ScreenCard>
        );
    }
}
