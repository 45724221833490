import React from "react";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import {
    TR_BEEPARK,
    TR_CONTRASENA,
    TR_CONTRASENA_MOVILTIK,
    TR_CONTRASENA_OBLIGATORIA,
    TR_EASYPARK,
    TR_EMAILS_NOTIFICACION_GRUA,
    TR_EMAILS_SOS,
    TR_EXPLOTACION_ID,
    TR_EXPLOTACION_ID_OBLIGATORIO,
    TR_FORMATO_INCORRECTO_EMAILS,
    TR_INTEGRACION_CON_OTROS_SISTEMAS, TR_LEY_SIN_TICKET, TR_LEY_EXCESO_TICKET, TR_LEY_OBLIGATORIA,
    TR_MOVILTIK,
    TR_NO,
    TR_NOMBRE_DE_USUARIO, TR_NOMBRE_DE_USUARIO_OBLIGATORIO,
    TR_SI,
    TR_TICKET_LINE,
    TR_TOKEN,
    TR_TOKEN_OBLIGATORIO,
    TR_USUARIO_MOVILTIK,
    TR_USUARIO_OBLIGATORIO,
    TR_VEHICULO_EMBARCADO
} from "../../../../../commons/I18n/constants";
import Row from "../../../../../components/Row";
import {Field, FormErrors, InjectedFormProps} from "redux-form";
import {connect} from "react-redux";
import FormSwitch from "../../../../../components/form/FormSwitch";
import {formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import Exploitation from "../../../../../commons/model/Exploitation";
import FormInput from "../../../../../components/form/FormInput";
import {isEmailValid} from "../../../../../utils/StringUtils";
import Law from "../../../../../commons/model/Law";
import FormInputOption from "../../../../../components/form/FormInputOption";
import FormSelect from "../../../../../components/form/FormSelect";

const FORM_NAME = 'ExploitationCardFormAdmin';

export interface ExploitationConnectionAppSectionAdminData {
    connectionEasypark: boolean,
    connectionMoviltik: boolean
    moviltikUsername?: string,
    moviltikPassword?: string,
    notifyCraneEmails: string,
    sosEmails: string,
    connectionBeepark: boolean,
    beeparkApiToken: string,
    beeparkExploitationId: string,

    connectionDeltaCar: boolean,
    deltaCarToken: string,
    connectionTicketLine: boolean,
    ticketLineUsername: string,
    ticketLinePassword: string,
    deltaCarLawId: string,
    timeExcessLawId: string,
}

interface ExploitationConnectionnAppSectionProps {
    className?: string,
    invalid: boolean,
    existErrorsHandler: (isValid: boolean) => void,
    laws: Law[],
    editMode: boolean,
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationConnectionAppSectionAdminData>(FORM_NAME);

const mapStateToProps = mapFormStateToProps;

type Props =
    ExploitationConnectionnAppSectionProps
    & typeof mapStateToProps
    & InjectedFormProps<ExploitationConnectionAppSectionAdminData>;

interface State {
    collapsed: boolean,
}

export enum ExploitationConnectionAppSectionFields {
    CONNECTION_EASYPARK = "connectionEasypark",
    CONNECTION_MOVILTIK = "connectionMoviltik",
    MOVILTIK_USERNAME = "moviltikUsername",
    MOVILTIK_PASSWORD = "moviltikPassword",
    NOTIFY_CRANE_EMAILS = "notifyCraneEmails",
    SOS_EMAILS = "sosEmails",
    CONNECTION_BEEPARK = "connectionBeepark",
    BEEPARK_API_TOKEN = "beeparkApiToken",
    BEEPARK_EXPLOITATION_ID = "beeparkExploitationId",
    CONNECTION_DELTA_CAR = "connectionDeltaCar",
    DELTA_CAR_TOKEN = "deltaCarToken",
    CONNECTION_TICKET_LINE = "connectionTicketLine",
    TICKET_LINE_USERNAME = "ticketLineUsername",
    TICKET_LINE_PASSWORD = "ticketLinePassword",
    DELTA_CAR_LAW_ID = "deltaCarLawId",
    TIME_EXCESS_LAW_ID = "timeExcessLawId",
}

class ExploitationConnectionAppSectionAdmin extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    private onCollapsedCard = () => {
        this.setState({collapsed: !this.state.collapsed})
    };

    public render(): React.ReactNode {
        const {invalid, className, valid, existErrorsHandler, formState, laws, editMode} = this.props;
        const {collapsed} = this.state;

        const moviltikEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_MOVILTIK];
        const connectionBeeparkEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_BEEPARK];
        const deltaCarEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_DELTA_CAR];
        const ticketLineEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_TICKET_LINE];

        const lawOptions: FormInputOption[] = laws.map((law) => ({
            value: law.id,
            name: `${law.law_type.name} ${law.municipal_code}`
        }));

        existErrorsHandler(valid);

        return (
            <CardCollapsed title={I18nUtils.tr(TR_INTEGRACION_CON_OTROS_SISTEMAS)}
                           collapsedHandler={this.onCollapsedCard}
                           collapsed={collapsed}
                           invalid={invalid}
                           className={className}
            >
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_MOVILTIK)}
                        labelEnabled={I18nUtils.tr(TR_SI)}
                        labelDisabled={I18nUtils.tr(TR_NO)}
                        name={ExploitationConnectionAppSectionFields.CONNECTION_MOVILTIK}
                        component={FormSwitch}
                        col={{md: 3, lg: 3}}
                    />
                    {moviltikEnabled &&
                    <>
                        <Field
                            label={I18nUtils.tr(TR_USUARIO_MOVILTIK)}
                            name={ExploitationConnectionAppSectionFields.MOVILTIK_USERNAME}
                            component={FormInput}
                            col={{md: 3, lg: 3}}
                        />
                        <Field
                            label={I18nUtils.tr(TR_CONTRASENA_MOVILTIK)}
                            name={ExploitationConnectionAppSectionFields.MOVILTIK_PASSWORD}
                            component={FormInput}
                            col={{md: 3, lg: 3}}
                        />
                    </>
                    }
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_BEEPARK)}
                        labelEnabled={I18nUtils.tr(TR_SI)}
                        labelDisabled={I18nUtils.tr(TR_NO)}
                        name={ExploitationConnectionAppSectionFields.CONNECTION_BEEPARK}
                        component={FormSwitch}
                        col={{md: 3, lg: 3}}
                    />
                    {connectionBeeparkEnabled &&
                    <>
                        <Field
                            label={I18nUtils.tr(TR_TOKEN)}
                            name={ExploitationConnectionAppSectionFields.BEEPARK_API_TOKEN}
                            component={FormInput}
                            col={{md: 3, lg: 3}}
                        />
                        <Field
                            label={I18nUtils.tr(TR_EXPLOTACION_ID)}
                            name={ExploitationConnectionAppSectionFields.BEEPARK_EXPLOITATION_ID}
                            component={FormInput}
                            col={{md: 3, lg: 3}}
                        />
                    </>
                    }
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_EASYPARK)}
                        labelEnabled={I18nUtils.tr(TR_SI)}
                        labelDisabled={I18nUtils.tr(TR_NO)}
                        name={ExploitationConnectionAppSectionFields.CONNECTION_EASYPARK}
                        component={FormSwitch}
                        col={{md: 3, lg: 3}}
                    />
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_EMAILS_NOTIFICACION_GRUA)}
                        name={ExploitationConnectionAppSectionFields.NOTIFY_CRANE_EMAILS}
                        component={FormInput}
                        col={{md: 9, lg: 9}}
                    />
                    <Field
                        label={I18nUtils.tr(TR_EMAILS_SOS)}
                        name={ExploitationConnectionAppSectionFields.SOS_EMAILS}
                        component={FormInput}
                        col={{md: 9, lg: 9}}
                    />
                </Row>

                {editMode && <Row>
                    <Field
                        label={I18nUtils.tr(TR_VEHICULO_EMBARCADO)}
                        labelEnabled={I18nUtils.tr(TR_SI)}
                        labelDisabled={I18nUtils.tr(TR_NO)}
                        name={ExploitationConnectionAppSectionFields.CONNECTION_DELTA_CAR}
                        component={FormSwitch}
                        col={{md: 3, lg: 3}}
                    />
                    {deltaCarEnabled &&
                    <>
                        <Field
                            label={I18nUtils.tr(TR_TOKEN)}
                            name={ExploitationConnectionAppSectionFields.DELTA_CAR_TOKEN}
                            component={FormInput}
                            col={{md: 8, lg: 8}}
                        />
                        <Field
                            label={I18nUtils.tr(TR_LEY_SIN_TICKET)}
                            name={ExploitationConnectionAppSectionFields.DELTA_CAR_LAW_ID}
                            component={FormSelect}
                            options={lawOptions}
                            col={{md: 4, lg: 4}}
                        />
                        <Field
                            label={I18nUtils.tr(TR_LEY_EXCESO_TICKET)}
                            name={ExploitationConnectionAppSectionFields.TIME_EXCESS_LAW_ID}
                            component={FormSelect}
                            options={lawOptions}
                            col={{md: 4, lg: 4}}
                        />
                    </>
                    }
                </Row>}
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_TICKET_LINE)}
                        labelEnabled={I18nUtils.tr(TR_SI)}
                        labelDisabled={I18nUtils.tr(TR_NO)}
                        name={ExploitationConnectionAppSectionFields.CONNECTION_TICKET_LINE}
                        component={FormSwitch}
                        col={{md: 3, lg: 3}}
                    />
                    {ticketLineEnabled &&
                    <>
                        <Field
                            label={I18nUtils.tr(TR_NOMBRE_DE_USUARIO)}
                            name={ExploitationConnectionAppSectionFields.TICKET_LINE_USERNAME}
                            component={FormInput}
                            col={{md: 4, lg: 4}}
                        />
                        <Field
                            label={I18nUtils.tr(TR_CONTRASENA)}
                            name={ExploitationConnectionAppSectionFields.TICKET_LINE_PASSWORD}
                            component={FormInput}
                            type={"password"}
                            col={{md: 3, lg: 3}}
                        />
                    </>
                    }
                </Row>
            </CardCollapsed>
        )
    }
}

// @ts-ignore
export default connect(mapStateToProps)(ExploitationConnectionAppSectionAdmin) as React.ComponentType<ExploitationConnectionnAppSectionProps>;

export class ExploitationConnectionAppSectionAdminHelpers extends BaseFormFieldsHelpers {

    public static validate(values: ExploitationConnectionAppSectionAdminData) {
        const errors: FormErrors<ExploitationConnectionAppSectionAdminData> = {};

        if (values.connectionMoviltik) {
            if (!values.moviltikUsername || values.moviltikUsername.length === 0) {
                errors.moviltikUsername = I18nUtils.tr(TR_USUARIO_OBLIGATORIO);
            }
            if (!values.moviltikPassword || values.moviltikPassword.length === 0) {
                errors.moviltikPassword = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA);
            }
        }
        if (values.notifyCraneEmails && values.notifyCraneEmails.length > 0) {
            const emails = values.notifyCraneEmails.replace(/\s/g, '').split(',');
            for (const email of emails) {
                if (!isEmailValid(email)) {
                    errors.notifyCraneEmails = I18nUtils.tr(TR_FORMATO_INCORRECTO_EMAILS);
                    break;
                }
            }

        }
        if (values.sosEmails && values.sosEmails.length > 0) {
            const emails = values.sosEmails.replace(/\s/g, '').split(',');
            for (const email of emails) {
                if (!isEmailValid(email)) {
                    errors.sosEmails = I18nUtils.tr(TR_FORMATO_INCORRECTO_EMAILS);
                    break;
                }
            }
        }
        if (values.connectionBeepark) {
            if (!values.beeparkApiToken || values.beeparkApiToken.length === 0) {
                errors.beeparkApiToken = I18nUtils.tr(TR_TOKEN_OBLIGATORIO);
            }
            if (!values.beeparkExploitationId || values.beeparkExploitationId.length === 0) {
                errors.beeparkExploitationId = I18nUtils.tr(TR_EXPLOTACION_ID_OBLIGATORIO);
            }
        }
        if (values.connectionDeltaCar) {
            if (!values.deltaCarToken || values.deltaCarToken.length === 0) {
                errors.deltaCarToken = I18nUtils.tr(TR_TOKEN_OBLIGATORIO);
            }
            if (!values.deltaCarLawId || values.deltaCarLawId === '-1') {
                errors.deltaCarLawId = I18nUtils.tr(TR_LEY_OBLIGATORIA)
            }
            if (!values.timeExcessLawId || values.timeExcessLawId === '-1') {
                errors.timeExcessLawId = I18nUtils.tr(TR_LEY_OBLIGATORIA)
            }
        }
        if (values.connectionTicketLine) {
            if (!values.ticketLineUsername || values.ticketLineUsername.length === 0) {
                errors.ticketLineUsername = I18nUtils.tr(TR_NOMBRE_DE_USUARIO_OBLIGATORIO);
            }
            if (!values.ticketLinePassword || values.ticketLinePassword.length === 0) {
                errors.ticketLinePassword = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA);
            }
        }

        return errors;
    }

    public static getFormData(formData: Partial<Exploitation>): ExploitationConnectionAppSectionAdminData {
        return {
            connectionEasypark: formData.connection_easypark ? formData.connection_easypark : false,
            connectionMoviltik: formData.connection_moviltik ? formData.connection_moviltik : false,
            moviltikUsername: formData.moviltik_username || '',
            moviltikPassword: formData.moviltik_password || '',
            notifyCraneEmails: formData.notify_crane_emails || '',
            sosEmails: formData.sos_emails || '',
            connectionBeepark: formData.connection_beepark ? formData.connection_beepark : false,
            beeparkApiToken: formData.beepark_api_token || '',
            beeparkExploitationId: formData.beepark_exploitation_id || '',
            connectionDeltaCar: formData.connection_delta_car ? formData.connection_delta_car : false,
            deltaCarToken: formData.delta_car_token || '',
            connectionTicketLine: formData.connection_ticket_line ? formData.connection_ticket_line : false,
            ticketLineUsername: formData.ticket_line_username || '',
            ticketLinePassword: formData.ticket_line_password || '',
            deltaCarLawId: formData.delta_car_law_id || '',
            timeExcessLawId: formData.time_excess_law_id || '',
        }
    }
}
