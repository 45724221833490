import { OnSortHandler, SortState } from '../../../../utils/Sort';
import { generateRoute } from '../../../../commons/utils/Router';
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL } from '../../../../routing/Routes';
import Th from '../../../../components/table/Th';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { TR_NOMBRE } from '../../../../commons/I18n/constants';
import ReportInvalidationReason from '../../../../commons/model/ReportInvalidationReason';
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

interface UserListTableProps {
    data: ReportInvalidationReason[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ReportInvalidationReasonAdminListTable extends React.Component<UserListTableProps> {

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                    {
                        data && data.map((reportInvalidationReason: ReportInvalidationReason) => {
                            const {id, name} = reportInvalidationReason;

                            return (
                                <tr
                                    key={id}
                                    onClick={() => this.goToItemDetail(id)}
                                    className={'tr-cursor'}
                                >
                                    {
                                        renderSelectTd ? renderSelectTd(reportInvalidationReason.id) : null
                                    }
                                    <td>{name}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );
    }

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL, {id: itemId}));
    };

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th
                    text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
            </tr>
        );
    }
}
