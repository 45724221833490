import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_LEYES} from "../../../../commons/I18n/constants";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import LawTypeListCardAdmin from "./LawTypeListCardAdmin";
import {ROUTE_ADMIN_LAW_TYPES} from "../../../../routing/Routes";
import React from 'react';

export default class LawTypeListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LEYES),
                    url: ROUTE_ADMIN_LAW_TYPES,
                    icon: AppIcon.LAW
                }
            ]}>
                <LawTypeListCardAdmin/>
            </ScreenCard>
        )
    }
}
