import {RouteComponentProps} from "react-router";
import {loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_PLAZA_PERDIDA, TR_PLAZAS_PERDIDAS} from "../../../../commons/I18n/constants";
import {
    ROUTE_COMPANY_USER_PARKING_LOSSES,
    ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import DateFormatter from "../../../../utils/DateFormatter";
import {generateRoute} from "../../../../commons/utils/Router";
import ParkingLossCardFormCompanyUser from "../formdetail/ParkingLossCardFormCompanyUser";
import ParkingLossFormScreenCompanyUserReducer from "./ParkingLossFormScreenCompanyUserReducer";
import ParkingLossFormCompanyUserScreenActions from "./ParkingLossFormCompanyUserScreenActions";
import TaskParkingLossDetail from "../../../../commons/ws/parkingLoss/TaskParkingLossDetail";
import {ParkingLossCardFormCompanyUserData} from "../formdetail/ParkingLossCardFormCompanyUser";
import React from 'react';

const mapStateToProps = ParkingLossFormScreenCompanyUserReducer.autoMapToProps();

const mapActionsToProps = ParkingLossFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class ParkingLossFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskParkingLossDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const parkingLossId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_PLAZAS_PERDIDAS),
            url: ROUTE_COMPANY_USER_PARKING_LOSSES,
            icon: AppIcon.PARKING
        }];

        if (parkingLossId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_PLAZA_PERDIDA),
                url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, {id: parkingLossId}),
                icon: AppIcon.PARKING
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderParkingMeterForm()}
            </ScreenCard>
        )
    }

    private renderParkingMeterForm(): React.ReactNode {
        const {parkingLossLoading, parkingLoss} = this.props;

        let initialValues: ParkingLossCardFormCompanyUserData | undefined;

        if (parkingLoss) {
            initialValues = {
                date: parkingLoss.date ? DateFormatter.formatDatetime(parkingLoss.date) : '',
                places: parkingLoss.places,
                occupied_hours: parkingLoss.occupied_hours,
                street: parkingLoss.street ? parkingLoss.street.name : '',
                parking_loss_reason: parkingLoss.parking_loss_reason ? parkingLoss.parking_loss_reason.name : '',
                controller: parkingLoss.controller ?
                    `${parkingLoss.controller.name} ${parkingLoss.controller.last_name}` : '',
                observations: parkingLoss.observations ? parkingLoss.observations : '',
            }
        }
        return (
            <ParkingLossCardFormCompanyUser
                initialValues={initialValues}
                parentLoading={parkingLossLoading}
            />
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(ParkingLossFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
