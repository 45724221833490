import {connect} from "react-redux";
import ChatBroadcastCompanyUserActions from "./ChatBroadcastCompanyUserActions";
import ChatBroadcastCompanyUserReducer from "./ChatBroadcastCompanyUserReducer";
import {TR_MENSAJES_A_TODOS, TR_NO_EXISTEN_MENSAJES} from "../../../../../commons/I18n/constants";
import TaskChatBroadcastList from "../../../../../commons/ws/chat/TaskChatBroadcastList";
import {ROUTE_COMPANY_USER_BROADCAST_ADD} from "../../../../../routing/Routes";
import ChatBroadcastListTable from "./ChatBroadcastListTable";
import I18nUtils from "../../../../../commons/I18n/I18nUtils";
import CardList from "../../../../../components/card/CardList";
import React from 'react';

const mapActionsToProps = ChatBroadcastCompanyUserActions.autoMapToProps();
const mapStateToProps = ChatBroadcastCompanyUserReducer.autoMapToProps();

class ChatBroadcastCardCompanyUser extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, broadcastList, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_MENSAJES_A_TODOS)}
                      sort={{column: 'expiration_date'}}
                      TaskList={TaskChatBroadcastList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_MENSAJES)}}
                      data={broadcastList}
                      error={error}
                      ItemsTable={ChatBroadcastListTable}
                      addItem={ROUTE_COMPANY_USER_BROADCAST_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ChatBroadcastCardCompanyUser as unknown as React.ComponentType<void>);
