import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../../../components/card/Card";
import CardHeader from "../../../../../../components/card/CardHeader";
import I18nUtils from "../../../../../../commons/I18n/I18nUtils";
import CardBody from "../../../../../../components/card/CardBody";
import Row from "../../../../../../components/Row";
import {
    TR_ANULACION_DENUNCIAS_DE_FICHERO,
    TR_ANULAR,
    TR_FICHERO_CSV, TR_FICHERO_CSV_OBLIGATORIO, TR_SOLO_ADMITE_UN_FICHERO,
} from "../../../../../../commons/I18n/constants";
import FormInputFile from "../../../../../../components/form/FormInputFile";
import Col from "../../../../../../components/Col";
import FormSubmit from "../../../../../../components/form/FormSubmit";
import React from 'react';

const FORM_NAME = 'ReportCancellationByFileCardForm';

export interface ReportCancellationByFileCardFormData {
    csv_file: File[],
}

interface ReportCancellationByFileCardFormExtProps {
    parkingMeterCollectionId?: string,
    initialValues?: Partial<ReportCancellationByFileCardFormData>,
    parentLoading: boolean,
    onSubmit: (data: ReportCancellationByFileCardFormData) => void,
}

type Props = ReportCancellationByFileCardFormExtProps & InjectedFormProps<ReportCancellationByFileCardFormData>;

enum ReportCancellationByFileCardFormFields {
    CSV_FILE = "csv_file",
}

class ReportCancellationByFileCardForm extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, invalid, parentLoading, pristine, onSubmit
        } = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader
                    title={I18nUtils.tr(TR_ANULACION_DENUNCIAS_DE_FICHERO)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_FICHERO_CSV)}
                                name={ReportCancellationByFileCardFormFields.CSV_FILE}
                                component={FormInputFile}
                                accept={'text/csv'}
                                col={{md: 12, lg: 12}}
                            />
                        </Row>
                        <Row>
                            <Col sm={8} md={9} lg={10} />
                            <Field
                                label={I18nUtils.tr(TR_ANULAR)}
                                name="submit"
                                component={FormSubmit}
                                col={{sm: 4, md: 3, lg: 2}}
                                disabled={invalid || pristine}
                            />
                        </Row>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: ReportCancellationByFileCardFormData) {
    const errors: FormErrors<ReportCancellationByFileCardFormData> = {};

    if (!values.csv_file || values.csv_file.length === 0) {
        errors.csv_file = I18nUtils.tr(TR_FICHERO_CSV_OBLIGATORIO);
    } else if (values.csv_file.length > 1) {
        errors.csv_file = I18nUtils.tr(TR_SOLO_ADMITE_UN_FICHERO);
    } else if (values.csv_file[0] && !values.csv_file[0].name.endsWith(".csv")) {
        console.warn('TYPE', values.csv_file[0] ? values.csv_file[0].type : 'NOT FILE');
        console.warn('TYPE', values.csv_file[0] ? values.csv_file[0] : '');
        errors.csv_file = I18nUtils.tr(TR_FICHERO_CSV_OBLIGATORIO);
    }
    return errors;
}

export default reduxForm<ReportCancellationByFileCardFormData, ReportCancellationByFileCardFormExtProps>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
})(ReportCancellationByFileCardForm as any) as React.ComponentType<ReportCancellationByFileCardFormExtProps>;
