import React, {ReactNode} from 'react';
import imgDefaultUser from '../../../../res/img/default-user.png';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_ADMINISTRACION,
    TR_CHAT,
    TR_CONFIGURACION_APP,
    TR_FIRMA_DIGITAL,
    TR_GESTION,
    TR_INICIO,
    TR_MENU,
    TR_PAVAPARK,
    TR_SALIR,
    TR_TABLAS_MAESTRAS
} from '../../../../commons/I18n/constants';
import Icon from '../../../../components/Icon';
import DropDown, {DropDownOption} from '../../../../components/dropdown/DropDown';
import LogoutModal from '../../LogoutModal';
import MenuItem, {SubItems} from './MenuItem';
import {
    ROUTE_ADMIN_SETTINGS_APP, ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
    // ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_USER_CERT
} from '../../../../routing/Routes';
import {matchPath, RouteComponentProps, withRouter} from 'react-router';
import Config, {AppIcon} from '../../../../config/Config';
import User from '../../../../commons/model/User';
import {UserRoleValue} from '../../../../commons/model/UserRole';
import {sidebarsItems} from './SideBarItems';
import AuthManager from '../../../../utils/AuthManager';
import {goToRoute} from '../../../../utils/Router';

interface SideBarProps {
    user: User;
    isAdmin: boolean,
}

interface State {
    showLogoutModal: boolean,
    expandedId: string
}

type Props = RouteComponentProps & SideBarProps;

class SideBar extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLogoutModal: false,
            expandedId: ''
        };
    }

    public render(): ReactNode {
        if (!this.props.user) {
            return null;
        }

        const userOptions: DropDownOption[] = [];
        if (this.props.user && this.props.user.exploitation && this.props.user.exploitation.digital_sign) {
            userOptions.push({
                text: I18nUtils.tr(TR_FIRMA_DIGITAL),
                icon: AppIcon.DIGITAL_SIGN,
                onClick: () => goToRoute(ROUTE_USER_CERT),
            });
        }
        userOptions.push({
            text: I18nUtils.tr(TR_SALIR),
            icon: AppIcon.EXIT_ACCOUNT,
            onClick: this.onOpenLogOutModal,
        });

        const userHasRatificationSection = this.props.user && this.props.user.exploitation && this.props.user.exploitation.ratification;

        const {admin, companyUser, complaintViewerSent, complaintViewer, police} = sidebarsItems;

        const companyUserManagementSubSections: SubItems[] = [];
        companyUser.management.forEach((subItem) => {
            if (subItem.route !== ROUTE_COMPANY_USER_RATIFICATION_REPORTS || (
                    subItem.route === ROUTE_COMPANY_USER_RATIFICATION_REPORTS && userHasRatificationSection)) {
                companyUserManagementSubSections.push(subItem)
                }
        });
        const companyUserSections = {
            ...companyUser,
            management: companyUserManagementSubSections,
        };

        const adminAdministrationRoutes: string[] = [];
        admin.administration.forEach((subItem) => adminAdministrationRoutes.splice(-1, 0, ...subItem.routes));

        const companyUserAdministrationRoutes: string[] = [];
        companyUserSections.administration.forEach((subItem) => companyUserAdministrationRoutes.splice(-1, 0, ...subItem.routes));

        const companyUserMasterTablesRoutes: string[] = [];
        companyUserSections.masterTables.forEach((subItem) => companyUserMasterTablesRoutes.splice(-1, 0, ...subItem.routes));

        const companyUserManagementRoutes: string[] = [];
        companyUserSections.management.forEach((subItem) => companyUserManagementRoutes.splice(-1, 0, ...subItem.routes));

        const companyUserChatRoutes: string[] = [];
        companyUserSections.chat.forEach((subItem) => companyUserChatRoutes.splice(-1, 0, ...subItem.routes));

        const complaintViewerSentRoutes: string[] = [];
        complaintViewerSent.management.forEach((subItem) => complaintViewerSentRoutes.splice(-1, 0, ...subItem.routes));

        const complaintViewerRoutes: string[] = [];
        complaintViewer.management.forEach((subItem) => complaintViewerRoutes.splice(-1, 0, ...subItem.routes));

        const policeRoutes: string[] = [];
        police.management.forEach((subItem) => policeRoutes.splice(-1, 0, ...subItem.routes));

        const isCompanyUser = this.props.user.roles.map((role) => role.id).includes(UserRoleValue.COMPANY_USER);
        const isComplaintViewerSent = this.props.user.roles.map((role) => role.id).includes(UserRoleValue.COMPLAINT_VIEWER_SENT);
        const isComplaintViewer = this.props.user.roles.map((role) => role.id).includes(UserRoleValue.COMPLAINT_VIEWER);
        const isPolice = this.props.user.roles.map((role) => role.id).includes(UserRoleValue.POLICE);

        return (
            <aside className="sidebar">
                <div className="user-info">
                    <div className="image">
                        <img src={imgDefaultUser} width="48" height="48" alt="User"/>
                    </div>
                    <div className="info-container">
                        <div className="name" data-toggle="dropdown" aria-haspopup="true"
                             aria-expanded="false">{this.props.user.name} {this.props.user.last_name}
                        </div>
                        <div className="email">{this.props.user.email}</div>
                        <DropDown className={'user-helper-dropdown'} options={userOptions} pullRight={true}>
                            <Icon icon={AppIcon.ARROW_DOWN_DROPDOWN}/>
                        </DropDown>
                    </div>
                </div>
                <div className="menu">
                    <ul className="list">
                        <li className="header">{I18nUtils.tr(TR_MENU).toUpperCase()}</li>
                        <MenuItem
                            title={I18nUtils.tr(TR_INICIO)}
                            icon={AppIcon.HOME}
                            active={this.isActive([ROUTE_HOME])}
                            route={ROUTE_HOME}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_CHAT)}
                            icon={AppIcon.CHAT}
                            active={this.isActive(companyUserChatRoutes)}
                            expanded={this.isActive(companyUserChatRoutes) || this.state.expandedId === TR_CHAT}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_CHAT : ''})}
                            subItems={companyUserSections.chat}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(companyUserSections.chat)}
                            visible={isCompanyUser}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_CONFIGURACION_APP)}
                            icon={AppIcon.SETTINGS}
                            active={this.isActive([ROUTE_ADMIN_SETTINGS_APP])}
                            route={ROUTE_ADMIN_SETTINGS_APP}
                            visible={this.props.isAdmin}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_ADMINISTRACION)}
                            icon={AppIcon.ADMIN}
                            active={this.isActive(adminAdministrationRoutes)}
                            expanded={this.isActive(adminAdministrationRoutes) || this.state.expandedId === TR_ADMINISTRACION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_ADMINISTRACION : ''})}
                            subItems={admin.administration}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(admin.administration)}
                            visible={this.props.isAdmin}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_ADMINISTRACION)}
                            icon={AppIcon.ADMIN}
                            active={this.isActive(companyUserAdministrationRoutes)}
                            expanded={this.isActive(companyUserAdministrationRoutes) || this.state.expandedId === TR_ADMINISTRACION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_ADMINISTRACION : ''})}
                            subItems={companyUserSections.administration}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(companyUserSections.administration)}
                            visible={isCompanyUser}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_TABLAS_MAESTRAS)}
                            icon={AppIcon.MASTER_TABLE}
                            active={this.isActive(companyUserMasterTablesRoutes)}
                            expanded={this.isActive(companyUserMasterTablesRoutes) || this.state.expandedId === TR_TABLAS_MAESTRAS}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_TABLAS_MAESTRAS : ''})}
                            subItems={companyUserSections.masterTables}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(companyUserSections.masterTables)}
                            visible={isCompanyUser}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_GESTION)}
                            icon={AppIcon.FOLDER}
                            active={this.isActive(companyUserManagementRoutes)}
                            expanded={this.isActive(companyUserManagementRoutes) || this.state.expandedId === TR_GESTION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_GESTION : ''})}
                            subItems={companyUserSections.management}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(companyUserSections.management)}
                            visible={isCompanyUser}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_GESTION)}
                            icon={AppIcon.FOLDER}
                            active={this.isActive(complaintViewerSentRoutes)}
                            expanded={this.isActive(complaintViewerSentRoutes) || this.state.expandedId === TR_GESTION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_GESTION : ''})}
                            subItems={complaintViewerSent.management}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(complaintViewerSent.management)}
                            visible={isComplaintViewerSent}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_GESTION)}
                            icon={AppIcon.FOLDER}
                            active={this.isActive(complaintViewerRoutes)}
                            expanded={this.isActive(complaintViewerRoutes) || this.state.expandedId === TR_GESTION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_GESTION : ''})}
                            subItems={complaintViewer.management}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(complaintViewer.management)}
                            visible={isComplaintViewer}
                        />
                        <MenuItem
                            title={I18nUtils.tr(TR_GESTION)}
                            icon={AppIcon.FOLDER}
                            active={this.isActive(policeRoutes)}
                            expanded={this.isActive(policeRoutes) || this.state.expandedId === TR_GESTION}
                            onExpand={(expanded: boolean) => this.setState({expandedId: expanded ? TR_GESTION : ''})}
                            subItems={police.management}
                            selectedSubItemIndex={this.getSelectedSubItemIndex(police.management)}
                            visible={isPolice}
                        />
                    </ul>
                </div>
                <div className="legal">
                    <div className="copyright">
                        <a href="http://www.pavapark.com/">&copy; 2019 {I18nUtils.tr(TR_PAVAPARK)}</a>
                    </div>
                    <div children={`v ${Config.VERSION}`}/>
                </div>
                <LogoutModal
                    show={this.state.showLogoutModal}
                    onClose={this.onCloseLogOutModal}
                    onLogout={this.doLogout}
                />
            </aside>
        );
    }

    private doLogout = (): void => {
        AuthManager.logout();
        goToRoute(ROUTE_LOGIN);
        // recargamos por temas de seguridad
        window.location.reload();
    };

    private isActive(routes: string[]): boolean {
        const {pathname} = this.props.location;
        let active = false;
        routes.forEach((route: string) => {
            const match = matchPath(pathname, route);
            if (match) {
                active = pathname === match.url;
            }
        });
        return active;
    }

    private getSelectedSubItemIndex(section: SubItems[]): number {
        const {pathname} = this.props.location;
        const splitPathName = pathname.split('/');
        const isEditOrDetailPath = splitPathName[splitPathName.length - 2] === ('edit' || 'detail');
        const includedPathName = isEditOrDetailPath ? splitPathName.slice(0, splitPathName.length - 1).join('/') + '/:id' : pathname;

        return section.findIndex((subItem) => subItem.routes.includes(includedPathName));
    }

    private onOpenLogOutModal = (): void => {
        this.setState({showLogoutModal: true});
    };

    private onCloseLogOutModal = (): void => {
        this.setState({showLogoutModal: false});
    };
}

export default withRouter(SideBar);
