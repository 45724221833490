
import {ReactNode} from 'react';
import {Button, Modal} from "react-bootstrap";
import I18nUtils from "../../commons/I18n/I18nUtils";
import {TR_CANCELAR, TR_CERRAR_SESSION, TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION} from "../../commons/I18n/constants";
import BaseModal, {BaseModalProps} from "../modal/BaseModal";

export interface HomeModalProps extends BaseModalProps {
    onLogout: () => void,
}

export default class LogoutModal extends BaseModal<HomeModalProps> {

    public render(): ReactNode {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{I18nUtils.tr(TR_CERRAR_SESSION)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{I18nUtils.tr(TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION)}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClose}>{I18nUtils.tr(TR_CANCELAR).toUpperCase()}</Button>
                    <Button bsStyle="danger" onClick={this.props.onLogout}>{I18nUtils.tr(TR_CERRAR_SESSION).toUpperCase()}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

