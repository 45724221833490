
import { connect } from 'react-redux';
import CardList from '../../../../components/card/CardList';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_ELIMINAR_RAZONES,
    TR_NO_EXISTEN_RAZONES,
    TR_RAZONES_DE_INVALIDACION
} from '../../../../commons/I18n/constants';
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD } from '../../../../routing/Routes';
import ReportInvalidationReasonAdminListActions from './ReportInvalidationReasonAdminListActions';
import ReportInvalidationReasonAdminListReducer from './ReportInvalidationReasonAdminListReducer';
import ReportInvalidationReasonAdminListTable from './ReportInvalidationReasonAdminListTable';
import TaskReportInvalidationReasonList from '../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList';
import TaskReportInvalidationReasonDelete from '../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonDelete';
import React from 'react';

const mapActionsToProps = ReportInvalidationReasonAdminListActions.autoMapToProps();
const mapStateToProps = ReportInvalidationReasonAdminListReducer.autoMapToProps();

class ReportInvalidationReasonAdminListCard extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, reportInvalidationReasons, error, pager} = this.props;

        return (
            <CardList
                loading={loading}
                pager={pager}
                title={I18nUtils.tr(TR_RAZONES_DE_INVALIDACION)}
                sort={{column: 'name'}}
                TaskList={TaskReportInvalidationReasonList}
                emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_RAZONES)}}
                data={reportInvalidationReasons}
                error={error}
                ItemsTable={ReportInvalidationReasonAdminListTable}
                addItem={ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD}
                deleteOption={{
                    title: I18nUtils.tr(TR_ELIMINAR_RAZONES),
                    Task: TaskReportInvalidationReasonDelete,
                }}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportInvalidationReasonAdminListCard as unknown as React.ComponentType<void>);
