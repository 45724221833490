import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_CODIGO_ALZIRA,
    TR_COLOR_DE_VEHICULO,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_COLOR_DE_VEHICULO
} from "../../../../commons/I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_VEHICLE_COLORS} from "../../../../routing/Routes";
import {compose} from "redux";
import {connect} from "react-redux";
import VehicleColorFormScreenAdminReducer from "../formscreen/VehicleColorFormScreenAdminReducer";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const FORM_NAME = 'VehicleColorFormAdmin';

export interface VehicleColorAdminData {
    name: string,
    alziraCode: string,
}

interface VehicleColorCardFormAdminProps {
    routeId?: string,
    readonly?: boolean,
    onSubmit: (data: VehicleColorAdminData) => void,
    initialValues?: Partial<VehicleColorCardFormAdminProps>,
    parentLoading?: boolean,
}

const mapStateToProps = VehicleColorFormScreenAdminReducer.autoMapToProps();

type Props =
    VehicleColorCardFormAdminProps &
    typeof mapStateToProps
    & InjectedFormProps<VehicleColorAdminData>

enum VehicleColorFormAdminFields {
    NAME = "name",
    ALZIRA_CODE = "alziraCode",
}

class VehicleColorCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, vehicleColorLoading, pristine, parentLoading
        } = this.props;

        return (
            <Card loading={parentLoading || vehicleColorLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_COLOR_DE_VEHICULO : TR_NUEVO_COLOR_DE_VEHICULO)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={VehicleColorFormAdminFields.NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_CODIGO_ALZIRA)}
                                name={VehicleColorFormAdminFields.ALZIRA_CODE}
                                component={FormInput}
                                col={{md: 2, lg: 1}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS)}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: VehicleColorAdminData) {
    const errors: FormErrors<VehicleColorAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<VehicleColorAdminData, VehicleColorCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(VehicleColorCardFormAdmin);
