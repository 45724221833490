import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ARTICULO,
    TR_ARTICULO_CREADO_CORRECTAMENTE,
    TR_ARTICULO_MODIFICADO_CORRECTAMENTE,
    TR_ARTICULOS,
    TR_NUEVO_ARTICULO
} from "../../../../commons/I18n/constants";
import {
    ROUTE_COMPANY_USER_LAW_ADD,
    ROUTE_COMPANY_USER_LAW_DETAIL,
    ROUTE_COMPANY_USER_LAWS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import LawFormScreenCompanyUserReducer from "./LawFormScreenCompanyUserReducer";
import LawFormCompanyUserScreenActions from "./LawFormCompanyUserScreenActions";
import TaskLawDetail from "../../../../commons/ws/law/TaskLawDetail";
import LawCardFormCompanyUser, {LawCardFormCompanyUserData} from "../formdetail/LawCardFormCompanyUser";
import {LawData} from "../../../../commons/model/Law";
import TaskLawAdd from "../../../../commons/ws/law/TaskLawAdd";
import TaskLawEdit from "../../../../commons/ws/law/TaskLawEdit";
import {Int} from "../../../../utils/StringUtils";
import {goToRoute} from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = LawFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = LawFormCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class LawFormCompanyUserScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskLawDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: LawCardFormCompanyUserData): void => {
        const existLaw = this.props.match.params.id !== undefined;

        const submitData: LawData = {
            law_type_id: values.lawTypeId,
            article_code: values.articleCode,
            post_code: values.postCode,
            required_expiration_date: values.requiredExpirationDate ? values.requiredExpirationDate : false,
            option_code: values.optionCode,
            screen_text: values.screenText,
            print_text: values.printText,
            format_text: values.formatText,
            amount: Number(values.amount),
            cancellation_amount: Number(values.cancellationAmount),
            cancelable_report: values.cancelableReport ? values.cancelableReport : false,
            cancellation_text: values.cancellationText,
            controller_photos: Int(values.controllerPhotos),
            ticket_time_exceeded: values.ticketTimeExceeded ? values.ticketTimeExceeded : false,
            cancellation_code: values.cancellationCode,
            allowed_minutes_cancel_report_app: Int(values.allowedMinutesCancelReportApp),
            cancellation_reference_type_id: values.cancellationReferenceTypeId,
        };

        if (existLaw) {
            executeItemTask(
                new TaskLawEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_COMPANY_USER_LAWS),
                I18nUtils.tr(TR_ARTICULO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskLawAdd(submitData),
                () => goToRoute(ROUTE_COMPANY_USER_LAWS),
                I18nUtils.tr(TR_ARTICULO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const lawId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_ARTICULOS),
            url: ROUTE_COMPANY_USER_LAWS,
            icon: AppIcon.LAW
        }];

        if (lawId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_ARTICULO),
                url: generateRoute(ROUTE_COMPANY_USER_LAW_DETAIL, {id: lawId}),
                icon: AppIcon.LAW
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_ARTICULO),
                url: ROUTE_COMPANY_USER_LAW_ADD,
                icon: AppIcon.LAW
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderLawForm()}
            </ScreenCard>
        )
    }

    private renderLawForm(): React.ReactNode {
        const {lawLoading, law} = this.props;

        let initialValues: Partial<LawCardFormCompanyUserData> | undefined;

        if (law) {
            initialValues = {
                lawTypeId: law.law_type ? law.law_type.id : "-1",
                // description: law.description ? law.description : '',
                articleCode: law.article_code ? law.article_code : '',
                postCode: law.post_code ? law.post_code : '',
                optionCode: law.option_code ? law.option_code : '',
                requiredExpirationDate: law.required_expiration_date || false,
                screenText: law.screen_text ? law.screen_text : '',
                printText: law.print_text ? law.print_text : '',
                formatText: law.format_text ? law.format_text : '',
                amount: law.amount ? law.amount.toString() : "0",
                cancellationAmount: law.cancellation_amount ? law.cancellation_amount.toString() : "0",
                cancelableReport: law.cancelable_report ? law.cancelable_report : false,
                cancellationText: law.cancellation_text ? law.cancellation_text : '',
                controllerPhotos: law.controller_photos ? law.controller_photos.toString() : "0",
                ticketTimeExceeded: law.ticket_time_exceeded ? law.ticket_time_exceeded : false,
                cancellationCode: law.cancellation_code ? law.cancellation_code : 0,
                allowedMinutesCancelReportApp: law.allowed_minutes_cancel_report_app ? law.allowed_minutes_cancel_report_app.toString() : "0",
                cancellationReferenceTypeId: law.cancellation_reference_type ? law.cancellation_reference_type.id : "-1",
            }
        }
        return (
            <LawCardFormCompanyUser
                parentLoading={lawLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(LawFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
