import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { TR_ADVERACION, TR_DETALLES_DENUNCIA } from '../../../../commons/I18n/constants';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import TaskReportDetail from '../../../../commons/ws/report/TaskReportDetail';
import { BreadcrumbItem } from '../../../../components/breadcrumb/BreadcrumbManager';
import ScreenCard from '../../../../components/screen/ScreenCard';
import { AppIcon } from '../../../../config/Config';
import {
    ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL,
    ROUTE_COMPANY_USER_RATIFICATION_REPORTS
} from '../../../../routing/Routes';
import { loadItemDetailsOrGoBack } from '../../../../utils/FormUtils';
import RatificationReportDetailsCompanyUserActions from './RatificationReportDetailsCompanyUserActions';
import RatificationReportDetailsCompanyUserReducer from './RatificationReportDetailsCompanyUserReducer';
import { ReportDetailsCard } from '../../../../components/sections/ReportDetailsCard';
import React from 'react';

const mapActionsToProps = RatificationReportDetailsCompanyUserActions.autoMapToProps();
const mapStateToProps = RatificationReportDetailsCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{id: string}> & typeof mapActionsToProps;

class RatificationReportDetailsCompanyUserScreen extends React.Component<Props> {

    public componentDidMount(): void {
        const {id} = this.props.match.params;
        if ( id ) {
            loadItemDetailsOrGoBack(new TaskReportDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.dispatchResetDetailsReducer();
    }

    public render(): React.ReactNode {
        const reportId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_ADVERACION),
            url: ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
            icon: AppIcon.REPORT
        }];

        if ( reportId ) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_DETALLES_DENUNCIA),
                url: ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL,
                icon: AppIcon.REPORT
            });
        }

        const {report, loading} = this.props;

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                <ReportDetailsCard
                    report={report}
                    loading={loading}
                    backRoute={ROUTE_COMPANY_USER_RATIFICATION_REPORTS}
                    withRatifier={true}
                    withExportPDF={true}
                />
            </ScreenCard>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(RatificationReportDetailsCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
