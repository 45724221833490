import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import VehicleModel from "../../model/VehicleModel";

type VehicleModelCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleModelList extends BaseGenericRequest<VehicleModelCriteriaProps, TaskResponse<VehicleModel[]>> {

    private readonly data: VehicleModelCriteriaProps;

    public constructor(criteria: VehicleModelCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_MODEL_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleModelCriteriaProps {
        return this.data;
    }

}
