import React, {Component, SyntheticEvent} from "react";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {goToRoute} from "../../../../utils/Router";
import {generateRoute} from "../../../../commons/utils/Router";
import {ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_ESTADO, TR_FECHA, TR_IMPORTE} from "../../../../commons/I18n/constants";
import {
    CollectionProcessState,
    ParkingMeterCollectionProcess
} from "../../../../commons/model/ParkingMeterCollectionProcess";
import DateFormatter from "../../../../utils/DateFormatter";
import {Checkbox} from "@material/react-checkbox";
import {connect} from "react-redux";
import CollectionProcessAdminReducer from "./CollectionProcessAdminReducer";
import CollectionProcessAdminActions from "./CollectionProcessAdminActions";

interface CollectionProcessTableProps {
    data: Array<ParkingMeterCollectionProcess>,
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

const mapStateToProps = CollectionProcessAdminReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessAdminActions.autoMapToProps();

type Props = CollectionProcessTableProps & typeof mapStateToProps & typeof mapActionsToProps;

class CollectionProcessTable extends Component<Props> {

    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL, {id: itemId}))
    };

    private areAnyPendingToSend (): boolean {
        const {data} = this.props;

        let areAnyPendingToSend: boolean = false;

        data.forEach(value => {
            if (value.collection_process_state.id === CollectionProcessState.PENDING_TO_SEND) {
                areAnyPendingToSend = true;
            }
        })

        return areAnyPendingToSend;
    }

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((collectionProcess: ParkingMeterCollectionProcess) => {
                    return (
                        <tr key={collectionProcess.id}
                            onClick={() => this.gotoItemDetail(collectionProcess.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(collectionProcess.id) : null}

                            {this.renderCheckboxTD(collectionProcess.collection_process_state.id, collectionProcess.id)}

                            <td>{collectionProcess.id}</td>

                            <td>{DateFormatter.formatDate(collectionProcess.date)}</td>

                            <td className={collectionProcess.collection_process_state.id === CollectionProcessState.INCORRECT ? 'incorrect-state' : ''}>
                                {collectionProcess.collection_process_state.name}
                            </td>

                            <td>{collectionProcess.real_cash}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}

                {this.renderCheckboxTH()}

                <Th text={"Id"}
                    columnName={'id'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ESTADO)}
                    columnName={'state'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_IMPORTE)}
                    columnName={'realCash'}
                    {...commonProps}
                />
            </tr>
        )
    }

    private renderCheckboxTD (state: CollectionProcessState, id: string): React.ReactNode {
        if (state === CollectionProcessState.PENDING_TO_SEND) {

            const selected = this.props.parkingMeterCollectionProcessToSend.filter(value => value === id);

            const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
                event.stopPropagation();
                if ( selected[0] ) {
                    this.props.dispatchRemoveCollectionProcessToSendList(id);
                } else {
                    this.props.dispatchAddCollectionProcessToSendList(id);
                }
            };

            return (
                <td className='td-checkbox'>
                    <Checkbox
                        initRipple={() => null}
                        unbounded={false}
                        checked={false}
                        onChange={onChangeHandler}
                        // @ts-ignore
                        onClick={(event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation()}
                    />
                </td>
            )
        } else if (this.areAnyPendingToSend()) {
            return <td/>
        }

        return null;
    }

    private renderCheckboxTH(): React.ReactNode {
        if (this.areAnyPendingToSend()) {
            return <Th text={''}/>
        }

        return null;
    }
}

export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessTable as unknown as React.ComponentType<CollectionProcessTableProps>);
