import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_MODELOS,
    TR_MODELOS_DE_VEHICULOS,
    TR_NO_EXISTEN_MODELOS_DE_VEHICULOS
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_VEHICLE_MODEL_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import VehicleModelListAdminReducer from "./VehicleModelListAdminReducer";
import TaskVehicleModelList from "../../../../commons/ws/vehicleModel/TaskVehicleModelList";
import VehicleModelListTable from "./VehicleModelListTable";
import TaskVehicleModelDelete from "../../../../commons/ws/vehicleModel/TaskVehicleModelDelete";
import React from 'react';

const mapStateToProps = VehicleModelListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleModelListCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, vehicleModels, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_MODELOS_DE_VEHICULOS)}
                      sort={{column: 'id'}}
                      TaskList={TaskVehicleModelList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_MODELOS_DE_VEHICULOS)}}
                      data={vehicleModels}
                      error={error}
                      ItemsTable={VehicleModelListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_MODELOS),
                          Task: TaskVehicleModelDelete,
                      }}
                      addItem={ROUTE_ADMIN_VEHICLE_MODEL_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(VehicleModelListCardAdmin as unknown as React.ComponentType<void>);
