import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_LEY,
    TR_LEY_CREADA_CORRECTAMENTE,
    TR_LEY_MODIFICADA_CORRECTAMENTE,
    TR_LEYES,
    TR_NUEVA_LEY
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_LAW_TYPE_ADD, ROUTE_ADMIN_LAW_TYPE_DETAIL, ROUTE_ADMIN_LAW_TYPES} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import {goToRoute} from '../../../../utils/Router';
import LawTypeFormScreenAdminReducer from "./LawTypeFormScreenAdminReducer";
import LawTypeFormAdminScreenActions from "./LawTypeFormAdminScreenActions";
import TaskLawTypeDetail from "../../../../commons/ws/lawType/TaskLawTypeDetail";
import LawTypeCardFormAdmin, {LawTypeCardFormAdminData} from "../formdetail/LawTypeCardFormAdmin";
import {LawTypeData} from "../../../../commons/model/LawType";
import TaskLawTypeEdit from "../../../../commons/ws/lawType/TaskLawTypeEdit";
import TaskLawTypeAdd from "../../../../commons/ws/lawType/TaskLawTypeAdd";
import {Int} from "../../../../utils/StringUtils";
import React from 'react';

const mapStateToProps = LawTypeFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = LawTypeFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class LawTypeFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskLawTypeDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: LawTypeCardFormAdminData): void => {
        const existTypeLaw = this.props.match.params.id !== undefined;

        const submitData: LawTypeData = {
            name: values.name,
            discount_percentage: Int(values.discountPercentage),
            suma_code: values.sumaCode
        };

        if (existTypeLaw) {
            executeItemTask(
                new TaskLawTypeEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_LAW_TYPES),
                I18nUtils.tr(TR_LEY_MODIFICADA_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskLawTypeAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_LAW_TYPES),
                I18nUtils.tr(TR_LEY_CREADA_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const lawTypeId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_LEYES),
            url: ROUTE_ADMIN_LAW_TYPES,
            icon: AppIcon.LAW
        }];

        if (lawTypeId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_LEY),
                url: generateRoute(ROUTE_ADMIN_LAW_TYPE_DETAIL, {id: lawTypeId}),
                icon: AppIcon.LAW
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVA_LEY),
                url: ROUTE_ADMIN_LAW_TYPE_ADD,
                icon: AppIcon.LAW
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderLawTypeForm()}
            </ScreenCard>
        )
    }

    private renderLawTypeForm(): React.ReactNode {
        const {lawTypeLoading, lawType} = this.props;

        let initialValues: Partial<LawTypeCardFormAdminData> | undefined;

        if (lawType) {
            initialValues = {
                sumaCode: lawType.suma_code ? lawType.suma_code.toString() : '',
                name: lawType.name ? lawType.name : '',
                discountPercentage: lawType.discount_percentage ? lawType.discount_percentage.toString() : '',
            }
        }
        return (
            <LawTypeCardFormAdmin
                initialValues={initialValues}
                parentLoading={lawTypeLoading}
                onSubmit={this.onSubmit}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(LawTypeFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
