import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_MARCAS,
    TR_MARCAS_DE_VEHICULOS,
    TR_NO_EXISTEN_MARCAS_DE_VEHICULOS
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_VEHICLE_BRAND_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {connect} from "react-redux";
import VehicleBrandListAdminReducer from "./VehicleBrandListAdminReducer";
import TaskVehicleBrandDelete from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandDelete";
import VehicleBrandListTable from "./VehicleBrandListTable";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";
import React from 'react';

const mapStateToProps = VehicleBrandListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleBrandListCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {loading, vehicleBrands, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_MARCAS_DE_VEHICULOS)}
                      sort={{column: 'id'}}
                      TaskList={TaskVehicleBrandList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_MARCAS_DE_VEHICULOS)}}
                      data={vehicleBrands}
                      error={error}
                      ItemsTable={VehicleBrandListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_MARCAS),
                          Task: TaskVehicleBrandDelete,
                      }}
                      addItem={ROUTE_ADMIN_VEHICLE_BRAND_ADD}
            />
        )
    }
}

export default connect(mapStateToProps)(VehicleBrandListCardAdmin as unknown as React.ComponentType<void>);
