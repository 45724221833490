import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_COLOR_DE_VEHICULO,
    TR_COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE,
    TR_COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE,
    TR_COLORES_DE_VEHICULOS,
    TR_NUEVO_COLOR_DE_VEHICULO
} from "../../../../commons/I18n/constants";
import {
    ROUTE_ADMIN_VEHICLE_COLOR_ADD,
    ROUTE_ADMIN_VEHICLE_COLOR_DETAIL,
    ROUTE_ADMIN_VEHICLE_COLORS
} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import {generateRoute} from "../../../../commons/utils/Router";
import VehicleColorFormScreenAdminReducer from "./VehicleColorFormScreenAdminReducer";
import VehicleColorFormAdminScreenActions from "./VehicleColorFormAdminScreenActions";
import TaskVehicleColorDetail from "../../../../commons/ws/vehicleColor/TaskVehicleColorDetail";
import VehicleColorCardFormAdmin, {VehicleColorAdminData} from "../formdetail/VehicleColorCardFormAdmin";
import {VehicleColorData} from "../../../../commons/model/VehicleColor";
import TaskVehicleColorEdit from "../../../../commons/ws/vehicleColor/TaskVehicleColorEdit";
import TaskVehicleColorAdd from "../../../../commons/ws/vehicleColor/TaskVehicleColorAdd";
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = VehicleColorFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = VehicleColorFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class VehicleColorFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskVehicleColorDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: VehicleColorAdminData): void => {
        const existVehicleColor = this.props.match.params.id !== undefined;

        const submitData: VehicleColorData = {
            name: values.name,
            alzira_code: values.alziraCode,
        };

        if (existVehicleColor) {
            executeItemTask(
                new TaskVehicleColorEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS),
                I18nUtils.tr(TR_COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskVehicleColorAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS),
                I18nUtils.tr(TR_COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const vehicleColorId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_COLORES_DE_VEHICULOS),
            url: ROUTE_ADMIN_VEHICLE_COLORS,
            icon: AppIcon.CAR
        }];

        if (vehicleColorId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_COLOR_DE_VEHICULO),
                url: generateRoute(ROUTE_ADMIN_VEHICLE_COLOR_DETAIL, {id: vehicleColorId}),
                icon: AppIcon.CAR
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_COLOR_DE_VEHICULO),
                url: ROUTE_ADMIN_VEHICLE_COLOR_ADD,
                icon: AppIcon.CAR
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderVehicleColorForm()}
            </ScreenCard>
        )
    }

    private renderVehicleColorForm(): React.ReactNode {
        const {vehicleColorLoading, vehicleColor} = this.props;

        let initialValues: Partial<VehicleColorAdminData> | undefined;

        if (vehicleColor) {
            initialValues = {
                name: vehicleColor.name ? vehicleColor.name : '',
                alziraCode: vehicleColor.alzira_code || '',
            }
        }
        return (
            <VehicleColorCardFormAdmin
                parentLoading={vehicleColorLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        )

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(VehicleColorFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
