import { connect } from 'react-redux';
import CardList from '../../../../components/card/CardList';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS,
    TR_NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO,
    TR_TIPOS_ZONAS_APARCAMIENTOS
} from '../../../../commons/I18n/constants';
import { ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD } from '../../../../routing/Routes';
import TaskParkingAreaTypeList from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList';
import TaskParkingAreaTypeDelete from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeDelete';
import ParkingAreaTypeCompanyUserListActions from './ParkingAreaTypeCompanyUserListActions';
import ParkingAreaTypeCompanyUserListReducer from './ParkingAreaTypeCompanyUserListReducer';
import ParkingAreaTypeCompanyUserListTable from './ParkingAreaTypeCompanyUserListTable';
import React from 'react';

const mapActionsToProps = ParkingAreaTypeCompanyUserListActions.autoMapToProps();
const mapStateToProps = ParkingAreaTypeCompanyUserListReducer.autoMapToProps();

class ParkingAreaTypeCompanyUserListCard extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, parkingAreaTypes, error, pager} = this.props;

        return (
            <CardList
                loading={loading}
                pager={pager}
                title={I18nUtils.tr(TR_TIPOS_ZONAS_APARCAMIENTOS)}
                sort={{column: 'name'}}
                TaskList={TaskParkingAreaTypeList}
                emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO)}}
                data={parkingAreaTypes}
                error={error}
                ItemsTable={ParkingAreaTypeCompanyUserListTable}
                deleteOption={{
                    title: I18nUtils.tr(TR_ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS),
                    Task: TaskParkingAreaTypeDelete,
                }}
                addItem={ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingAreaTypeCompanyUserListCard as unknown as React.ComponentType<void>);
