import FormInputOption from '../form/FormInputOption';
import ReportState from '../../commons/model/ReportState';
import ReportCancellationMethod from '../../commons/model/ReportCancellationMethod';
import ReportInvalidationReason from '../../commons/model/ReportInvalidationReason';
import I18nUtils from '../../commons/I18n/I18nUtils';
import {
    TR_ADVERADA,
    TR_NO,
    TR_NO_SOLUCIONADA,
    TR_PENDIENTE,
    TR_SI,
    TR_SOLUCIONADA
} from '../../commons/I18n/constants';
import ReportSentToProcessState from '../../commons/model/ReportSentToProcessState';
import Route from '../../commons/model/Route';
import Law from '../../commons/model/Law';
import User from '../../commons/model/User';

const formInputOptionSorter = (optionA: FormInputOption, optionB: FormInputOption) => {
    if (optionA.name > optionB.name) return 1;
    if (optionA.name < optionB.name) return -1;
    return 0;
};

// - - - - -

const reportStates = (data: ReportState[]): FormInputOption[] => {
    return data.map(({id, name}) => ( {
        value: id,
        name,
    } ));
};

const reportCancellationMethods = (data: ReportCancellationMethod[]): FormInputOption[] => {
    return data.map(({id, name}) => ( {
        value: id,
        name,
    } ));
};

const reportInvalidationReasons = (data: ReportInvalidationReason[]): FormInputOption[] => {
    return data.map(({id, name}) => ( {
        value: id,
        name,
    } ));
};

const reportSentToProcessStates = (data: ReportSentToProcessState[]): FormInputOption[] => {
    return data.map(({id, name}) => ( {
        value: id,
        name,
    } ));
};

const routes = (data: Route[]): FormInputOption[] => {
    return data.map(
        ({id, name}) => ( {
            value: id,
            name,
        } )
    ).sort(formInputOptionSorter);
};

const laws = (data: Law[]): FormInputOption[] => {
    return data.map(
        ({id, municipal_code, screen_text}) => ( {
            value: id,
            name: `${municipal_code} - ${screen_text}`,
        } )
    ).sort(formInputOptionSorter);
};

const users = (data: User[]): FormInputOption[] => {
    return data.map(({id, name, last_name}) => ( {
            value: id,
            name: `${name} ${last_name}`,
        } )
    ).sort(formInputOptionSorter);
};

const ratified: FormInputOption[] = [
    {name: I18nUtils.tr(TR_ADVERADA), value: 'true'},
    {name: I18nUtils.tr(TR_PENDIENTE), value: 'false'}
];

const controllerSignature: FormInputOption[] = [
    {name: I18nUtils.tr(TR_SI), value: 'true'},
    {name: I18nUtils.tr(TR_NO), value: 'false'}
];

const ratifierSignature: FormInputOption[] = [
    {name: I18nUtils.tr(TR_SI), value: 'true'},
    {name: I18nUtils.tr(TR_NO), value: 'false'}
];

// - - - - - TODO: PIC: Refactorización - Sustituir

export class IncidenceStateOptions {
    public static SOLVED = {
        name: I18nUtils.tr(TR_SOLUCIONADA),
        value: '1'
    };
    public static NO_SOLVED = {
        name: I18nUtils.tr(TR_NO_SOLUCIONADA),
        value: '2'
    };
}

const incidenceState: FormInputOption[] = [
    {name: I18nUtils.tr(TR_SOLUCIONADA), value: '1'},
    {name: I18nUtils.tr(TR_NO_SOLUCIONADA), value: '2'}
];

// - - - - -

export const selectOptionsBuilder = {
    reportStates,
    reportCancellationMethods,
    reportInvalidationReasons,
    reportSentToProcessStates,
    routes,
    laws,
    users,
    ratified: () => ratified,
    controllerSignature: () => controllerSignature,
    ratifierSignature: () => ratifierSignature,
    incidenceState: () => incidenceState,
};
