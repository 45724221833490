import React from "react";
import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {
    TR_ELIMINAR_METODOS,
    TR_METODOS_DE_CANCELACION_DE_DENUNCIA,
    TR_NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA
} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD} from "../../../../routing/Routes";
import ReportCancellationMethodListCompanyUserReducer from "./ReportCancellationMethodListAdminReducer";
import ReportCancellationMethodListCompanyUserActions from "./ReportCancellationMethodListAdminActions";
import TaskReportCancellationMethodList
    from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList";
import TaskReportCancellationMethodDelete
    from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodDelete";
import ReportCancellationMethodListTable from "./ReportCancellationMethodListTable";

const mapActionsToProps = ReportCancellationMethodListCompanyUserActions.autoMapToProps();
const mapStateToProps = ReportCancellationMethodListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class ReportCancellationMethodListCardAdmin extends React.Component<Props> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, reportCancellationMethods, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_METODOS_DE_CANCELACION_DE_DENUNCIA)}
                      sort={{column: 'name'}}
                      TaskList={TaskReportCancellationMethodList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA)}}
                      data={reportCancellationMethods}
                      error={error}
                      ItemsTable={ReportCancellationMethodListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_METODOS),
                          Task: TaskReportCancellationMethodDelete,
                      }}
                      addItem={ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportCancellationMethodListCardAdmin as unknown as React.ComponentType<void>);
