import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../commons/I18n/I18nUtils";
import {TR_MOTIVOS_DE_PLAZAS_PERDIDAS} from "../../../../commons/I18n/constants";
import {ROUTE_ADMIN_PARKING_LOSS_REASONS} from "../../../../routing/Routes";
import {AppIcon} from "../../../../config/Config";
import ParkingLossReasonListCardAdmin from "./ParkingLossReasonListCardAdmin";
import React from 'react';

export default class ParkingLossReasonListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_MOTIVOS_DE_PLAZAS_PERDIDAS),
                    url: ROUTE_ADMIN_PARKING_LOSS_REASONS,
                    icon: AppIcon.PARKING
                }
            ]}>
                <ParkingLossReasonListCardAdmin/>
            </ScreenCard>
        )
    }
}
