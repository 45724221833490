
import I18nUtils from "../../../commons/I18n/I18nUtils";
import CryptoJS from "crypto-js";
import md5 from "md5";
import {
    TR_ACTUALIZAR,
    TR_ACTUALIZAR_CERTIFICADO,
    TR_CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE,
    TR_CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE,
    TR_CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE,
    TR_CREAR,
    TR_ELIMINAR,
    TR_ELIMINAR_CERTIFICADO_DIGITAL,
    TR_ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR,
    TR_FECHA_EXPIRACION,
    TR_FECHA_INICIO,
    TR_FIRMA_DIGITAL,
    TR_NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO
} from "../../../commons/I18n/constants";
import {AppIcon} from "../../../config/Config";
import CardHeader from "../../../components/card/CardHeader";
import Card from "../../../components/card/Card";
import Col from "../../../components/Col";
import Row from "../../../components/Row";
import Icon from "../../../components/Icon";
import CardBody from "../../../components/card/CardBody";
import DateFormatter from "../../../utils/DateFormatter";
import Button from "../../../components/buttons/Button";
import UserCertCompanyUserUploadModal, {UserCertCompanyUserUploadModalData} from "./UserCertCompanyUserUploadModal";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import TaskUserCertDelete from "../../../commons/ws/userCert/TaskUserCertDelete";
import Alert from "../../../base/alerts/Alert";
import {UserCertData} from "../../../commons/model/UserCert";
import TaskUserCertUpload from "../../../commons/ws/userCert/TaskUserCertUpload";
import {debounce} from 'lodash';
import React from 'react';

export interface UserCertCardCompanyUserData {
    startDate: string,
    endDate: string,
}

interface Props {
    parentLoading: boolean,
    initialValues?: UserCertCardCompanyUserData,
    refreshHandler: () => void,
}

interface State {
    showCreateCertModal: boolean,
    showUploadCertModal: boolean,
    showDeleteCertModal: boolean
}

enum UserCertCardCompanyUserFields {
    START_DATE = 'startDate',
    END_DATE = 'endDate',
}

export default class UserCertCardCompanyUser extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showCreateCertModal: false,
            showUploadCertModal: false,
            showDeleteCertModal: false,
        }
    }

    public onOpenCreateCertModal = () => {
        this.setState({showCreateCertModal: true})
    };

    public onCloseCreateCertModal = () => {
        this.setState({showCreateCertModal: false})
    };

    public onOpenUploadCertModal = () => {
        this.setState({showUploadCertModal: true})
    };

    public onCloseUploadCertModal = () => {
        this.setState({showUploadCertModal: false})
    };

    public onOpenDeleteCertModal = () => {
        this.setState({showDeleteCertModal: true})
    };

    public onCloseDeleteCertModal = () => {
        this.setState({showDeleteCertModal: false})
    };

    private onRefreshDebounced = debounce(this.props.refreshHandler, 1000);

    private onSubmit = (values: UserCertCompanyUserUploadModalData, override: boolean = false) => {

        const passphrase: string = md5(Math.random().toString(8));
        const encryptedPassword: string = CryptoJS.AES.encrypt(values.password, passphrase).toString();

        const submitData: UserCertData = {
            file: values.files[0],
            password: encryptedPassword,
            passphrase,
            override
        };

        this.onCloseCreateCertModal();
        this.onCloseUploadCertModal();

        new TaskUserCertUpload(submitData)
            .onSuccess((response) => {
                if (response.success) {
                    Alert.success(I18nUtils.tr(this.state.showCreateCertModal ?
                        TR_CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE : TR_CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE));
                }
            })
            .onFail((error) => {
                Alert.error(error);
            })
            .execute();
        this.onRefreshDebounced();
    };

    private onDelete = () => {
        new TaskUserCertDelete()
            .onSuccess((response) => {
                if (response.success) {
                    Alert.success(I18nUtils.tr(TR_CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE));
                }
            })
            .execute();
        this.onRefreshDebounced();
    };

    public render(): React.ReactNode {
        const {parentLoading, initialValues} = this.props;

        return (
            <>
                <Card loading={parentLoading}>
                    <CardHeader title={I18nUtils.tr(TR_FIRMA_DIGITAL)}/>
                    <CardBody>
                        {!!initialValues ? this.renderUserCertContent() : this.renderUploadCert()}
                    </CardBody>
                </Card>

                <UserCertCompanyUserUploadModal key={'createUserCert'}
                                                show={this.state.showCreateCertModal}
                                                onSubmit={this.onSubmit}
                                                onClose={this.onCloseCreateCertModal}
                />
                <UserCertCompanyUserUploadModal key={'updateUserCert'}
                                                show={this.state.showUploadCertModal}
                                                onSubmit={(values) => this.onSubmit(values, true)}
                                                onClose={this.onCloseUploadCertModal}
                                                title={I18nUtils.tr(TR_ACTUALIZAR_CERTIFICADO)}
                />

                <ConfirmModal title={I18nUtils.tr(TR_ELIMINAR_CERTIFICADO_DIGITAL)}
                              message={I18nUtils.tr(TR_ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR)}
                              onConfirmAction={this.onDelete}
                              textConfirmButton={I18nUtils.tr(TR_ELIMINAR)}
                              show={this.state.showDeleteCertModal}
                              onClose={this.onCloseDeleteCertModal}
                />
            </>
        )
    }

    private renderUploadCert(): React.ReactNode {
        return (
            <>
                <p>{I18nUtils.tr(TR_NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO)}</p>
                <Row className={`p-t-20`}>
                    <Col sm={8} md={9} lg={10}/>
                    <Col sm={4} md={3} lg={2}>
                        <Button
                            text={I18nUtils.tr(TR_CREAR)}
                            onClick={this.onOpenCreateCertModal}
                            type={'button'}
                            block={true}
                            className={'btn-primary btn-lg'}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    private renderUserCertContent(): React.ReactNode {
        const {initialValues} = this.props;

        const startDate = initialValues ? DateFormatter.formatDate(initialValues.startDate) : '';
        const endDate = initialValues ? DateFormatter.formatDate(initialValues.endDate) : '';

        return (<>
            <Row>
                <Col lg={3} md={3}>
                    <Icon className={'file-icon'} icon={AppIcon.DIGITAL_SIGN}/>
                </Col>

                <Col lg={2} md={2}>
                    <div className={'form-group'}>
                        <label className='main-label'>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                        <input name={UserCertCardCompanyUserFields.START_DATE}
                               type={'text'}
                               className={'form-control'}
                               disabled={true}
                               defaultValue={startDate}
                        />
                    </div>

                    <div className={'form-group'}>
                        <label className='main-label'>{I18nUtils.tr(TR_FECHA_EXPIRACION)}</label>
                        <input name={UserCertCardCompanyUserFields.END_DATE}
                               type={'text'}
                               className={'form-control'}
                               disabled={true}
                               defaultValue={endDate}
                        />
                    </div>
                </Col>
            </Row>

            <Row className={`p-t-20`}>
                <Col sm={4} md={6} lg={8}/>
                <Col sm={4} md={3} lg={2}>
                    <Button
                        text={I18nUtils.tr(TR_ELIMINAR)}
                        onClick={this.onOpenDeleteCertModal}
                        type={'button'}
                        block={true}
                        className={'btn-lg btn-default'}
                    />
                </Col>
                <Col sm={4} md={3} lg={2}>
                    <Button
                        text={I18nUtils.tr(TR_ACTUALIZAR)}
                        onClick={this.onOpenUploadCertModal}
                        type={'button'}
                        block={true}
                        className={'btn-primary btn-lg'}
                    />
                </Col>
            </Row>
        </>)
    }
}
