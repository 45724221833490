
import React, { ReactNode } from 'react';
import { TR_FICHERO_DE_ENVIO_A_TRAMITE, TR_VOLVER } from '../../../../commons/I18n/constants';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { Card } from '../../../../components-new/Card';
import CardBody from '../../../../components/card/CardBody';
import { NewButton } from '../../../../components-new/Button';
import { CardHeader } from '../../../../components-new/CardHeader';
import { ROUTE_COMPANY_USER_SENDTOPROCESS } from '../../../../routing/Routes';
import { goToRoute } from '../../../../utils/Router';
import DateFormatter from "../../../../utils/DateFormatter";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface Props {
    date: Date | null
    fileUrl: string
    explotationId: string
    loading: boolean
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class SendToProcessFileCard extends React.Component<Props> {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    public render(): ReactNode {
        const {date, fileUrl, explotationId, loading} = this.props;
        const hasData: boolean = !!date && !!fileUrl && !!explotationId;

        return (
            <div className={'card-send-to-process-file'}>
                <Card loading={loading}>
                    <CardHeader title={I18nUtils.tr(TR_FICHERO_DE_ENVIO_A_TRAMITE)}>
                    </CardHeader>
                    <CardBody>
                        {
                            hasData ? this.renderDataText() : this.renderNoDataText()
                        }
                        <NewButton
                            style={{gridArea: 'button'}}
                            label={I18nUtils.tr(TR_VOLVER)}
                            disabled={false}
                            type={'btn-filled'}
                            onClick={this.handleGoBack}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private renderDataText = () => {
        const {date, explotationId} = this.props;

        const textMarginStyle = {margin: '0 5px'};

        return (
            <div style={{
                gridArea: 'text',
                display: 'flex',
                flexFlow: 'row wrap',
            }}>
                <div>
                    {I18nUtils.tr('Se ha generado el')}
                </div>
                <a style={textMarginStyle} onClick={this.handleGetFile}>
                    {I18nUtils.tr('fichero')}
                </a>
                <div>
                    {I18nUtils.tr('de suma para la empresa')}
                </div>
                <div style={{...textMarginStyle, fontWeight: 'bold'}}>
                    {explotationId}
                </div>
                <div>
                    {I18nUtils.tr('con fecha')}
                </div>
                <div style={{...textMarginStyle, fontWeight: 'bold'}}>
                    {DateFormatter.formatDate(date!)}
                </div>
            </div>
        );
    };

    private renderNoDataText = () => {
        return (
            <div style={{gridArea: 'text'}}>
                {I18nUtils.tr(`No se ha generado ningún fichero.`)}
            </div>
        );
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    private handleGetFile = () => {
        const {fileUrl} = this.props;

        window.open(fileUrl, "_blank");
    };

    private handleGoBack = () => {
        goToRoute(ROUTE_COMPANY_USER_SENDTOPROCESS);
    };

}
