
import { RouteComponentProps } from 'react-router';
import { executeItemTask, loadItemDetailsOrGoBack } from '../../../../utils/FormUtils';
import { BreadcrumbItem } from '../../../../components/breadcrumb/BreadcrumbManager';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import {
    TR_METODO_DE_CANCELACION_DE_DENUNCIA,
    TR_METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE,
    TR_METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE,
    TR_METODOS_DE_CANCELACION_DE_DENUNCIA,
    TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA
} from '../../../../commons/I18n/constants';
import {
    ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
    ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL,
    ROUTE_ADMIN_REPORT_CANCELLATION_METHODS
} from '../../../../routing/Routes';
import { AppIcon } from '../../../../config/Config';
import ScreenCard from '../../../../components/screen/ScreenCard';
import { connect } from 'react-redux';
import { generateRoute } from '../../../../commons/utils/Router';
import ReportCancellationMethodFormScreenAdminReducer from './ReportCancellationMethodFormScreenAdminReducer';
import ReportCancellationMethodFormAdminScreenActions from './ReportCancellationMethodFormAdminScreenActions';
import TaskReportCancellationMethodDetail
    from '../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodDetail';
import ReportCancellationMethodCardFormAdmin, { ReportCancellationMethodAdminData } from '../formdetail/ReportCancellationMethodCardFormAdmin';
import { ReportCancellationMethodData } from '../../../../commons/model/ReportCancellationMethod';
import TaskReportCancellationMethodEdit
    from '../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodEdit';
import TaskReportCancellationMethodAdd
    from '../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodAdd';
import { goToRoute } from '../../../../utils/Router';
import React from 'react';

const mapStateToProps = ReportCancellationMethodFormScreenAdminReducer.autoMapToProps();

const mapActionsToProps = ReportCancellationMethodFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{id: string}> & typeof mapActionsToProps;

class ReportCancellationMethodFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if ( id ) {
            loadItemDetailsOrGoBack(new TaskReportCancellationMethodDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const reportCancellationMethodId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_METODOS_DE_CANCELACION_DE_DENUNCIA),
            url: ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
            icon: AppIcon.REPORT_OFF
        }];

        if ( reportCancellationMethodId ) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_METODO_DE_CANCELACION_DE_DENUNCIA),
                url: generateRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL, {id: reportCancellationMethodId}),
                icon: AppIcon.REPORT_OFF
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA),
                url: ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
                icon: AppIcon.REPORT_OFF
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderReportCancellationMethodForm()}
            </ScreenCard>
        );
    }

    private onSubmit = (values: ReportCancellationMethodAdminData): void => {
        const existReportCancellationMethod = this.props.match.params.id !== undefined;

        const submitData: ReportCancellationMethodData = {
            name: values.name
        };

        if ( existReportCancellationMethod ) {
            executeItemTask(
                new TaskReportCancellationMethodEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS),
                I18nUtils.tr(TR_METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE)
            );
        } else {
            executeItemTask(
                new TaskReportCancellationMethodAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS),
                I18nUtils.tr(TR_METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE)
            );
        }
    };

    private renderReportCancellationMethodForm(): React.ReactNode {
        const {reportCancellationMethodLoading, reportCancellationMethod} = this.props;

        let initialValues: Partial<ReportCancellationMethodAdminData> | undefined;

        if ( reportCancellationMethod ) {
            initialValues = {
                name: reportCancellationMethod.name ? reportCancellationMethod.name : ''
            };
        }

        return (
            <ReportCancellationMethodCardFormAdmin
                parentLoading={reportCancellationMethodLoading}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />
        );

    }

}

export default connect(mapStateToProps, mapActionsToProps)
(ReportCancellationMethodFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
