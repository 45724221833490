export const TR_PAVAPARK = 'TR_PAVAPARK';
export const TR_GENERIC_WS_ERROR = 'TR_GENERIC_WS_ERROR';
export const TR_ACEPTAR = 'TR_ACEPTAR';
export const TR_CANCELAR = 'TR_CANCELAR';
export const TR_CARGANDO = 'TR_CARGANDO';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_APELLIDO = 'TR_APELLIDO';
export const TR_APELLIDOS = 'TR_APELLIDOS';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_EXPLOTACION = 'TR_EXPLOTACION';
export const TR_ROL = 'TR_ROL';
export const TR_ANADIR = 'TR_ANADIR';
export const TR_NO = 'TR_NO';
export const TR_SI = 'TR_SI';
export const TR_GUARDAR = 'TR_GUARDAR';
export const TR_HOME = 'TR_HOME';
export const TR_ANADIR_A_FAVORITOS = 'TR_ANADIR_A_FAVORITOS';
export const TR_QUITAR_DE_FAVORITOS = 'TR_QUITAR_DE_FAVORITOS';
export const TR_CREAR = 'TR_CREAR';
export const TR_ELIMINAR = 'TR_ELIMINAR';
export const TR_ELIMINADOS_CORRECTAMENTE = 'TR_ELIMINADOS_CORRECTAMENTE';
export const TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE = 'TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE';
export const TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE = 'TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE';
export const TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE = 'TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE';
export const TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE = 'TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE';
export const TR_BUSCANDO = 'TR_BUSCANDO';
export const TR_NO_SE_HAN_ENCONTRADO_RESULTADOS = 'TR_NO_SE_HAN_ENCONTRADO_RESULTADOS';
export const TR_ROLES = 'TR_ROLES';
export const TR_DE = 'TR_DE';
export const TR_NUEVO = 'TR_NUEVO';
export const TR_VALOR_MAXIMO = 'TR_VALOR_MAXIMO';
export const TR_VALOR_MINIMO = 'TR_VALOR_MINIMO';
export const TR_LIMITE_DE_CARACTERES = 'TR_LIMITE_DE_CARACTERES';
export const TR_CERRAR = 'TR_CERRAR';
export const TR_FECHA = 'TR_FECHA';
export const TR_FILTROS = 'TR_FILTROS';
export const TR_NO_ESTAS_AUTORIZADO = 'TR_NO_ESTAS_AUTORIZADO';
export const TR_CONTRASENA_INCORRECTA = 'TR_CONTRASENA_INCORRECTA';
export const TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA = 'TR_NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA';
export const TR_EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO = 'TR_EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO';
export const TR_YA_EXISTE_UN_ELEMENTO_SIMILAR = 'TR_YA_EXISTE_UN_ELEMENTO_SIMILAR';
export const TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF = 'TR_YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF';
export const TR_MENU = 'TR_MENU';
export const TR_PERFIL = 'TR_PERFIL';
export const TR_SALIR = 'TR_SALIR';
export const TR_INICIO = 'TR_INICIO';
export const TR_MOVIMIENTOS = 'TR_MOVIMIENTOS';
export const TR_NUEVA_ENTRADA = 'TR_NUEVA_ENTRADA';
export const TR_NUEVA_SALIDA = 'TR_NUEVA_SALIDA';
export const TR_TRANSPORTISTAS = 'TR_TRANSPORTISTAS';
export const TR_SESIONES = 'TR_SESIONES';
export const TR_BUSCAR = 'TR_BUSCAR';
export const TR_VER_TODAS_LAS_ALERTAS = 'TR_VER_TODAS_LAS_ALERTAS';
export const TR_CERRAR_SESSION = 'TR_CERRAR_SESSION';
export const TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION = 'TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION';
export const TR_IR_A_LA_PAGINA = 'TR_IR_A_LA_PAGINA';
export const TR_DATOS_DE_AUTENTICACION_INCORRECTOS = 'TR_DATOS_DE_AUTENTICACION_INCORRECTOS';
export const TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE = 'TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE';
export const TR_ABRIR_SESION = 'TR_ABRIR_SESION';
export const TR_EMAIL_O_NOMBRE_DE_USUARIO = 'TR_EMAIL_O_NOMBRE_DE_USUARIO';
export const TR_CONTRASENA = 'TR_CONTRASENA';
export const TR_RECUERDAME = 'TR_RECUERDAME';
export const TR_LOGIN = 'TR_LOGIN';
export const TR_OLVIDASTE_TU_CONTRASENA = 'TR_OLVIDASTE_TU_CONTRASENA';
export const TR_RESTABLECE_LA_CONTRASENA = 'TR_RESTABLECE_LA_CONTRASENA';
export const TR_TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = 'TR_TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA';
export const TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = 'TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA';
export const TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA = 'TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA';
export const TR_USUARIO_NO_ENCONTRADO = 'TR_USUARIO_NO_ENCONTRADO';
export const TR_INTRODUCE_TU_CONTRASENA = 'TR_INTRODUCE_TU_CONTRASENA';
export const TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA = 'TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA';
export const TR_LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS = 'TR_LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS';
export const TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS = 'TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS';
export const TR_EL_EMAIL_NO_ES_VALIDO = 'TR_EL_EMAIL_NO_ES_VALIDO';
export const TR_URL = 'TR_URL';
export const TR_CLAVE_ELK = 'TR_CLAVE_ELK';
export const TR_CLAVE_ELK_OBLIGATORIA = 'TR_CLAVE_ELK_OBLIGATORIA';
export const TR_LA_URL_NO_ES_VALIDA = 'TR_LA_URL_NO_ES_VALIDA';
export const TR_LAS_CONTRASENAS_NO_COINCIDEN = 'TR_LAS_CONTRASENAS_NO_COINCIDEN';
export const TR_ALERTAS = 'TR_ALERTAS';
export const TR_PENDIENTES = 'TR_PENDIENTES';
export const TR_FAVORITOS = 'TR_FAVORITOS';
export const TR_DASHBOARDS = 'TR_DASHBOARDS';
export const TR_FORMULARIOS = 'TR_FORMULARIOS';
export const TR_RESULTADO_DE_FORMULARIO = 'TR_RESULTADO_DE_FORMULARIO';
export const TR_DOCUMENTACION = 'TR_DOCUMENTACION';
export const TR_ENTIDAD = 'TR_ENTIDAD';
export const TR_FECHA_OBLIGATORIA = 'TR_FECHA_OBLIGATORIA';
export const TR_HORA_OBLIGATORIA = 'TR_HORA_OBLIGATORIA';
export const TR_USUARIOS = 'TR_USUARIOS';
export const TR_NO_EXISTEN_USUARIOS = 'TR_NO_EXISTEN_USUARIOS';
export const TR_NUEVO_USUARIO = 'TR_NUEVO_USUARIO';
export const TR_USUARIO = 'TR_USUARIO';
export const TR_CREAR_USUARIO = 'TR_CREAR_USUARIO';
export const TR_USUARIO_CREADO_CORRECTAMENTE = 'TR_USUARIO_CREADO_CORRECTAMENTE';
export const TR_USUARIO_MODIFICADO_CORRECTAMENTE = 'TR_USUARIO_MODIFICADO_CORRECTAMENTE';
export const TR_CAMBIAR_CONTRASENA = 'TR_CAMBIAR_CONTRASENA';
export const TR_RESTABLECER_CONTRASENA = 'TR_RESTABLECER_CONTRASENA';
export const TR_NUEVA_CONTRASENA = 'TR_NUEVA_CONTRASENA';
export const TR_REPITE_LA_CONTRASENA = 'TR_REPITE_LA_CONTRASENA';
export const TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA = 'TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA';
export const TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES = 'TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES';
export const TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES = 'TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES';
export const TR_CONTRASENA_MODIFICADA_CORRECTAMENTE = 'TR_CONTRASENA_MODIFICADA_CORRECTAMENTE';
export const TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA = 'TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA';
export const TR_ANADE_AL_MENOS_UN_USUARIO = 'TR_ANADE_AL_MENOS_UN_USUARIO';
export const TR_GRUPOS = 'TR_GRUPOS';
export const TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO = 'TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO';
export const TR_ELIMINAR_USUARIOS = 'TR_ELIMINAR_USUARIOS';
export const TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES = 'TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES';
export const TR_EL_USUARIO_NO_EXISTE = 'TR_EL_USUARIO_NO_EXISTE';
export const TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE = 'TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE';
export const TR_TIPO = 'TR_TIPO';
export const TR_TIPO_DE_USUARIO_OBLIGATORIO = 'TR_TIPO_DE_USUARIO_OBLIGATORIO';
export const TR_CODIGO_DE_COLOR_NO_VALIDO = 'TR_CODIGO_DE_COLOR_NO_VALIDO';
export const TR_ROL_REPETIDO = 'TR_ROL_REPETIDO';
export const TR_EXPLOTACIONES = 'TR_EXPLOTACIONES';
export const TR_LOG = 'TR_LOG';
export const TR_ADMINISTRACION = 'TR_ADMINISTRACION';
export const TR_NOMBRE_OBLIGATORIO = 'TR_NOMBRE_OBLIGATORIO';
export const TR_APELLIDO_OBLIGATORIO = 'TR_APELLIDO_OBLIGATORIO';
export const TR_EMAIL_OBLIGATORIO = 'TR_EMAIL_OBLIGATORIO';
export const TR_CONTRASENA_OBLIGATORIA = 'TR_CONTRASENA_OBLIGATORIA';
export const TR_EXPLOTACION_OBLIGATORIO = 'TR_EXPLOTACION_OBLIGATORIO';
export const TR_ROL_OBLIGATORIO = 'TR_ROL_OBLIGATORIO';
export const TR_NIF = 'TR_NIF';
export const TR_NOMBRE_DE_USUARIO = 'TR_NOMBRE_DE_USUARIO';
export const TR_CODIGO_SUMA = 'TR_CODIGO_SUMA';
export const TR_COLOR = 'TR_COLOR';
export const TR_TIPOS_DE_USUARIO = 'TR_TIPOS_DE_USUARIO';
export const TR_NOMBRE_DE_USUARIO_OBLIGATORIO = 'TR_NOMBRE_DE_USUARIO_OBLIGATORIO';
export const TR_TIPO_DE_USUARIO_REPETIDO = 'TR_TIPO_DE_USUARIO_REPETIDO';
export const TR_EL_USUARIO_ADMINISTRADOR_NO_PUEDE_TENER_MAS_ROLES = 'TR_EL_USUARIO_ADMINISTRADOR_NO_PUEDE_TENER_MAS_ROLES';
export const TR_GRUPO_DE_USUARIOS = 'TR_GRUPO_DE_USUARIOS';
export const TR_GRUPOS_DE_USUARIOS = 'TR_GRUPOS_DE_USUARIOS';
export const TR_NUEVO_GRUPO_DE_USUARIOS = 'TR_NUEVO_GRUPO_DE_USUARIOS';
export const TR_CREAR_GRUPO_DE_USUARIOS = 'TR_CREAR_GRUPO_DE_USUARIOS';
export const TR_NO_EXISTEN_GRUPOS_DE_USUARIOS = 'TR_NO_EXISTEN_GRUPOS_DE_USUARIOS';
export const TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE = 'TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE';
export const TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE = 'TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE';
export const TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO = 'TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO';
export const TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO = 'TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO';
export const TR_YA_HAS_ASIGNADO_ESTE_PERMISO = 'TR_YA_HAS_ASIGNADO_ESTE_PERMISO';
export const TR_SELECCIONA_UN_USUARIO = 'TR_SELECCIONA_UN_USUARIO';
export const TR_ELIMINAR_GRUPOS_DE_USUARIOS = 'TR_ELIMINAR_GRUPOS_DE_USUARIOS';
export const TR_NIF_OBLIGATORIO = 'TR_NIF_OBLIGATORIO';
export const TR_EL_NIF_NO_ES_VALIDO = 'TR_EL_NIF_NO_ES_VALIDO';
export const TR_CODIGO_SUMA_OBLIGATORIO = 'TR_CODIGO_SUMA_OBLIGATORIO';
export const TR_COLOR_OBLIGATORIO = 'TR_COLOR_OBLIGATORIO';
export const TR_AL_MENOS_DEBE_TENER_UN_TIPO_ASIGNADO = 'TR_AL_MENOS_DEBE_TENER_UN_TIPO_ASIGNADO';
export const TR_ZONA = 'TR_ZONA';
export const TR_ZONAS = 'TR_ZONAS';
export const TR_NUEVA_ZONA = 'TR_NUEVA_ZONA';
export const TR_CREAR_ZONA = 'TR_CREAR_ZONA';
export const TR_NO_EXISTEN_ZONAS = 'TR_NO_EXISTEN_ZONAS';
export const TR_ZONA_CREADA_CORRECTAMENTE = 'TR_ZONA_CREADA_CORRECTAMENTE';
export const TR_ZONA_MODIFICADA_CORRECTAMENTE = 'TR_ZONA_MODIFICADA_CORRECTAMENTE';
export const TR_ELIMINAR_ZONAS = 'TR_ELIMINAR_ZONAS';
export const TR_TIPO_DE_EXPLOTACION = 'TR_TIPO_DE_EXPLOTACION';
export const TR_TIPOS_DE_EXPLOTACION = 'TR_TIPOS_DE_EXPLOTACION';
export const TR_NUEVO_TIPO_DE_EXPLOTACION = 'TR_NUEVO_TIPO_DE_EXPLOTACION';
export const TR_CREAR_TIPO_DE_EXPLOTACION = 'TR_CREAR_TIPO_DE_EXPLOTACION';
export const TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION = 'TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION';
export const TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE = 'TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE';
export const TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE = 'TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE';
export const TR_ELIMINAR_TIPOS_DE_EXPLOTACION = 'TR_ELIMINAR_TIPOS_DE_EXPLOTACION';
export const TR_TIPO_DE_EXPLOTACION_OBLIGATORIO = 'TR_TIPO_DE_EXPLOTACION_OBLIGATORIO';
export const TR_RESPONSABLE = 'TR_RESPONSABLE';
export const TR_FECHA_INICIO = 'TR_FECHA_INICIO';
export const TR_FECHA_FIN = 'TR_FECHA_FIN';
export const TR_TIPO_OBLIGATORIO = 'TR_TIPO_OBLIGATORIO';
export const TR_NOMBRE_DE_LA_EXPLOTACION = 'TR_NOMBRE_DE_LA_EXPLOTACION';
export const TR_DESCRIPCION_DE_LA_EXPLOTACION = 'TR_DESCRIPCION_DE_LA_EXPLOTACION';
export const TR_USUARIOS_DE_LA_EXPLOTACION = 'TR_USUARIOS_DE_LA_EXPLOTACION';
export const TR_NUEVA_EXPLOTACION = 'TR_NUEVA_EXPLOTACION';
export const TR_CREAR_EXPLOTACION = 'TR_CREAR_EXPLOTACION';
export const TR_HABILITADO = 'TR_HABILITADO';
export const TR_HABILITADA = 'TR_HABILITADA';
export const TR_DESAHABILITADA = 'TR_DESAHABILITADA';
export const TR_DESHABILITADO = 'TR_DESHABILITADO';
export const TR_NO_EXISTEN_EXPLOTACIONES = 'TR_NO_EXISTEN_EXPLOTACIONES';
export const TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE = 'TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE';
export const TR_EXPLOTACION_CREADA_CORRECTAMENTE = 'TR_EXPLOTACION_CREADA_CORRECTAMENTE';
export const TR_ZONA_OBLIGATORIA = 'TR_ZONA_OBLIGATORIA';
export const TR_ELIMINAR_EXPLOTACIONES = 'TR_ELIMINAR_EXPLOTACIONES';
export const TR_TIPO_DE_PARQUIMETRO = 'TR_TIPO_DE_PARQUIMETRO';
export const TR_TIPO_DE_PARQUIMETRO_OBLIGATORIO = 'TR_TIPO_DE_PARQUIMETRO_OBLIGATORIO';
export const TR_DESTINATARIO = 'TR_DESTINATARIO';
export const TR_FECHA_ENVIO = 'TR_FECHA_ENVIO';
export const TR_ALERTA = 'TR_ALERTA';
export const TR_NOMBRE_DE_LA_ALERTA = 'TR_NOMBRE_DE_LA_ALERTA';
export const TR_DESCRIPCION_DE_LA_ALERTA = 'TR_DESCRIPCION_DE_LA_ALERTA';
export const TR_ENLAZAR_CON = 'TR_ENLAZAR_CON';
export const TR_ENVIAR = 'TR_ENVIAR';
export const TR_INACTIVA = 'TR_INACTIVA';
export const TR_ACTIVA = 'TR_ACTIVA';
export const TR_ELIMINAR_ALERTA = 'TR_ELIMINAR_ALERTA';
export const TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA = 'TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA';
export const TR_CREAR_ALERTA = 'TR_CREAR_ALERTA';
export const TR_NUEVA_ALERTA = 'TR_NUEVA_ALERTA';
export const TR_HORA_ENVIO = 'TR_HORA_ENVIO';
export const TR_NO_EXISTEN_ALERTAS = 'TR_NO_EXISTEN_ALERTAS';
export const TR_ELIMINAR_ALERTAS = 'TR_ELIMINAR_ALERTAS';
export const TR_VER = 'TR_VER';
export const TR_RESOLVER = 'TR_RESOLVER';
export const TR_RESUELTA = 'TR_RESUELTA';
export const TR_MIS_ALERTAS = 'TR_MIS_ALERTAS';
export const TR_ALERTA_MODIFICADA_CORRECTAMENTE = 'TR_ALERTA_MODIFICADA_CORRECTAMENTE';
export const TR_ALERTA_CREADA_CORRECTAMENTE = 'TR_ALERTA_CREADA_CORRECTAMENTE';
export const TR_NO_HAY_ALERTAS = 'TR_NO_HAY_ALERTAS';
export const TR_FICHERO_CARGADO_CORRECTAMENTE = 'TR_FICHERO_CARGADO_CORRECTAMENTE';
export const TR_ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS = 'TR_ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS';
export const TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO = 'TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO';
export const TR_LOGS = 'TR_LOGS';
export const TR_NO_EXISTEN_LOGS = 'TR_NO_EXISTEN_LOGS';
export const TR_TIPOS_ZONAS_APARCAMIENTOS = 'TR_TIPOS_ZONAS_APARCAMIENTOS';
export const TR_ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS = 'TR_ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS';
export const TR_TIPO_ZONA_APARCAMIENTO = 'TR_TIPO_ZONA_APARCAMIENTO';
export const TR_NUEVO_TIPO_ZONA_APARCAMIENTO = 'TR_NUEVO_TIPO_ZONA_APARCAMIENTO';
export const TR_TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE = 'TR_TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE';
export const TR_TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE = 'TR_TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE';
export const TR_NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO = 'TR_NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO';
export const TR_RUTAS = 'TR_RUTAS';
export const TR_NO_EXISTEN_RUTAS = 'TR_NO_EXISTEN_RUTAS';
export const TR_ELIMINAR_RUTAS = 'TR_ELIMINAR_RUTAS';
export const TR_RUTA = 'TR_RUTA';
export const TR_NUEVA_RUTA = 'TR_NUEVA_RUTA';
export const TR_RUTA_MODIFICADA_CORRECTAMENTE = 'TR_RUTA_MODIFICADA_CORRECTAMENTE';
export const TR_RUTA_CREADA_CORRECTAMENTE = 'TR_RUTA_CREADA_CORRECTAMENTE';
export const TR_CALLES = 'TR_CALLES';
export const TR_NO_EXISTEN_CALLES = 'TR_NO_EXISTEN_CALLES';
export const TR_ELIMINAR_CALLES = 'TR_ELIMINAR_CALLES';
export const TR_N_PLAZAS = 'TR_N_PLAZAS';
export const TR_N_PLAZAS_OBLIGATIORIO = 'TR_N_PLAZAS_OBLIGATIORIO';
export const TR_TIPO_DE_CALLE = 'TR_TIPO_DE_CALLE';
export const TR_CALLE = 'TR_CALLE';
export const TR_NUEVA_CALLE = 'TR_NUEVA_CALLE';
export const TR_TIPO_DE_ZONA_OBLIGATORIO = 'TR_TIPO_DE_ZONA_OBLIGATORIO';
export const TR_TIPO_DE_CALLE_OBLIGATORIO = 'TR_TIPO_DE_CALLE_OBLIGATORIO';
export const TR_RUTA_OBLIGATORIA = 'TR_RUTA_OBLIGATORIA';
export const TR_ESPACIOS_OBLIGATORIOS = 'TR_ESPACIOS_OBLIGATORIOS';
export const TR_CALLE_MODIFICADA_CORRECTAMENTE = 'TR_CALLE_MODIFICADA_CORRECTAMENTE';
export const TR_CALLE_CREADA_CORRECTAMENTE = 'TR_CALLE_CREADA_CORRECTAMENTE';
export const TR_PARQUIMETROS = 'TR_PARQUIMETROS';
export const TR_NO_EXISTEN_PARQUIMETROS = 'TR_NO_EXISTEN_PARQUIMETROS';
export const TR_ELIMINAR_PARQUIMETROS = 'TR_ELIMINAR_PARQUIMETROS';
export const TR_CODIGO = 'TR_CODIGO';
export const TR_DESCRIPCION = 'TR_DESCRIPCION';
export const TR_LATITUD = 'TR_LATITUD';
export const TR_LONGITUD = 'TR_LONGITUD';
export const TR_PARQUIMETRO = 'TR_PARQUIMETRO';
export const TR_NUEVO_PARQUIMETRO = 'TR_NUEVO_PARQUIMETRO';
export const TR_CODIGO_OBLIGATORIO = 'TR_CODIGO_OBLIGATORIO';
export const TR_DESCRIPCION_OBLIGATORIA = 'TR_DESCRIPCION_OBLIGATORIA';
export const TR_LATITUD_OBLIGATORIA = 'TR_LATITUD_OBLIGATORIA';
export const TR_LONGITUD_OBLIGATORIA = 'TR_LONGITUD_OBLIGATORIA';
export const TR_PARQUIMETRO_MODIFICADO_CORRECTAMENTE = 'TR_PARQUIMETRO_MODIFICADO_CORRECTAMENTE';
export const TR_PARQUIMETRO_CREADO_CORRECTAMENTE = 'TR_PARQUIMETRO_CREADO_CORRECTAMENTE';
export const TR_COORDENADAS = 'TR_COORDENADAS';
export const TR_COORDENADAS_OBLIGATORIAS = 'TR_COORDENADAS_OBLIGATORIAS';
export const TR_PARQUIMETROS_INCIDENCIAS = 'TR_PARQUIMETROS_INCIDENCIAS';
export const TR_PARQUIMETRO_INCIDENCIA = 'TR_PARQUIMETRO_INCIDENCIA';
export const TR_NUEVO_PARQUIMETRO_INCIDENCIA = 'TR_NUEVO_PARQUIMETRO_INCIDENCIA';
export const TR_ESTADO_INCIDENCIA = 'TR_ESTADO_INCIDENCIA';
export const TR_OBSERVACIONES = 'TR_OBSERVACIONES';
export const TR_PARQUIMETRO_OBLIGATORIO = 'TR_PARQUIMETRO_OBLIGATORIO';
export const TR_ESTADO_OBLIGATORIO = 'TR_ESTADO_OBLIGATORIO';
export const TR_CONTROLADOR = 'TR_CONTROLADOR';
export const TR_FECHA_SOLUCIONADA = 'TR_FECHA_SOLUCIONADA';
export const TR_SOLUCIONADA_POR_CONTROLADOR = 'TR_SOLUCIONADA_POR_CONTROLADOR';
export const TR_NO_EXISTEN_INCIDENCIAS = 'TR_NO_EXISTEN_INCIDENCIAS';
export const TR_ESTADO = 'TR_ESTADO';
export const TR_SOLUCIONADA = 'TR_SOLUCIONADA';
export const TR_NO_SOLUCIONADA = 'TR_NO_SOLUCIONADA';
export const TR_PARQUIMETRO_RECAUDACION = 'TR_PARQUIMETRO_RECAUDACION';
export const TR_PARQUIMETRO_RECAUDACIONES = 'TR_PARQUIMETRO_RECAUDACIONES';
export const TR_EFECTIVO = 'TR_EFECTIVO';
export const TR_NUMERO_DE_BRIDA = 'TR_NUMERO_DE_BRIDA';
export const TR_NO_EXISTEN_RECAUDACIONES = 'TR_NO_EXISTEN_RECAUDACIONES';
export const TR_NUEVO_PARQUIMETRO_RECAUDACION = 'TR_NUEVO_PARQUIMETRO_RECAUDACION';
export const TR_EXPORTAR_EXCEL = 'TR_EXPORTAR_EXCEL';
export const TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES = 'TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES';
export const TR_PLAZAS_PERDIDAS = 'TR_PLAZAS_PERDIDAS';
export const TR_NO_EXISTEN_PLAZAS_PERDIDAS = 'TR_NO_EXISTEN_PLAZAS_PERDIDAS';
export const TR_MOTIVO = 'TR_MOTIVO';
export const TR_PLAZA_PERDIDA = 'TR_PLAZA_PERDIDA';
export const TR_ELEMENTO = 'TR_ELEMENTO';
export const TR_ELEMENTOS = 'TR_ELEMENTOS';
export const TR_ELIMINADO_CORRECTAMENTE = 'TR_ELIMINADO_CORRECTAMENTE';
export const TR_NO_SE_HA_ENCONTRADO = 'TR_NO_SE_HA_ENCONTRADO';
export const TR_NO_SE_HAN_ENCONTRADO = 'TR_NO_SE_HAN_ENCONTRADO';
export const TR_NO_SE_PERMITE_BORRAR = 'TR_NO_SE_PERMITE_BORRAR';
export const TR_NO_SE_PERMITEN_BORRAR = 'TR_NO_SE_PERMITEN_BORRAR';
export const TR_NO_TIENES_PERMISO_PARA_ELIMINAR = 'TR_NO_TIENES_PERMISO_PARA_ELIMINAR';
export const TR_NO_TIENES_PERMISOS_PARA_ELIMINAR = 'TR_NO_TIENES_PERMISOS_PARA_ELIMINAR';
export const TR_ADVERACION_FIRMADA = 'TR_ADVERACION_FIRMADA';
export const TR_ARTICULO_DE_LA_DENUNCIA = 'TR_ARTICULO_DE_LA_DENUNCIA';
export const TR_BORRAR_FILTROS = 'TR_BORRAR_FILTROS';
export const TR_DENUNCIA_FIRMADA = 'TR_DENUNCIA_FIRMADA';
export const TR_ESTADO_ADVERACION = 'TR_ESTADO_ADVERACION';
export const TR_ESTADO_DE_TRAMITE_DE_DENUNCIA = 'TR_ESTADO_DE_TRAMITE_DE_DENUNCIA';
export const TR_MATRICULA = 'TR_MATRICULA';
export const TR_NUMERO_DE_DENUNCIA = 'TR_NUMERO_DE_DENUNCIA';
export const TR_RATIFICADOR = 'TR_RATIFICADOR';
export const TR_ADVERADA = 'TR_ADVERADA';
export const TR_ENVIADA = 'TR_ENVIADA';
export const TR_NO_ENVIADA = 'TR_NO_ENVIADA';
export const TR_PENDIENTE = 'TR_PENDIENTE';
export const TR_DENUNCIAS = 'TR_DENUNCIAS';
export const TR_ESTADO_DE_ADVERACION = 'TR_ESTADO_DE_ADVERACION';
export const TR_FECHA_Y_HORA_DE_DENUNCIA = 'TR_FECHA_Y_HORA_DE_DENUNCIA';
export const TR_MARCA = 'TR_MARCA';
export const TR_MODELO = 'TR_MODELO';
export const TR_TIPO_DE_VEHICULO = 'TR_TIPO_DE_VEHICULO';
export const TR_GESTION = 'TR_GESTION';
export const TR_INCIDENCIAS_PARQUIMETROS = 'TR_INCIDENCIAS_PARQUIMETROS';
export const TR_INCIDENCIA_PARQUIMETRO = 'TR_INCIDENCIA_PARQUIMETRO';
export const TR_NUEVA_INCIDENCIA_PARQUIMETRO = 'TR_NUEVA_INCIDENCIA_PARQUIMETRO';
export const TR_LA_POSICION_ES_OBLIGATORIA = 'TR_LA_POSICION_ES_OBLIGATORIA';
export const TR_POSICION = 'TR_POSICION';
export const TR_NO_EXISTEN_DENUNCIAS = 'TR_NO_EXISTEN_DENUNCIAS';
export const TR_RECAUDACIONES_PARQUIMETROS = 'TR_RECAUDACIONES_PARQUIMETROS';
export const TR_RECAUDACION_PARQUIMETRO = 'TR_RECAUDACION_PARQUIMETRO';
export const TR_ARTICULOS = 'TR_ARTICULOS';
export const TR_NO_EXISTEN_ARTICULOS = 'TR_NO_EXISTEN_ARTICULOS';
export const TR_ELIMINAR_ARTICULOS = 'TR_ELIMINAR_ARTICULOS';
export const TR_TIPO_DE_LEY = 'TR_TIPO_DE_LEY';
export const TR_CODIGO_MUNICIPAL = 'TR_CODIGO_MUNICIPAL';
export const TR_IMPORTE = 'TR_IMPORTE';
export const TR_TABLAS_MAESTRAS = 'TR_TABLAS_MAESTRAS';
export const TR_IMPORTE_ANULACION = 'TR_IMPORTE_ANULACION';
export const TR_DENUNCIA_ANULABLE = 'TR_DENUNCIA_ANULABLE';
export const TR_ANULABLE = 'TR_ANULABLE';
export const TR_NO_ANULABLE = 'TR_NO_ANULABLE';
export const TR_ARTICULO = 'TR_ARTICULO';
export const TR_APARTADO = 'TR_APARTADO';
export const TR_OPCION = 'TR_OPCION';
export const TR_ARTICULO_OBLIGATORIO = 'TR_ARTICULO_OBLIGATORIO';
export const TR_APARTADO_OBLIGATORIO = 'TR_APARTADO_OBLIGATORIO';
export const TR_OPCION_OBLIGATORIA = 'TR_OPCION_OBLIGATORIA';
export const TR_NUEVO_ARTICULO = 'TR_NUEVO_ARTICULO';
export const TR_TEXTO_A_MOSTRAR_EN_PANTALLA = 'TR_TEXTO_A_MOSTRAR_EN_PANTALLA';
export const TR_TEXTO_DE_IMPRESION = 'TR_TEXTO_DE_IMPRESION';
export const TR_FORMATO_DE_VISUALIZACION_EN_TICKET = 'TR_FORMATO_DE_VISUALIZACION_EN_TICKET';
export const TR_TEXTO_DE_ANULACION = 'TR_TEXTO_DE_ANULACION';
export const TR_NUMERO_DE_FOTOGRAFIAS_PARA_CONTROLADOR = 'TR_NUMERO_DE_FOTOGRAFIAS_PARA_CONTROLADOR';
export const TR_TIEMPO_DE_TICKET_EXCEDEDIDO = 'TR_TIEMPO_DE_TICKET_EXCEDEDIDO';
export const TR_CODIGO_CANCELACION = 'TR_CODIGO_CANCELACION';
export const TR_CODIGO_CANCELACION_OBLIGATORIO = 'TR_CODIGO_CANCELACION_OBLIGATORIO';
export const TR_FECHA_HORA_REFERENCIA_ANULACION_APP = 'TR_FECHA_HORA_REFERENCIA_ANULACION_APP';
export const TR_MINUTOS_DE_EXCESO_PERMITIDOS_PARA_ANULACION_EN_APP = 'TR_MINUTOS_DE_EXCESO_PERMITIDOS_PARA_ANULACION_EN_APP';
export const TR_TIPO_DE_CANCELACION = 'TR_TIPO_DE_CANCELACION';
export const TR_TIPO_DE_LEY_OBLIGATORIO = 'TR_TIPO_DE_LEY_OBLIGATORIO';
export const TR_CODIGO_MUNICIPAL_OBLIGATORIO = 'TR_CODIGO_MUNICIPAL_OBLIGATORIO';
export const TR_TEXTO_A_MOSTRAR_EN_PANTALLA_OBLIGATORIO = 'TR_TEXTO_A_MOSTRAR_EN_PANTALLA_OBLIGATORIO';
export const TR_TEXTO_DE_IMPRESION_OBLIGATORIO = 'TR_TEXTO_DE_IMPRESION_OBLIGATORIO';
export const TR_FORMATO_OBLIGATORIO = 'TR_FORMATO_OBLIGATORIO';
export const TR_IMPORTE_OBLIGATORIO = 'TR_IMPORTE_OBLIGATORIO';
export const TR_TEXTO_A_LA_ANULACION_OBLIGATORIO = 'TR_TEXTO_A_LA_ANULACION_OBLIGATORIO';
export const TR_DEBE_INDICAR_EL_NUMERO_DE_FOTOGRAFIAS = 'TR_DEBE_INDICAR_EL_NUMERO_DE_FOTOGRAFIAS';
export const TR_MINUTOS_EN_SCADA_OBLIGATORIO = 'TR_MINUTOS_EN_SCADA_OBLIGATORIO';
export const TR_MINUTOS_DE_EXCESO_OBLIGATORIO = 'TR_MINUTOS_DE_EXCESO_OBLIGATORIO';
export const TR_TIPO_DE_CANCELACION_OBLIGATORIO = 'TR_TIPO_DE_CANCELACION_OBLIGATORIO';
export const TR_IMPORTE_ANULACION_OBLIGATORIO = 'TR_IMPORTE_ANULACION_OBLIGATORIO';
export const TR_ARTICULO_MODIFICADO_CORRECTAMENTE = 'TR_ARTICULO_MODIFICADO_CORRECTAMENTE';
export const TR_ARTICULO_CREADO_CORRECTAMENTE = 'TR_ARTICULO_CREADO_CORRECTAMENTE';
export const TR_SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_99 = 'TR_SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_99';
export const TR_CONFIGURACION_DE_LISTAS_NEGRAS = 'TR_CONFIGURACION_DE_LISTAS_NEGRAS';
export const TR_NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS = 'TR_NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS';
export const TR_ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS = 'TR_ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS';
export const TR_MULTAS_ACUMULADAS = 'TR_MULTAS_ACUMULADAS';
export const TR_DIAS = 'TR_DIAS';
export const TR_CONFIGURACION_LISTA_NEGRA = 'TR_CONFIGURACION_LISTA_NEGRA';
export const TR_NUEVA_CONFIGURACION_LISTA_NEGRA = 'TR_NUEVA_CONFIGURACION_LISTA_NEGRA';
export const TR_NUMERO_DE_MULTAS_ACUMULADAS_SIN_ANULAR_NI_INVALIDAR = 'TR_NUMERO_DE_MULTAS_ACUMULADAS_SIN_ANULAR_NI_INVALIDAR';
export const TR_DIAS_ATRAS_PARA_REALIZAR_LA_MEDICION = 'TR_DIAS_ATRAS_PARA_REALIZAR_LA_MEDICION';
export const TR_NUMERO_DE_MULTAS_OBLIGATORIO = 'TR_NUMERO_DE_MULTAS_OBLIGATORIO';
export const TR_NUMERO_DE_DIAS_OBLIGATORIO = 'TR_NUMERO_DE_DIAS_OBLIGATORIO';
export const TR_CONFIGURACION_DE_LISTA_NEGRA_MODIFICADA_CORRECTAMENTE = 'TR_CONFIGURACION_DE_LISTA_NEGRA_MODIFICADA_CORRECTAMENTE';
export const TR_CONFIGURACION_DE_LISTA_NEGRA_CREADA_CORRECTAMENTE = 'TR_CONFIGURACION_DE_LISTA_NEGRA_CREADA_CORRECTAMENTE';
export const TR_IDENTIFICADOR = 'TR_IDENTIFICADOR';
export const TR_CONTROLADORES = 'TR_CONTROLADORES';
export const TR_NO_EXISTEN_INFORMES_DE_CONTROLADORES = 'TR_NO_EXISTEN_INFORMES_DE_CONTROLADORES';
export const TR_INICIO_DE_JORNADA = 'TR_INICIO_DE_JORNADA';
export const TR_FIN_DE_JORNADA = 'TR_FIN_DE_JORNADA';
export const TR_HORAS_EFECTIVAS = 'TR_HORAS_EFECTIVAS';
export const TR_DENUNCIAS_PUESTAS = 'TR_DENUNCIAS_PUESTAS';
export const TR_DENUNCIAS_INVALIDADAS = 'TR_DENUNCIAS_INVALIDADAS';
export const TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES = 'TR_SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES';
export const TR_ADVERACION = 'TR_ADVERACION';
export const TR_DETALLES_DENUNCIA = 'TR_DETALLES_DENUNCIA';
export const TR_CIUDAD = 'TR_CIUDAD';
export const TR_IMPORTE_A_PAGAR = 'TR_IMPORTE_A_PAGAR';
export const TR_ESTADO_DE_LA_DENUNCIA = 'TR_ESTADO_DE_LA_DENUNCIA';
export const TR_MAPA_DE_SITUACION = 'TR_MAPA_DE_SITUACION';
export const TR_TRAMITE_DE_LA_DENUNCIA = 'TR_TRAMITE_DE_LA_DENUNCIA';
export const TR_DIRECCION = 'TR_DIRECCION';
export const TR_TIPO_DE_ZONA = 'TR_TIPO_DE_ZONA';
export const TR_NOTIFICACION_DE_LA_GRUA = 'TR_NOTIFICACION_DE_LA_GRUA';
export const TR_LEY = 'TR_LEY';
export const TR_LEY_SIN_TICKET = 'TR_LEY_SIN_TICKET';
export const TR_LEY_EXCESO_TICKET = 'TR_LEY_EXCESO_TICKET';
export const TR_VEHICULO = 'TR_VEHICULO';
export const TR_FIRMAS = 'TR_FIRMAS';
export const TR_FIRMA_DEL_CONTROLADOR = 'TR_FIRMA_DEL_CONTROLADOR';
export const TR_FIRMA_DEL_ADVERADOR = 'TR_FIRMA_DEL_ADVERADOR';
export const TR_FOTOGRAFIAS = 'TR_FOTOGRAFIAS';
export const TR_FOTOGRAFIAS_DEL_CONTROLADOR = 'TR_FOTOGRAFIAS_DEL_CONTROLADOR';
export const TR_EXPORTAR_A_PDF = 'TR_EXPORTAR_A_PDF';
export const TR_AVISADA = 'TR_AVISADA';
export const TR_NO_AVISADA = 'TR_NO_AVISADA';
export const TR_ESCOJA_UN_MOTIVO = 'TR_ESCOJA_UN_MOTIVO';
export const TR_ANULAR = 'TR_ANULAR';
export const TR_ANULACION = 'TR_ANULACION';
export const T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS = 'T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_INVALIDAR = 'TR_INVALIDAR';
export const TR_INVALIDACION = 'TR_INVALIDACION';
export const TR_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS = 'TR_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_OBSERVACIONES_DE_INVALIDACION = 'TR_OBSERVACIONES_DE_INVALIDACION';
export const TR_RECTIFICAR_ANULACION_INVALIDACION = 'TR_RECTIFICAR_ANULACION_INVALIDACION';
export const TR_RECTIFICAR = 'TR_RECTIFICAR';
export const TR_RECTIFICACION = 'TR_RECTIFICACION';
export const TR_RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS = 'TR_RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_ENVIAR_A_TRAMITE = 'TR_ENVIAR_A_TRAMITE';
export const TR_ENVIO_A_TRAMITE = 'TR_ENVIO_A_TRAMITE';
export const TR_ENVIO_A_TRAMITE_DE_LAS_DENUNCIAS_SELECCIONADAS = 'TR_ENVIO_A_TRAMITE_DE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_ADVERAR_DENUNCIA = 'TR_ADVERAR_DENUNCIA';
export const TR_ANULAR_ADVERACION = 'TR_ANULAR_ADVERACION';
export const TR_FOTOGRAFIAS_DEL_ADVERADOR = 'TR_FOTOGRAFIAS_DEL_ADVERADOR';
export const TR_DENUNCIA_ADVERADA = 'TR_DENUNCIA_ADVERADA';
export const TR_ADVERACION_ANULADA = 'TR_ADVERACION_ANULADA';
export const TR_ATRAS = 'TR_ATRAS';
export const TR_VOLVER = 'TR_VOLVER';
export const TR_ADVERADOR = 'TR_ADVERADOR';
export const TR_FIRMA = 'TR_FIRMA';
export const TR_FECHA_DENUNCIA = 'TR_FECHA_DENUNCIA';
export const TR_HORA_DENUNCIA = 'TR_HORA_DENUNCIA';
export const TR_DATOS_DENUNCIA = 'TR_DATOS_DENUNCIA';
export const TR_INFORME_CONTROLADORES = 'TR_INFORME_CONTROLADORES';
export const TR_HORAS = 'TR_HORAS';
export const TR_INVALIDADAS = 'TR_INVALIDADAS';
export const TR_SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION = 'TR_SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION';
export const TR_DENUNCIA_EXPORTADA_COMO_PDF_A = 'TR_DENUNCIA_EXPORTADA_COMO_PDF_A';
export const TR_METODOS_DE_CANCELACION_DE_DENUNCIA = 'TR_METODOS_DE_CANCELACION_DE_DENUNCIA';
export const TR_NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA = 'TR_NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA';
export const TR_ELIMINAR_METODOS = 'TR_ELIMINAR_METODOS';
export const TR_METODO_DE_CANCELACION_DE_DENUNCIA = 'TR_METODO_DE_CANCELACION_DE_DENUNCIA';
export const TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA = 'TR_NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA';
export const TR_METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE = 'TR_METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE';
export const TR_METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE = 'TR_METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE';
export const TR_COLORES_DE_VEHICULOS = 'TR_COLORES_DE_VEHICULOS';
export const TR_NO_EXISTEN_COLORES_DE_VEHICULOS = 'TR_NO_EXISTEN_COLORES_DE_VEHICULOS';
export const TR_ELIMINAR_COLORES = 'TR_ELIMINAR_COLORES';
export const TR_COLOR_DE_VEHICULO = 'TR_COLOR_DE_VEHICULO';
export const TR_NUEVO_COLOR_DE_VEHICULO = 'TR_NUEVO_COLOR_DE_VEHICULO';
export const TR_COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE = 'TR_COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE';
export const TR_COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE = 'TR_COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE';
export const TR_MARCAS_DE_VEHICULOS = 'TR_MARCAS_DE_VEHICULOS';
export const TR_NO_EXISTEN_MARCAS_DE_VEHICULOS = 'TR_NO_EXISTEN_MARCAS_DE_VEHICULOS';
export const TR_ELIMINAR_MARCAS = 'TR_ELIMINAR_MARCAS';
export const TR_MARCA_DE_VEHICULO = 'TR_MARCA_DE_VEHICULO';
export const TR_NUEVA_MARCA_DE_VEHICULO = 'TR_NUEVA_MARCA_DE_VEHICULO';
export const TR_MARCA_DE_VEHICULO_MODIFICADA_CORRECTAMENTE = 'TR_MARCA_DE_VEHICULO_MODIFICADA_CORRECTAMENTE';
export const TR_MARCA_DE_VEHICULO_CREADA_CORRECTAMENTE = 'TR_MARCA_DE_VEHICULO_CREADA_CORRECTAMENTE';
export const TR_MODELOS_DE_VEHICULOS = 'TR_MODELOS_DE_VEHICULOS';
export const TR_NO_EXISTEN_MODELOS_DE_VEHICULOS = 'TR_NO_EXISTEN_MODELOS_DE_VEHICULOS';
export const TR_ELIMINAR_MODELOS = 'TR_ELIMINAR_MODELOS';
export const TR_MODELO_OBLIGATORIO = 'TR_MODELO_OBLIGATORIO';
export const TR_MODELO_DE_VEHICULO = 'TR_MODELO_DE_VEHICULO';
export const TR_NUEVO_MODELO_DE_VEHICULO = 'TR_NUEVO_MODELO_DE_VEHICULO';
export const TR_MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE = 'TR_MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE';
export const TR_MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE = 'TR_MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE';
export const TR_VEHICULOS = 'TR_VEHICULOS';
export const TR_NO_EXISTEN_VEHICULOS = 'TR_NO_EXISTEN_VEHICULOS';
export const TR_ELIMINAR_VEHICULOS = 'TR_ELIMINAR_VEHICULOS';
export const TR_MASCARA = 'TR_MASCARA';
export const TR_ESPECIAL = 'TR_ESPECIAL';
export const TR_NUEVO_VEHICULO = 'TR_NUEVO_VEHICULO';
export const TR_MATRICULA_OBLIGATORIA = 'TR_MATRICULA_OBLIGATORIA';
export const TR_MASCARA_OBLIGATORIA = 'TR_MASCARA_OBLIGATORIA';
export const TR_VEHICULO_MODIFICADO_CORRECTAMENTE = 'TR_VEHICULO_MODIFICADO_CORRECTAMENTE';
export const TR_VEHICULO_CREADO_CORRECTAMENTE = 'TR_VEHICULO_CREADO_CORRECTAMENTE';
export const TR_RAZONES_DE_INVALIDACION = 'TR_RAZONES_DE_INVALIDACION';
export const TR_NO_EXISTEN_RAZONES = 'TR_NO_EXISTEN_RAZONES';
export const TR_ELIMINAR_RAZONES = 'TR_ELIMINAR_RAZONES';
export const TR_RAZONES = 'TR_RAZONES';
export const TR_RAZON = 'TR_RAZON';
export const TR_NUEVA_RAZON = 'TR_NUEVA_RAZON';
export const TR_RAZON_DE_INVALIDACION = 'TR_RAZON_DE_INVALIDACION';
export const TR_NUEVA_RAZON_DE_INVALIDACION = 'TR_NUEVA_RAZON_DE_INVALIDACION';
export const TR_RAZON_DE_INVALIDACION_MODIFICADA_CORRECTAMENTE = 'TR_RAZON_DE_INVALIDACION_MODIFICADA_CORRECTAMENTE';
export const TR_RAZON_DE_INVALIDACION_CREADA_CORRECTAMENTE = 'TR_RAZON_DE_INVALIDACION_CREADA_CORRECTAMENTE';
export const TR_UPS = 'TR_UPS';
export const TR_MANAGER_CONTROL_PLUS = 'TR_MANAGER_CONTROL_PLUS';
export const TR_INICIAR_SESION = 'TR_INICIAR_SESION';
export const TR_CARGANDO_PUNTOS = 'TR_CARGANDO_PUNTOS';
export const TR_HOLA_NOS_GUSTARIA_QUE_CAMBIARAS_TU_CONTRASENA_POR_SEGURIDAD_PLACEHOLDER = 'TR_HOLA_NOS_GUSTARIA_QUE_CAMBIARAS_TU_CONTRASENA_POR_SEGURIDAD_PLACEHOLDER';
export const TR_RECUERDA_NO_INTRODUCIR_LA_MISMA_CONTRASENA_QUE_LAS_ULTIMAS_3_VECES = 'TR_RECUERDA_NO_INTRODUCIR_LA_MISMA_CONTRASENA_QUE_LAS_ULTIMAS_3_VECES';
export const TR_TODAS = 'TR_TODAS';
export const TR_PUESTAS = 'TR_PUESTAS';
export const TR_ADVERACIONES_PENDIENTES = 'TR_ADVERACIONES_PENDIENTES';
export const TR_INVALIDADAS_Y_ANULADAS = 'TR_INVALIDADAS_Y_ANULADAS';
export const TR_BUSCAR_DENUNCIA = 'TR_BUSCAR_DENUNCIA';
export const TR_HORA_Y_FECHA = 'TR_HORA_Y_FECHA';
export const TR_NUMERO_DE_MATRICULA = 'TR_NUMERO_DE_MATRICULA';
export const TR_MARCA_MODELO_Y_COLOR = 'TR_MARCA_MODELO_Y_COLOR';
export const TR_CALLE_Y_NUMERO = 'TR_CALLE_Y_NUMERO';
export const TR_LEY_INCUMPLIDA = 'TR_LEY_INCUMPLIDA';
export const TR_CODIGO_C60 = 'TR_CODIGO_C60';
export const TR_NINGUNA_ADVERACION = 'TR_NINGUNA_ADVERACION';
export const TR_INVALIDAR_DENUNCIA = 'TR_INVALIDAR_DENUNCIA';
export const TR_AVISAR_A_GRUA = 'TR_AVISAR_A_GRUA';
export const TR_ESTADO_DE_LA_ADVERACION = 'TR_ESTADO_DE_LA_ADVERACION';
export const TR_SI_AVISO_MARCADO = 'TR_SI_AVISO_MARCADO';
export const TR_IR_A_MAPA = 'TR_IR_A_MAPA';
export const TR_ULTIMA_BUSQUEDA = 'TR_ULTIMA_BUSQUEDA';
export const TR_ESCANEAR_CODIGO = 'TR_ESCANEAR_CODIGO';
export const TR_CHAT = 'TR_CHAT';
export const TR_HOY = 'TR_HOY';
export const TR_Ayer = 'TR_Ayer';
export const TR_ESCRIBIR_MENSAJE = 'TR_ESCRIBIR_MENSAJE';
export const TR_EL_USUARIO_HA_SIDO_BLOQUEADO = 'TR_EL_USUARIO_HA_SIDO_BLOQUEADO';
export const TR_NO_PODRAS_VOLVERLO_A_INTENTAR_HASTA_PASADAS_2_HORAS = 'TR_NO_PODRAS_VOLVERLO_A_INTENTAR_HASTA_PASADAS_2_HORAS';
export const TR_DE_ACUERDO = 'TR_DE_ACUERDO';
export const TR_HOLA_PLACEHOLDER = 'TR_HOLA_PLACEHOLDER';
export const TR_INDICA_LA_RUTA_QUE_VAS_A_REALIZAR_EN_TU_JORNADA_LABORAL_DE_HOY = 'TR_INDICA_LA_RUTA_QUE_VAS_A_REALIZAR_EN_TU_JORNADA_LABORAL_DE_HOY';
export const TR_INICIAR_RUTA = 'TR_INICIAR_RUTA';
export const TR_CONTRASENA_CAMBIADA_CORRECTAMENTE = 'TR_CONTRASENA_CAMBIADA_CORRECTAMENTE';
export const TR_PUBLICAR_MENSAJE = 'TR_PUBLICAR_MENSAJE';
export const TR_MENSAJES_A_TODOS = 'TR_MENSAJES_A_TODOS';
export const TR_NO_EXISTEN_MENSAJES = 'TR_NO_EXISTEN_MENSAJES';
export const TR_MENSAJE = 'TR_MENSAJE';
export const TR_CADUCIDAD = 'TR_CADUCIDAD';
export const TR_NUEVO_MENSAJE = 'TR_NUEVO_MENSAJE';
export const TR_FECHA_EXPIRACION = 'TR_FECHA_EXPIRACION';
export const TR_MENSAJE_OBLIGATORIO = 'TR_MENSAJE_OBLIGATORIO';
export const TR_FECHA_DE_EXPIRACION_OBLIGATORIA = 'TR_FECHA_DE_EXPIRACION_OBLIGATORIA';
export const TR_MENSAJE_CREADO_CORRECTAMENTE = 'TR_MENSAJE_CREADO_CORRECTAMENTE';
export const TR_SOLO_ADMITE_UN_FICHERO = 'TR_SOLO_ADMITE_UN_FICHERO';
export const TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO_IMAGEN = 'TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO_IMAGEN';
export const TR_TIPO_DE_FICHERO_NO_VALIDO = 'TR_TIPO_DE_FICHERO_NO_VALIDO';
export const TR_ESTADO_PLACEHOLDER = 'TR_ESTADO_PLACEHOLDER';
export const TR_FINALIZAR_JORNADA = 'TR_FINALIZAR_JORNADA';
export const TR_HISTORICO = 'TR_HISTORICO';
export const TR_VER_MAS = 'TR_VER_MAS';
export const TR_ESTAS_SEGURO_DE_FINALIZAR_LA_JORNADA = 'TR_ESTAS_SEGURO_DE_FINALIZAR_LA_JORNADA';
export const TR_AL_FINALIZAR_LA_JORNADA_TAMBIEN_CERRARAS_LA_SESION_DE_TU_PERFIL = 'TR_AL_FINALIZAR_LA_JORNADA_TAMBIEN_CERRARAS_LA_SESION_DE_TU_PERFIL';
export const TR_NO_GRACIAS = 'TR_NO_GRACIAS';
export const TR_INICIO_JORNADA = 'TR_INICIO_JORNADA';
export const TR_FIN_JORNADA = 'TR_FIN_JORNADA';
export const TR_NO_TIENES_NINGUNA_JORNADA_REGISTRADA = 'TR_NO_TIENES_NINGUNA_JORNADA_REGISTRADA';
export const TR_EDITAR_PERFIL = 'TR_EDITAR_PERFIL';
export const TR_INFORMACION_PERSONAL = 'TR_INFORMACION_PERSONAL';
export const TR_SELECCIONAR_IMAGEN = 'TR_SELECCIONAR_IMAGEN';
export const TR_HACER_UNA_FOTO = 'TR_HACER_UNA_FOTO';
export const TR_SELECCIONAR_DE_GALERIA = 'TR_SELECCIONAR_DE_GALERIA';
export const TR_INFORMACION_ACTUALIZADA = 'TR_INFORMACION_ACTUALIZADA';
export const TR_INFORMACION = 'TR_INFORMACION';
export const TR_AJUSTES_IMPRESORA = 'TR_AJUSTES_IMPRESORA';
export const TR_DAR_PERMISOS = 'TR_DAR_PERMISOS';
export const TR_PERMISO_DENEGADO = 'TR_PERMISO_DENEGADO';
export const TR_NO_SE_DISPONE_DE_LOS_PERMISOS_NECESARIOS_PARA_CONTINUAR = 'TR_NO_SE_DISPONE_DE_LOS_PERMISOS_NECESARIOS_PARA_CONTINUAR';
export const TR_DENUNCIAS_ANULADAS = 'TR_DENUNCIAS_ANULADAS';
export const TR_DENUNCIAS_RECTIFICADAS = 'TR_DENUNCIAS_RECTIFICADAS';
export const TR_DESHACER_ENVIO_A_TRAMITE = 'TR_DESHACER_ENVIO_A_TRAMITE';
export const TR_DESHACER = 'TR_DESHACER';
export const TR_DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE = 'TR_DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE';
export const TR_DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE = 'TR_DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE';
export const TR_PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS = 'TR_PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS = 'TR_PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS';
export const TR_CONFIRMACION_DE_ENVIO_A_TRAMITE = 'TR_CONFIRMACION_DE_ENVIO_A_TRAMITE';
export const TR_ENVIAR_A_TRAMITE_TODAS_AQUELLAS_DENUNCIAS_PENDIENTES_DE_ENVIAR = 'TR_ENVIAR_A_TRAMITE_TODAS_AQUELLAS_DENUNCIAS_PENDIENTES_DE_ENVIAR';
export const TR_ESCOJA_UNA_FECHA_ANTES_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_PENDIENTES_DE_ENVIAR = 'TR_ESCOJA_UNA_FECHA_ANTES_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_PENDIENTES_DE_ENVIAR';
export const TR_EXPORTAR_LISTA_NEGRA = 'TR_EXPORTAR_LISTA_NEGRA';
export const TR_HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA = 'TR_HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA';
export const TR_FICHERO_DE_ENVIO_A_TRAMITE = 'TR_FICHERO_DE_ENVIO_A_TRAMITE';
export const TR_DENUNCIA_EXPORTADA = 'TR_DENUNCIA_EXPORTADA';
export const TR_DESCARTAR = 'TR_DESCARTAR';
export const TR_MOTIVO_INFRACCION = 'TR_MOTIVO_INFRACCION';
export const TR_DATOS_VEHICULO = 'TR_DATOS_VEHICULO';
export const TR_INFORMACION_DE_LA_VIA = 'TR_INFORMACION_DE_LA_VIA';
export const TR_SIGUIENTE = 'TR_SIGUIENTE';
export const TR_DATOS_ESTACIONAMIENTO = 'TR_DATOS_ESTACIONAMIENTO';
export const TR_BUSCAR_MARCA = 'TR_BUSCAR_MARCA';
export const TR_BUSCAR_MODELO = 'TR_BUSCAR_MODELO';
export const TR_MATRICULA_MANUAL = 'TR_MATRICULA_MANUAL';
export const TR_MATRICULA_POR_VOZ = 'TR_MATRICULA_POR_VOZ';
export const TR_COMPRUEBA_LA_MATRICULA_MANUALMENTE = 'TR_COMPRUEBA_LA_MATRICULA_MANUALMENTE';
export const TR_INTRODUCE_MATRICULA = 'TR_INTRODUCE_MATRICULA';
export const TR_NUM_ACRON = 'TR_NUM_ACRON';
export const TR_SITUACION = 'TR_SITUACION';
export const TR_LOCALIZARME = 'TR_LOCALIZARME';
export const TR_ANYADIR_LOCALIZACION = 'TR_ANYADIR_LOCALIZACION';
export const TR_TICKET_MOVILTIK_ORA = 'TR_TICKET_MOVILTIK_ORA';
export const TR_VEHICULO_EN_LISTA_NEGRA = 'TR_VEHICULO_EN_LISTA_NEGRA';
export const TR_VEHICULO_ESPECIAL = 'TR_VEHICULO_ESPECIAL';
export const TR_VEHICULO_SIN_TICKET = 'TR_VEHICULO_SIN_TICKET';
export const TR_LA_MATRICULA_INTRODUCIDA_NO_TIENE_TICKET = 'TR_LA_MATRICULA_INTRODUCIDA_NO_TIENE_TICKET';
export const TR_VOLVER_A_INTRODUCIR_MATRICULA = 'TR_VOLVER_A_INTRODUCIR_MATRICULA';
export const TR_ANYADIR_DENUNCIA = 'TR_ANYADIR_DENUNCIA';
export const TR_LOS_VEHICULOS_ESPECIALES_NO_NECESITAN_TICKET = 'TR_LOS_VEHICULOS_ESPECIALES_NO_NECESITAN_TICKET';
export const TR_LISTAS_A_LA_QUE_ESTA_INSCRITO_EL_VEHICULO = 'TR_LISTAS_A_LA_QUE_ESTA_INSCRITO_EL_VEHICULO';
export const TR_INICIO_ESTACIONAMIENTO = 'TR_INICIO_ESTACIONAMIENTO';
export const TR_FIN_ESTACIONAMIENTO = 'TR_FIN_ESTACIONAMIENTO';
export const TR_TICKET_RESIDENTE = 'TR_TICKET_RESIDENTE';
export const TR_COMPROBAR = 'TR_COMPROBAR';
export const TR_LUGAR = 'TR_LUGAR';
export const TR_TELEFONO = 'TR_TELEFONO';
export const TR_POBLACION = 'TR_POBLACION';
export const TR_PROVINCIA = 'TR_PROVINCIA';
export const TR_CODIFICACION_DE_DENUNCIA_Y_TRAMITACION = 'TR_CODIFICACION_DE_DENUNCIA_Y_TRAMITACION';
export const TR_TIPO_DE_CODIFICACION_DE_DENUNCIA = 'TR_TIPO_DE_CODIFICACION_DE_DENUNCIA';
export const TR_TIPO_DE_CODIFICACION_DE_CANCELACION = 'TR_TIPO_DE_CODIFICACION_DE_CANCELACION';
export const TR_TIPO_DE_GESTION_DE_TRAMITACION = 'TR_TIPO_DE_GESTION_DE_TRAMITACION';
export const TR_TIPO_DE_CODIFICACION_DE_DENUNCIA_OBLIGATORIO = 'TR_TIPO_DE_CODIFICACION_DE_DENUNCIA_OBLIGATORIO';
export const TR_TIPO_DE_GESTION_DE_TRAMITACION_OBLIGATORIA = 'TR_TIPO_DE_GESTION_DE_TRAMITACION_OBLIGATORIA';
export const TR_ADVERACION_RATIFICACION_REMOTA = 'TR_ADVERACION_RATIFICACION_REMOTA';
export const TR_IDENTIFICACION_DE_DENUNCIADO = 'TR_IDENTIFICACION_DE_DENUNCIADO';
export const TR_FIRMA_DIGITAL = 'TR_FIRMA_DIGITAL';
export const TR_GENERACION_C60 = 'TR_GENERACION_C60';
export const TR_DIRECCION_OBLIGATORIA = 'TR_DIRECCION_OBLIGATORIA';
export const TR_POBLACION_OBLIGATORIA = 'TR_POBLACION_OBLIGATORIA';
export const TR_PROVINCIA_OBLIGATORIA = 'TR_PROVINCIA_OBLIGATORIA';
export const TR_TELEFONO_OBLIGATORIO = 'TR_TELEFONO_OBLIGATORIO';
export const TR_IMPRESION_DENUNCIA = 'TR_IMPRESION_DENUNCIA';
export const TR_ICONO_PARA_IMPRESION = 'TR_ICONO_PARA_IMPRESION';
export const TR_NOMBRE_DE_LA_EXPLOTACION_PARA_TICKET = 'TR_NOMBRE_DE_LA_EXPLOTACION_PARA_TICKET';
export const TR_IMPRIMIR_IMPORTE = 'TR_IMPRIMIR_IMPORTE';
export const TR_IMPRIMIR_IMPORTE_REDUCIDO = 'TR_IMPRIMIR_IMPORTE_REDUCIDO';
export const TR_ICONO_OBLIGATORIO = 'TR_ICONO_OBLIGATORIO';
export const TR_ERROR_EN_EL_SERVIDOR = 'TR_ERROR_EN_EL_SERVIDOR';
export const TR_IMPRIMIR_TIEMPO_EXCEDIDO = 'TR_IMPRIMIR_TIEMPO_EXCEDIDO';
export const TR_TITULO_1 = 'TR_TITULO_1';
export const TR_TITULO_2 = 'TR_TITULO_2';
export const TR_NUMERO = 'TR_NUMERO';
export const TR_NUMERO_CANCELACION = 'TR_NUMERO_CANCELACION';
export const TR_HORA = 'TR_HORA';
export const TR_HORA_LIMITE = 'TR_HORA_LIMITE';
export const TR_HECHO_DENUNCIADO = 'TR_HECHO_DENUNCIADO';
export const TR_LUGAR_DE_LA_DENUNCIA = 'TR_LUGAR_DE_LA_DENUNCIA';
export const TR_PRECEPTO_INFRINGIDO = 'TR_PRECEPTO_INFRINGIDO';
export const TR_IMPORTE_REDUCIDO = 'TR_IMPORTE_REDUCIDO';
export const TR_FIRMA_1 = 'TR_FIRMA_1';
export const TR_FIRMA_2 = 'TR_FIRMA_2';
export const TR_USO_INTERNO = 'TR_USO_INTERNO';
export const TR_IMPRIMIR_CODIGO_DE_BARRAS_NUM_DENUNCIA = 'TR_IMPRIMIR_CODIGO_DE_BARRAS_NUM_DENUNCIA';
export const TR_IMPRIMIR_CODIGO_DE_BARRAS_C60 = 'TR_IMPRIMIR_CODIGO_DE_BARRAS_C60';
export const TR_TITULO_OBLIGATORIO = 'TR_TITULO_OBLIGATORIO';
export const TR_NUMERO_OBLIGATORIO = 'TR_NUMERO_OBLIGATORIO';
export const TR_NUMERO_CANCELACION_OBLIGATORIO = 'TR_NUMERO_CANCELACION_OBLIGATORIO';
export const TR_VEHICULO_OBLIGATORIO = 'TR_VEHICULO_OBLIGATORIO';
export const TR_MARCA_OBLIGATORIA = 'TR_MARCA_OBLIGATORIA';
export const TR_HORA_LIMITE_OBLIGATORIA = 'TR_HORA_LIMITE_OBLIGATORIA';
export const TR_HECHO_DENUNCIADO_OBLIGATORIO = 'TR_HECHO_DENUNCIADO_OBLIGATORIO';
export const TR_LUGAR_DE_LA_DENUNCIA_OBLIGATORIO = 'TR_LUGAR_DE_LA_DENUNCIA_OBLIGATORIO';
export const TR_PRECEPTO_INFRINGIDO_OBLIGATORIO = 'TR_PRECEPTO_INFRINGIDO_OBLIGATORIO';
export const TR_IMPORTE_REDUCIDO_OBLIGATORIO = 'TR_IMPORTE_REDUCIDO_OBLIGATORIO';
export const TR_FIRMA_1_OBLIGATORIA = 'TR_FIRMA_1_OBLIGATORIA';
export const TR_FIRMA_2_OBLIGATORIA = 'TR_FIRMA_2_OBLIGATORIA';
export const TR_CONTROLADOR_OBLIGATORIO = 'TR_CONTROLADOR_OBLIGATORIO';
export const TR_ADVERADOR_OBLIGATORIO = 'TR_ADVERADOR_OBLIGATORIO';
export const TR_USO_INTERNO_OBLIGATORIO = 'TR_USO_INTERNO_OBLIGATORIO';
export const TR_EASYPARK = 'TR_EASYPARK';
export const TR_MOVILTIK = 'TR_MOVILTIK';
export const TR_NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA = 'TR_NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA';
export const TR_FECHA_INVALIDA = 'TR_FECHA_INVALIDA';
export const TR_ESCANEAR_MATRICULA = 'TR_ESCANEAR_MATRICULA';
export const TR_HAZ_CAPTURA_MATRICULA = 'TR_HAZ_CAPTURA_MATRICULA';
export const TR_PARA_SEGUIR_ADVERANDO_ES_NECESARIO_ANADIR_UNA_IMAGEN = 'TR_PARA_SEGUIR_ADVERANDO_ES_NECESARIO_ANADIR_UNA_IMAGEN';
export const TR_FIRMA_DE_CONTROLADOR = 'TR_FIRMA_DE_CONTROLADOR';
export const TR_BORRAR_FIRMA = 'TR_BORRAR_FIRMA';
export const TR_ES_NECESARIO_PROPORCIONAR_LA_FIRMA = 'TR_ES_NECESARIO_PROPORCIONAR_LA_FIRMA';
export const TR_LA_DENUNCIA_HA_SIDO_ADVERADA = 'TR_LA_DENUNCIA_HA_SIDO_ADVERADA';
export const TR_PDF_DENUNCIA = 'TR_PDF_DENUNCIA';
export const TR_PDF_DENUNCIA_RATIFICADA = 'TR_PDF_DENUNCIA_RATIFICADA';
export const TR_CODIGO_DE_PROVINCIA_SUMA = 'TR_CODIGO_DE_PROVINCIA_SUMA';
export const TR_CODIGO_DE_MUNICIPIO_SUMA = 'TR_CODIGO_DE_MUNICIPIO_SUMA';
export const TR_CODIGO_DE_PROVINCIA_OBLIGATORIO = 'TR_CODIGO_DE_PROVINCIA_OBLIGATORIO';
export const TR_CODIGO_DE_MUNICIPIO_OBLIGATORIO = 'TR_CODIGO_DE_MUNICIPIO_OBLIGATORIO';
export const TR_LONGITUD_DEBE_SER = 'TR_LONGITUD_DEBE_SER';
export const TR_FICHERO_KML = 'TR_FICHERO_KML';
export const TR_DENUNCIA_ANULADA = 'TR_DENUNCIA_ANULADA';
export const TR_DENUNCIA_INVALIDADA = 'TR_DENUNCIA_INVALIDADA';
export const TR_DENUNCIA_RECTIFICADA = 'TR_DENUNCIA_RECTIFICADA';
export const TR_DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE = 'TR_DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE';
export const TR_DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE = 'TR_DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE';
export const TR_CONFIGURACION_DE_LA_APP = 'TR_CONFIGURACION_DE_LA_APP';
export const TR_TIEMPO_INACTIVIDAD_DENUNCIA_MINUTOS = 'TR_TIEMPO_INACTIVIDAD_DENUNCIA_MINUTOS';
export const TR_TIEMPO_INACTIVIDAD_MOVIMIENTO_MINUTOS = 'TR_TIEMPO_INACTIVIDAD_MOVIMIENTO_MINUTOS';
export const TR_TIEMPO_INACTIVIDAD_DENUNCIA_OBLIGATORIO = 'TR_TIEMPO_INACTIVIDAD_DENUNCIA_OBLIGATORIO';
export const TR_TIEMPO_INACTIVIDAD_MOVIMIENTO_OBLIGATORIO = 'TR_TIEMPO_INACTIVIDAD_MOVIMIENTO_OBLIGATORIO';
export const TR_DEBE_SER_UN_VALOR_POSITIVO = 'TR_DEBE_SER_UN_VALOR_POSITIVO';
export const TR_PERMISOS_UBICACION_ANDROID = 'TR_PERMISOS_UBICACION_ANDROID';
export const TR_PERMISOS_UBICACION_IOS = 'TR_PERMISOS_UBICACION_IOS';
export const TR_IR_A_AJUSTES = 'TR_IR_A_AJUSTES';
export const TR_DATOS_CONDUCTOR = 'TR_DATOS_CONDUCTOR';
export const TR_TIPO_CONDUCTOR = 'TR_TIPO_CONDUCTOR';
export const TR_RAZON_DE_ANULACION = 'TR_RAZON_DE_ANULACION';
export const TR_FOTOGRAFIA_DE_INVALIDACION = 'TR_FOTOGRAFIA_DE_INVALIDACION';
export const TR_LEYES = 'TR_LEYES';
export const TR_NO_EXISTEN_LEYES = 'TR_NO_EXISTEN_LEYES';
export const TR_ELIMINAR_LEYES = 'TR_ELIMINAR_LEYES';
export const TR_DESCUENTO = 'TR_DESCUENTO';
export const TR_NUEVA_LEY = 'TR_NUEVA_LEY';
export const TR_DESCUENTO_OBLIGATORIO = 'TR_DESCUENTO_OBLIGATORIO';
export const TR_LEY_MODIFICADA_CORRECTAMENTE = 'TR_LEY_MODIFICADA_CORRECTAMENTE';
export const TR_LEY_CREADA_CORRECTAMENTE = 'TR_LEY_CREADA_CORRECTAMENTE';
export const TR_MOTIVOS_DE_PLAZAS_PERDIDAS = 'TR_MOTIVOS_DE_PLAZAS_PERDIDAS';
export const TR_NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS = 'TR_NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS';
export const TR_MOTIVO_DE_PLAZA_PERDIDA = 'TR_MOTIVO_DE_PLAZA_PERDIDA';
export const TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA = 'TR_NUEVO_MOTIVO_DE_PLAZA_PERDIDA';
export const TR_MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE = 'TR_MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE';
export const TR_MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE = 'TR_MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE';
export const TR_ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS = 'TR_ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS';
export const TR_DENUNCIAS_ENVIADAS = 'TR_DENUNCIAS_ENVIADAS';
export const TR_FECHA_Y_HORA_REFERENCIA_ANULACION_APP = 'TR_FECHA_Y_HORA_REFERENCIA_ANULACION_APP';
export const TR_RECLAMAR = 'TR_RECLAMAR';
export const TR_RECLAMADA = 'TR_RECLAMADA';
export const TR_DEVOLVER_ANULACION = 'TR_DEVOLVER_ANULACION';
export const TR_DENUNCIA_RECLAMADA = 'TR_DENUNCIA_RECLAMADA';
export const TR_IMPORTE_ANULACION_DEVUELTO = 'TR_IMPORTE_ANULACION_DEVUELTO';
export const TR_DENUNCIA = 'TR_DENUNCIA';
export const TR_CREADA_CORRECTAMENTE = 'TR_CREADA_CORRECTAMENTE';
export const TR_IMPRIMIR = 'TR_IMPRIMIR';
export const TR_NUEVA_DENUNCIA = 'TR_NUEVA_DENUNCIA';
export const TR_MOTIVO_DE_INFRACCION = 'TR_MOTIVO_DE_INFRACCION';
export const TR_OBLIGATORIO_ANYADIR_MINIMO_X_IMAGENES = 'TR_OBLIGATORIO_ANYADIR_MINIMO_X_IMAGENES';
export const TR_ANYADIR_FOTO = 'TR_ANYADIR_FOTO';
export const TR_DATOS_DENUNCIADO = 'TR_DATOS_DENUNCIADO';
export const TR_MAXIMO_200_CARACTERES = 'TR_MAXIMO_200_CARACTERES';
export const TR_ANYADIR_IMAGEN_Y_CONTINUAR = 'TR_ANYADIR_IMAGEN_Y_CONTINUAR';
export const TR_DESEAS_ANYADIR_LA_SIGUIENTE_IMAGEN = 'TR_DESEAS_ANYADIR_LA_SIGUIENTE_IMAGEN';
export const TR_LLEVAS = 'TR_LLEVAS';
export const TR_FINALIZAR_DENUNCIA = 'TR_FINALIZAR_DENUNCIA';
export const TR_NOMBRE_Y_APELLIDOS = 'TR_NOMBRE_Y_APELLIDOS';
export const TR_DNI = 'TR_DNI';
export const TR_PROPIETARIO_CONDUCTOR = 'TR_PROPIETARIO_CONDUCTOR';
export const TR_REGISTRO_DATOS_DENUNCIADO = 'TR_REGISTRO_DATOS_DENUNCIADO';
export const TR_PROPIETARIO = 'TR_PROPIETARIO';
export const TR_CONDUCTOR = 'TR_CONDUCTOR';
export const TR_DESEAS_DESCARTAR_DENUNCIA = 'TR_DESEAS_DESCARTAR_DENUNCIA';
export const TR_SE_PERDERAN_LOS_CAMBIOS = 'TR_SE_PERDERAN_LOS_CAMBIOS';
export const TR_FIRMA_CONTROLADOR = 'TR_FIRMA_CONTROLADOR';
export const TR_DEBES_SELECCIONAR_PRIMERO_ZONA_Y_RUTA = 'TR_DEBES_SELECCIONAR_PRIMERO_ZONA_Y_RUTA';
export const TR_ANYADIR_FIRMA_DIGITAL = 'TR_ANYADIR_FIRMA_DIGITAL';
export const TR_NUM = 'TR_NUM';
export const TR_NO_SE_HA_ENCONTRADO_LA_DIRECCION = 'TR_NO_SE_HA_ENCONTRADO_LA_DIRECCION';
export const TR_FALTAN_CAMPOS = 'TR_FALTAN_CAMPOS';
export const TR_LA_DENUNCIA_DEBE_ESTAR_FIRMADA = 'TR_LA_DENUNCIA_DEBE_ESTAR_FIRMADA';
export const TR_GESTION_DE_INCIDENCIAS = 'TR_GESTION_DE_INCIDENCIAS';
export const TR_REPORTAR = 'TR_REPORTAR';
export const TR_RECAUDACIONES = 'TR_RECAUDACIONES';
export const TR_NUEVA_RECAUDACION = 'TR_NUEVA_RECAUDACION';
export const TR_BUSCAR_POR_CODIGO = 'TR_BUSCAR_POR_CODIGO';
export const TR_ULTIMAS_INCIDENCIAS = 'TR_ULTIMAS_INCIDENCIAS';
export const TR_SIN_OBSERVACIONES = 'TR_SIN_OBSERVACIONES';
export const TR_SIN_INCIDENCIAS = 'TR_SIN_INCIDENCIAS';
export const TR_SIN_RECAUDACIONES = 'TR_SIN_RECAUDACIONES';
export const TR_SIN_PLAZAS_PERDIDAS = 'TR_SIN_PLAZAS_PERDIDAS';
export const TR_REPORTAR_INCIDENCIA_EN_PARQUIMETRO = 'TR_REPORTAR_INCIDENCIA_EN_PARQUIMETRO';
export const TR_CODIGO_DEL_PARQUIMETRO = 'TR_CODIGO_DEL_PARQUIMETRO';
export const TR_TIPO_DE_PROBLEMA_DETECTADO = 'TR_TIPO_DE_PROBLEMA_DETECTADO';
export const TR_INCIDENCIA_EN_PARQUIMETRO_REPORTADA_CORRECTAMENTE = 'TR_INCIDENCIA_EN_PARQUIMETRO_REPORTADA_CORRECTAMENTE';
export const TR_IMPORTE_EN_E_QUE_SE_RECAUDA = 'TR_IMPORTE_EN_E_QUE_SE_RECAUDA';
export const TR_CODIGO_DE_LA_BRIDA = 'TR_CODIGO_DE_LA_BRIDA';
export const TR_RECAUDACION_CREADA_CORRECTAMENTE = 'TR_RECAUDACION_CREADA_CORRECTAMENTE';
export const TR_REPORTAR_INCIDENCIA_EN_PLAZAS_PERDIDAS = 'TR_REPORTAR_INCIDENCIA_EN_PLAZAS_PERDIDAS';
export const TR_N = 'TR_N';
export const TR_NUMERO_DE_PLAZAS_AFECTADAS = 'TR_NUMERO_DE_PLAZAS_AFECTADAS';
export const TR_HORAS_QUE_ESTARAN_OCUPADAS = 'TR_HORAS_QUE_ESTARAN_OCUPADAS';
export const TR_INCIDENCIA_EN_PLAZAS_PERDIDAS_CREADA_CORRECTAMENTE = 'TR_INCIDENCIA_EN_PLAZAS_PERDIDAS_CREADA_CORRECTAMENTE';
export const TR_BRIDA_PLACEHOLDER = 'TR_BRIDA_PLACEHOLDER';
export const TR_CURRENCY_PLACEHOLDER = 'TR_CURRENCY_PLACEHOLDER';
export const TR_CODIGO_PLACEHOLDER = 'TR_CODIGO_PLACEHOLDER';
export const TR_N_PLAZAS_PLACEHOLDER = 'TR_N_PLAZAS_PLACEHOLDER';
export const TR_BLUETOOTH = 'TR_BLUETOOTH';
export const TR_CONECTADO_A_PLACEHOLDER = 'TR_CONECTADO_A_PLACEHOLDER';
export const TR_SIN_CONEXION = 'TR_SIN_CONEXION';
export const TR_CONECTANDO_A_PLACEHOLDER = 'TR_CONECTANDO_A_PLACEHOLDER';
export const TR_ULTIMAS_IMPRESORAS_CONECTADAS = 'TR_ULTIMAS_IMPRESORAS_CONECTADAS';
export const TR_CONECTADO = 'TR_CONECTADO';
export const TR_ESCANEAR_NUEVOS_DISPOSITIVOS = 'TR_ESCANEAR_NUEVOS_DISPOSITIVOS';
export const TR_DISPOSITIVOS_ENCONTRADOS = 'TR_DISPOSITIVOS_ENCONTRADOS';
export const TR_ERROR_DE_CONEXION = 'TR_ERROR_DE_CONEXION';
export const TR_BLUETOOTH_DESACTIVADO = 'TR_BLUETOOTH_DESACTIVADO';
export const TR_EL_DISPOSITIVO_NO_DISPONE_DE_BLUETOOTH = 'TR_EL_DISPOSITIVO_NO_DISPONE_DE_BLUETOOTH';
export const TR_PERMISOS_RECHAZADOS = 'TR_PERMISOS_RECHAZADOS';
export const DESEAS_ELIMINAR_PLACEHOLDER = 'DESEAS_ELIMINAR_PLACEHOLDER';
export const TR_ELIMINAR_IMPRESORA = 'TR_ELIMINAR_IMPRESORA';
export const NO_GRACIAS = 'NO_GRACIAS';
export const TR_AUN_NO_TE_HAS_CONECTADO_A_NINGUNA_IMPRESORA = 'TR_AUN_NO_TE_HAS_CONECTADO_A_NINGUNA_IMPRESORA';
export const TR_DISPOSITIVO_SIN_NOMBRE = 'TR_DISPOSITIVO_SIN_NOMBRE';
export const TR_CONECTANDO_BLUETOOTH_A = 'TR_CONECTANDO_BLUETOOTH_A';
export const TR_ESTE_DISPOSITIVO_NO_ESTA_RECONOCIDO_COMO_IMPRESORA = 'TR_ESTE_DISPOSITIVO_NO_ESTA_RECONOCIDO_COMO_IMPRESORA';
export const TR_NO_SE_HAN_PODIDO_ENCONTRAR_LAS_IMPRESORAS_EMPAREJADAS_A_ESTE_DISPOSITIVO = 'TR_NO_SE_HAN_PODIDO_ENCONTRAR_LAS_IMPRESORAS_EMPAREJADAS_A_ESTE_DISPOSITIVO';
export const TR_NO_SE_HA_PODIDO_ELIMINAR_LA_IMPRESORA = 'TR_NO_SE_HA_PODIDO_ELIMINAR_LA_IMPRESORA';
export const TR_AYUDANTE = 'TR_AYUDANTE';
export const TR_ID_COLECTA = 'TR_ID_COLECTA';
export const TR_IMAGEN = 'TR_IMAGEN';
export const TR_NUMERO_DE_ANULACION = 'TR_NUMERO_DE_ANULACION';
export const TR_FECHA_DE_EXPIRACION = 'TR_FECHA_DE_EXPIRACION';
export const TR_PANTALLA_DATOS_DENUNCIADO = 'TR_PANTALLA_DATOS_DENUNCIADO';
export const TR_USUARIO_MOVILTIK = 'TR_USUARIO_MOVILTIK';
export const TR_CONTRASENA_MOVILTIK = 'TR_CONTRASENA_MOVILTIK';
export const TR_INTEGRACION_CON_OTROS_SISTEMAS = 'TR_INTEGRACION_CON_OTROS_SISTEMAS';
export const TR_USUARIO_OBLIGATORIO = 'TR_USUARIO_OBLIGATORIO';
export const TR_NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR = 'TR_NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR';
export const TR_ESTE_CAMPO_NO_PUEDE_ESTAR_VACIO = 'TR_ESTE_CAMPO_NO_PUEDE_ESTAR_VACIO';
export const TR_REINTENTAR = 'TR_REINTENTAR';
export const TR_NO_HAY_DENUNCIAS = 'TR_NO_HAY_DENUNCIAS';
export const TR_PULSE_ATRAS_DE_NUEVO_PARA_CERRAR_APP = 'TR_PULSE_ATRAS_DE_NUEVO_PARA_CERRAR_APP';
export const TR_MIS_DENUNCIAS = 'TR_MIS_DENUNCIAS';
export const TR_BUSQUEDA = 'TR_BUSQUEDA';
export const TR_ELIGE_PRIMERO_UNA_MARCA_DE_COCHE = 'TR_ELIGE_PRIMERO_UNA_MARCA_DE_COCHE';
export const TR_LA_MATRICULA_SOLO_PUEDE_CONTENER_LETRAS_MAYUSCULAS_Y_NUMEROS = 'TR_LA_MATRICULA_SOLO_PUEDE_CONTENER_LETRAS_MAYUSCULAS_Y_NUMEROS';
export const TR_CONFIRMAR = 'TR_CONFIRMAR';
export const TR_FECHA_EXCESO_DE_TICKET = 'TR_FECHA_EXCESO_DE_TICKET';
export const TR_TIEMPO_EXCEDIDO = 'TR_TIEMPO_EXCEDIDO';
export const TR_MOTIVO_DE_LA_INVALIDACION_DE_LA_DENUNCIA = 'TR_MOTIVO_DE_LA_INVALIDACION_DE_LA_DENUNCIA';
export const TR_DESCRIPCION_DEL_MOTIVO = 'TR_DESCRIPCION_DEL_MOTIVO';
export const TR_PARA_SEGUIR_CON_LA_INVALIDACION_ES_NECESARIO_ANYADIR_UNA_IMAGEN = 'TR_PARA_SEGUIR_CON_LA_INVALIDACION_ES_NECESARIO_ANYADIR_UNA_IMAGEN';
export const TR_MOTIVO_DE_LA_INVALIDACION = 'TR_MOTIVO_DE_LA_INVALIDACION';
export const TR_INVALIDACION_TRAMITADA_CORRECTAMENTE = 'TR_INVALIDACION_TRAMITADA_CORRECTAMENTE';
export const TR_OCUPADAS_PLACEHOLDER = 'TR_OCUPADAS_PLACEHOLDER';
export const TR_IMAGEN_ADJUNTA = 'TR_IMAGEN_ADJUNTA';
export const TR_SIN_IMAGEN_ADJUNTA = 'TR_SIN_IMAGEN_ADJUNTA';
export const TR_COLECTA_PLACEHOLDER = 'TR_COLECTA_PLACEHOLDER';
export const TR_AYUDANTE_PLACEHOLDER = 'TR_AYUDANTE_PLACEHOLDER';
export const TR_AYUDANTE_NO_ESPECIFICADO = 'TR_AYUDANTE_NO_ESPECIFICADO';
export const TR_CODIGO_DE_COLECTA = 'TR_CODIGO_DE_COLECTA';
export const TR_FECHA_HORA_DE_COLETA = 'TR_FECHA_HORA_DE_COLETA';
export const TR_IMPORTE_RECAUDADO_EN_MONEDAS = 'TR_IMPORTE_RECAUDADO_EN_MONEDAS';
export const TR_AYUDANTE_DE_RECAUDACION = 'TR_AYUDANTE_DE_RECAUDACION';
export const TR_FOTO_DE_LA_TIRA_DE_RECAUDACION = 'TR_FOTO_DE_LA_TIRA_DE_RECAUDACION';
export const TR_BUSCAR_POR_CODIGO_U_OBSERVACIONES = 'TR_BUSCAR_POR_CODIGO_U_OBSERVACIONES';
export const TR_DENUNCIA_YA_INVALIDADA = 'TR_DENUNCIA_YA_INVALIDADA';
export const TR_NO_PUEDES_ADVERAR_UNA_DENUNCIA_CREADA_POR_TI = 'TR_NO_PUEDES_ADVERAR_UNA_DENUNCIA_CREADA_POR_TI';
export const TR_FORMATO_DE_MATRICULA_NO_RECONOCIDA_DESEA_CONTINUAR = 'TR_FORMATO_DE_MATRICULA_NO_RECONOCIDA_DESEA_CONTINUAR';
export const TR_CONTINUAR = 'TR_CONTINUAR';
export const TR_NO_SE_HA_PODIDO_IMPRIMIR_LA_DENUNCIA = 'TR_NO_SE_HA_PODIDO_IMPRIMIR_LA_DENUNCIA';
export const TR_DEBES_CONECTARTE_PRIMERO_A_UNA_IMPRESORA = 'TR_DEBES_CONECTARTE_PRIMERO_A_UNA_IMPRESORA';
export const TR_HA_OCURRIDO_UN_ERROR_AL_INTENTAR_GUARDAR_LA_FOTO = 'TR_HA_OCURRIDO_UN_ERROR_AL_INTENTAR_GUARDAR_LA_FOTO';
export const TR_IMPRIMIR_DENUNCIA = 'TR_IMPRIMIR_DENUNCIA';
export const TR_POR_FAVOR_ANADE_TU_FIRMA = 'TR_POR_FAVOR_ANADE_TU_FIRMA';
export const TR_A_CONTINUACION_PULSA_EL_BOTON_PARA_IMPRIMIR_DENUNCIA = 'TR_A_CONTINUACION_PULSA_EL_BOTON_PARA_IMPRIMIR_DENUNCIA';
export const TR_PARA_SEGUIR_CON_LA_ADEVERACION_ES_NECESARIO_REALIZAR_UNA_FOTO_FORMATTED = 'TR_PARA_SEGUIR_CON_LA_ADEVERACION_ES_NECESARIO_REALIZAR_UNA_FOTO_FORMATTED';
export const TR_POR_FAVOR_ANADE_LA_FOTO = 'TR_POR_FAVOR_ANADE_LA_FOTO';
export const TR_NO_PUEDES_ADVERAR_UNA_DENUNCIA_YA_INVALIDADA = 'TR_NO_PUEDES_ADVERAR_UNA_DENUNCIA_YA_INVALIDADA';
export const TR_NO_HAY_NINGUNA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO = 'TR_NO_HAY_NINGUNA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO';
export const TR_NO_SE_HA_PODIDO_CONECTAR_A_LA_ULTIMA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO = 'TR_NO_SE_HA_PODIDO_CONECTAR_A_LA_ULTIMA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO';
export const TR_NO_SE_HA_PODIDO_DESCONECTAR_LA_IMPRESORA_VINCULADA_AL_DISPOSITIVO = 'TR_NO_SE_HA_PODIDO_DESCONECTAR_LA_IMPRESORA_VINCULADA_AL_DISPOSITIVO';
export const TR_BUSCANDO_ULTIMA_IMPRESORA_USADA = 'TR_BUSCANDO_ULTIMA_IMPRESORA_USADA';
export const TR_CONECTANDO_ULTIMA_IMPRESORA_USADA = 'TR_CONECTANDO_ULTIMA_IMPRESORA_USADA';
export const TR_IMPRIMIENDO = 'TR_IMPRIMIENDO';
export const TR_DESCONECTANDO_IMPRESORA = 'TR_DESCONECTANDO_IMPRESORA';
export const TR_CONTACTO = 'TR_CONTACTO';
export const TR_AVISO_LEGAL = 'TR_AVISO_LEGAL';
export const TR_OBLIGATORIO_ANYADIR_MINIMO_1_IMAGEN = 'TR_OBLIGATORIO_ANYADIR_MINIMO_1_IMAGEN';
export const TR_DEBES_SELECCIONAR_PRIMERO_UNA_LEY_INCUMPLIDA = 'TR_DEBES_SELECCIONAR_PRIMERO_UNA_LEY_INCUMPLIDA';
export const TR_NO_HAY_NINGUNA_IMPRESORA_VINCULADA_AL_DISPOSITIVO_DESEAS_IR_A_LOS_AJUSTES = 'TR_NO_HAY_NINGUNA_IMPRESORA_VINCULADA_AL_DISPOSITIVO_DESEAS_IR_A_LOS_AJUSTES';
export const TR_INVALIDADA = 'TR_INVALIDADA';
export const TR_NO_SE_HA_PODIDO_PONER_LA_ALTURA_POR_DEFECTO_DE_LA_ETIQUETA = 'TR_NO_SE_HA_PODIDO_PONER_LA_ALTURA_POR_DEFECTO_DE_LA_ETIQUETA';
export const TR_ERROR = 'TR_ERROR';
export const TR_ERROR_CERT_NO_AUTORIZADO = 'TR_ERROR_CERT_NO_AUTORIZADO';
export const TR_ERROR_CERT_CERTIFICADO_NO_ENCONTRADO = 'TR_ERROR_CERT_CERTIFICADO_NO_ENCONTRADO';
export const TR_ERROR_CERT_CERTIFICADO_EXPIRADO = 'TR_ERROR_CERT_CERTIFICADO_EXPIRADO';
export const TR_ERROR_INTERNO_DEL_SERVIDOR = 'TR_ERROR_INTERNO_DEL_SERVIDOR';
export const TR_CERTIFICADO_DIGITAL = 'TR_CERTIFICADO_DIGITAL';
export const TR_INTRODUCE_LA_CONTRASEÑA_DE_TU_CERTIFICADO_DIGITAL = 'TR_INTRODUCE_LA_CONTRASEÑA_DE_TU_CERTIFICADO_DIGITAL';
export const TR_ACCESO_NO_PERMITIDO = 'TR_ACCESO_NO_PERMITIDO';
export const TR_ANULACION_DENUNCIAS_DE_FICHERO = 'TR_ANULACION_DENUNCIAS_DE_FICHERO';
export const TR_FICHERO_CSV = 'TR_FICHERO_CSV';
export const TR_FICHERO_CSV_OBLIGATORIO = 'TR_FICHERO_CSV_OBLIGATORIO';
export const TR_ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE = 'TR_ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE';
export const TR_LOGS_CANCELACION_DENUNCIAS = 'TR_LOGS_CANCELACION_DENUNCIAS';
export const TR_INFORME_ANULACION_DENUNCIAS_DE_FICHERO = 'TR_INFORME_ANULACION_DENUNCIAS_DE_FICHERO';
export const TR_ORDEN = 'TR_ORDEN';
export const TR_ORDEN_OBLIGATORIO = 'TR_ORDEN_OBLIGATORIO';
export const TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5 = 'TR_EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5';
export const TR_NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR = 'TR_NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR';
export const TR_INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS = 'TR_INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS';
export const TR_FECHA_Y_HORA_DE_RATIFICACION = 'TR_FECHA_Y_HORA_DE_RATIFICACION';
export const TR_INFORMACION_LEGAL = 'TR_INFORMACION_LEGAL';
export const TR_ACTUALIZAR = 'TR_ACTUALIZAR';
export const TR_NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO = 'TR_NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO';
export const TR_CREAR_CERTIFICADO = 'TR_CREAR_CERTIFICADO';
export const TR_ACTUALIZAR_CERTIFICADO = 'TR_ACTUALIZAR_CERTIFICADO';
export const TR_CERTIFICADO = 'TR_CERTIFICADO';
export const TR_ELIMINAR_CERTIFICADO_DIGITAL = 'TR_ELIMINAR_CERTIFICADO_DIGITAL';
export const TR_ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR = 'TR_ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR';
export const TR_CERTIFICADO_OBLIGATORIO = 'TR_CERTIFICADO_OBLIGATORIO';
export const TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO = 'TR_SOLO_SE_ADMITEN_FICHEROS_DE_TIPO';
export const TR_CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE = 'TR_CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE';
export const TR_CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE = 'TR_CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE';
export const TR_EL_CERTIFICADO_NO_ES_VALIDO = 'TR_EL_CERTIFICADO_NO_ES_VALIDO';
export const TR_LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA = 'TR_LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA';
export const TR_CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE = 'TR_CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE';
export const TR_CERTIFICADO_VALIDO = 'TR_CERTIFICADO_VALIDO';
export const TR_CERTIFICADO_INVALIDO = 'TR_CERTIFICADO_INVALIDO';
export const TR_EL_CERTIFICADO_CADUCO_EL = 'TR_EL_CERTIFICADO_CADUCO_EL';
export const TR_EL_CERTIFICADO_CADUCA_EL = 'TR_EL_CERTIFICADO_CADUCA_EL';
export const TR_CERTIFICADO_VALIDO_HASTA_EL = 'TR_CERTIFICADO_VALIDO_HASTA_EL';
export const TR_NO_EXISTE_FIRMA_DIGITAL = 'TR_NO_EXISTE_FIRMA_DIGITAL';
export const TR_NO_SE_HA_PODIDO_CONSEGUIR_LA_UBICACION_ACTUAL = 'TR_NO_SE_HA_PODIDO_CONSEGUIR_LA_UBICACION_ACTUAL';
export const TR_ESTAS_FUERA_DE_LA_ZONA_DE_EXPLOTACION = 'TR_ESTAS_FUERA_DE_LA_ZONA_DE_EXPLOTACION';
export const TR_ESTAS_DEMASIADO_LEJOS_DEL_PUNTO_DE_DENUNCIA_ORIGINAL = 'TR_ESTAS_DEMASIADO_LEJOS_DEL_PUNTO_DE_DENUNCIA_ORIGINAL';
export const TR_ESTAS_COLOCANDO_UNA_DENUNCIA_FUERA_DE_LA_ZONA_ASIGNADA_A_TU_EXPLOTACION = 'TR_ESTAS_COLOCANDO_UNA_DENUNCIA_FUERA_DE_LA_ZONA_ASIGNADA_A_TU_EXPLOTACION';
export const TR_RESUMEN_DE_LA_DENUNCIA = 'TR_RESUMEN_DE_LA_DENUNCIA';
export const TR_EDITAR_CAMPOS = 'TR_EDITAR_CAMPOS';
export const TR_RESUMEN_DENUNCIA = 'TR_RESUMEN_DENUNCIA';
export const TR_NUMERO_DE_FICHERO_DE_EXPORTACION_SUMA = 'TR_NUMERO_DE_FICHERO_DE_EXPORTACION_SUMA';
export const TR_DEBE_SER_UN_VALOR_SUPERIOR_A_CERO = 'TR_DEBE_SER_UN_VALOR_SUPERIOR_A_CERO';
export const TR_RADIO_DENUNCIA_ADVERACION_OBLIGATORIO = 'TR_RADIO_DENUNCIA_ADVERACION_OBLIGATORIO';
export const TR_TIEMPO_FINALIZACION_JORNADA_OBLIGATORIO = 'TR_TIEMPO_FINALIZACION_JORNADA_OBLIGATORIO';
export const TR_RADIO_DENUNCIA_ADVERACION_METROS = 'TR_RADIO_DENUNCIA_ADVERACION_METROS';
export const TR_TIEMPO_FINALIZACION_JORNADA_MINUTOS = 'TR_TIEMPO_FINALIZACION_JORNADA_MINUTOS';
export const TR_GESTION_DE_ANULACION = 'TR_GESTION_DE_ANULACION';
export const TR_TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_MINUTOS = 'TR_TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_MINUTOS';
export const TR_TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_OBLIGATORIO = 'TR_TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_OBLIGATORIO';
export const TR_LA_DENUNCIA_NO_TIENE_UNA_UBICACION_ASIGNADA = 'TR_LA_DENUNCIA_NO_TIENE_UNA_UBICACION_ASIGNADA';
export const TR_LICENCIA_RECONOCIMIENTO_MATRICULAS = 'TR_LICENCIA_RECONOCIMIENTO_MATRICULAS';
export const TR_CORREO_ELECTRONICO = 'TR_CORREO_ELECTRONICO';
export const TR_ESTAS_FUERA_DEL_HORARIO_DE_LA_JORNADA_SE_CERRARA_LA_SESION_EN_X_MINUTOS = 'TR_ESTAS_FUERA_DEL_HORARIO_DE_LA_JORNADA_SE_CERRARA_LA_SESION_EN_X_MINUTOS';
export const TR_LUNES = 'TR_LUNES';
export const TR_MARTES = 'TR_MARTES';
export const TR_MIERCOLES = 'TR_MIERCOLES';
export const TR_JUEVES = 'TR_JUEVES';
export const TR_VIERNES = 'TR_VIERNES';
export const TR_SABADO = 'TR_SABADO';
export const TR_DOMINGO = 'TR_DOMINGO';
export const TR_HORARIO_TARDE = 'TR_HORARIO_TARDE';
export const TR_HORARIO_MANANA = 'TR_HORARIO_MANANA';
export const TR_MANANA_HORA_INICIO = 'TR_MANANA_HORA_INICIO';
export const TR_MANANA_HORA_FIN = 'TR_MANANA_HORA_FIN';
export const TR_TARDE_HORA_INICIO = 'TR_TARDE_HORA_INICIO';
export const TR_TARDE_HORA_FIN = 'TR_TARDE_HORA_FIN';
export const TR_DIAS_DE_LA_SEMANA = 'TR_DIAS_DE_LA_SEMANA';
export const TR_CONTROL_DE_LA_JORNADA = 'TR_CONTROL_DE_LA_JORNADA';
export const TR_NO_HAY_HORARIOS_INTRODUCIDOS = 'TR_NO_HAY_HORARIOS_INTRODUCIDOS';
export const TR_HAY_DIAS_DE_LA_SEMANA_SELECCIONADA = 'TR_HAY_DIAS_DE_LA_SEMANA_SELECCIONADA';
export const TR_HORARIO_DE_MANANA_INCOMPLETO = 'TR_HORARIO_DE_MANANA_INCOMPLETO';
export const TR_EL_HORARIO_DE_INICIO_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_DIA_DE_FIN_DE_MANANA = 'TR_EL_HORARIO_DE_INICIO_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_DIA_DE_FIN_DE_MANANA';
export const TR_HORARIO_DE_TARDE_INCOMPLETO = 'TR_HORARIO_DE_TARDE_INCOMPLETO';
export const TR_EL_HORARIO_DE_INICIO_DE_TARDE_DEBE_SER_INFERIOR_AL_DE_FIN_DE_TARDE = 'TR_EL_HORARIO_DE_INICIO_DE_TARDE_DEBE_SER_INFERIOR_AL_DE_FIN_DE_TARDE';
export const TR_EL_HORARIO_DE_FIN_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_INICIO_DE_TARDE = 'TR_EL_HORARIO_DE_FIN_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_INICIO_DE_TARDE';
export const TR_EXISTEN_DIAS_REPETIDOS = 'TR_EXISTEN_DIAS_REPETIDOS';
export const TR_CONFIGURACION_GENERAL = 'TR_CONFIGURACION_GENERAL';
export const TR_CONFIGURACION_APP = 'TR_CONFIGURACION_APP';
export const TR_VERSION_MINIMA = 'TR_VERSION_MINIMA';
export const TR_URL_ANDROID = 'TR_URL_ANDROID';
export const TR_URL_IOS = 'TR_URL_IOS';
export const TR_CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE = 'TR_CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE';
export const TR_VERSION_OBLIGATORIA = 'TR_VERSION_OBLIGATORIA';
export const TR_URL_ANDROID_OBLIGATORIA = 'TR_URL_ANDROID_OBLIGATORIA';
export const TR_URL_IOS_OBLIGATORIA = 'TR_URL_IOS_OBLIGATORIA';
export const TR_FORMATO_DE_LA_VERSION_INCORRECTO = 'TR_FORMATO_DE_LA_VERSION_INCORRECTO';
export const TR_HAY_UNA_NUEVA_VERSION_DE_LA_APP_DISPONIBLE = 'TR_HAY_UNA_NUEVA_VERSION_DE_LA_APP_DISPONIBLE';
export const TR_INCIDENCIA_CERRADA = 'TR_INCIDENCIA_CERRADA';
export const TR_INCIDENCIA_ABIERTA = 'TR_INCIDENCIA_ABIERTA';
export const TR_CERRAR_INCIDENCIA = 'TR_CERRAR_INCIDENCIA';
export const TR_DESEAS_CERRAR_LA_INCIDENCIA = 'TR_DESEAS_CERRAR_LA_INCIDENCIA';
export const TR_ULTIMO_ENVIO = 'TR_ULTIMO_ENVIO';
export const TR_TOTALIZACION_DENUNCIAS = 'TR_TOTALIZACION_DENUNCIAS';
export const TR_INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS = 'TR_INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS';
export const TR_LOTE = 'TR_LOTE';
export const TR_PERIODO_DENUNCIAS = 'TR_PERIODO_DENUNCIAS';
export const TR_TOTAL_DENUNCIAS_TRAMITADAS = 'TR_TOTAL_DENUNCIAS_TRAMITADAS';
export const TR_DIA_ACTUAL = 'TR_DIA_ACTUAL';
export const TR_SEMANA_ACTUAL = 'TR_SEMANA_ACTUAL';
export const TR_MES_ACTUAL = 'TR_MES_ACTUAL';
export const TR_ANO_ACTUAL = 'TR_ANO_ACTUAL';
export const TR_DIA_ANTERIOR = 'TR_DIA_ANTERIOR';
export const TR_SEMANA_ANTERIOR = 'TR_SEMANA_ANTERIOR';
export const TR_MES_ANTERIOR = 'TR_MES_ANTERIOR';
export const TR_ANO_ANTERIOR = 'TR_ANO_ANTERIOR';
export const FECHA_ULTIMA_RECAUDACION = 'FECHA_ULTIMA_RECAUDACION';
export const TR_TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL = 'TR_TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL';
export const TR_TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL = 'TR_TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL';
export const TR_NO_SE_HA_PODIDO_CERRAR_LA_JORNADA_DE_TRABAJO = 'TR_NO_SE_HA_PODIDO_CERRAR_LA_JORNADA_DE_TRABAJO';
export const TR_CODIGO_ALZIRA = 'TR_CODIGO_ALZIRA';
export const TR_CONTACTOS = 'TR_CONTACTOS';
export const TR_NO_HAY_USUARIOS_DISPONIBLES = 'TR_NO_HAY_USUARIOS_DISPONIBLES';
export const TR_CARGANDO_MENSAJES_ANTERIORES = 'TR_CARGANDO_MENSAJES_ANTERIORES';
export const TR_X_MENSAJE_NO_LEIDO = 'TR_X_MENSAJE_NO_LEIDO';
export const TR_X_MENSAJES_NO_LEIDOS = 'TR_X_MENSAJES_NO_LEIDOS';
export const TR_EQUIPO_PAVAPARK = 'TR_EQUIPO_PAVAPARK';
export const TR_EMAILS_NOTIFICACION_GRUA = 'TR_EMAILS_NOTIFICACION_GRUA';
export const TR_EMAILS_SOS = 'TR_EMAILS_SOS';
export const TR_FORMATO_INCORRECTO_EMAILS = 'TR_FORMATO_INCORRECTO_EMAILS';
export const TR_TIPO_DE_INCIDENCIA = 'TR_TIPO_DE_INCIDENCIA';
export const TR_HISTORICO_DE_EXPORTACIONES = 'TR_HISTORICO_DE_EXPORTACIONES';
export const TR_NO_EXISTEN_EXPORTACIONES = 'TR_NO_EXISTEN_EXPORTACIONES';
export const TR_DESCARGAR_FICHERO = 'TR_DESCARGAR_FICHERO';
export const TR_PULSA_LA_NOTIFICACION_SI_TIENES_PROBLEMAS = 'TR_PULSA_LA_NOTIFICACION_SI_TIENES_PROBLEMAS';
export const TR_Y_NECESITAS_AYUDA = 'TR_Y_NECESITAS_AYUDA';
export const TR_TE_ENCUENTRAS_EN_PELIGRO = 'TR_TE_ENCUENTRAS_EN_PELIGRO';
export const TR_ENVIA_UN_AVISO_DE_AYUDA_A_TUS_COMPANYEROS_MAS_CERCANOS = 'TR_ENVIA_UN_AVISO_DE_AYUDA_A_TUS_COMPANYEROS_MAS_CERCANOS';
export const TR_X_HA_ACTIVADO_UNA_ALARMA_A_LAS_X = 'TR_X_HA_ACTIVADO_UNA_ALARMA_A_LAS_X';
export const TR_ALARMA_SOS = 'TR_ALARMA_SOS';
export const TR_VER_UBICACION = 'TR_VER_UBICACION';
export const TR_BEEPARK = 'TR_BEEPARK';
export const TR_EXPLOTACION_ID = 'TR_EXPLOTACION_ID';
export const TR_EXPLOTACION_ID_OBLIGATORIO = 'TR_EXPLOTACION_ID_OBLIGATORIO';
export const TR_TOKEN = 'TR_TOKEN';
export const TR_TOKEN_OBLIGATORIO = 'TR_TOKEN_OBLIGATORIO';
export const TR_PROCESOS_RECAUDACION = 'TR_PROCESOS_RECAUDACION';
export const TR_PROCESO_RECAUDACION = 'TR_PROCESO_RECAUDACION';
export const TR_NO_EXISTEN_PROCESOS_DE_RECAUDACION = 'TR_NO_EXISTEN_PROCESOS_DE_RECAUDACION';
export const TR_ELIMINAR_PROCESOS_RECAUDACION = 'TR_ELIMINAR_PROCESOS_RECAUDACION';
export const TR_IMPORTE_REAL = 'TR_IMPORTE_REAL';
export const TR_CAMBIAR = 'TR_CAMBIAR';
export const TR_INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO = 'TR_INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO';
export const TR_REVISE_IMPORTES_INTRODUCIDOS = 'TR_REVISE_IMPORTES_INTRODUCIDOS';
export const TR_TOTALIZAR_DE_NUEVO = 'TR_TOTALIZAR_DE_NUEVO';
export const TR_INTRODUZCA_MOTIVO_DESFASE_DINERO = 'TR_INTRODUZCA_MOTIVO_DESFASE_DINERO';
export const TR_DETALLE_MOTIVO_DESFASE = 'TR_DETALLE_MOTIVO_DESFASE';
export const TR_INTRODUZCA_IMPORTE = 'TR_INTRODUZCA_IMPORTE';
export const TR_INTRODUZCA_IMPORTE_REAL = 'TR_INTRODUZCA_IMPORTE_REAL';
export const TR_SELECCIONAR_COLECTAS_PARQUIMETROS = 'TR_SELECCIONAR_COLECTAS_PARQUIMETROS';
export const TR_COLECTAS_PARQUIMETRO = 'TR_COLECTAS_PARQUIMETRO';
export const TR_NO_EXISTEN_COLECTAS_PARQUIMETROS = 'TR_NO_EXISTEN_COLECTAS_PARQUIMETROS';
export const TR_ERROR_SUMA_COLECTAS = 'TR_ERROR_SUMA_COLECTAS';
export const TR_SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION = 'TR_SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION';
export const TR_PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE = 'TR_PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE';
export const TR_SELECCIONE_LOS_PROCESOS_RECAUDACION = 'TR_SELECCIONE_LOS_PROCESOS_RECAUDACION';
export const TR_UMBRAL_ALERTA = 'TR_UMBRAL_ALERTA';
export const TR_DESVIACION = 'TR_DESVIACION';
export const TR_MENSAJE_ALERTA = 'TR_MENSAJE_ALERTA';
export const TR_COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE = 'TR_COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE';
export const TR_ENVIAR_PROCESOS_RECAUDACION = 'TR_ENVIAR_PROCESOS_RECAUDACION';
export const TR_ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION = 'TR_ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION';
export const TR_INTRODUCIR_MATRICULA_POR_VOZ = 'TR_INTRODUCIR_MATRICULA_POR_VOZ';
export const TR_INTRODUCE_LA_MATRICULA_POR_VOZ_PULSANDO_EN_EL_MICROFONO = 'TR_INTRODUCE_LA_MATRICULA_POR_VOZ_PULSANDO_EN_EL_MICROFONO';
export const TR_NO_TIENES_LOS_PERMISOS_NECESARIOS_PARA_UTILIZAR_ESTA_FUNCIONALIDAD = 'TR_NO_TIENES_LOS_PERMISOS_NECESARIOS_PARA_UTILIZAR_ESTA_FUNCIONALIDAD';
export const TR_NO_HA_SIDO_POSIBLE_RECONOCER_NINGUNA_PALABRA = 'TR_NO_HA_SIDO_POSIBLE_RECONOCER_NINGUNA_PALABRA';
export const TR_NO_SE_HA_PODIDO_ESCUCHAR_NINGUNA_VOZ = 'TR_NO_SE_HA_PODIDO_ESCUCHAR_NINGUNA_VOZ';
export const TR_ = 'TR_';
export const TR_DELTA_CAR = 'TR_DELTA_CAR';
export const TR_NO_EXISTE_NINGUN_VEHICULO_EMBARCADO = 'TR_NO_EXISTE_NINGUN_VEHICULO_EMBARCADO';
export const TR_ELIMINAR_VEHICULO_EMBARCADO = 'TR_ELIMINAR_VEHICULO_EMBARCADO';
export const TR_VEHICULO_EMBARCADO = 'TR_VEHICULO_EMBARCADO';
export const TR_VEHICULOS_EMBARCADOS = 'TR_VEHICULOS_EMBARCADOS';
export const TR_VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE = 'TR_VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE';
export const TR_VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE = 'TR_VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE';
export const TR_NUEVO_VEHICULO_EMBARCADO = 'TR_NUEVO_VEHICULO_EMBARCADO';
export const TR_TICKET_LINE = 'TR_TICKET_LINE';
export const TR_COMO_VAS_A_REALIZAR_LA_RUTA_DE_HOY = 'TR_COMO_VAS_A_REALIZAR_LA_RUTA_DE_HOY';
export const TR_PULSA_SOBRE_UNA_DE_LAS_OPCIONES = 'TR_PULSA_SOBRE_UNA_DE_LAS_OPCIONES';
export const TR_A_PIE = 'TR_A_PIE';
export const TR_CON_VEHICULO_EMBARCADO = 'TR_CON_VEHICULO_EMBARCADO';
export const TR_SELECCIONA_EL_VEHICULO_QUE_VAS_A_UTILIZAR = 'TR_SELECCIONA_EL_VEHICULO_QUE_VAS_A_UTILIZAR';
export const TR_POR_ULTIMO_NECESITAMOS_QUE_REGISTRES_TU_FIRMA = 'TR_POR_ULTIMO_NECESITAMOS_QUE_REGISTRES_TU_FIRMA';
export const TR_REGISTRAR_FIRMA = 'TR_REGISTRAR_FIRMA';
export const TR_FIRMA_REGISTRADA_CORRECTAMENTE = 'TR_FIRMA_REGISTRADA_CORRECTAMENTE';
export const TR_LEY_OBLIGATORIA = 'TR_LEY_OBLIGATORIA';
export const TR_RUTA_VEHICULO_EMBARCADO_EN_MARCHA = 'TR_RUTA_VEHICULO_EMBARCADO_EN_MARCHA';
export const TR_FIRMAR_Y_FINALIZAR_JORNADA = 'TR_FIRMAR_Y_FINALIZAR_JORNADA';
export const TR_CAMBIAR_DE_ESTADO = 'TR_CAMBIAR_DE_ESTADO';
export const TR_FICHERO_KML_OBLIGATORIO = 'TR_FICHERO_KML_OBLIGATORIO';
export const TR_POR_FAVOR_ANYADE_LA_CALLE_Y_EL_NUMERO = 'TR_POR_FAVOR_ANYADE_LA_CALLE_Y_EL_NUMERO';
export const TR_NO_ES_POSIBLE_SELECCIONAR_UNA_CALLE = 'TR_NO_ES_POSIBLE_SELECCIONAR_UNA_CALLE';
export const TR_POR_FAVOR_ANADE_LOS_DATOS_DEL_VEHICULO = 'TR_POR_FAVOR_ANADE_LOS_DATOS_DEL_VEHICULO';
export const TR_TRANSCRIPCION = 'TR_TRANSCRIPCION';
export const TR_ATENCION_VEHICULO_ESPECIAL = 'TR_ATENCION_VEHICULO_ESPECIAL';
export const TR_NO_PRESENTE = 'TR_NO_PRESENTE';
export const TR_TITULO_DATOS_CONDUCTOR_DENUNCIADO = 'TR_TITULO_DATOS_CONDUCTOR_DENUNCIADO';
export const TR_NIF_CONDUCTOR = 'TR_NIF_CONDUCTOR';
export const TR_NOMBRE_CONDUCTOR = 'TR_NOMBRE_CONDUCTOR';
export const TR_APELLIDOS_CONDUCTOR = 'TR_APELLIDOS_CONDUCTOR';
export const TR_DIRECCION_CONDUCTOR = 'TR_DIRECCION_CONDUCTOR';
export const TR_FIRMA_CONDUCTOR = 'TR_FIRMA_CONDUCTOR';
export const TR_TITULO_DATOS_CONDUCTOR_DENUNCIADO_OBLIGATORIO = 'TR_TITULO_DATOS_CONDUCTOR_DENUNCIADO_OBLIGATORIO';
export const TR_NIF_CONDUCTOR_OBLIGATORIO = 'TR_NIF_CONDUCTOR_OBLIGATORIO';
export const TR_TIPO_CONDUCTOR_OBLIGATORIO = 'TR_TIPO_CONDUCTOR_OBLIGATORIO';
export const TR_NOMBRE_CONDUCTOR_OBLIGATORIO = 'TR_NOMBRE_CONDUCTOR_OBLIGATORIO';
export const TR_APELLIDOS_CONDUCTOR_OBLIGATORIO = 'TR_APELLIDOS_CONDUCTOR_OBLIGATORIO';
export const TR_DIRECCION_CONDUCTOR_OBLIGATORIO = 'TR_DIRECCION_CONDUCTOR_OBLIGATORIO';
export const TR_FIRMA_CONDUCTOR_OBLIGATORIO = 'TR_FIRMA_CONDUCTOR_OBLIGATORIO';
export const TR_CODIGO_DE_APLICACION_C60 = 'TR_CODIGO_DE_APLICACION_C60';
export const TR_CODIGO_DE_TIPO_DE_FORMATO_C60 = 'TR_CODIGO_DE_TIPO_DE_FORMATO_C60';
export const TR_CODIGO_DE_PROVINCIA_C60 = 'TR_CODIGO_DE_PROVINCIA_C60';
export const TR_CODIGO_DE_MUNICIPIO_C60 = 'TR_CODIGO_DE_MUNICIPIO_C60';
export const TR_DIGITO_DE_CONTROL_C60 = 'TR_DIGITO_DE_CONTROL_C60';
export const TR_CAMPO_OBLIGATORIO = 'TR_CAMPO_OBLIGATORIO';
export const TR_ETIQUETA_CODIGO_DE_BARRAS_C60 = 'TR_ETIQUETA_CODIGO_DE_BARRAS_C60';
export const TR_NO_HA_SIDO_POSIBLE_COMPROBAR_EN_MOVIKTIK_LOS_TICKETS_DE_ESTA_MATRICULA = 'TR_NO_HA_SIDO_POSIBLE_COMPROBAR_EN_MOVIKTIK_LOS_TICKETS_DE_ESTA_MATRICULA';
export const TR_ESTA_EL_CONDUCTOR_PRESENTE = 'TR_ESTA_EL_CONDUCTOR_PRESENTE';
export const TR_FIRMA_DEL_DENUNCIADO = 'TR_FIRMA_DEL_DENUNCIADO';
export const TR_LINTERNA = 'TR_LINTERNA';
export const TR_ZOOM = 'TR_ZOOM';
export const TR_TRIBUTO_CONCEPTO_O_GRAVAMEN = 'TR_TRIBUTO_CONCEPTO_O_GRAVAMEN';
export const TR_PLAZO_DIAS_PAGO = 'TR_PLAZO_DIAS_PAGO';
