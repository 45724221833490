import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Route, {RouteData} from "../../model/Route";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_YA_EXISTE_UN_ELEMENTO_SIMILAR} from "../../I18n/constants";

export default class TaskRouteEdit extends BaseGenericRequest<RouteData, TaskResponse<Route>> {

    private readonly data: RouteData;

    public constructor(routeId: string, routerData: RouteData) {
        super(METHOD.PUT, generateRoute(Urls.URL_ROUTE_EDIT, {uuid: routeId}));
        this.data = routerData;

        this.setErrorMessage(488, I18nUtils.tr(TR_YA_EXISTE_UN_ELEMENTO_SIMILAR));
    }

    protected getRequest(): RouteData {
        return this.data;
    }
}
