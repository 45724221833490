import ScreenCard from '../../../../components/screen/ScreenCard';
import I18nUtils from '../../../../commons/I18n/I18nUtils';
import { TR_ADVERACION } from '../../../../commons/I18n/constants';
import { ROUTE_COMPANY_USER_RATIFICATION_REPORTS } from '../../../../routing/Routes';
import { AppIcon } from '../../../../config/Config';
import RatificationReportListCompanyUserCard from './RatificationReportListCompanyUserCard';
import React from 'react';

export default class RatificationReportListCompanyUserScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_ADVERACION),
                    url: ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
                    icon: AppIcon.REPORT
                }
            ]}>
                <RatificationReportListCompanyUserCard/>
            </ScreenCard>
        );
    }
}
